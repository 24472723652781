import { useEffect } from "react";
import { SERVER_URL } from "../../../../config/config";
import { RouletteBetStatus } from "../../../../constants/enum/roulette";
import { SSE_HEADER } from "../../../../constants/sse";
import {
  parseFinishedBet,
  parseInitializedBetEvent,
} from "../../../../helper/roulette";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  setBetResult,
  updateBetStatus,
} from "../../../../reducers/roulette.slice";

const useRouletteSSE = (account: string) => {
  const dispatch = useAppDispatch();

  const { betResult, pastBetResults, currentRouletteId } = useAppSelector(
    (state) => state.roulette
  );

  // SSE
  useEffect(() => {
    const eventSource = new EventSource(`${SERVER_URL}/event/roulette`);

    eventSource.onmessage = (e) => {
      if (e.data.includes("welcome")) {
      } else if (e.data.includes("eventName")) {
        const event = JSON.parse(e.data);

        if (event.eventName === SSE_HEADER.ROULETTE.INITIALIZED_BET) {
          parseInitializedBetEvent(event.data);
        }

        if (event.eventName === SSE_HEADER.ROULETTE.FINISHED_BET) {
          const newBetResult = parseFinishedBet(event.data);

          if (betResult?.betId !== newBetResult.betId) {
            if (
              newBetResult?.player.toLowerCase() === account.toLowerCase() &&
              newBetResult.rouletteId === currentRouletteId
            ) {
              if (
                pastBetResults.find((bet) => bet.betId === newBetResult.betId)
              )
                return;

              dispatch(setBetResult(newBetResult));
              dispatch(updateBetStatus(RouletteBetStatus.SPINNING));
            }
          }
        }
      }
    };
    return () => {
      eventSource.close();
    };
  }, [dispatch, account, betResult, currentRouletteId, pastBetResults]);
};

export default useRouletteSSE;
