import {
  Box,
  Button,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import bulletIcon from "../../assets/imgs/inventory/bulletitem.png";
import heartIcon from "../../assets/imgs/inventory/healthitem.png";
import keyIcon from "../../assets/imgs/inventory/key.png";
import ogNFTIcon from "../../assets/imgs/inventory/OGNFTIMAGE.png";
import bulkIcon from "../../assets/imgs/inventory/product.png";
import stMafiaIcon from "../../assets/imgs/profile/stlogo.png";

import {
  City,
  CitySimple,
  CONFIRMATION_DELAY,
  ItemCategoryInfoList,
} from "../../constants/const";
import { InventoryType, ItemCategory } from "../../constants/enum/enum";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import { getInventoryItemInfo } from "../../helper/inventory";
import useWallet from "../../hook/useWallet";
import {
  bulkTransferInventoryItems,
  getInventoryItem,
} from "../../reducers/profile.slice";
import { ItemInfo } from "../../types/ItemInfo";
import {
  isEthereumAddress,
  isStartWith0x,
  shortAddress,
  toastSuccess,
  toUSDFormat,
} from "../../utils/utils";

import Dot from "../Dot";
import InventoryItemActionPopup from "../InventoryItemActionPopup";
import Logo from "../Logo";

import useStyles from "./index.styles";
import { BuildingTypeOptions, Rarity } from "../../constants/map";
import { BuildingType, RarityType, SlotType } from "../../constants/enum/map";

const ProfileInventoryMafia = ({
  isStaked,
  mafiaBalance,
}: {
  isStaked: boolean;
  mafiaBalance: number;
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Box className={classes.inventoryItemBox}>
        {isStaked ? (
          <Box
            component="img"
            src={stMafiaIcon}
            className={clsx(
              classes.inventoryItemIcon,
              classes.inventoryMafiaIcon
            )}
          ></Box>
        ) : (
          <Logo
            classNames={clsx(
              classes.inventoryItemIcon,
              classes.inventoryMafiaIcon
            )}
          />
        )}
      </Box>
      <Typography className={classes.inventoryItemName}>
        {toUSDFormat(mafiaBalance)}
      </Typography>
    </Box>
  );
};

const ProfileInventoryBullets = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Box className={classes.inventoryItemBox}>
        <Box
          className={clsx(
            classes.inventoryItemIcon,
            classes.inventoryCrateIcon
          )}
          component="img"
          src={bulletIcon}
        ></Box>
      </Box>
      <Typography className={classes.inventoryItemName}>0</Typography>
    </Box>
  );
};

const ProfileInventoryHearts = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Box className={classes.inventoryItemBox}>
        <Box
          className={clsx(
            classes.inventoryItemIcon,
            classes.inventoryCrateIcon
          )}
          component="img"
          src={heartIcon}
        ></Box>
      </Box>
      <Typography className={classes.inventoryItemName}>0</Typography>
    </Box>
  );
};

const ProfileInventoryCrate = ({
  setTransferItemInfo,
}: {
  setTransferItemInfo: () => void;
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Box className={classes.inventoryItemBox}>
        <Box
          className={clsx(
            classes.inventoryItemIcon,
            classes.inventoryCrateIcon
          )}
          component="img"
          src={keyIcon}
          onClick={setTransferItemInfo}
        ></Box>
      </Box>
      <Typography className={classes.inventoryItemName}>NFT Key</Typography>
    </Box>
  );
};

const ProfileInventoryOGNFT = ({
  setTransferItemInfo,
}: {
  setTransferItemInfo: () => void;
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Box className={classes.inventoryItemBox}>
        <Box
          className={clsx(classes.inventoryItemIcon, classes.inventoryOGIcon)}
          component="img"
          src={ogNFTIcon}
          onClick={setTransferItemInfo}
        ></Box>
      </Box>
      <Typography className={classes.inventoryItemName}>OG NFT</Typography>
    </Box>
  );
};

export const ProfileInventoryItem = ({
  item,
  onClick,
  selected = false,
}: {
  onClick: () => void;
  item: ItemInfo;
  selected?: boolean;
}) => {
  const { classes } = useStyles();

  const itemInfo = getInventoryItemInfo(item.categoryId, item.typeId);
  const { mySlotItems } = useAppSelector((state) => state.profile);
  const slotItem = mySlotItems.find(
    (slot) => slot.inventoryItemId === item.itemId
  );

  return (
    <Box className={classes.inventoryItem}>
      {item.categoryId === ItemCategory.LANDSLOT &&
      slotItem?.slotType === SlotType.UpgradableSlot ? (
        <Tooltip
          title={`#${CitySimple[slotItem.city]}-${slotItem.slotX}-${
            slotItem.slotY
          }`}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -50],
                  },
                },
              ],
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Box
              sx={{
                position: "relative",
              }}
              className={classes.inventoryItemBox}
            >
              <Box
                sx={{
                  position: "relative",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  className={classes.slotItemIcon}
                  component="img"
                  src={`/assets/buildings/${
                    BuildingTypeOptions[slotItem.slotSubType as BuildingType]
                      .logo
                  }`}
                  onClick={onClick}
                ></Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "8px",
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                    backgroundColor:
                      Rarity[slotItem.slotRarity as RarityType].color,
                    position: "absolute",
                    bottom: 0,
                  }}
                ></Box>
              </Box>
              {selected && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: 60,
                    height: 60,
                    boxShadow:
                      "inset 0 -3px 10px 0px #1976d2, inset 0 3px 10px 0px #1976d2",
                    pointerEvents: "none",
                  }}
                ></Box>
              )}
            </Box>

            <Typography className={classes.inventoryItemName}>
              {`${
                BuildingTypeOptions[slotItem.slotSubType as BuildingType].name
              } - ${City[slotItem.city]}`}
            </Typography>
          </Box>
        </Tooltip>
      ) : (
        <>
          <Box
            sx={{
              position: "relative",
            }}
            className={classes.inventoryItemBox}
          >
            <Box
              className={classes.inventoryItemIcon}
              component="img"
              src={itemInfo.itemIcon}
              onClick={onClick}
            ></Box>
            {selected && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: 60,
                  height: 60,
                  boxShadow:
                    "inset 0 -3px 10px 0px #1976d2, inset 0 3px 10px 0px #1976d2",
                  pointerEvents: "none",
                }}
              ></Box>
            )}

            {(item.categoryId === 8 || item.categoryId === 9) && (
              <Logo
                sx={{
                  position: "absolute",
                  width: 16,
                  height: 16,
                  top: 4,
                  left: 4,
                }}
              />
            )}
          </Box>

          <Typography className={classes.inventoryItemName}>
            {itemInfo.itemValue}
          </Typography>
        </>
      )}
    </Box>
  );
};

const ProfileInventoryItemSkeleton = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.inventoryItem}>
      <Skeleton
        height={56}
        width={62}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />

      <Skeleton
        height={24}
        width={62}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />
    </Box>
  );
};

const ProfileInventory = ({
  small,
  profileView,
  crateBalance,
  ogNFTBalance,
  inventoryType,
  isPrivate,
}: {
  small?: boolean;
  profileView?: boolean;
  crateBalance?: number;
  ogNFTBalance?: number;
  inventoryType?: InventoryType;
  isPrivate?: boolean;
}) => {
  const { classes } = useStyles();
  const { account } = useWallet();
  const dispatch = useAppDispatch();
  const {
    profile,
    myProfile,
    profileNames,
    inventoryItems,
    isLoadingInventoryItems,
  } = useAppSelector((state) => state.profile);

  const [inventoryWorth, setInventoryWorth] = useState(1000);
  const [openCrateOpeningPopup, setOpenCrateOpeningPopup] = useState(false);
  const [transferItemInfo, setTransferItemInfo] = useState<ItemInfo>({
    categoryId: -1,
    cityId: -1,
    typeId: 0,
    itemId: 0,
  });

  const [playerName, setPlayerName] = useState("");
  const [playerNameLabel, setPlayerNameLabel] = useState("");
  const [selectedItems, setSelectedItems] = useState<ItemInfo[]>([]);
  const [isBulkSelecting, setIsBulkSelecting] = useState(false);

  const randomItems = useMemo(() => {
    return [...new Array(16)].map((_, index) => {
      return {
        categoryId: Math.floor(Math.random() * 8),
        typeId: Math.floor(Math.random() * 8),
        cityId: -1,
        itemId: -1,
      } as ItemInfo;
    });
  }, []);

  useEffect(() => {
    const inventoryWorth = inventoryItems
      .map(
        (item) =>
          ItemCategoryInfoList[item.categoryId]?.inventoryWorth[item.typeId] ||
          0
      )
      .reduce((a, b) => a + b, 0);

    setInventoryWorth(inventoryWorth);
  }, [inventoryItems]);

  const handleOnClickItem = (item: ItemInfo) => {
    if (isBulkSelecting) {
      const indexInSelectedItems = selectedItems
        .map((item) => item.itemId)
        .indexOf(item.itemId);

      if (indexInSelectedItems >= 0)
        setSelectedItems((items) => {
          return [
            ...items.slice(0, indexInSelectedItems),
            ...items.slice(indexInSelectedItems + 1, items.length),
          ];
        });
      else {
        setSelectedItems((items) => [...items, item]);
      }
    } else {
      setTransferItemInfo(item);
      setOpenCrateOpeningPopup(true);
    }
  };

  const handleConfirmBulkTransfer = () => {
    if (selectedItems.length === 0) return;

    const profile = profileNames.find(
      (profileName, index) =>
        profileName.name === playerName || profileName.address === playerName
    );

    if (profile && profile.address) {
      if (!isStartWith0x(playerName)) {
        const itemIds = selectedItems.map((itemInfo) => itemInfo.itemId);

        dispatchTxAction(
          dispatch,
          bulkTransferInventoryItems({
            account,
            itemIds,
            to: profile.address,
          }),
          () => {
            setSelectedItems([]);
            toastSuccess(Messages.FAMILY.INVENTORY.TRANSFERRED_SUCCESS);
            if (myProfile.id)
              dispatch(getInventoryItem({ userId: myProfile.id }));
          },
          CONFIRMATION_DELAY * 2,
          Errors.FAMILY.INVENTORY.TRANSFER_FAILED
        );
      }
    }
  };

  const handlePlayerNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const playerName = e.target.value;
    setPlayerName(playerName);

    if (isStartWith0x(playerName)) {
      if (isEthereumAddress(playerName)) {
        const profile = profileNames.find(
          (profileName, index) => profileName.address === playerName
        );

        if (profile) {
          setPlayerNameLabel(profile.name || "");
        } else {
          setPlayerNameLabel("No profile exist.");
        }
      } else {
        setPlayerNameLabel("Invalid address.");
      }
    } else {
      const profile = profileNames.find(
        (profileName, index) => profileName.name === playerName
      );

      if (profile) {
        setPlayerNameLabel(shortAddress(profile.address || ""));
      } else {
        setPlayerNameLabel("No profile exist.");
      }
    }
  };

  const renderProfileInventory = () => {
    const renderItems = () => {
      return (
        <>
          {[...inventoryItems]
            .filter((item) => item.categoryId !== ItemCategory.LANDSLOT)
            .sort((item1, item2) => {
              const item1Category = ItemCategoryInfoList.find(
                (category) => category.id === item1.categoryId
              );

              const item2Category = ItemCategoryInfoList.find(
                (category) => category.id === item2.categoryId
              );

              if (!item1Category || !item2Category) return 1;

              return (
                item1Category.inventoryWorth[item2.typeId] -
                  item1Category.inventoryWorth[item1.typeId] ||
                item1.categoryId - item2.categoryId
              );
            })
            .map((item, index) => {
              const indexInSelectedItems = selectedItems
                .map((item) => item.itemId)
                .indexOf(item.itemId);

              return (
                <ProfileInventoryItem
                  onClick={() => {
                    handleOnClickItem(item);
                  }}
                  item={item}
                  key={index}
                  selected={isBulkSelecting && indexInSelectedItems >= 0}
                />
              );
            })}
        </>
      );
    };

    const renderNFTs = () => {
      return (
        <>
          <ProfileInventoryMafia
            isStaked={true}
            mafiaBalance={profile.stMafia || 0}
          />

          <ProfileInventoryMafia
            isStaked={false}
            mafiaBalance={profile.mafia || 0}
          />

          <ProfileInventoryBullets />
          <ProfileInventoryHearts />

          {[...new Array(ogNFTBalance)].map((item, index) => {
            return (
              <ProfileInventoryOGNFT
                setTransferItemInfo={() => {
                  const ogNFT: ItemInfo = {
                    categoryId: -2, // OG NFT
                    cityId: -1,
                    typeId: 0,
                    itemId: 0,
                  };
                  setTransferItemInfo(ogNFT);
                  setOpenCrateOpeningPopup(true);
                }}
                key={index}
              />
            );
          })}

          {[...new Array(crateBalance)].map((item, index) => {
            return (
              <ProfileInventoryCrate
                setTransferItemInfo={() => {
                  const crate: ItemInfo = {
                    categoryId: -1,
                    cityId: -1,
                    typeId: 0,
                    itemId: 0,
                  };
                  setTransferItemInfo(crate);
                  setOpenCrateOpeningPopup(true);
                }}
                key={index}
              />
            );
          })}
        </>
      );
    };

    const renderPrivateItems = () => {
      return randomItems.map((item, index) => {
        return (
          <ProfileInventoryItem
            onClick={() => {
              handleOnClickItem(item);
            }}
            item={item}
            key={index}
          />
        );
      });
    };

    return (
      <Box className={classes.inventoryCard}>
        <Box className={classes.cardHeader}>
          {inventoryType === "NFTs" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Typography className={classes.cardHeaderTitle}>
                  Wallet Inventory
                </Typography>

                <Box className={classes.cardHeaderBadgeSafe}>
                  <Box>SAFE</Box>
                  <Dot width={10} color="#50b828" isShadow={true} />
                </Box>
              </Box>
            </>
          ) : inventoryType === "Items" ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className={classes.cardHeaderTitle}>
                    Game Inventory
                  </Typography>
                  <Box className={classes.cardHeaderBadgeDanger}>
                    <Box>AT RISK</Box>
                    <Dot width={10} color="red" isShadow={true} />
                  </Box>
                </Box>
                {profile.id === myProfile.id && (
                  <Box>
                    <Button
                      endIcon={
                        <Box
                          component="img"
                          src={bulkIcon}
                          sx={{
                            width: 16,
                            height: 16,
                          }}
                        ></Box>
                      }
                      sx={{
                        color: "#646464",
                        fontFamily: "Philosopher",
                        textTransform: "none",
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setIsBulkSelecting((prev) => !prev);
                      }}
                    >
                      Bulk Transfer
                    </Button>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "24.5px!important",
                  alignItems: "center",
                  gap: { xs: "16px", md: "32px" },
                }}
              >
                <Typography className={classes.cardHeaderWorth}>
                  Estimated worth: {inventoryWorth}
                </Typography>

                {isBulkSelecting && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "4px",
                    }}
                  >
                    <Typography className={classes.cardHeaderWorth}>
                      {selectedItems.length} Items
                    </Typography>

                    <Box
                      sx={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        placeholder="Player Name"
                        variant="standard"
                        className={classes.inputBox}
                        value={playerName}
                        onChange={handlePlayerNameChange}
                        InputProps={{
                          disableUnderline: true, // Disable the underline
                        }}
                        autoComplete="off"
                        sx={{
                          input: {
                            "&::placeholder": {
                              opacity: 1,
                            },
                          },
                        }}
                      />

                      <Box
                        sx={{
                          position: "absolute",
                        }}
                        className={classes.playerNameLabel}
                      >
                        {playerNameLabel}
                      </Box>
                    </Box>

                    <Button
                      className={clsx(classes.confirmButton)}
                      onClick={handleConfirmBulkTransfer}
                    >
                      Confirm
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <>
              <Typography className={classes.cardHeaderTitle}>
                Your Inventory
              </Typography>
              <Typography className={classes.cardHeaderWorth}>
                {crateBalance} Key NFT, {ogNFTBalance} OG NFT, Estimated worth:{" "}
                {inventoryWorth}
              </Typography>
            </>
          )}
        </Box>

        <Box
          className={
            isPrivate
              ? clsx(classes.cardList, classes.private)
              : classes.cardList
          }
        >
          {isLoadingInventoryItems ? (
            [...new Array(small ? 21 : 30)].map((item, index) => {
              return <ProfileInventoryItemSkeleton key={index} />;
            })
          ) : inventoryType === "Items" ? (
            isPrivate ? (
              renderPrivateItems()
            ) : (
              renderItems()
            )
          ) : inventoryType === "NFTs" ? (
            renderNFTs()
          ) : (
            <>
              {renderItems()}
              {renderNFTs()}
            </>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.body}>
      <Box
        className={classes.container}
        sx={{
          width: small ? "100%!important" : "920px",
        }}
      >
        {renderProfileInventory()}

        {isPrivate && (
          <Typography
            fontFamily="Philosopher"
            fontSize={20}
            color={"white"}
            fontWeight={700}
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Inventory Private
          </Typography>
        )}
      </Box>

      {(!profileView || (profileView && profile.id === myProfile.id)) && (
        <InventoryItemActionPopup
          itemInfo={transferItemInfo}
          openItemTransferPopup={openCrateOpeningPopup}
          setOpenCrateOpeningPopup={setOpenCrateOpeningPopup}
          type="inventory"
        />
      )}
    </Box>
  );
};

export default ProfileInventory;
