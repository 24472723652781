import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, TextField } from "@mui/material";
import clsx from "clsx";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import {
  City,
  CitySimple,
  CONFIRMATION_DELAY,
  ItemCategoryInfoList,
  PREMIUM_KEY_CLAIM_CYCLE,
} from "../../constants/const";
import useWallet from "../../hook/useWallet";

import {
  approveAllCrate,
  approveAllOGNFT,
  buyPremiumAction,
  getCrateBalance,
  getInventoryItem,
  getOgNFTBalance,
  transferInventoryItem,
  transferNFT,
  unwrapKeyItemAction,
  unwrapMafiaItemAction,
  unwrapOGCrateAction,
  unwrapOGNFTAction,
  unwrapOGNFTItemAction,
  wrapOGCrateAction,
  wrapOGNFTAction,
} from "../../reducers/profile.slice";

import { ItemInfo } from "../../types/ItemInfo";
import {
  convertDateTimeSimple,
  isEthereumAddress,
  isStartWith0x,
  shortAddress,
  toastError,
  toastInfo,
  toastSuccess,
} from "../../utils/utils";

import { isOGNFTApprovedForAll } from "../../helper/contractFunctions/OGNFT";
import { isCrateApprovedForAll } from "../../helper/contractFunctions/OgCrate";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import { getInventoryItemName } from "../../helper/inventory";

import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";

import useStyles from "./index.styles";
import useCurrentTime from "../../hook/useCurrentTime";
import { BuildingType, BusinessType, SlotType } from "../../constants/enum/map";
import { BuildingTypeOptions, BusinessOptions } from "../../constants/map";
import { goToMapSlotPage } from "../../helper/map";

interface InventoryItemActionPopupProps {
  itemInfo: ItemInfo;
  openItemTransferPopup: boolean;
  setOpenCrateOpeningPopup: React.Dispatch<React.SetStateAction<boolean>>;
  type: "landslot" | "inventory";
}

const InventoryItemActionPopup: React.FC<InventoryItemActionPopupProps> = ({
  itemInfo,
  openItemTransferPopup,
  setOpenCrateOpeningPopup,
  type,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { account } = useWallet();
  const navigate = useNavigate();
  const currentTime = useCurrentTime();

  const {
    myProfile,
    profileNames,
    crateBalance,
    ogNFTBalance,
    premiumInfo,
    slotItems,
  } = useAppSelector((state) => state.profile);

  const [playerName, setPlayerName] = useState("");
  const [itemCount, setItemCount] = useState(1);
  const [label, setLabel] = useState("Input player name");

  const getTargetItem = useCallback(() => {
    const info = slotItems.find(
      (item) => item.inventoryItemId === itemInfo?.inventoryId
    );

    if (!info) return null;

    if (info.slotType === SlotType.Business) {
      return {
        detail: info,
        info: BusinessOptions[info.slotSubType as BusinessType],
      };
    }

    if (info.slotType === SlotType.UpgradableSlot) {
      return {
        detail: info,
        info: BuildingTypeOptions[info.slotSubType as BuildingType],
      };
    }

    return null;
  }, [itemInfo.inventoryId, slotItems]);

  const renderItemTitleDisplay = () => {
    if (type === "landslot") {
      const targetItem = getTargetItem();
      return targetItem
        ? `${targetItem.info.name} - ${City[itemInfo.cityId]}`
        : "";
    }

    switch (itemInfo.categoryId) {
      case -1:
        return "Key";
      case -2:
        return "OG NFT";
      default:
        return selectedCategory?.name || "";
    }
  };

  const renderSubTitleDisplay = () => {
    if (type === "landslot") {
      const targetItem = getTargetItem();

      return targetItem
        ? `#${CitySimple[itemInfo.cityId]}-${targetItem.detail.slotX}-${
            targetItem.detail.slotY
          }`
        : "";
    }

    if (itemInfo.categoryId === -1 || itemInfo.categoryId === -2) return "";

    return getInventoryItemName(
      itemInfo.categoryId,
      itemInfo.typeId,
      itemInfo.cityId
    );
  };

  const getItemImage = () => {
    if (type === "landslot") {
      const targetItem = getTargetItem();

      return targetItem ? `/assets/buildings/${targetItem.info.logo}` : "";
    }

    let inventory = "/assets/imgs/inventory/";
    if (itemInfo.categoryId === -1) {
      inventory += "key.png";
    } else if (itemInfo.categoryId === -2) {
      inventory += "OGNFTIMAGE.png";
    } else if (typeof selectedCategory?.values[0] === "number") {
      inventory += selectedCategory.icon;
    } else {
      inventory += selectedCategory?.icons[selectedItemId];
    }

    return inventory;
  };

  const handleClose = () => {
    setOpenCrateOpeningPopup(false);
  };

  const handleOnChangeName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const playerName = e.target.value;
    setPlayerName(playerName);

    if (isStartWith0x(playerName)) {
      if (isEthereumAddress(playerName)) {
        const profile = profileNames.find(
          (profileName, index) => profileName.address === playerName
        );

        if (profile) {
          setLabel(profile.name || "");
        } else {
          setLabel("No profile exist.");
        }
      } else {
        setLabel("Invalid address.");
      }
    } else {
      const profile = profileNames.find(
        (profileName, index) => profileName.name === playerName
      );

      if (profile) {
        setLabel(shortAddress(profile.address || ""));
      } else {
        setLabel("");
      }
    }
  };

  const handleOnChangeCrateCount = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!e.target.value) setItemCount(0);
    else setItemCount(parseInt(e.target.value));
  };

  const handleConfirmTransfer = () => {
    const profile = profileNames.find(
      (profileName, index) =>
        profileName.name === playerName || profileName.address === playerName
    );

    if (profile && profile.address) {
      if (!isStartWith0x(playerName))
        setLabel(shortAddress(profile.address || ""));

      if (itemInfo) {
        if (itemInfo.categoryId >= 0) {
          dispatchTxAction(
            dispatch,
            transferInventoryItem({
              account,
              itemId: itemInfo?.itemId || 0,
              to: profile.address,
            }),
            () => {
              toastSuccess(Messages.FAMILY.INVENTORY.TRANSFERRED_SUCCESS);

              if (myProfile.id)
                dispatch(getInventoryItem({ userId: myProfile.id }));

              handleClose();
            },
            0,
            Errors.FAMILY.INVENTORY.TRANSFER_FAILED
          );
        } else if (itemInfo.categoryId === -1) {
          if (crateBalance < itemCount) {
            toastInfo(Messages.FAMILY.INVENTORY.NOT_ENOUGH, {
              crateBalance: crateBalance.toFixed(0),
            });
            return;
          }

          if (itemCount === 0) {
            toastError(Errors.FAMILY.INVENTORY.AMOUNT_INVALID);
            return;
          }

          dispatchTxAction(
            dispatch,
            transferNFT({
              account,
              amount: itemCount,
              to: profile.address,
              contractAddress: config.ogCrateAddress,
            }),
            () => {
              toastSuccess(Messages.FAMILY.INVENTORY.NFT_TRANSFERRED_SUCCESS);
              handleClose();
              dispatch(getCrateBalance({ account: myProfile.address || "" }));
            },
            CONFIRMATION_DELAY
          );
        } else if (itemInfo.categoryId === -2) {
          if (ogNFTBalance < itemCount) {
            toastError(Errors.FAMILY.INVENTORY.NOT_ENOUGH_NFT, {
              ogNFTBalance: ogNFTBalance.toString(),
            });
            return;
          }

          if (itemCount === 0) {
            toastError(Errors.FAMILY.INVENTORY.NFT_AMOUNT_INVALID);
            return;
          }

          dispatchTxAction(
            dispatch,
            transferNFT({
              account,
              amount: itemCount,
              to: profile.address,
              contractAddress: config.ogNFTAddress,
            }),
            () => {
              toastSuccess(
                Messages.FAMILY.INVENTORY.OG_NFT_TRANSFERRED_SUCCESS
              );
              handleClose();
              dispatch(getOgNFTBalance({ account: myProfile.address || "" }));
            },
            CONFIRMATION_DELAY
          );
        }
      }
    } else {
      if (isStartWith0x(playerName))
        setLabel(`No player exist with the address.`);
      else setLabel(`No player exist with the name.`);
    }
  };

  const handleWrapItem = async () => {
    if (myProfile.id && myProfile.address) {
      if (itemInfo.categoryId === -1) {
        const isApproved = await isCrateApprovedForAll(
          myProfile.address,
          config.inventoryAddress
        );

        const wrapOGCrate = () => {
          dispatchTxAction(dispatch, wrapOGCrateAction({ account }), () => {
            if (myProfile.id && myProfile.address) {
              toastSuccess(Messages.FAMILY.INVENTORY.DEPOSITED_NFT_SUCCESS);
              dispatch(getInventoryItem({ userId: myProfile.id }));
              dispatch(getCrateBalance({ account: myProfile.address }));
              handleClose();
            }
          });
        };

        const approveOGCrate = () => {
          dispatchTxAction(
            dispatch,
            approveAllCrate({
              spender: config.inventoryAddress,
              account,
            }),
            () => {
              wrapOGCrate();
            },
            CONFIRMATION_DELAY
          );
        };

        if (isApproved) {
          wrapOGCrate();
        } else {
          approveOGCrate();
        }
      } else if (itemInfo.categoryId === -2) {
        const isApproved = await isOGNFTApprovedForAll(
          myProfile.address,
          config.inventoryAddress
        );

        const wrapOGNFT = () => {
          dispatchTxAction(dispatch, wrapOGNFTAction({ account }), () => {
            toastSuccess(Messages.FAMILY.INVENTORY.DEPOSITED_OG_NFT_SUCCESS);

            if (myProfile.id && myProfile.address) {
              dispatch(getInventoryItem({ userId: myProfile.id }));
              dispatch(getOgNFTBalance({ account: myProfile.address }));
              handleClose();
            }
          });
        };

        const approveOGNFT = () => {
          dispatchTxAction(
            dispatch,
            approveAllOGNFT({ address: config.inventoryAddress, account }),
            () => {
              wrapOGNFT();
            },
            CONFIRMATION_DELAY
          );
        };

        if (isApproved) {
          wrapOGNFT();
        } else {
          approveOGNFT();
        }
      }
    }
  };

  const handleUnwrapItem = () => {
    if (itemInfo.categoryId === 8 && itemInfo.typeId === 0) {
      dispatchTxAction(
        dispatch,
        unwrapOGCrateAction({ account, itemId: itemInfo?.itemId }),
        () => {
          if (myProfile.id && myProfile.address) {
            toastSuccess(Messages.FAMILY.INVENTORY.WITHDRAW_NFT);
            dispatch(getInventoryItem({ userId: myProfile.id }));
            dispatch(getCrateBalance({ account: myProfile.address }));
            handleClose();
          }
        }
      );
    } else if (itemInfo.categoryId === 8 && itemInfo.typeId === 1) {
      dispatchTxAction(
        dispatch,
        unwrapOGNFTAction({ account, itemId: itemInfo?.itemId }),
        () => {
          toastSuccess(Messages.FAMILY.INVENTORY.WITHDRAW_OG_NFT);

          if (myProfile.id && myProfile.address) {
            dispatch(getInventoryItem({ userId: myProfile.id }));
            dispatch(getOgNFTBalance({ account: myProfile.address }));
            handleClose();
          }
        }
      );
    } else if (itemInfo.categoryId === 9) {
      dispatchTxAction(
        dispatch,
        unwrapKeyItemAction({ account, itemId: itemInfo?.itemId }),
        () => {
          toastSuccess(Messages.FAMILY.INVENTORY.UNWRAP_GAMEKEY_ITEM_SUCCESS);

          if (myProfile.id && myProfile.address) {
            dispatch(getInventoryItem({ userId: myProfile.id }));
            dispatch(getCrateBalance({ account: myProfile.address }));
            handleClose();
          }
        }
      );
    } else if (itemInfo.categoryId === 10) {
      dispatchTxAction(
        dispatch,
        unwrapMafiaItemAction({ account, itemId: itemInfo?.itemId }),
        () => {
          toastSuccess(Messages.FAMILY.INVENTORY.UNWRAP_MAFIA_ITEM_SUCCESS);

          if (myProfile.id && myProfile.address) {
            dispatch(getInventoryItem({ userId: myProfile.id }));
            handleClose();
          }
        }
      );
    } else if (itemInfo.categoryId === 11) {
      dispatchTxAction(
        dispatch,
        unwrapOGNFTItemAction({ account, itemId: itemInfo?.itemId }),
        () => {
          toastSuccess(Messages.FAMILY.INVENTORY.UNWRAP_OG_NFT_SUCCESS);

          if (myProfile.id && myProfile.address) {
            dispatch(getInventoryItem({ userId: myProfile.id }));
            dispatch(getOgNFTBalance({ account: myProfile.address }));
            handleClose();
          }
        }
      );
    }
  };

  const handleBuyPremium = async () => {
    if (myProfile.id && myProfile.address) {
      if (itemInfo.categoryId === -2) {
        const isApproved = await isOGNFTApprovedForAll(
          myProfile.address,
          config.ogPremiumAddress
        );

        const buyPremium = () => {
          dispatchTxAction(
            dispatch,
            buyPremiumAction({ account }),
            () => {
              toastSuccess(Messages.FAMILY.INVENTORY.BOUGHT_PREMIUM);
              if (myProfile.id && myProfile.address) {
                dispatch(getOgNFTBalance({ account: myProfile.address }));
                handleClose();
              }
            },
            CONFIRMATION_DELAY
          );
        };

        const approveOGNFT = () => {
          dispatchTxAction(
            dispatch,
            approveAllOGNFT({ account, address: config.ogPremiumAddress }),
            () => {
              buyPremium();
            },
            CONFIRMATION_DELAY
          );
        };

        if (isApproved) {
          buyPremium();
        } else {
          approveOGNFT();
        }
      }
    }
  };

  const handleSellItem = () => {
    navigate(`/market?listItem=${itemInfo?.itemId || "0"}`);
  };

  const handleViewItem = () => {
    navigate(`/market/item/${itemInfo.categoryId * 10 + itemInfo.typeId}`);
  };

  const handleToMap = (
    slotX: number | undefined,
    slotY: number | undefined,
    city: number | undefined
  ) => {
    if (city === undefined || slotX === undefined || slotY === undefined)
      return;
    goToMapSlotPage(city, slotX, slotY);
  };

  const selectedCategory =
    itemInfo.categoryId >= 0
      ? ItemCategoryInfoList[itemInfo?.categoryId || 0]
      : undefined;
  const selectedItemId = itemInfo?.typeId || 0;

  const premiumEnabled = useMemo(() => {
    return (
      myProfile.premium === 1 &&
      premiumInfo.userInfo.startedAt + PREMIUM_KEY_CLAIM_CYCLE * 3 > currentTime
    );
  }, [currentTime, premiumInfo.userInfo.startedAt, myProfile.premium]);

  return (
    <Dialog
      open={openItemTransferPopup}
      onClose={handleClose}
      className={classes.body}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Box className={classes.itemInfo}>
            <Box className={classes.itemName}>{renderItemTitleDisplay()}</Box>
            <Box
              className={clsx(
                classes.itemIcon,
                type === "inventory" ? classes.itemInventory : classes.itemLand
              )}
            >
              <Box component="img" src={getItemImage()}></Box>
            </Box>

            <Box className={classes.itemDescription}>
              {renderSubTitleDisplay()}
            </Box>
          </Box>

          <Box className={classes.itemActions}>
            {itemInfo?.categoryId >= 0 && (
              <Button
                onClick={
                  type === "landslot"
                    ? () =>
                        handleToMap(
                          getTargetItem()?.detail.slotX,
                          getTargetItem()?.detail.slotY,
                          getTargetItem()?.detail.city
                        )
                    : () => handleViewItem()
                }
                className={clsx(
                  classes.viewItemButton,
                  classes.itemActionButton
                )}
              >
                {type === "landslot" ? "View on Map" : "View Item on Market"}
              </Button>
            )}

            {itemInfo?.categoryId >= 0 && (
              <Button
                onClick={handleSellItem}
                className={clsx(
                  classes.sellItemButton,
                  classes.itemActionButton
                )}
              >
                Sell item
              </Button>
            )}
            {(itemInfo?.categoryId === 8 ||
              itemInfo?.categoryId === 9 ||
              itemInfo?.categoryId === 10 ||
              itemInfo?.categoryId === 11) && (
              <Button
                onClick={handleUnwrapItem}
                className={clsx(
                  classes.wrapItemButton,
                  classes.itemActionButton
                )}
              >
                Withdraw from game
              </Button>
            )}
            {itemInfo?.categoryId < 0 && (
              <Button
                onClick={handleWrapItem}
                className={clsx(
                  classes.wrapItemButton,
                  classes.itemActionButton
                )}
              >
                Deposit into game
              </Button>
            )}
            {itemInfo.categoryId === -2 && (
              <Button
                onClick={handleBuyPremium}
                className={clsx(
                  classes.useItemButton,
                  classes.itemActionButton
                )}
                disabled={premiumEnabled}
              >
                {!premiumEnabled
                  ? "Activate Premium+"
                  : "Expire after " +
                    convertDateTimeSimple(
                      (myProfile.premiumExpire || 0) -
                        new Date().getTime() / 1000
                    )}
              </Button>
            )}
          </Box>

          <Box className={classes.confirmSection}>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Box className={classes.confirmDescription}>Transfer item:</Box>
                <TextField
                  placeholder="Input player name"
                  variant="standard"
                  className={classes.inputBox}
                  InputProps={{
                    disableUnderline: true, // Disable the underline
                  }}
                  value={playerName}
                  onChange={handleOnChangeName}
                  autoComplete="off"
                  sx={{
                    input: {
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                  }}
                />
              </Box>

              {itemInfo && itemInfo.categoryId < 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    width: 80,
                    alignItems: "center",
                  }}
                >
                  <Box className={classes.confirmDescription}>Amount:</Box>
                  <TextField
                    variant="standard"
                    className={clsx(classes.inputBox, classes.smallInputBox)}
                    InputProps={{
                      disableUnderline: true, // Disable the underline
                    }}
                    value={itemCount}
                    onChange={handleOnChangeCrateCount}
                    autoComplete="off"
                    sx={{
                      input: {
                        "&::placeholder": {
                          opacity: 1,
                        },
                      },
                    }}
                  />
                </Box>
              )}
            </Box>

            <label>{label}</label>

            <Button
              onClick={handleConfirmTransfer}
              className={clsx(classes.confirmButton, classes.itemActionButton)}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default InventoryItemActionPopup;
