import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  approveMafiaToVote,
  cancelProposal,
  closeProposal,
  createProposal,
  editDelivery,
  getIsAdmin,
  getProposals,
  getUserVotes,
  openProposal,
  voteOnProposal,
  withdrawVoteToken,
} from "../helper/contractFunctions/MafiaMarketingProposal";
import {
  parseProposalOptions,
  parseProposals,
  parseProposalUserVotes,
  parseProposalVotes,
} from "../helper/dao";
import { Proposal } from "../types/Contract/Dao/Proposal";
import { ProposalOption } from "../types/Contract/Dao/ProposalOption";
import { ProposalVote } from "../types/Contract/Dao/ProposalVote";

export interface DaoState {
  isGettingProposals: boolean;
  isCreatingProposal: boolean;
  isVotingProposal: boolean;
  isOpeningProposal: boolean;
  isClosingProposal: boolean;
  isWithdrawingProposal: boolean;
  isAdmin: boolean;

  proposals: Proposal[];
  options: ProposalOption[][];
  votes: number[][];
  userVotes: ProposalVote[];
  completedIds: number[];

  targetOption: {
    roundId: number;
    optionId: number;
  };

  manageRoundId: number;
}

const initialState: DaoState = {
  isGettingProposals: false,
  isCreatingProposal: false,
  isVotingProposal: false,
  isOpeningProposal: false,
  isClosingProposal: false,
  isWithdrawingProposal: false,
  isAdmin: false,

  proposals: [],
  options: [],
  votes: [],
  userVotes: [],
  completedIds: [],

  targetOption: {
    roundId: -1,
    optionId: -1,
  },

  manageRoundId: -1,
};

export const createProposalAction = createAsyncThunk(
  "dao/createProposalAction",
  async ({
    duration,
    options,
    account,
  }: {
    duration: number;
    options: ProposalOption[];
    account: string;
  }) => {
    const result = await createProposal(duration, options, account);

    return result.transactionHash;
  }
);

export const openProposalAction = createAsyncThunk(
  "dao/openProposalAction",
  async ({ id, account }: { id: number; account: string }) => {
    const result = await openProposal(id, account);

    return result.transactionHash;
  }
);

export const closeProposalAction = createAsyncThunk(
  "dao/closeProposalAction",
  async ({ id, account }: { id: number; account: string }) => {
    const result = await closeProposal(id, account);

    return result.transactionHash;
  }
);

export const getProposalsAction = createAsyncThunk(
  "dao/getProposalsAction",
  async () => {
    const result = await getProposals();
    const parsedProposals = parseProposals(result);
    const parsedOptions = parseProposalOptions(result);
    const parsedVotes = parseProposalVotes(result);
    return {
      proposals: parsedProposals,
      options: parsedOptions,
      votes: parsedVotes,
    };
  }
);

export const getIsAdminAction = createAsyncThunk(
  "dao/getIsAdminAction",
  async ({ account }: { account: string }) => {
    const result = await getIsAdmin(account);
    return result;
  }
);

export const voteOnProposalAction = createAsyncThunk(
  "dao/voteOnProposalAction",
  async ({
    id,
    optionId,
    amount,
    account,
  }: {
    id: number;
    optionId: number;
    amount: number;
    account: string;
  }) => {
    const result = await voteOnProposal(id, optionId, amount, account);

    return result.transactionHash;
  }
);

export const approveMafiaToVoteAction = createAsyncThunk(
  "dao/approveMafiaToVoteAction",
  async ({ account, amount }: { account: string; amount: number }) => {
    await approveMafiaToVote(account, amount);
  }
);

export const getUserVoteAction = createAsyncThunk(
  "dao/getUserVoteAction",
  async ({
    signature,
    signMsg,
    account,
    completedIds,
  }: {
    signature: string;
    signMsg: string;
    account: string;
    completedIds: number[];
  }) => {
    const data = await getUserVotes(signature, signMsg, account, completedIds);
    const parsedData = parseProposalUserVotes(data);
    return parsedData;
  }
);

export const withdrawVotesTokenAction = createAsyncThunk(
  "dao/withdrawVoteToken",
  async ({ roundId, account }: { roundId: number; account: string }) => {
    const result = await withdrawVoteToken(roundId, account);
    return result.transactionHash;
  }
);

export const handleEditDaoAction = createAsyncThunk(
  "dao/handleEditDao",
  async ({
    account,
    optionIds,
    links,
    roundId,
  }: {
    account: string;
    optionIds: number[];
    links: string[];
    roundId: number;
  }) => {
    const result = await editDelivery(account, optionIds, links, roundId);
    return result.transactionHash;
  }
);

export const handleCancelProposalAction = createAsyncThunk(
  "dao/handleCancelProposalAction",
  async ({ account, roundId }: { account: string; roundId: number }) => {
    const result = await cancelProposal(account, roundId);
    return result.transactionHash;
  }
);

export const daoSlice = createSlice({
  name: "dao",
  initialState,
  reducers: {
    createProposal: (state) => {
      state.isCreatingProposal = true;
    },
    voteProposal: (state) => {
      state.isVotingProposal = true;
    },
    openProposal: (state) => {
      state.isOpeningProposal = true;
    },
    closeProposal: (state) => {
      state.isClosingProposal = true;
    },
    setTargetOption: (
      state,
      { payload }: { payload: { roundId: number; optionId: number } }
    ) => {
      state.targetOption = payload;
    },
    setManageRoundId: (state, { payload }: { payload: number }) => {
      state.manageRoundId = payload;
    },
    setCompletedIds: (state, { payload }: { payload: number[] }) => {
      state.completedIds = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createProposalAction.pending, (state) => {
      state.isCreatingProposal = true;
    });
    builder.addCase(createProposalAction.rejected, (state) => {
      state.isCreatingProposal = false;
    });
    builder.addCase(createProposalAction.fulfilled, (state) => {
      state.isCreatingProposal = false;
    });

    builder.addCase(openProposalAction.pending, (state) => {
      state.isOpeningProposal = true;
    });
    builder.addCase(openProposalAction.rejected, (state) => {
      state.isOpeningProposal = false;
    });
    builder.addCase(openProposalAction.fulfilled, (state) => {
      state.isOpeningProposal = false;
    });

    builder.addCase(voteOnProposalAction.pending, (state) => {
      state.isVotingProposal = true;
    });
    builder.addCase(voteOnProposalAction.rejected, (state) => {
      state.isVotingProposal = false;
    });
    builder.addCase(voteOnProposalAction.fulfilled, (state) => {
      state.isVotingProposal = false;
    });

    builder.addCase(approveMafiaToVoteAction.pending, (state) => {
      state.isVotingProposal = true;
    });
    builder.addCase(approveMafiaToVoteAction.rejected, (state) => {
      state.isVotingProposal = false;
    });
    builder.addCase(approveMafiaToVoteAction.fulfilled, (state) => {
      state.isVotingProposal = false;
    });

    builder.addCase(getProposalsAction.pending, (state) => {
      state.isGettingProposals = true;
    });
    builder.addCase(getProposalsAction.rejected, (state, { error }) => {
      state.isGettingProposals = false;
    });
    builder.addCase(getProposalsAction.fulfilled, (state, { payload }) => {
      state.isGettingProposals = false;

      state.proposals = payload.proposals;
      state.options = payload.options;
      state.votes = payload.votes;
    });

    builder.addCase(getIsAdminAction.pending, (state) => {
      state.isAdmin = false;
    });
    builder.addCase(getIsAdminAction.fulfilled, (state, { payload }) => {
      state.isAdmin = payload;
    });
    builder.addCase(getIsAdminAction.rejected, (state, { error }) => {
      state.isAdmin = false;
    });

    builder.addCase(closeProposalAction.pending, (state) => {
      state.isClosingProposal = true;
    });
    builder.addCase(closeProposalAction.fulfilled, (state, { payload }) => {
      state.isClosingProposal = false;
    });
    builder.addCase(closeProposalAction.rejected, (state, { error }) => {
      state.isClosingProposal = false;
    });

    builder.addCase(getUserVoteAction.pending, (state) => {});
    builder.addCase(getUserVoteAction.fulfilled, (state, { payload }) => {
      state.userVotes = payload;
    });
    builder.addCase(getUserVoteAction.rejected, (state, { error }) => {});

    builder.addCase(withdrawVotesTokenAction.pending, (state) => {
      state.isWithdrawingProposal = true;
    });
    builder.addCase(
      withdrawVotesTokenAction.fulfilled,
      (state, { payload }) => {
        state.isWithdrawingProposal = false;
      }
    );
    builder.addCase(withdrawVotesTokenAction.rejected, (state, { error }) => {
      state.isWithdrawingProposal = false;
    });

    builder.addCase(handleEditDaoAction.pending, (state) => {
      state.isCreatingProposal = true;
    });
    builder.addCase(handleEditDaoAction.fulfilled, (state, { payload }) => {
      state.isCreatingProposal = false;
    });
    builder.addCase(handleEditDaoAction.rejected, (state, { error }) => {
      state.isCreatingProposal = false;
    });

    builder.addCase(handleCancelProposalAction.pending, (state) => {
      state.isCreatingProposal = true;
    });
    builder.addCase(
      handleCancelProposalAction.fulfilled,
      (state, { payload }) => {
        state.isCreatingProposal = false;
      }
    );
    builder.addCase(handleCancelProposalAction.rejected, (state, { error }) => {
      state.isCreatingProposal = false;
    });
  },
});

export const { setTargetOption, setManageRoundId, setCompletedIds } =
  daoSlice.actions;

export default daoSlice.reducer;
