import { Box, Button } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import useStyles from "./index.styles";
import RouletteHistoryBoard from "./HistoryBoard";
import RouletteManageBoard from "./ManageBoard";
import { useAppSelector } from "../../../app/hooks";
import TableHistory from "./TableHistory";

enum PanelType {
  TableHistory,
  MY_HISTORY,
  MANAGE_TABLE,
}

const RouletteHistoryManageBoard = () => {
  const { classes } = useStyles();
  const [activePanel, setActivePanel] = useState(PanelType.MY_HISTORY);

  const { myInventoryItems } = useAppSelector((state) => state.profile);
  const { roulettes, currentRouletteId } = useAppSelector(
    (state) => state.roulette
  );

  const isMyRoulette =
    roulettes[currentRouletteId] &&
    myInventoryItems.filter(
      (item) => item.itemId === roulettes[currentRouletteId].inventoryItemId
    ).length > 0;

  return (
    <Box className={classes.panel}>
      <Box
        className={
          !isMyRoulette ? classes.singlePanelHeader : classes.panelHeader
        }
      >
        {!isMyRoulette ? (
          <Button
            className={clsx(
              classes.myHistory,
              activePanel === PanelType.MY_HISTORY && classes.selected
            )}
            onClick={() => setActivePanel(PanelType.MY_HISTORY)}
          >
            My History
          </Button>
        ) : (
          <>
            <Button
              className={clsx(
                classes.tableHistory,
                activePanel === PanelType.TableHistory && classes.selected
              )}
              onClick={() => setActivePanel(PanelType.TableHistory)}
            >
              Table history
            </Button>
            <Button
              className={clsx(
                classes.myHistory,
                activePanel === PanelType.MY_HISTORY && classes.selected
              )}
              onClick={() => setActivePanel(PanelType.MY_HISTORY)}
            >
              My history
            </Button>
            <Button
              className={clsx(
                classes.manageTable,
                activePanel === PanelType.MANAGE_TABLE && classes.selected
              )}
              onClick={() => setActivePanel(PanelType.MANAGE_TABLE)}
            >
              Manage table
            </Button>
          </>
        )}
      </Box>

      <Box
        className={
          activePanel === PanelType.MY_HISTORY ||
          activePanel === PanelType.TableHistory
            ? classes.historyPanelBody
            : classes.managePanelBody
        }
      >
        {activePanel === PanelType.MY_HISTORY ? (
          <RouletteHistoryBoard />
        ) : activePanel === PanelType.TableHistory ? (
          <TableHistory />
        ) : (
          <RouletteManageBoard />
        )}
      </Box>
    </Box>
  );
};

export default RouletteHistoryManageBoard;
