import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { CHAIN } from "../../config/config";
import { ChainType } from "../../constants/enum/enum";
import { useEffect, useState } from "react";

const BackOverlay = () => {
  const {
    isSigningData,
    isLoadingFamilyDetail,
    isUpdatingLeader,
    isAcceptingInvitation,
    isRejectingInvitation,
    isCancelingInvitation,
    isLeavingFamily,
    isKickingMember,
    isUpdatingFamilyBranding,
    isUpdatingFamilyPromotion,
    isUpdatingFamilyLeaveFee,

    isCreatingAllegiance,
    isRegisteringAllegiance,
    isInvitingAllegiance,
    isRequestingToJoinAllegiance,
    isAcceptingAllegiance,
    isRejectingAllegiance,

    isAcceptingAllegianceRequest,
    isRejectingAllegianceRequest,
    isKickingAllegiance,
    isExitingAllegiance,

    isUpdatingAllegianceSharing,
    isWithdrawingStaking,
  } = useAppSelector((state) => state.family);

  const {
    isBuyingCrate,
    isOpeningCrate,
    isRequestOpenCrate,
    isFinishOpenCrate,
    isWaitingVRF,
  } = useAppSelector((state) => state.crate);

  const {
    isTransferringItem,
    isTransferringCrate,
    isWrappingOgCrate,
    isUnwrappingOgCrate,
    isWrappingOGNFTAction,
    isUnwrappingOGNFTAction,
    isUnwrappingKeyItem,
    isUnwrappingMafiaItem,
    isUnwrappingOGNFTItem,

    isApprovingOGNFT,
    isApprovingCrate,

    isBuyingPremium,
    isClaimingKeys,
    isSigningProfileData,
    isUpdatingProfileDescription,
    isLoadingCashHistory,
  } = useAppSelector((state) => state.profile);

  const {
    isCreatingListing,
    isPurchasingFixedItem,
    isBidOnAuctionItem,
    isCancelListing,
    isFinishAuctionItem,
  } = useAppSelector((state) => state.market);

  const {
    isConvertingItems,
    isCreatingOTC,
    isAcceptingOTC,
    isCancelingOTC,
    isAddLiquidity,
    isLoadingAvgCashPerMafia,
    isDepositApprove,
    loadingWithdrawStat,
    isCancelLiquidity,
    isSwapping,
    isExecutingSwap,
  } = useAppSelector((state) => state.exchange);

  const { isLoadingWorldMapInfo } = useAppSelector((state) => state.map);

  const {
    isCreatingProposal,
    isGettingProposals,
    isOpeningProposal,
    isVotingProposal,
    isClosingProposal,
    isWithdrawingProposal,
  } = useAppSelector((state) => state.dao);

  const {
    isRouletteListLoading,
    isInitializingBet,
    isFinishingBet,
    isUpdatingRouletteSettings,
    isAddingLiquidity,
    isRemovingLiquidity,
    isApprovingGameCash,
    loadingRouletteProfile,
  } = useAppSelector((state) => state.roulette);
  const { isLoadingBusinessList } = useAppSelector((state) => state.business);

  const [loadingText, setLoadingText] = useState("");

  useEffect(() => {
    if (!isSwapping) {
      setLoadingText("");
      return;
    }
    setLoadingText("Matching your order with liquidity");

    let timeout = setTimeout(() => {
      setLoadingText("Purchasing cash liquidity");
    }, 12000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isSwapping]);

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.modal + 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
      open={
        isLoadingFamilyDetail ||
        isSigningData ||
        isUpdatingLeader ||
        isAcceptingInvitation ||
        isRejectingInvitation ||
        isCancelingInvitation ||
        isLeavingFamily ||
        isKickingMember ||
        isUpdatingFamilyBranding ||
        isUpdatingFamilyPromotion ||
        isUpdatingFamilyLeaveFee ||
        isBuyingCrate ||
        isApprovingCrate ||
        isRequestOpenCrate ||
        isFinishOpenCrate ||
        isAcceptingInvitation ||
        isWaitingVRF ||
        isTransferringItem ||
        isTransferringCrate ||
        isOpeningCrate ||
        isCreatingListing ||
        isPurchasingFixedItem ||
        isBidOnAuctionItem ||
        isCancelListing ||
        isFinishAuctionItem ||
        isWrappingOgCrate ||
        isUnwrappingOgCrate ||
        isWrappingOGNFTAction ||
        isUnwrappingOGNFTAction ||
        isUnwrappingKeyItem ||
        isApprovingOGNFT ||
        isApprovingCrate ||
        isUnwrappingMafiaItem ||
        isUnwrappingOGNFTItem ||
        isBuyingPremium ||
        isSigningData ||
        isSigningProfileData ||
        isUpdatingProfileDescription ||
        isClaimingKeys ||
        isCreatingAllegiance ||
        isRegisteringAllegiance ||
        isInvitingAllegiance ||
        isRequestingToJoinAllegiance ||
        isAcceptingAllegiance ||
        isRejectingAllegiance ||
        isAcceptingAllegianceRequest ||
        isRejectingAllegianceRequest ||
        isKickingAllegiance ||
        isExitingAllegiance ||
        isUpdatingAllegianceSharing ||
        isConvertingItems ||
        isCreatingOTC ||
        isAcceptingOTC ||
        isCancelingOTC ||
        isWithdrawingStaking ||
        isAddLiquidity ||
        isLoadingAvgCashPerMafia ||
        isDepositApprove ||
        loadingWithdrawStat ||
        isCancelLiquidity ||
        isSwapping ||
        isExecutingSwap ||
        isLoadingWorldMapInfo ||
        isLoadingCashHistory ||
        isCreatingProposal ||
        isGettingProposals ||
        isOpeningProposal ||
        isVotingProposal ||
        isClosingProposal ||
        isWithdrawingProposal ||
        isRouletteListLoading ||
        isInitializingBet ||
        isFinishingBet ||
        isUpdatingRouletteSettings ||
        isAddingLiquidity ||
        isRemovingLiquidity ||
        isApprovingGameCash ||
        isLoadingBusinessList ||
        loadingRouletteProfile
      }
    >
      <CircularProgress color="inherit" />

      {isWaitingVRF &&
        (CHAIN === ChainType.BNB ? (
          <Box>Waiting for chainlink...</Box>
        ) : (
          <Box>Waiting for block...</Box>
        ))}
      {isSwapping && <Box>{loadingText}</Box>}

      {isLoadingWorldMapInfo && <Box>Loading world map...</Box>}

      {isApprovingGameCash && <Box>Approving Game Cash...</Box>}

      {isInitializingBet && <Box>Initializing bet...</Box>}

      {isFinishingBet && <Box>Finishing bet...</Box>}

      {isUpdatingRouletteSettings && <Box>Updating roulette settings...</Box>}

      {isAddingLiquidity && <Box>Adding liquidity...</Box>}

      {isRemovingLiquidity && <Box>Removing liquidity...</Box>}
    </Backdrop>
  );
};

export default BackOverlay;
