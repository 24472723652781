import { Box, ClickAwayListener, Fade, Popper } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTimeAgo from "react-time-ago";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getFamilyInvitations } from "../../reducers/family.slice";
import {
  getNotifications,
  getUnreadNotificationInfo,
  updateNotificationId,
} from "../../reducers/notification.slice";

import { AllegianceInvitationInfo } from "../../types/AllegianceInvitationInfo";
import { AllegianceRequestInfo } from "../../types/AllegianceRequestInfo";
import { FamilyInvitation } from "../../types/FamilyInvitation";
import { Notification } from "../../types/Notification";

import useCurrentTime from "../../hook/useCurrentTime";
import useProfile from "../../hook/useProfile";

import { NOTIFICATION_COUNT_PER_PAGE } from "../../constants/const";
import { NotificationType } from "../../constants/enum/enum";
import { Messages } from "../../constants/messages";

import inviteIcon from "../../assets/imgs/profile/add-user.png";

import { useStyles } from "./index.styles";
import { toastInfo } from "../../utils/utils";

const NotificationPanel = ({
  account,
  showNotification,
  anchorElNotification,
  toggleNotification,
  setInvitationInfo,
  setOpenInviteModal,
  setAllegianceInvitationInfo,
  setOpenAllegianceInviteModal,
  setAllegianceRequestInfo,
  setOpenAllegianceRequestModal,
}: {
  account: string;
  showNotification: boolean;
  anchorElNotification: null | HTMLElement;
  toggleNotification: React.Dispatch<React.SetStateAction<boolean>>;
  setInvitationInfo: React.Dispatch<
    React.SetStateAction<FamilyInvitation | undefined>
  >;
  setOpenInviteModal: React.Dispatch<React.SetStateAction<boolean>>;

  setAllegianceInvitationInfo: React.Dispatch<
    React.SetStateAction<AllegianceInvitationInfo | undefined>
  >;
  setOpenAllegianceInviteModal: React.Dispatch<React.SetStateAction<boolean>>;

  setAllegianceRequestInfo: React.Dispatch<
    React.SetStateAction<AllegianceRequestInfo | undefined>
  >;
  setOpenAllegianceRequestModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { myProfile } = useProfile(account);
  const currentTime = useCurrentTime();

  const { hasMore, notifications } = useAppSelector(
    (state) => state.notification
  );
  const invitationList = useAppSelector((state) => state.family.invitationList);

  const [currentPage, setCurrentPage] = useState(0);

  const canBeOpen = showNotification && Boolean(anchorElNotification);
  const id = canBeOpen ? "transition-popper" : undefined;

  const handleHideNotification = () => {
    if (showNotification) toggleNotification(false);

    const notificationId = Math.max(
      ...notifications.map((notification, _) => {
        return notification.id;
      })
    );

    if (!myProfile.id) return;

    dispatch(updateNotificationId({ notificationId, playerId: myProfile.id }));
  };

  const fetchMoreNotification = () => {
    dispatch(
      getNotifications({
        address: account,
        from: (currentPage + 1) * NOTIFICATION_COUNT_PER_PAGE,
        to: NOTIFICATION_COUNT_PER_PAGE,
      })
    );

    setCurrentPage((currentPage) => currentPage + 1);
  };

  const handleClickNotification = (notification: Notification) => {
    if (notification.type === 0) {
      if (myProfile.family === -1) {
        if (notification.familyId >= 0) {
          const invitation = invitationList.find(
            (invitation, index) => invitation.id === notification.invitationId
          );
          if (!invitation || invitation.expiresAt < currentTime) return;

          setInvitationInfo(invitation);
          setOpenInviteModal(true);
        }
      }
    } else if (notification.type === NotificationType.AllegianceInvitation) {
      const allegianceInvitationInfo: AllegianceInvitationInfo = JSON.parse(
        notification.content
      );

      if (allegianceInvitationInfo.expiresAt < currentTime) {
        toastInfo(Messages.FAMILY.ALLEGIANCE.INVITATION_EXPIRED);
        return;
      }

      setAllegianceInvitationInfo(allegianceInvitationInfo);
      setOpenAllegianceInviteModal(true);
    } else if (notification.type === NotificationType.AllegianceRequest) {
      const allegianceRequestInfo: AllegianceRequestInfo = JSON.parse(
        notification.content
      );

      if (allegianceRequestInfo.expiresAt < currentTime) {
        toastInfo(Messages.FAMILY.ALLEGIANCE.REQUEST_EXPIRED);
        return;
      }

      setAllegianceRequestInfo(allegianceRequestInfo);
      setOpenAllegianceRequestModal(true);
    }
  };

  const renderMessage = (notification: Notification) => {
    // Normal messages
    if (
      notification.type === NotificationType.FamilyInvitation ||
      notification.type === NotificationType.OTCRequest
    ) {
      return notification.content;
    } else if (notification.type === NotificationType.AllegianceInvitation) {
      const allegianceInvitationInfo: AllegianceInvitationInfo = JSON.parse(
        notification.content
      );

      return allegianceInvitationInfo.message;
    } else if (notification.type === NotificationType.AllegianceRequest) {
      const allegianceRequestInfo: AllegianceRequestInfo = JSON.parse(
        notification.content
      );

      return allegianceRequestInfo.message;
    }
  };

  const renderNotifications = () => {
    return notifications.map((notification, index) => {
      const clickable =
        notification.familyId >= 0 ||
        notification.type === NotificationType.AllegianceInvitation ||
        notification.type === NotificationType.AllegianceRequest;

      return (
        <Box
          className={
            notification.id > (myProfile.notificationId || 0) ||
            notification.id == null
              ? classes.notificationListItem
              : clsx(classes.notificationListItem, "read")
          }
          key={index}
          sx={{
            cursor: clickable ? "pointer" : "auto",
          }}
          onClick={() => {
            handleClickNotification(notification);
          }}
        >
          <Box className={classes.notificationTypeIcon} width={20}>
            <Box component="img" src={inviteIcon} width={20} />
          </Box>

          <Box className={classes.notificationContent}>
            <p> {renderMessage(notification)}</p>
            <p>
              <ReactTimeAgo
                date={notification.timestamp * 1000}
                locale="en-US"
              />
            </p>
          </Box>
        </Box>
      );
    });
  };

  useEffect(() => {
    if (!myProfile.name) return;

    dispatch(
      getUnreadNotificationInfo({
        address: account,
        notificationId: myProfile.notificationId || 0,
      })
    );

    dispatch(
      getNotifications({
        address: account,
        from: currentPage,
        to: NOTIFICATION_COUNT_PER_PAGE,
      })
    );

    dispatch(getFamilyInvitations(account));
  }, [myProfile, dispatch, account, currentPage]);

  return (
    <Popper
      id={id}
      open={showNotification}
      anchorEl={anchorElNotification}
      transition
      sx={{
        zIndex: 2,
      }}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={handleHideNotification}>
          <Fade {...TransitionProps} timeout={350}>
            <Box className={classes.notificationPanel}>
              <Box className={classes.notificationHeader}>
                <p>Notifications</p>
              </Box>

              <Box className={classes.notificationList} id="scrollableDiv">
                {notifications.length > 0 ? (
                  <InfiniteScroll
                    dataLength={notifications.length}
                    hasMore={hasMore}
                    next={fetchMoreNotification}
                    loader={<></>}
                    scrollableTarget="scrollableDiv"
                  >
                    {renderNotifications()}
                  </InfiniteScroll>
                ) : (
                  <Box className={classes.notificationEmpty}>
                    No new notifications
                  </Box>
                )}
              </Box>
            </Box>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

export default NotificationPanel;
