import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import InventoryItemIcon from "../InventoryItemIcon";

import { ItemInfo } from "../../types/ItemInfo";

import { getInventoryItemInfo } from "../../helper/inventory";

import useStyles from "./CustomDropDown.styles";
import { ItemCategory } from "../../constants/enum/enum";
import { useAppSelector } from "../../app/hooks";
import { City, CitySimple } from "../../constants/const";
import { BuildingTypeOptions, Rarity } from "../../constants/map";
import { BuildingType, RarityType } from "../../constants/enum/map";

interface ListingItemDropDownProps {
  readonly sx?: React.CSSProperties;
  readonly items: ItemInfo[];
  readonly value?: string;
  readonly disabled?: boolean;
  readonly handleChange?: (event: SelectChangeEvent) => void;
  readonly placeholder?: string;
}

export default function ListingItemDropDown({
  disabled = false,
  placeholder = "",
  sx,
  items,
  value,
  handleChange,
}: ListingItemDropDownProps) {
  const { classes } = useStyles();
  const { mySlotItems } = useAppSelector((state) => state.profile);

  const getLandSlotItemId = (item: ItemInfo) => {
    const slotInfo = mySlotItems.find(
      (slot) => slot.inventoryItemId === item.itemId
    );
    if (slotInfo) {
      return {
        slotId: `#${CitySimple[slotInfo.city]}-${slotInfo.slotX}-${
          slotInfo.slotY
        }`,
        color: Rarity[slotInfo.slotRarity as RarityType].color,
        slotSubType: slotInfo.slotSubType,
      };
    }
    return { slotId: null, color: null, slotSubType: null };
  };

  return (
    <FormControl
      className={classes.formControl}
      sx={{
        ...sx,
      }}
    >
      {!value && <Box className={classes.placeholder}>{placeholder}</Box>}
      <Select
        value={value}
        disabled={disabled}
        onChange={handleChange}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        inputProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 320,
              },
              sx: {
                display: "block",
                padding: 0,
                backgroundColor: "#282828",
                color: "white",
              },
            },
            children: {
              sx: {
                fontSize: "12px",
              },
            },
          },
        }}
        sx={{
          "& .MuiSelect-select>div:first-of-type": {
            display: "none",
          },
        }}
      >
        {items?.map((item, index) => {
          const itemInfo = getInventoryItemInfo(
            item.categoryId,
            item.typeId,
            item.cityId
          );

          return (
            <MenuItem
              key={index}
              value={item.itemId}
              className={classes.menuItem}
            >
              <InventoryItemIcon
                categoryId={item.categoryId}
                typeId={item.typeId}
                bottomBorderHeight={4}
                bottomBorderColor={
                  item.categoryId === ItemCategory.LANDSLOT
                    ? getLandSlotItemId(item).color
                    : ""
                }
                slotSubType={
                  item.categoryId === ItemCategory.LANDSLOT
                    ? getLandSlotItemId(item).slotSubType
                    : null
                }
                sx={{
                  borderRadius: "4px",
                  marginRight: "8px",
                  width: 24,
                  height: 24,
                }}
              />
              <Box className={classes.detail}>
                {item.categoryId === ItemCategory.LANDSLOT &&
                getLandSlotItemId(item).slotId
                  ? BuildingTypeOptions[
                      getLandSlotItemId(item).slotSubType as BuildingType
                    ].name
                  : itemInfo.itemName}{" "}
                {item.categoryId === ItemCategory.LANDSLOT &&
                  getLandSlotItemId(item).slotId &&
                  " - " + getLandSlotItemId(item).slotId}
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
