import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import useCurrentTime from "../../../hook/useCurrentTime";
import { RouletteBetInfo } from "../../../types/Contract/Roulette/RouletteBetInfo";
import { RouletteBetStatus } from "../../../constants/enum/roulette";

import useStyles from "./index.styles";

const RouletteTimer = ({
  betInfo,
  betStatus,
  currentBlock,
  remainBlock,
}: {
  betInfo?: RouletteBetInfo;
  betStatus: RouletteBetStatus;
  currentBlock: number;
  remainBlock: number;
}) => {
  const currentTime = useCurrentTime();
  const { classes } = useStyles();

  const defaultWaitTime = 10;
  const [startTime, setStartTime] = useState<number>(0);

  const remainTime =
    startTime + defaultWaitTime > currentTime
      ? Math.floor(startTime + defaultWaitTime - currentTime)
      : 0;

  const renderTimer = () => {
    switch (betStatus) {
      case RouletteBetStatus.NOT_REQUESTED:
        return (
          <>
            <Typography className={classes.timerLabel}>Please bet</Typography>
            <Typography className={classes.timerSecs}>-</Typography>
            <Typography className={classes.timerLabel}>-</Typography>
          </>
        );
      case RouletteBetStatus.REQUESTED:
        return remainBlock === 0 ? (
          betInfo?.isPending ? (
            <>
              <Typography className={classes.timerLabel}>
                Waiting for
              </Typography>
              <Typography className={classes.timerSecs}>Spin</Typography>
              <Typography className={classes.timerLabel}>
                Spin Now!!!
              </Typography>
            </>
          ) : (
            <>
              <Typography className={classes.timerLabel}>-</Typography>
              <Typography className={classes.timerSecs}>Wait...</Typography>
              <Typography className={classes.timerLabel}>-</Typography>
            </>
          )
        ) : (
          <>
            <Typography className={classes.timerLabel}>Waiting for</Typography>
            <Typography className={classes.timerSecs}>{remainBlock}</Typography>
            <Typography className={classes.timerLabel}>Block</Typography>
          </>
        );

      case RouletteBetStatus.FINISHING:
        return remainTime > 0 ? (
          <>
            <Typography className={classes.timerLabel}>Spinning in</Typography>
            <Typography className={classes.timerSecs}>{remainTime}</Typography>
            <Typography className={classes.timerLabel}>Seconds</Typography>
          </>
        ) : (
          <>
            <Typography className={classes.timerLabel}>-</Typography>
            <Typography className={classes.timerSecs}>Waiting</Typography>
            <Typography className={classes.timerLabel}>-</Typography>
          </>
        );

      case RouletteBetStatus.SPINNING:
        return (
          <>
            <Typography className={classes.timerLabel}>-</Typography>
            <Typography className={classes.timerSecs}>Spinning</Typography>
            <Typography className={classes.timerLabel}>-</Typography>
          </>
        );

      case RouletteBetStatus.FINISHED:
        return (
          <>
            <Typography className={classes.timerLabel}>-</Typography>
            <Typography className={classes.timerSecs}>Finished</Typography>
            <Typography className={classes.timerLabel}>-</Typography>
          </>
        );
    }
  };

  useEffect(() => {
    if (betStatus === RouletteBetStatus.FINISHING) {
      setStartTime(new Date().getTime() / 1000);
    }
  }, [betStatus]);

  return (
    <Box className={classes.panel}>
      <Box className={classes.panelBody}>{renderTimer()}</Box>
    </Box>
  );
};

export default RouletteTimer;
