import { Box, Tooltip } from "@mui/material";
import millify from "millify";
import { ReactNode } from "react";

import InventoryItemIcon from "../InventoryItemIcon";
import { ListingTokenType } from "../MarketListItemPopup";

import { City, CitySimple, GAME_CASH_ADDRESS } from "../../constants/const";
import { Market } from "../../constants/enum/market";
import { getInventoryItemName } from "../../helper/inventory";
import { toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";
import { BuildingType, RarityType, SlotType } from "../../constants/enum/map";
import { BuildingTypeOptions, Rarity } from "../../constants/map";
import { goToMapSlotPage } from "../../helper/map";

const ActiveListingItem = ({
  item,
  currentTime,
  renderActionButton,
  rarityType,
}: {
  item: any;
  currentTime: number;
  renderActionButton: (item: any) => ReactNode;
  rarityType: RarityType | null;
}) => {
  const { classes } = useStyles();

  const itemName = getInventoryItemName(
    item.categoryId,
    item.typeId,
    item.cityId
  );

  return (
    <Box className={classes.listingPanelItem}>
      <Box>
        <Tooltip
          title={
            item.slotData !== null &&
            item.slotData.slotType === SlotType.UpgradableSlot
              ? `#${CitySimple[item.slotData?.cityId]}-${
                  item.slotData?.slotX
                }-${item.slotData?.slotY}`
              : ""
          }
        >
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
              fontSize: "14px",
              cursor:
                item.slotData !== null &&
                item.slotData.slotType === SlotType.UpgradableSlot
                  ? "pointer"
                  : "auto",
            }}
            onClick={() =>
              item.slotData !== null &&
              item.slotData.slotType === SlotType.UpgradableSlot &&
              goToMapSlotPage(
                item.slotData.cityId,
                item.slotData.slotX,
                item.slotData.slotY
              )
            }
          >
            <InventoryItemIcon
              categoryId={item.categoryId}
              typeId={item.typeId}
              className={classes.listingItemIcon}
              bottomBorderColor={
                item.slotData &&
                item.slotData.slotType === SlotType.UpgradableSlot
                  ? Rarity[item.slotData.slotRarity as RarityType].color
                  : ""
              }
              bottomBorderHeight={4}
              slotSubType={item.slotData?.slotSubType}
            />
            {item.slotData && item.slotData.slotType === SlotType.UpgradableSlot
              ? BuildingTypeOptions[item.slotData.slotSubType as BuildingType]
                  .name
              : itemName}{" "}
            {item.slotData && item.slotData.slotType === SlotType.UpgradableSlot
              ? ` - ${City[item.slotData.cityId]}`
              : ""}
          </Box>
        </Tooltip>
      </Box>

      <Box>
        {item.listingInfo.listingType === Market.ListType.FIXED
          ? "Fixed"
          : "Auction"}
      </Box>

      <Box>
        {item.listingInfo.token === GAME_CASH_ADDRESS
          ? millify(item.listingInfo.currentPrice)
          : `$${toUSDFormat(item.listingInfo.currentPrice)}`}
      </Box>

      <Box
        sx={{
          gap: "4px!important",
        }}
      >
        <Box
          component="img"
          sx={{ width: 24 }}
          src={
            item.currency === ListingTokenType.GameCash
              ? "/assets/imgs/inventory/cashitem.png"
              : `/assets/tokens/${item.currency.toLocaleLowerCase()}.png`
          }
        ></Box>
        {item.currency}
      </Box>

      <Box>
        {renderActionButton(item)}
        {/* {item.listingInfo.expiresAt - currentTime < 0 ? (
          renderActionButton(item)
        ) : (
          <Box className={classes.listingRemainTimeNormal}>
            {convertDateTime(item.listingInfo.expiresAt - currentTime)}
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default ActiveListingItem;
