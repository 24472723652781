import { Box } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { City } from "../../constants/const";
import { ItemCategory } from "../../constants/enum/enum";
import {
  BuildingType,
  BusinessType,
  RarityType,
  SlotType,
} from "../../constants/enum/map";
import {
  BuildingTypeOptions,
  BusinessOptions,
  Rarity,
} from "../../constants/map";
import InventorySlotInfo from "../../types/InventorySlotInfo";
import { ItemInfo } from "../../types/ItemInfo";
import InventoryItemActionPopup from "../InventoryItemActionPopup";
import useStyles from "./index.styles";

const ProfileSlot = () => {
  const { classes } = useStyles();
  const { slotItems, profile, myProfile } = useAppSelector(
    (state) => state.profile
  );

  const [openPopup, setOpenPopup] = useState(false);
  const [targetItem, setTargetItem] = useState<ItemInfo>({
    categoryId: -1,
    cityId: -1,
    typeId: 0,
    itemId: 0,
  });

  const handleClickSlot = (item: InventorySlotInfo) => {
    setOpenPopup(true);
    setTargetItem({
      categoryId: ItemCategory.LANDSLOT,
      typeId: 0,
      cityId: item.city,
      itemId: item.inventoryItemId,
      inventoryId: item.inventoryItemId,
    });
  };

  const getBuildingInfo = (item: InventorySlotInfo) => {
    const data =
      item.slotType === SlotType.UpgradableSlot
        ? BuildingTypeOptions[item.slotSubType as BuildingType]
        : SlotType.Business
        ? BusinessOptions[item.slotSubType as BusinessType]
        : null;

    return data;
  };

  const updatedSlotItems = () => {
    const basicSortedItems = [...slotItems].sort(
      (a, b) => b.slotSubType - a.slotSubType
    );
    const sortedItems = [
      ...basicSortedItems.filter((item) => item.slotType === SlotType.Business),
      ...basicSortedItems.filter(
        (item) =>
          item.slotType === SlotType.UpgradableSlot &&
          item.slotRarity === RarityType.Strategic
      ),
      ...basicSortedItems.filter(
        (item) =>
          item.slotType === SlotType.UpgradableSlot &&
          item.slotRarity === RarityType.Ellite
      ),
      ...basicSortedItems.filter(
        (item) =>
          item.slotType === SlotType.UpgradableSlot &&
          item.slotRarity === RarityType.Upper
      ),
      ...basicSortedItems.filter(
        (item) =>
          item.slotType === SlotType.UpgradableSlot &&
          item.slotRarity === RarityType.Common
      ),
    ];
    return sortedItems;
  };

  return (
    <Box className={classes.body}>
      <Box component={"p"} className={classes.title}>
        Real estate
      </Box>
      <Box className={classes.buildingPanel}>
        {updatedSlotItems().map((item, index) => (
          <Box
            key={index}
            className={classes.buildingBox}
            onClick={() => handleClickSlot(item)}
          >
            <Box
              className={clsx(
                classes.topBuildingPart,
                item.slotType === SlotType.UpgradableSlot
                  ? classes.buildingPart
                  : classes.businessPart
              )}
            >
              <Box className={classes.buildingName}>
                {getBuildingInfo(item)?.name}
              </Box>
              <Box>{City[item.city]}</Box>
              <Box
                component={"img"}
                src={`/assets/buildings/${getBuildingInfo(item)?.logo}`}
              />
            </Box>
            {item.slotType === SlotType.UpgradableSlot && (
              <Box
                className={classes.bottomBuildingPart}
                sx={{
                  backgroundColor: Rarity[item.slotRarity as RarityType].color,
                }}
              >
                {Rarity[item.slotRarity as RarityType].name}
              </Box>
            )}
          </Box>
        ))}
      </Box>

      {profile.id === myProfile.id && (
        <InventoryItemActionPopup
          itemInfo={targetItem}
          openItemTransferPopup={openPopup}
          setOpenCrateOpeningPopup={setOpenPopup}
          type="landslot"
        />
      )}
    </Box>
  );
};

export default ProfileSlot;
