import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import useCurrentBlock from "../../../hook/useCurrentBlock";
import useWallet from "../../../hook/useWallet";
import useRouletteSSE from "./hook/useRouletteSSE";

import { getUserBetInfo } from "../../../helper/contractFunctions/MafiaRoulette";

import { CONFIRMATION_DELAY } from "../../../constants/const";
import { RouletteBetStatus } from "../../../constants/enum/roulette";
import { Errors } from "../../../constants/errors";
import { Messages } from "../../../constants/messages";
import { dispatchTxAction } from "../../../helper/dispatchTxAction";
import { generateBets } from "../../../helper/roulette";
import {
  approveGameCashAction,
  finishBetAction,
  initializeBetAction,
  updateBetDisabled,
  updateBetStatus,
} from "../../../reducers/roulette.slice";
import { RouletteBetInfo } from "../../../types/Contract/Roulette/RouletteBetInfo";
import { toastError, toastInfo } from "../../../utils/utils";

import RouletteBetBoard from "../BetBoard/RouletteBetBoard";
import RouletteTimer from "../Timer/RouletteTimer";
import RouletteWheel from "../Wheel/RouletteWheel";

const RouletteMain = () => {
  const currentBlock = useCurrentBlock();
  const { account, connectWallet } = useWallet();
  const dispatch = useAppDispatch();

  const { currentRouletteId, bets, roulettes, betStatus, betDisabled } =
    useAppSelector((state) => state.roulette);

  const [betInfo, setBetInfo] = useState<RouletteBetInfo>();
  const [remainBlock, setRemainBlock] = useState(3);

  const handleInitializeBet = () => {
    if (!account) {
      connectWallet();
      return;
    }

    if (bets.length === 0) return;

    if (!roulettes[currentRouletteId]) return;

    const parsedBets = generateBets(bets);

    const totalAmount = parsedBets.reduce((acc, bet) => acc + bet.amount, 0);

    if (!roulettes[currentRouletteId].isOpened) {
      toastInfo(Messages.ROULETTE.INITIALIZE_BET.CLOSE_ERROR);
      return;
    }

    if (totalAmount < roulettes[currentRouletteId].minBet) {
      toastInfo(Messages.ROULETTE.INITIALIZE_BET.MIN_BET_ERROR, {
        minBet: roulettes[currentRouletteId].minBet.toString(),
      });
      return;
    }

    if (totalAmount > roulettes[currentRouletteId].maxBet) {
      toastInfo(Messages.ROULETTE.INITIALIZE_BET.MAX_BET_ERROR, {
        maxBet: roulettes[currentRouletteId].maxBet.toString(),
      });
      return;
    }

    dispatchTxAction(
      dispatch,
      approveGameCashAction({
        tokenAmount: totalAmount,
        account,
      }),
      () => {
        dispatchTxAction(
          dispatch,
          initializeBetAction({
            account,
            rouletteId: currentRouletteId,
            bets: parsedBets,
          }),
          () => {
            dispatch(updateBetDisabled(true));
            dispatch(updateBetStatus(RouletteBetStatus.REQUESTED));
            setRemainBlock(3);
            fetchUserBetInfo();
          },
          CONFIRMATION_DELAY
        );
      },
      CONFIRMATION_DELAY * 2
    );
  };

  const handleFinishBet = () => {
    if (betInfo?.isPending && currentBlock > betInfo?.requestBlock + 2) {
      dispatchTxAction(
        dispatch,
        finishBetAction({ account, rouletteId: currentRouletteId }),
        () => {
          dispatch(updateBetStatus(RouletteBetStatus.FINISHING));
        },
        CONFIRMATION_DELAY
      );
    } else {
      toastError(Errors.ROULETTE.BET.NOT_REQUESTED);
    }
  };

  // Define the function to fetch user bet info
  const fetchUserBetInfo = useCallback(async () => {
    if (account && currentRouletteId >= 0) {
      try {
        const userBetInfo = await getUserBetInfo(account, currentRouletteId);

        if (userBetInfo.isPending) {
          setBetInfo(userBetInfo);

          if (!betDisabled) dispatch(updateBetDisabled(true));

          if (betStatus === RouletteBetStatus.NOT_REQUESTED)
            dispatch(updateBetStatus(RouletteBetStatus.REQUESTED));
        }

        // Dispatch or handle the fetched userBetInfo as needed
      } catch (error) {
        console.error("Error fetching user bet info:", error);
      }
    }
  }, [account, currentRouletteId, betDisabled, betStatus, dispatch]);

  useEffect(() => {
    const remainBlock =
      betInfo?.requestBlock && betInfo?.requestBlock + 2 >= currentBlock
        ? betInfo?.requestBlock + 3 - currentBlock
        : 0;

    setRemainBlock(remainBlock);
  }, [betInfo, currentBlock]);

  useEffect(() => {
    // Set up the interval
    const intervalId = setInterval(() => {
      fetchUserBetInfo();
    }, CONFIRMATION_DELAY); // Fetch every 5 seconds (adjust the interval as needed)

    // Clean up the interval on component unmount or dependency change
    return () => clearInterval(intervalId);
  }, [currentRouletteId, account, fetchUserBetInfo]); // Dependencies to re-run the effect

  useEffect(() => {
    dispatch(updateBetDisabled(false));
    dispatch(updateBetStatus(RouletteBetStatus.NOT_REQUESTED));
  }, [currentRouletteId, account, dispatch]);

  useRouletteSSE(account);

  return (
    <>
      <RouletteTimer
        betInfo={betInfo}
        betStatus={betStatus}
        currentBlock={currentBlock}
        remainBlock={remainBlock}
      />

      <RouletteWheel />

      <RouletteBetBoard
        finishBet={handleFinishBet}
        initializeBet={handleInitializeBet}
        betInfo={betInfo}
        remainBlock={remainBlock}
        betStatus={betStatus}
        betDisabled={betDisabled}
      />
    </>
  );
};

export default RouletteMain;
