import { getProvider, getWeb3, waitForConfirmation } from ".";

import MafiaInventoryABI from "../../abis/MafiaInventory.json";
import MafiaInventoryPlsABI from "../../abis/MafiaInventoryPLS.json";

import config, { CHAIN } from "../../config/config";
import { ChainType } from "../../constants/enum/enum";
import { Category } from "../../types/Contract/Inventory/Category";
import { UserNonceStatus } from "../../types/Contract/Inventory/UserNonceStatus";

export const getMafiaInventoryContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.inventoryAddress;
  const MafiaInventoryContract = new web3.eth.Contract(
    CHAIN === ChainType.BNB ? MafiaInventoryABI : MafiaInventoryPlsABI,
    contractAddress
  );
  return MafiaInventoryContract;
};

export const getNonceStatus = async (account: string) => {
  const MafiaInventoryContract = getMafiaInventoryContract();

  const fulfilled: boolean = await MafiaInventoryContract.methods
    .getNonceStatus(account)
    .call();

  return fulfilled;
};

export const getUserStatus = async (account: string) => {
  const MafiaInventoryContract = getMafiaInventoryContract();

  const userStatus: UserNonceStatus = await MafiaInventoryContract.methods
    .userNonceStatus(account)
    .call();

  return userStatus;
};

export const getStockCounts = async () => {
  const MafiaInventoryContract = getMafiaInventoryContract();

  const categories: Category[] = await MafiaInventoryContract.methods
    .getCategories()
    .call();

  return categories.map((category: Category) => {
    return Number(category.totalStock);
  });
};

export const requestOpenCrate = async (account: string) => {
  const MafiaInventoryContract = getMafiaInventoryContract(true);

  const data = await MafiaInventoryContract.methods
    .requestOpenCrate()
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
};

export const finishOpenCrate = async (account: string) => {
  const MafiaInventoryContract = getMafiaInventoryContract(true);

  const data = await MafiaInventoryContract.methods
    .finishOpenCrate()
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
};

export const transferItem = async (
  account: string,
  to: string,
  itemId: number
) => {
  const MafiaInventoryContract = getMafiaInventoryContract(true);

  const data = await MafiaInventoryContract.methods
    .transferItem(to, itemId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
};

export const transferItems = async (
  account: string,
  to: string,
  itemIds: number[]
) => {
  const MafiaInventoryContract = getMafiaInventoryContract(true);

  const data = await MafiaInventoryContract.methods
    .transferItems(to, itemIds)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
};

export const getLatestAirdropTimestamp = async () => {
  const MafiaInventoryContract = getMafiaInventoryContract();
  const latestAirdropTimestamp = await MafiaInventoryContract.methods
    .latestAirdropTimestamp()
    .call();

  return Number(latestAirdropTimestamp);
};

export const getCategoryAvailable = async (categoryId: number) => {
  const MafiaInventoryContract = getMafiaInventoryContract();
  const isAvailable: boolean = await MafiaInventoryContract.methods
    .isCategoryAvailable(categoryId)
    .call();

  return isAvailable;
};

export const wrapOGCrate = async (account: string) => {
  const MafiaInventoryContract = getMafiaInventoryContract(true);

  const data = await MafiaInventoryContract.methods
    .wrapOGCrate()
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const unwrapOGCrate = async (account: string, itemId: number) => {
  const MafiaInventoryContract = getMafiaInventoryContract(true);

  const data = await MafiaInventoryContract.methods
    .unwrapOGCrate(itemId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const wrapOGNFT = async (account: string) => {
  const MafiaInventoryContract = getMafiaInventoryContract(true);

  const data = await MafiaInventoryContract.methods
    .wrapOGNFT()
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const unwrapOGNFT = async (account: string, itemId: number) => {
  const MafiaInventoryContract = getMafiaInventoryContract(true);

  const data = await MafiaInventoryContract.methods
    .unwrapOGNFT(itemId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const unwrapKeyItem = async (account: string, itemId: number) => {
  const MafiaInventoryContract = getMafiaInventoryContract(true);

  const data = await MafiaInventoryContract.methods
    .unwrapKeyItem(itemId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const unwrapMafiaItem = async (account: string, itemId: number) => {
  const MafiaInventoryContract = getMafiaInventoryContract(true);

  const data = await MafiaInventoryContract.methods
    .unwrapMafiaItem(itemId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const unwrapOGNFTItem = async (account: string, itemId: number) => {
  const MafiaInventoryContract = getMafiaInventoryContract(true);

  const data = await MafiaInventoryContract.methods
    .unwrapOGNFTItem(itemId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};
