import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ethers } from "ethers";
import config, { SERVER_URL } from "../config/config";

import { StatType } from "../constants/enum/deposit";
import { Messages } from "../constants/messages";

import { approveToken, getTokenBalance } from "../helper/contractFunctions";
import {
  acceptOTCTrade,
  addNewLiquidity,
  approveInGameCashToDepositContract,
  cancelExpiredOTCOffers,
  cancelOTCTrade,
  convertItems,
  createOTCTrade,
  executeVaultSplitterSwap,
  getAvailablePrices,
  getCashWithEstimateSwap,
  getSwapTokens,
  removeLiquidity,
  swapCash,
  withdrawMAFIA,
} from "../helper/contractFunctions/MafiaExchange";
import { getTotalSupply } from "../helper/contractFunctions/MafiaGameBank";
import { OTCRequestItem } from "../types/Contract/Exchange/OTCRequestItem";

import { SwapToken } from "../types/Contract/SwapToken";
import { DepositHistoryItem } from "../types/DepositHistoryItem";
import { DepositItem } from "../types/DepositItem";

import { toastInfo, toUSDFormat } from "../utils/utils";

export interface ExchangeState {
  isConvertingItems: boolean;
  isCreatingOTC: boolean;
  isAcceptingOTC: boolean;
  isCancelingOTC: boolean;
  isDepositApprove: boolean;
  isAddLiquidity: boolean;
  avgCashPerMafia: number;
  isLoadingAvgCashPerMafia: boolean;
  activeLPs: DepositItem[];
  countStats: number;
  withdrawStat: DepositItem[];
  loadingWithdrawStat: boolean;
  isCancelLiquidity: boolean;

  swapTokenInfo: [SwapToken[], number[]];
  swapTokenBalances: { [key: string]: number };
  estimatedCash: number;
  loadingEstimatedCash: boolean;
  isSwapping: boolean;
  isExecutingSwap: boolean;
  chartHistory: DepositHistoryItem[];
  loadingChartHistory: boolean;
  availablePrices: {
    prices: number[];
    currentRatio: number;
  };

  cashTotalSupply: number;
}

const initialState: ExchangeState = {
  isConvertingItems: false,
  isCreatingOTC: false,
  isAcceptingOTC: false,
  isCancelingOTC: false,
  isDepositApprove: false,
  isAddLiquidity: false,
  avgCashPerMafia: 100,
  isLoadingAvgCashPerMafia: false,
  activeLPs: [],
  countStats: 0,
  withdrawStat: [],
  loadingWithdrawStat: false,
  isCancelLiquidity: false,

  swapTokenInfo: [[], []],
  swapTokenBalances: {},
  estimatedCash: 0,
  loadingEstimatedCash: false,
  isSwapping: false,
  isExecutingSwap: false,
  chartHistory: [],
  loadingChartHistory: false,
  availablePrices: {
    prices: [],
    currentRatio: 0,
  },

  cashTotalSupply: 0,
};

export const convertItemsAction = createAsyncThunk(
  "exchange/convertItemsAction",
  async ({ itemIds, account }: { itemIds: number[]; account: string }) => {
    await convertItems(itemIds, account);
  }
);

export const createOTCTradeAction = createAsyncThunk(
  "exchange/createOTCTradeAction",
  async ({
    itemIds,
    requestItems,
    account,
  }: {
    itemIds: number[];
    requestItems: OTCRequestItem[];
    account: string;
  }) => {
    await createOTCTrade(itemIds, requestItems, account);
  }
);

export const acceptOTCTradeAction = createAsyncThunk(
  "exchange/acceptOTCTradeAction",
  async ({
    offerId,
    itemIds,
    account,
  }: {
    offerId: number;
    itemIds: number[];
    account: string;
  }) => {
    await acceptOTCTrade(offerId, itemIds, account);
  }
);

export const cancelOTCTradeAction = createAsyncThunk(
  "exchange/cancelOTCTradeAction",
  async ({ offerId, account }: { offerId: number; account: string }) => {
    await cancelOTCTrade(offerId, account);
  }
);

export const cancelBulkOTCAction = createAsyncThunk(
  "exchange/cancelBulkOTCAction",
  async ({ offerIds, account }: { offerIds: number[]; account: string }) => {
    await cancelExpiredOTCOffers(offerIds, account);
  }
);

export const approveInGameCashToDepositTicketAction = createAsyncThunk(
  "exchange/approveDepositAction",
  async ({
    account,
    cash,
    cashPerMafia,
  }: {
    account: string;
    cash: number;
    cashPerMafia: number;
  }) => {
    await approveInGameCashToDepositContract(account, Math.ceil(cash));
  }
);

export const addLiquidityTicketAction = createAsyncThunk(
  "exchange/addLiquidity",
  async ({
    account,
    cash,
    cashPerMafia,
  }: {
    account: string;
    cash: number;
    cashPerMafia: number;
  }) => {
    await addNewLiquidity(account, cash, cashPerMafia);
  }
);

export const getRatioData = createAsyncThunk(
  "exchange/getRatioData",
  async () => {
    const response = await axios.get(`${SERVER_URL}/deposit/ratio`);
    return response.data;
  }
);

export const getWithdrawStat = createAsyncThunk(
  "exchange/getWithdrawStat",
  async ({
    statType,
    account,
    pageIndex,
    perPage,
  }: {
    statType: StatType;
    account: string;
    pageIndex: number;
    perPage: number;
  }) => {
    let active, sort, provider;
    let existCash = false;
    if (statType === StatType.AllPosition) {
      active = 1;
      sort = "cashPerMafia";
      provider = undefined;
      existCash = true;
    } else if (statType === StatType.MyActivePos) {
      if (!account) {
        return {
          data: [],
          count: 0,
        };
      }
      active = 1;
      sort = "cashPerMafia";
      provider = account;
    } else if (statType === StatType.MyCompletedPos) {
      if (!account) {
        return {
          data: [],
          count: 0,
        };
      }
      active = 0;
      sort = "cashPerMafia";
      provider = account;
    }
    const response = await axios.get(
      `${SERVER_URL}/deposit/stat?active=${active}&sort=${sort}&pageIndex=${pageIndex}&perPage=${perPage}&existCash=${existCash}${
        provider ? `&provider=${provider}` : ""
      }`
    );
    return response.data;
  }
);

export const getChartHistory = createAsyncThunk(
  "exchange/chartHistory",
  async () => {
    const response = await axios.get(`${SERVER_URL}/deposit/history`);
    return response.data;
  }
);

export const cancelLiquidity = createAsyncThunk(
  "exchange/cancelLiquidty",
  async ({ id, account }: { id: number; account: string }) => {
    await removeLiquidity(id, account);
  }
);

export const withdrawMafia = createAsyncThunk(
  "exchange/withdrawMafia",
  async ({ id, account }: { id: number; account: string }) => {
    await withdrawMAFIA(id, account);
  }
);

export const getAvailablePrice = createAsyncThunk(
  "exchange/getAvailalbePrice",
  async () => {
    const data = await getAvailablePrices();
    return data;
  }
);

export const swapActivity = createAsyncThunk(
  "exchange/swap",
  async ({
    tokenId,
    account,
    amount,
    tokenInfo,
    estimatedCash,
  }: {
    tokenId: number;
    account: string;
    amount: number;
    tokenInfo: SwapToken;
    estimatedCash: number;
  }) => {
    await swapCash(tokenId, account, amount, tokenInfo);
    return estimatedCash;
  }
);

export const approveTokenToDepositTicketAction = createAsyncThunk(
  "exchange/approveTokenToDepositAction",
  async ({
    tokenId,
    account,
    sellTokenAmount,
    tokenInfo,
  }: {
    tokenId: number;
    account: string;
    sellTokenAmount: number;
    tokenInfo: SwapToken;
  }) => {
    await approveToken(
      tokenInfo.tokenAddress,
      Math.ceil(sellTokenAmount),
      config.depositAddress,
      account
    );
  }
);

export const executeSwapAction = createAsyncThunk(
  "exchange/executeSwapAction",
  async (address: string) => {
    await executeVaultSplitterSwap(address);
  }
);

export const getSwapTokenInfo = createAsyncThunk(
  "exchange/getSwapTokenInfo",
  async () => {
    const data = await getSwapTokens();

    const swapTokenList = data[0].map((tokenInfo, index) => {
      return {
        name: tokenInfo.name,
        tokenAddress: tokenInfo.tokenAddress,
        isStable: tokenInfo.isStable,
        isEnabled: tokenInfo.isEnabled,
        price: Number(tokenInfo.price),
        decimal: Number(tokenInfo.decimal),
        tokenId: index,
      } as SwapToken;
    });

    const swapTokenPrice = data[1].map((price) => {
      return Number(ethers.utils.formatEther(price));
    });

    return { swapTokenList, swapTokenPrice };
  }
);

export const getSwapTokenBalance = createAsyncThunk(
  "exchange/getSwapTokenBalance",
  async ({
    account,
    swapTokenList,
  }: {
    account: string;
    swapTokenList: SwapToken[];
  }) => {
    const balanceArray = await Promise.all(
      swapTokenList.map(async (token) => {
        const balance = await getTokenBalance(token.tokenAddress, account);
        return { [token.name]: Number(balance) };
      })
    );

    const balances = balanceArray.reduce((acc, entry) => {
      const key = Object.keys(entry)[0];
      acc[key] = entry[key];
      return acc;
    }, {});

    return balances;
  }
);

export const estimateSwap = createAsyncThunk(
  "exchagne/estimateSwap",
  async ({ amount }: { amount: number }) => {
    const cash = await getCashWithEstimateSwap(amount);
    return cash;
  }
);

export const getCashTotalSupply = createAsyncThunk(
  "exchange/getCashTotalSupply",
  async () => {
    const data = await getTotalSupply();
    return data;
  }
);

export const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(convertItemsAction.pending, (state) => {
      state.isConvertingItems = true;
    });
    builder.addCase(convertItemsAction.fulfilled, (state, { payload }) => {
      state.isConvertingItems = false;
    });
    builder.addCase(convertItemsAction.rejected, (state, { error }) => {
      state.isConvertingItems = false;
    });

    builder.addCase(createOTCTradeAction.pending, (state) => {
      state.isCreatingOTC = true;
    });
    builder.addCase(createOTCTradeAction.fulfilled, (state, { payload }) => {
      state.isCreatingOTC = false;
    });
    builder.addCase(createOTCTradeAction.rejected, (state, { error }) => {
      state.isCreatingOTC = false;
    });

    builder.addCase(acceptOTCTradeAction.pending, (state) => {
      state.isAcceptingOTC = true;
    });
    builder.addCase(acceptOTCTradeAction.fulfilled, (state, { payload }) => {
      state.isAcceptingOTC = false;
    });
    builder.addCase(acceptOTCTradeAction.rejected, (state, { error }) => {
      state.isAcceptingOTC = false;
    });

    builder.addCase(cancelOTCTradeAction.pending, (state) => {
      state.isCancelingOTC = true;
    });
    builder.addCase(cancelOTCTradeAction.fulfilled, (state, { payload }) => {
      state.isCancelingOTC = false;
    });
    builder.addCase(cancelOTCTradeAction.rejected, (state, { error }) => {
      state.isCancelingOTC = false;
      console.log(error);
    });

    builder.addCase(cancelBulkOTCAction.pending, (state) => {
      state.isCancelingOTC = true;
    });
    builder.addCase(cancelBulkOTCAction.fulfilled, (state, { payload }) => {
      state.isCancelingOTC = false;
    });
    builder.addCase(cancelBulkOTCAction.rejected, (state, { error }) => {
      state.isCancelingOTC = false;
      console.log(error);
    });

    builder.addCase(approveInGameCashToDepositTicketAction.pending, (state) => {
      state.isDepositApprove = true;
    });
    builder.addCase(
      approveInGameCashToDepositTicketAction.fulfilled,
      (state, { payload }) => {
        state.isDepositApprove = false;
      }
    );
    builder.addCase(
      approveInGameCashToDepositTicketAction.rejected,
      (state, { error }) => {
        state.isDepositApprove = false;
        console.log(error);
      }
    );

    builder.addCase(addLiquidityTicketAction.pending, (state) => {
      state.isAddLiquidity = true;
    });
    builder.addCase(
      addLiquidityTicketAction.fulfilled,
      (state, { payload }) => {
        state.isAddLiquidity = false;
      }
    );
    builder.addCase(addLiquidityTicketAction.rejected, (state, { error }) => {
      state.isAddLiquidity = false;
      console.log(error);
    });

    builder.addCase(executeSwapAction.pending, (state) => {
      state.isExecutingSwap = true;
    });

    builder.addCase(executeSwapAction.fulfilled, (state) => {
      state.isExecutingSwap = false;
    });

    builder.addCase(executeSwapAction.rejected, (state) => {
      state.isExecutingSwap = false;
    });

    builder.addCase(getRatioData.pending, (state) => {
      state.isLoadingAvgCashPerMafia = true;
    });
    builder.addCase(getRatioData.fulfilled, (state, { payload }) => {
      state.isLoadingAvgCashPerMafia = false;
      state.avgCashPerMafia = payload.avgCashPerMafia;
      state.activeLPs = payload.activeData;
    });
    builder.addCase(getRatioData.rejected, (state, { error }) => {
      state.isLoadingAvgCashPerMafia = false;
      console.log(error);
    });

    builder.addCase(getWithdrawStat.pending, (state) => {
      state.loadingWithdrawStat = true;
    });
    builder.addCase(getWithdrawStat.fulfilled, (state, { payload }) => {
      state.loadingWithdrawStat = false;
      state.withdrawStat = payload.data;
      state.countStats = payload.count;
    });
    builder.addCase(getWithdrawStat.rejected, (state, { error }) => {
      state.loadingWithdrawStat = false;
    });

    builder.addCase(cancelLiquidity.pending, (state) => {
      state.isCancelLiquidity = true;
    });
    builder.addCase(cancelLiquidity.fulfilled, (state, { payload }) => {
      state.isCancelLiquidity = false;
    });
    builder.addCase(cancelLiquidity.rejected, (state, { payload }) => {
      state.isCancelLiquidity = false;
    });

    builder.addCase(withdrawMafia.pending, (state) => {
      state.isCancelLiquidity = true;
    });
    builder.addCase(withdrawMafia.fulfilled, (state, { payload }) => {
      state.isCancelLiquidity = false;
    });
    builder.addCase(withdrawMafia.rejected, (state, { payload }) => {
      state.isCancelLiquidity = false;
    });

    builder.addCase(getSwapTokenInfo.pending, (state) => {});
    builder.addCase(getSwapTokenInfo.fulfilled, (state, { payload }) => {
      state.swapTokenInfo = [payload.swapTokenList, payload.swapTokenPrice];
    });
    builder.addCase(getSwapTokenInfo.rejected, (state, { error }) => {});

    builder.addCase(getSwapTokenBalance.pending, (state) => {});
    builder.addCase(getSwapTokenBalance.fulfilled, (state, { payload }) => {
      state.swapTokenBalances = payload;
    });
    builder.addCase(getSwapTokenBalance.rejected, (state, { error }) => {
      console.error(error);
    });

    builder.addCase(estimateSwap.pending, (state) => {
      state.estimatedCash = 0;
      state.loadingEstimatedCash = true;
    });
    builder.addCase(estimateSwap.fulfilled, (state, { payload }) => {
      state.estimatedCash = payload;
      state.loadingEstimatedCash = false;
    });
    builder.addCase(estimateSwap.rejected, (state, { error }) => {
      console.error(error);
      state.loadingEstimatedCash = false;
    });

    builder.addCase(swapActivity.pending, (state) => {
      state.isSwapping = true;
    });
    builder.addCase(swapActivity.fulfilled, (state, { payload }) => {
      toastInfo(Messages.EXCHANGE.SWAP.SWAP_SUCCEED, {
        cash: toUSDFormat(payload, 0),
      });
      state.isSwapping = false;
    });
    builder.addCase(swapActivity.rejected, (state, { error }) => {
      state.isSwapping = false;
    });

    builder.addCase(approveTokenToDepositTicketAction.pending, (state) => {
      state.isSwapping = true;
    });
    builder.addCase(
      approveTokenToDepositTicketAction.fulfilled,
      (state, { payload }) => {
        state.isSwapping = false;
      }
    );
    builder.addCase(
      approveTokenToDepositTicketAction.rejected,
      (state, { error }) => {
        state.isSwapping = false;
      }
    );

    builder.addCase(getChartHistory.pending, (state) => {
      state.loadingChartHistory = true;
    });
    builder.addCase(getChartHistory.fulfilled, (state, { payload }) => {
      state.loadingChartHistory = false;
      state.chartHistory = payload.data;
    });
    builder.addCase(getChartHistory.rejected, (state, { error }) => {
      state.loadingChartHistory = false;
    });

    builder.addCase(getAvailablePrice.pending, (state) => {});
    builder.addCase(getAvailablePrice.fulfilled, (state, { payload }) => {
      state.availablePrices = {
        prices: payload,
        currentRatio: payload.length === 0 ? 0 : payload[payload.length - 1],
      };
    });
    builder.addCase(getAvailablePrice.rejected, (state, { error }) => {});

    builder.addCase(getCashTotalSupply.pending, (state) => {});
    builder.addCase(getCashTotalSupply.fulfilled, (state, { payload }) => {
      state.cashTotalSupply = payload;
    });
    builder.addCase(getCashTotalSupply.rejected, (state, { error }) => {});
  },
});

export default exchangeSlice.reducer;
