import { BuildingType, BusinessType, RarityType, SlotType } from "./enum/map";

export const BusinessEffectiveness = [20, 40, 60, 100];

export const Rarity = {
  [RarityType.Strategic]: {
    name: "Strategic",
    color: "#9c671c",
  },
  [RarityType.Common]: {
    name: "Common",
    color: "#848484",
  },
  [RarityType.Upper]: {
    name: "Upper",
    color: "#99351d",
  },
  [RarityType.Ellite]: {
    name: "Elite",
    color: "#8a23d9",
  },
};

export const CashExchangeSlotUSD = {
  [RarityType.Strategic]: 50,
  [RarityType.Common]: 4,
  [RarityType.Upper]: 8,
  [RarityType.Ellite]: 40,
};

export const BuildingTypeOptions = {
  [BuildingType.Grass]: {
    name: "Empty tile",
    logo: "00.png",
  },
  [BuildingType.Shed]: {
    name: "Shed",
    logo: "01.png",
  },
  [BuildingType.House]: {
    name: "House",
    logo: "13.png",
  },
  [BuildingType.Villa]: {
    name: "Villa",
    logo: "25.png",
  },
  [BuildingType.Office]: {
    name: "Office",
    logo: "37.png",
  },
  [BuildingType.Apartment]: {
    name: "Apartment",
    logo: "49.png",
  },
  [BuildingType.Mansion]: {
    name: "Mansion",
    logo: "61.png",
  },
  [BuildingType.Hotel]: {
    name: "Hotel",
    logo: "73.png",
  },
};

export const BusinessOptions = {
  [BusinessType.CarCrusher]: {
    name: "Car crusher",
    logo: "85.png",
  },
  [BusinessType.ShopOwner]: {
    name: "Gunstore",
    logo: "86.png",
  },
  [BusinessType.Bank]: {
    name: "Bank",
    logo: "87.png",
  },
  [BusinessType.Hospital]: {
    name: "Hospital",
    logo: "88.png",
  },
  [BusinessType.DetectiveAgency]: {
    name: "Detective agency",
    logo: "89.png",
  },
  [BusinessType.BoozeWareHouse]: {
    name: "Booze warehouse",
    logo: "90.png",
  },
  [BusinessType.Narcotics]: {
    name: "Narchtics warehouse",
    logo: "91.png",
  },
  [BusinessType.SLotmachine]: {
    name: "Slotmachine",
    logo: "92.png",
  },
  [BusinessType.Roulette]: {
    name: "Roulette",
    logo: "93.png",
  },
  [BusinessType.BulletFactory]: {
    name: "Bullet factory",
    logo: "94.png",
  },
};

export const BoostPercentage = {
  [RarityType.Common]: 0.1,
  [RarityType.Upper]: 0.5,
  [RarityType.Ellite]: 1,
  [RarityType.Strategic]: 0.1,
};

export const ConnectorsBoost = [
  0, 0.07, 0.1, 0.15, 0.3, 0.38, 0.56, 0.64, 0.92,
];

export const BasicSlotWorth = {
  [BuildingType.Grass]: 20,
  [BuildingType.Shed]: 35,
  [BuildingType.House]: 50,
  [BuildingType.Villa]: 75,
  [BuildingType.Office]: 125,
  [BuildingType.Apartment]: 125,
  [BuildingType.Mansion]: 175,
  [BuildingType.Hotel]: 300,
};
