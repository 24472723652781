import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "./index.styles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import {
  getProposalsAction,
  handleEditDaoAction,
} from "../../reducers/dao.slice";
import useWallet from "../../hook/useWallet";
import { toastSuccess } from "../../utils/utils";

interface EditPopupInterface {
  openModal: boolean;
  handleClose: () => void;
}
const EditPopup = ({ openModal, handleClose }: EditPopupInterface) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { account } = useWallet();

  const { manageRoundId, proposals, options } = useAppSelector(
    (state) => state.dao
  );

  const [targetIndex, setTargetIndex] = useState(-1);
  const [optionIds, setOptionIds] = useState<number[]>([]);
  const [links, setLinks] = useState<string[]>([]);

  useEffect(() => {
    const index = proposals.findIndex((item) => item.id === manageRoundId);
    setTargetIndex(index);
    setOptionIds(options[manageRoundId]?.map((option, index) => index));
    setLinks(options[manageRoundId]?.map((option) => option.delieveryLink));
  }, [proposals, manageRoundId, options]);

  const handleChangeLink = (optionId: number, link: string) => {
    const updatedLinks = [...links];
    updatedLinks[optionId] = link;
    setLinks(updatedLinks);
  };

  const handleEditDao = () => {
    dispatch(
      handleEditDaoAction({ account, optionIds, links, roundId: manageRoundId })
    )
      .unwrap()
      .then(() => {
        toastSuccess("Successfully updated");
        dispatch(getProposalsAction());
        handleClose()
      })
      .catch();
  };

  return (
    <Dialog open={openModal} onClose={handleClose} className={classes.body}>
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Typography className={classes.modalTitle}>
            Edit Marketing DAO
          </Typography>
          Round {(proposals[targetIndex]?.id || 0) + 1}
          {options[targetIndex]?.map((option, index) => (
            <Box className={classes.editBox} key={index}>
              <Box sx={{ maxWidth: "150px" }}>{option.title}</Box>
              <TextField
                placeholder="Delivery"
                value={links[index]}
                onChange={(e) => {
                  handleChangeLink(index, e.target.value);
                }}
                className={classes.inputField}
                type="text"
              />
            </Box>
          ))}
          <Button className={classes.createDaoButton} onClick={handleEditDao}>
            Edit
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default EditPopup;
