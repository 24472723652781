import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Theme, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ProfileInventoryItem } from "../../ProfileInventory";
import OTCItemSelection from "./OTCItemSelection";
import OTCSlotSelection from "./OTCSlotSelection";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  BUSINESS_CATEGORY_ID,
  CitySimple,
  CONFIRMATION_DELAY,
  ItemCategoryInfoList,
  OTC_TRADE_MAX_ITEM,
} from "../../../constants/const";
import { Errors } from "../../../constants/errors";
import { Messages } from "../../../constants/messages";

import { dispatchTxAction } from "../../../helper/dispatchTxAction";
import { getInventoryItemInfo } from "../../../helper/inventory";
import useGameBankBalance from "../../../hook/useGameBankBalance";
import useWallet from "../../../hook/useWallet";
import { createOTCTradeAction } from "../../../reducers/exchange.slice";
import { getMyInventoryItems } from "../../../reducers/profile.slice";
import { CustomTypeBackground, CustomTypeText } from "../../../theme";
import { OTCRequestItem } from "../../../types/Contract/Exchange/OTCRequestItem";
import { ItemInfo } from "../../../types/ItemInfo";
import { toastError, toastInfo, toastSuccess } from "../../../utils/utils";

import moneyIcon from "../../../assets/imgs/exchange/money.png";
import tradeIcon from "../../../assets/imgs/exchange/transfer.png";


import { ItemCategory } from "../../../constants/enum/enum";
import { SlotType } from "../../../constants/enum/map";
import useStyles from "../index.styles";

interface OTCPanelProps {
  handleBackToOTCDesk: () => void;
}

const OTCPanel = ({ handleBackToOTCDesk }: OTCPanelProps) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const theme = useTheme<Theme>();
  const { account } = useWallet();
  const background = theme.palette.background as CustomTypeBackground;
  const text = theme.palette.text as CustomTypeText;
  const navigate = useNavigate();

  const { signMsg, signature } = useAppSelector((state) => state.auth);
  const { balance } = useGameBankBalance(account, signMsg, signature);

  const { myInventoryItems, myProfile, mySlotItems } = useAppSelector(
    (state) => state.profile
  );

  const [selectedItems, setSelectedItems] = useState<ItemInfo[]>([]);
  const [optionItems, setOptionItems] = useState<ItemInfo[]>([]);
  const [openOTCItemSelection, setOpenOTCItemSelection] = useState(false);
  const [openOTCSlotSelection, setOpenOTCSlotSelection] = useState(false);
  const [selectedFee, setSelectedFee] = useState(0);

  const sortedItem = useMemo(() => {
    return [...myInventoryItems].sort(
      (item1, item2) =>
        ItemCategoryInfoList[item2.categoryId].inventoryWorth[item2.typeId] -
          ItemCategoryInfoList[item1.categoryId].inventoryWorth[item1.typeId] ||
        item1.categoryId - item2.categoryId
    );
  }, [myInventoryItems]);

  const handleConfirmConversion = () => {
    if (!account) return;
    const itemIds = selectedItems.map((item, index) => item.itemId);
    if (itemIds.length === 0) {
      toastError(Errors.EXCHANGE.OTC.TRADE_ITEM_NOT_SELECTED);
    } else if (optionItems.length === 0) {
      toastError(Errors.EXCHANGE.OTC.OPTION_ITEM_NOT_SELECTED);
    } else {
      const requestItems: OTCRequestItem[] = [];
      optionItems.forEach((item, index) => {
        if (item.categoryId === BUSINESS_CATEGORY_ID) {
          requestItems.push({
            itemType: 1,
            categoryId: item.categoryId,
            typeId: item.typeId,
            cityId: item.cityId,
            x: 0,
            y: 0,
            mapCityId: -1,
            slotSubType: -1,
          });
        } else if (item.categoryId === ItemCategory.LANDSLOT) {
          requestItems.push({
            itemType: 2,
            categoryId: item.categoryId,
            typeId: item.typeId,
            cityId: item.cityId,
            x: item.slotX || 0,
            y: item.slotY || 0,
            mapCityId: item.cityId,
            slotSubType: -1,
          });
        } else {
          requestItems.push({
            itemType: 0,
            categoryId: item.categoryId,
            typeId: item.typeId,
            cityId: 0,
            x: 0,
            y: 0,
            mapCityId: -1,
            slotSubType: -1,
          });
        }
      });

      dispatchTxAction(
        dispatch,
        createOTCTradeAction({ itemIds, requestItems, account }),
        () => {
          toastSuccess(Messages.EXCHANGE.OTC.TRADE_CREATED);
          dispatch(getMyInventoryItems({ userId: myProfile.id || 0 }));
          setSelectedItems([]);
          navigate("/exchange/trade");
        },
        CONFIRMATION_DELAY * 3
      );
    }
  };

  const handleCloseItemSelection = () => {
    setOpenOTCItemSelection(false);
  };

  const handleCloseSlotSelection = () => {
    setOpenOTCSlotSelection(false);
  };

  const handleItemSelect = useCallback(
    (item: ItemInfo) => {
      if (selectedItems.length >= OTC_TRADE_MAX_ITEM) {
        toastInfo(Messages.EXCHANGE.BASIC.ITEM_SELECT_LIMIT);
        return;
      }

      const indexInSelectedItems = selectedItems
        .map((item) => item.itemId)
        .indexOf(item.itemId);

      if (indexInSelectedItems >= 0)
        setSelectedItems((items) => {
          return [
            ...items.slice(0, indexInSelectedItems),
            ...items.slice(indexInSelectedItems + 1, items.length),
          ];
        });
      else {
        setSelectedItems((items) => [...items, item]);
      }
    },
    [selectedItems]
  );

  const handleOptionItemClick = (item: ItemInfo) => {
    const indexInOptionItems = optionItems
      .map((item) => item.itemId)
      .indexOf(item.itemId);

    setOptionItems((items) => {
      return [
        ...items.slice(0, indexInOptionItems),
        ...items.slice(indexInOptionItems + 1, items.length),
      ];
    });
  };

  const handleAddItems = (items: ItemInfo[]) => {
    if (optionItems.length + items.length > 5) {
      toastError(Errors.EXCHANGE.OTC.MAXIMUM_FIVE_ITEMS);
      return;
    }
    setOptionItems((prev) => [...prev, ...items]);
  };

  useEffect(() => {
    setSelectedFee(
      selectedItems.findIndex(
        (item, index) => item.categoryId === BUSINESS_CATEGORY_ID
      ) >= 0 ||
        optionItems.findIndex(
          (item, index) => item.categoryId === BUSINESS_CATEGORY_ID
        ) >= 0
        ? 750000
        : selectedItems.findIndex(
            (item, index) => item.categoryId === ItemCategory.LANDSLOT
          ) >= 0 ||
          optionItems.findIndex(
            (item, index) => item.categoryId === ItemCategory.LANDSLOT
          ) >= 0
        ? 250000
        : 50000
    );
  }, [optionItems, selectedItems]);

  const renderItems = useCallback(() => {
    return (
      <>
        {sortedItem.map((item, index) => {
          const indexInSelectedItems = selectedItems
            .map((item) => item.itemId)
            .indexOf(item.itemId);
          return (
            <ProfileInventoryItem
              onClick={() => {
                handleItemSelect(item);
              }}
              item={item}
              key={index}
              selected={indexInSelectedItems >= 0}
            />
          );
        })}
      </>
    );
  }, [handleItemSelect, selectedItems, sortedItem]);

  return (
    <>
      <Box className={classes.exchangeInfo}>
        <Box
          className={classes.exchangeCard}
          sx={{
            minHeight: 480,
          }}
        >
          <Typography
            fontFamily={"Philosopher"}
            color={"white"}
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
          >
            What do you offer?
          </Typography>
          <Typography
            fontFamily={"Philosopher"}
            color={text.grayColor}
            textAlign={"center"}
            fontSize={12}
            fontWeight={700}
          >
            {selectedItems.length} item selected
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "160px",
              mt: "16px",
              backgroundColor: background.gray,
              borderRadius: "16px",
              padding: "4px",
              display: "flex", // Flex container
              flexWrap: "wrap", // Allow wrapping
              gap: "4px", // Optional: Add gap between buttons
              alignContent: "flex-start",
            }}
          >
            {selectedItems.map((item, index) => {
              const itemInfo = getInventoryItemInfo(
                item.categoryId,
                item.typeId
              );
              const slotData = mySlotItems.find(
                (slotInfo) => slotInfo.inventoryItemId === item.itemId
              );

              return (
                <Button
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    padding: "2px 8px",
                    border: "2px solid",
                    borderColor: background.blackBorder,
                    color: text.grayColor,
                    borderRadius: "16px",
                    userSelect: "none",
                    fontSize: "12px",
                    fontWeight: "700",
                    cursor: "pointer",
                    fontFamily: "Philosopher",
                    height: "fit-content",
                    width: "max-content",
                  }}
                  onClick={() => {
                    const indexInSelectedItems = selectedItems
                      .map((item) => item.itemId)
                      .indexOf(item.itemId);

                    setSelectedItems((items) => {
                      return [
                        ...items.slice(0, indexInSelectedItems),
                        ...items.slice(indexInSelectedItems + 1, items.length),
                      ];
                    });
                  }}
                >
                  {item.categoryId === ItemCategory.LANDSLOT &&
                  slotData &&
                  slotData.slotType === SlotType.UpgradableSlot
                    ? `#${CitySimple[slotData.city]}-${slotData.slotX}-${
                        slotData.slotY
                      }`
                    : itemInfo.itemName}
                  <CloseIcon htmlColor="gray" fontSize="small" />
                </Button>
              );
            })}
          </Box>

          <Box
            sx={{
              mt: 2,
              display: "grid",
              gap: "8px",
              gridTemplateColumns: "repeat(auto-fill, minmax(66px, 1fr))",
              overflowY: "scroll",
              maxHeight: "200px",
              padding: "16px",
            }}
          >
            {renderItems()}
          </Box>
        </Box>

        <Box className={classes.fees}>
          <Box
            className={classes.feeInfo}
            sx={{
              gap: 1,
            }}
          >
            <Typography
              fontFamily={"Philosopher"}
              color={"white"}
              textAlign={"center"}
              fontSize={18}
              fontWeight={700}
            >
              OTC
            </Typography>

            <Box
              component={"img"}
              src={tradeIcon}
              sx={{
                width: 40,
                height: 40,
                top: 42,
                filter: "blur(10px)",
                position: "absolute",
              }}
            ></Box>

            <Box
              component={"img"}
              src={tradeIcon}
              sx={{
                width: 40,
                height: 40,
                zIndex: 1,
              }}
            ></Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box
                component="img"
                src={moneyIcon}
                sx={{
                  width: 16,
                  height: 16,
                }}
              />
              <Typography
                fontFamily={"Philosopher"}
                color={text.grayColor}
                textAlign={"center"}
                fontSize={14}
                fontWeight={700}
              >
                Fee
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                fontFamily={"Philosopher"}
                color={selectedFee === 50000 ? "white" : text.grayColor}
                textAlign={"center"}
                fontSize={14}
                fontWeight={700}
              >
                50,000
              </Typography>
              <Typography
                fontFamily={"Philosopher"}
                color={selectedFee === 250000 ? "white" : text.grayColor}
                textAlign={"center"}
                fontSize={14}
                fontWeight={700}
              >
                250,000
              </Typography>
              <Typography
                fontFamily={"Philosopher"}
                color={selectedFee === 750000 ? "white" : text.grayColor}
                textAlign={"center"}
                fontSize={14}
                fontWeight={700}
              >
                750,000
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.exchangeCard}>
          <Typography
            fontFamily={"Philosopher"}
            color={"white"}
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
            mt={1}
          >
            What do you want?
          </Typography>
          <Typography
            fontFamily={"Philosopher"}
            color={"white"}
            fontSize={16}
            fontWeight={700}
            mt={1}
          >
            Option
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "160px",
              mt: "16px",
              backgroundColor: background.gray,
              borderRadius: "16px",
              padding: "4px",
              display: "flex", // Flex container
              flexWrap: "wrap", // Allow wrapping
              gap: "4px", // Optional: Add gap between buttons
              alignContent: "flex-start",
              overflowY: "scroll",
            }}
          >
            {optionItems.map((item, index) => {
              const itemInfo = getInventoryItemInfo(
                item.categoryId,
                item.typeId,
                item.cityId
              );
              return (
                <Button
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    padding: "2px 8px",
                    border: "2px solid",
                    borderColor: background.blackBorder,
                    color: text.grayColor,
                    borderRadius: "16px",
                    userSelect: "none",
                    fontSize: "12px",
                    fontWeight: "700",
                    cursor: "pointer",
                    fontFamily: "Philosopher",
                    height: "fit-content",
                    width: "max-content",
                  }}
                  onClick={() => {
                    handleOptionItemClick(item);
                  }}
                >
                  {item.categoryId === ItemCategory.LANDSLOT
                    ? `#${CitySimple[item.cityId]}-${item.slotX}-${item.slotY}`
                    : itemInfo.itemName}
                  <CloseIcon htmlColor="gray" fontSize="small" />
                </Button>
              );
            })}
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 8,
              alignItems: "center",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Button
                className={classes.addItemButton}
                onClick={() => {
                  setOpenOTCItemSelection(true);
                }}
              >
                Add items
              </Button>

              <Button
                className={classes.addItemButton}
                onClick={() => {
                  setOpenOTCSlotSelection(true);
                }}
              >
                Add land slots
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Button
                className={classes.confirm}
                onClick={handleConfirmConversion}
                sx={{
                  textTransform: "none",
                }}
                disabled={selectedFee > balance}
              >
                Confirm OTC Trade
              </Button>

              {selectedFee > balance && (
                <Typography
                  fontFamily={"Philosopher"}
                  fontSize={12}
                  color={text.brightGray}
                >
                  Insufficient cash balance
                </Typography>
              )}
            </Box>
          </Box>
        </Box>

        <OTCItemSelection
          openOTCItemSelection={openOTCItemSelection}
          handleClose={handleCloseItemSelection}
          handleAddItems={handleAddItems}
        />

        <OTCSlotSelection
          openOTCSlotSelection={openOTCSlotSelection}
          handleClose={handleCloseSlotSelection}
          handleAddItems={handleAddItems}
        />
      </Box>
    </>
  );
};

export default OTCPanel;
