import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    justifyContent: "center",
    padding: "0 16px",
    boxSizing: "border-box",
    maxWidth: 1920,
    margin: "auto",
    marginTop: -64,
    minHeight: 1000,
    background: "url(/assets/imgs/map/city_selection_bg.png) no-repeat",
    backgroundSize: "100% auto",
    backgroundPosition: "right top",
    WebkitMaskImage:
      "-webkit-gradient(linear, left 85%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",

    [theme.breakpoints.down("sm")]: {
      padding: "16px",
      backgroundSize: "auto 400px",
      backgroundPosition: "top",
      WebkitMaskImage:
        "-webkit-gradient(linear, left 85%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
    },
  },

  body: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    display: "flex",
    flexDirection: "column",
    maxWidth: 1500,
    margin: "auto",
    paddingTop: 64,
  },
}));

export default styles;
