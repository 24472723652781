import { ethers } from "ethers";
import { Proposal } from "../types/Contract/Dao/Proposal";
import { ProposalOption } from "../types/Contract/Dao/ProposalOption";
import { ProposalVote } from "../types/Contract/Dao/ProposalVote";

export const parseProposals = (data: any) => {
  return data[0].map((proposal: any) => {
    return {
      id: Number(proposal.id),
      status: Number(proposal.status),
      optionCount: Number(proposal.optionCount),
      chosenOption: Number(proposal.chosenOption),
      openedAt: Number(proposal.openedAt),
      closedAt: Number(proposal.closedAt),
      duration: Number(proposal.duration),
      totalUsers: Number(proposal.totalUsers),
      totalMafia: Number(ethers.utils.formatEther(proposal.totalMafia)),
    } as Proposal;
  });
};

export const parseProposalOptions = (data: any): ProposalOption[][] => {
  return data[1].map(
    (proposals: any) =>
      proposals.map((proposal: any) => {
        return {
          adminFee: Number(proposal.adminFee),
          cost: Number(proposal.cost),
          delieveryLink: proposal.delieveryLink,
          title: proposal.title,
        } as unknown as ProposalOption;
      }) as ProposalOption[]
  );
};

export const parseProposalVotes = (data: any): number[][] => {
  return data[2].map((votes: any) =>
    votes.map((vote: any) => Number(ethers.utils.formatEther(vote)))
  );
};

export const parseProposalUserVotes = (data: any): ProposalVote[] => {
  return data.map((vote: any) => ({
    amount: Number(ethers.utils.formatEther(vote.amount)),
    isVoted: Boolean(vote.isVoted),
    isWithdrawn: Boolean(vote.isWithdrawn),
    optionId: Number(vote.optionId),
  }));
};
