import { Box, Collapse, Typography } from "@mui/material";
import { useState } from "react";

import DaoRoundHeader from "./DaoRoundHeader";
import DaoRoundOptionItem from "./DaoRoundOptionItem";

import { DaoProposalStatus } from "../../constants/enum/enum";
import { setTargetOption } from "../../reducers/dao.slice";
import { ProposalOption } from "../../types/Contract/Dao/ProposalOption";

import useStyles from "./index.styles";
import { useAppDispatch } from "../../app/hooks";

interface DaoRoundListItemProps {
  roundNumber: number;
  userCount?: number;
  totalVotes?: number;
  timeLeft?: number;
  roundStatus: DaoProposalStatus;
  options: ProposalOption[];
  showVotePopup: () => void;
  showManagePopup: () => void;
}

const DaoRoundListItem: React.FC<DaoRoundListItemProps> = ({
  roundNumber,
  userCount,
  totalVotes,
  timeLeft,
  roundStatus,
  options,
  showVotePopup,
  showManagePopup,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(false);

  const handleShowVotePopup = (optionId: number) => {
    dispatch(setTargetOption({ roundId: roundNumber, optionId }));
    showVotePopup();
  };

  return (
    <Box
      className={classes.daoRoundListItem}
      sx={{
        marginBottom: roundStatus === DaoProposalStatus.Closed ? "" : "",
      }}
    >
      <DaoRoundHeader
        roundNumber={roundNumber}
        userCount={userCount}
        totalVotes={totalVotes}
        timeLeft={timeLeft}
        roundStatus={roundStatus}
        expanded={expanded}
        setExpanded={setExpanded}
        showManagePopup={showManagePopup}
      />

      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{ width: "100%" }}
      >
        <Box className={classes.daoRoundDetailOptionList}>
          <Box className={classes.optionHeader}>
            <Typography>Options</Typography>
            <Typography>Voting</Typography>
            <Typography>Cost</Typography>
            <Typography>Admin fee</Typography>
            <Typography>Delivery</Typography>
          </Box>

          <Box className={classes.optionList}>
            {options.map((option, index) => (
              <DaoRoundOptionItem
                key={index}
                optionTitle={option.title}
                status={roundStatus}
                cost={option.cost}
                adminFee={option.adminFee}
                deliveryLink={option.delieveryLink}
                isDelivered={option.delieveryLink !== ""}
                handleShowVotePopup={() => handleShowVotePopup(index)}
                roundId={roundNumber}
                optionId={index}
              />
            ))}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default DaoRoundListItem;
