import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import config, { SERVER_URL } from "../config/config";

import { approveGameCash } from "../helper/contractFunctions/MafiaGameBank";
import {
  depositLiquidity,
  finishBet,
  getRouletteLiquidity,
  getRoulettes,
  getUserBetInfo,
  initializeBet,
  removeLiquidity,
  updateRouletteSettings,
} from "../helper/contractFunctions/MafiaRoulette";

import { Roulette } from "../types/Contract/Roulette/Roulette";
import { RouletteBet } from "../types/Contract/Roulette/RouletteBet";
import { RouletteBetResult } from "../types/RouletteBetResult";
import { RouletteBetHistory } from "../types/RouletteBetHistory";
import { RouletteBetStatus } from "../constants/enum/roulette";
import { RouletteProfile } from "../types/RouletteProfile";

export interface IRouletteBet {
  type: string;
  typeText: string;
  text: string;
  backColor: string;
  multi: number;
  value: number;
}

export interface RouletteState {
  isSpinning: boolean;
  currentNonce: number;
  currentRound: number;
  bets: IRouletteBet[];
  defaultBetAmount: number;

  currentRouletteId: number;
  currentRouletteLiquidity: number;

  isRouletteListLoading: boolean;
  isInitializingBet: boolean;
  isFinishingBet: boolean;
  isUpdatingRouletteSettings: boolean;
  isGettingRouletteLiquidity: boolean;
  isAddingLiquidity: boolean;
  isRemovingLiquidity: boolean;
  isApprovingGameCash: boolean;
  isGettingBetHistory: boolean;

  betResult?: RouletteBetResult;

  betStatus: RouletteBetStatus;
  betDisabled: boolean;

  pastBetResults: RouletteBetResult[];

  betHistory: RouletteBetHistory[];

  roulettes: Roulette[];

  rouletteBetHistory: RouletteBetHistory[];
  rouletteProfile: RouletteProfile | null;
  loadingRouletteProfile: boolean;
}

const initialState: RouletteState = {
  isSpinning: false,
  currentNonce: 0,
  currentRound: 0,
  bets: [],
  defaultBetAmount: 1000,

  currentRouletteId: -1,
  currentRouletteLiquidity: 0,

  isRouletteListLoading: false,
  isFinishingBet: false,
  isInitializingBet: false,
  isUpdatingRouletteSettings: false,
  isGettingRouletteLiquidity: false,
  isAddingLiquidity: false,
  isRemovingLiquidity: false,
  isApprovingGameCash: false,
  isGettingBetHistory: false,

  betStatus: RouletteBetStatus.NOT_REQUESTED,
  betDisabled: false,

  // Test bet result
  // betResult: {
  //   betAmount: 10000,
  //   betId: 0,
  //   block: 0,
  //   feeAmount: 0,
  //   nonce: 4,
  //   player: "0xaD3f2043D1C1F42427F4Ca893B637F629647ae16",
  //   rewardReceived: 360000,
  //   rouletteId: 0,
  //   timestamp: 0,
  //   totalReward: 360000,
  // },

  pastBetResults: [],

  betHistory: [],

  roulettes: [],

  rouletteBetHistory: [],
  rouletteProfile: null,
  loadingRouletteProfile: false,
};

export const getRoulettesAction = createAsyncThunk(
  "roulette/getRoulettesAction",
  async () => {
    const roulettes = await getRoulettes();

    return roulettes;
  }
);

export const getUserBetInfoAction = createAsyncThunk(
  "roulette/getUserBetInfoAction",
  async ({ account, rouletteId }: { account: string; rouletteId: number }) => {
    const userBetInfo = await getUserBetInfo(account, rouletteId);

    return userBetInfo;
  }
);

export const initializeBetAction = createAsyncThunk(
  "roulette/initializeBetAction",
  async ({
    account,
    rouletteId,
    bets,
  }: {
    account: string;
    rouletteId: number;
    bets: RouletteBet[];
  }) => {
    const data = await initializeBet(account, rouletteId, bets);

    return data.transactionHash;
  }
);

export const finishBetAction = createAsyncThunk(
  "roulette/finishBetAction",
  async ({ account, rouletteId }: { account: string; rouletteId: number }) => {
    const data = await finishBet(account, rouletteId);

    return data.transactionHash;
  }
);

export const updateRouletteSettingsAction = createAsyncThunk(
  "roulette/updateRouletteSettingsAction",
  async ({
    account,
    rouletteId,
    isOpened,
    minBet,
    maxBet,
  }: {
    account: string;
    rouletteId: number;
    isOpened: boolean;
    minBet: number;
    maxBet: number;
  }) => {
    const data = await updateRouletteSettings(
      account,
      rouletteId,
      isOpened,
      minBet,
      maxBet
    );

    return data.transactionHash;
  }
);

export const getRouletteLiquidityAction = createAsyncThunk(
  "roulette/getRouletteLiquidityAction",
  async ({
    rouletteId,
    message,
    signature,
  }: {
    rouletteId: number;
    message: string;
    signature: string;
  }) => {
    console.log(rouletteId, message, signature);
    const liquidity = await getRouletteLiquidity(
      rouletteId,
      message,
      signature
    );
    return Number(liquidity);
  }
);

export const addLiquidityAction = createAsyncThunk(
  "roulette/addLiquidityAction",
  async ({
    account,
    rouletteId,
    amount,
  }: {
    account: string;
    rouletteId: number;
    amount: number;
  }) => {
    const data = await depositLiquidity(account, rouletteId, amount);

    return data.transactionHash;
  }
);

export const removeLiquidityAction = createAsyncThunk(
  "roulette/removeLiquidityAction",
  async ({
    account,
    rouletteId,
    amount,
  }: {
    account: string;
    rouletteId: number;
    amount: number;
  }) => {
    const data = await removeLiquidity(account, rouletteId, amount);

    return data.transactionHash;
  }
);

export const approveGameCashAction = createAsyncThunk(
  "roulette/approveGameCashAction",
  async ({
    tokenAmount,
    account,
  }: {
    tokenAmount: number;
    account: string;
  }) => {
    const data = await approveGameCash(
      tokenAmount,
      config.rouletteAddress,
      account
    );

    return data.transactionHash || "";
  }
);

export const getBetHistoryAction = createAsyncThunk(
  "roulette/getBetHistoryAction",
  async ({ account }: { account: string }) => {
    const response = await axios.get(
      `${SERVER_URL}/business/roulette/history/${account}`
    );

    return response;
  }
);

export const getRouletteBetHistoryAction = createAsyncThunk(
  "roulette/getRouletteBetHistoryAction",
  async ({ rouletteId }: { rouletteId: number }) => {
    const response = await axios.get(
      `${SERVER_URL}/business/roulette/${rouletteId}`
    );

    return response;
  }
);

export const getRouletteProfileAction = createAsyncThunk(
  "roullete/getRouletteProfileAction",
  async ({ itemId, cityId }: { itemId: number; cityId: number }) => {
    const reponse = await axios.get(
      `${SERVER_URL}/business/detail?itemId=${itemId}&cityId=${cityId}`
    );

    return reponse;
  }
);

export const rouletteSlice = createSlice({
  name: "roulette",
  initialState,
  reducers: {
    setSpinning(state, action) {
      state.isSpinning = action.payload;
    },

    updateRouletteRoundStatus(state) {},

    addBet: (state, { payload }) => {
      state.bets.push(payload);
    },

    removeBet: (state, { payload }) => {
      state.bets = state.bets.filter((val) => val.type !== payload.type);
    },

    updateBet: (state, { payload }) => {
      const index = state.bets.findIndex((val) => val.type === payload.type);
      if (index >= 0) state.bets[index] = payload;
    },

    clearBets: (state) => {
      state.bets = [];
    },

    setCurrentRouletteId: (state, { payload }) => {
      state.currentRouletteId = payload;
    },

    updateDefaultBetAmount: (state, { payload }) => {
      state.defaultBetAmount = Number(payload);
    },

    setBetResult: (state, { payload }) => {
      state.betResult = payload;
      if (payload === null) return;
      state.pastBetResults = [payload, ...state.pastBetResults];
    },

    updateBetStatus: (state, { payload }) => {
      state.betStatus = payload;
    },

    updateBetDisabled: (state, { payload }) => {
      state.betDisabled = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getRoulettesAction.pending, (state) => {
      state.isRouletteListLoading = true;
    });
    builder.addCase(getRoulettesAction.fulfilled, (state, action) => {
      state.roulettes = action.payload;
      state.isRouletteListLoading = false;
    });
    builder.addCase(getRoulettesAction.rejected, (state) => {
      state.isRouletteListLoading = false;
    });

    builder.addCase(initializeBetAction.pending, (state) => {
      state.isInitializingBet = true;
    });
    builder.addCase(initializeBetAction.fulfilled, (state) => {
      state.isInitializingBet = false;
    });
    builder.addCase(initializeBetAction.rejected, (state) => {
      state.isInitializingBet = false;
    });

    builder.addCase(finishBetAction.pending, (state) => {
      state.isFinishingBet = true;
    });
    builder.addCase(finishBetAction.fulfilled, (state) => {
      state.isFinishingBet = false;
    });
    builder.addCase(finishBetAction.rejected, (state) => {
      state.isFinishingBet = false;
    });

    builder.addCase(updateRouletteSettingsAction.pending, (state) => {
      state.isUpdatingRouletteSettings = true;
    });
    builder.addCase(updateRouletteSettingsAction.fulfilled, (state) => {
      state.isUpdatingRouletteSettings = false;
    });
    builder.addCase(updateRouletteSettingsAction.rejected, (state) => {
      state.isUpdatingRouletteSettings = false;
    });

    builder.addCase(getRouletteLiquidityAction.pending, (state) => {
      state.isGettingRouletteLiquidity = true;
    });
    builder.addCase(
      getRouletteLiquidityAction.fulfilled,
      (state, { payload }) => {
        state.isGettingRouletteLiquidity = false;
        state.currentRouletteLiquidity = payload;
      }
    );
    builder.addCase(getRouletteLiquidityAction.rejected, (state, { error }) => {
      state.isGettingRouletteLiquidity = false;

      console.log(error);
    });

    builder.addCase(addLiquidityAction.pending, (state) => {
      state.isAddingLiquidity = true;
    });
    builder.addCase(addLiquidityAction.fulfilled, (state) => {
      state.isAddingLiquidity = false;
    });
    builder.addCase(addLiquidityAction.rejected, (state) => {
      state.isAddingLiquidity = false;
    });

    builder.addCase(removeLiquidityAction.pending, (state) => {
      state.isRemovingLiquidity = true;
    });
    builder.addCase(removeLiquidityAction.fulfilled, (state) => {
      state.isRemovingLiquidity = false;
    });
    builder.addCase(removeLiquidityAction.rejected, (state) => {
      state.isRemovingLiquidity = false;
    });

    builder.addCase(approveGameCashAction.pending, (state) => {
      state.isApprovingGameCash = true;
    });
    builder.addCase(approveGameCashAction.fulfilled, (state) => {
      state.isApprovingGameCash = false;
    });
    builder.addCase(approveGameCashAction.rejected, (state) => {
      state.isApprovingGameCash = false;
    });

    builder.addCase(getBetHistoryAction.pending, (state) => {
      state.isGettingBetHistory = true;
    });

    builder.addCase(getBetHistoryAction.fulfilled, (state, { payload }) => {
      state.betHistory = payload.data;
      state.isGettingBetHistory = false;
    });

    builder.addCase(getBetHistoryAction.rejected, (state) => {
      state.isGettingBetHistory = false;
    });

    builder.addCase(getRouletteBetHistoryAction.pending, (state) => {});
    builder.addCase(
      getRouletteBetHistoryAction.fulfilled,
      (state, { payload }) => {
        state.rouletteBetHistory = payload.data;
      }
    );
    builder.addCase(getRouletteBetHistoryAction.rejected, (state) => {});

    builder.addCase(getRouletteProfileAction.pending, (state) => {
      state.loadingRouletteProfile = true;
    });
    builder.addCase(
      getRouletteProfileAction.fulfilled,
      (state, { payload }) => {
        state.loadingRouletteProfile = false;
        state.rouletteProfile = {
          id: payload.data.id,
          userId: payload.data.user_id,
          address: payload.data.address,
          itemId: payload.data.item_id,
          categoryId: Math.floor(payload.data.item_id / 10),
          typeId: payload.data.item_id % 10,
          cityId: payload.data.city,
          removed: payload.data.removed,
          status: payload.data.status,
          timestamp: payload.data.timestamp,
          map: payload.data.Map,
          ownerName: payload.data.ownerName,
          ownerImageId: payload.data.ownerImageId,
          ownerGender: payload.data.ownerGender,
          familyInfo: payload.data.familyInfo,
          profit: payload.data.profit,
          feeAmount: payload.data.feeAmount,
        };
      }
    );
    builder.addCase(getRouletteProfileAction.rejected, (state) => {
      state.loadingRouletteProfile = false;
    });
  },
});

export const {
  setSpinning,
  updateRouletteRoundStatus,
  addBet,
  removeBet,
  updateBet,
  clearBets,
  setCurrentRouletteId,
  updateDefaultBetAmount,
  setBetResult,
  updateBetStatus,
  updateBetDisabled,
} = rouletteSlice.actions;

export default rouletteSlice.reducer;
