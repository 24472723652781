import { Box } from "@mui/material";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import MarketAuctionPopup from "../../components/MarketAuctionPopup";
import MarketHeader from "../../components/MarketHeader";
import MarketHistoryList from "../../components/MarketHistoryList";
import MarketItemDetail from "../../components/MarketItemDetail";
import MarketItemList from "../../components/MarketItemList";
import MarketListItemPopup from "../../components/MarketListItemPopup";
import MarketMyListing from "../../components/MarketMyListing";
import MarketRulesPopup from "../../components/MarketRulesPopup";
import TokenListPopup from "../../components/TokenListPopup";

import {
  approveGameCashAction,
  bidOnAuctionItemAction,
  cancelListingAction,
  clearItems,
  finishAuctionItemAction,
  getItemInfoById,
  getListedItems,
  getMarketStatusInfo,
  getMyListings,
  getSwapTokenBalance,
  getSwapTokenInfo,
  purchaseFixedItemAction,
  removeItemInfoById,
  setListedItems,
} from "../../reducers/market.slice";
import {
  getMyInventoryItems,
  updateCashBalance,
} from "../../reducers/profile.slice";

import config, { SERVER_URL } from "../../config/config";
import { CONFIRMATION_DELAY, GAME_CASH_ADDRESS } from "../../constants/const";
import { MarketSSEEventHeader } from "../../constants/enum/enum";
import { RarityType } from "../../constants/enum/map";
import { Market } from "../../constants/enum/market";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import { MarketListedItem } from "../../types/MarketListedItem";
import { toastError, toastSuccess } from "../../utils/utils";

import useGameBankBalance from "../../hook/useGameBankBalance";
import useWallet from "../../hook/useWallet";

import { dispatchTxAction } from "../../helper/dispatchTxAction";

import useStyles from "./index.styles";

export default function MarketPage() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { account } = useWallet();
  const { signMsg, signature } = useAppSelector((state) => state.auth);
  const { updateBalance } = useGameBankBalance(account, signMsg, signature);
  const [searchParams] = useSearchParams();

  const showItemList =
    location.pathname === "/market/itemList" || location.pathname === "/market";
  const showHistory = location.pathname === "/market/history";
  const showMyListing = location.pathname === "/market/myListings";
  const showItemDetails = location.pathname.includes("/market/item/");

  const { swapTokenInfo, swapTokenBalances } = useAppSelector(
    (state) => state.market
  );
  const { myProfile } = useAppSelector((state) => state.profile);
  const { listedItems } = useAppSelector((state) => state.market);

  const [openMarketListItemPopup, setOpenMarketListItemPopup] = useState(false);
  const [openMarketRulesPopup, setOpenMarketRulesPopup] = useState(false);
  const [openMarketAuctionPopup, setOpenMarketAuctionPopup] = useState(false);
  const [openTokenListPopup, setOpenTokenListPopup] = useState(false);

  const [showingBids, setShowingBids] = useState(false);
  const [actionItem, setActionItem] = useState<MarketListedItem | undefined>();
  const [purchasingType, setPurchasingType] = useState<
    Market.ListType | undefined
  >();
  const [newPrice, setNewPrice] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemFilter, setItemFilter] = useState<number[]>([]);
  const [itemSortBy, setItemSortBy] = useState<Market.SortBy>("Time");
  const [itemSortDirection, setItemSortDirection] = useState(true);

  const rarityType =
    searchParams.get("type") !== null
      ? (Number(searchParams.get("type")) as RarityType)
      : null;

  const itemPerPage = 15;

  const fetchListedItems = () => {
    if (!showHistory && !showMyListing)
      dispatch(
        getListedItems({
          itemSortDirection: itemSortDirection,
          itemSortBy: itemSortBy,
          itemFilter: itemFilter.toString(),
          from: itemPerPage * (currentPage - 1),
          count: itemPerPage,
        })
      );
  };

  const fetchItemInfoById = (listingId: number) => {
    dispatch(getItemInfoById({ listingId }));
  };

  const removeItemInfo = (listingId: number) => {
    dispatch(removeItemInfoById(listingId));
  };

  const handleBuyItem = (
    swapTokenId: number,
    item?: MarketListedItem,
    itemListType?: Market.ListType
  ) => {
    console.log("Buy item called with id:");
    if (!myProfile.address || !myProfile.id) {
      toastError(Errors.GLOBAL.PROFILE.NOT_CREATED);
      return;
    }
    const itemToBuy = item || actionItem;
    const listType = itemListType !== undefined ? itemListType : purchasingType;

    if (itemToBuy?.listingId === undefined) return;
    let tokenAmount =
      itemToBuy.listingInfo.token === GAME_CASH_ADDRESS
        ? listType === Market.ListType.FIXED
          ? itemToBuy.listingInfo.currentPrice
          : newPrice
        : ((listType === Market.ListType.FIXED
            ? itemToBuy.listingInfo.currentPrice
            : newPrice) *
            1.02) /
          swapTokenInfo[1][swapTokenId];

    const tokenAddress =
      itemToBuy.listingInfo.token === GAME_CASH_ADDRESS
        ? GAME_CASH_ADDRESS
        : swapTokenInfo[0][swapTokenId].tokenAddress;

    if (tokenAddress === config.mafiaAddress) {
      if (itemToBuy.listingInfo.token === ethers.constants.AddressZero) {
        tokenAmount *= 100 / 83.4;
      }
    }

    if (listType === Market.ListType.FIXED) {
      const purchase = () => {
        dispatchTxAction(
          dispatch,
          purchaseFixedItemAction({
            listingId: itemToBuy?.listingId,
            swapTokenId: swapTokenId,
            tokenAmount: tokenAmount,
            tokenAddress: tokenAddress,
            account: account,
          }),
          () => {
            updateBalance(account, signMsg, signature);
            toastSuccess(Messages.MARKET.BASIC.PURCHASED_ITEM_SUCCESS);
            setOpenTokenListPopup(false);
            getItemInfoById({ listingId: itemToBuy.listingId });
            if (myProfile.id)
              dispatch(getMyInventoryItems({ userId: myProfile.id }));

            dispatch(updateCashBalance(account));
            dispatch(updateCashBalance(itemToBuy.listingInfo.seller));
          },
          CONFIRMATION_DELAY * 2
        );
      };

      if (itemToBuy.listingInfo.token === GAME_CASH_ADDRESS) {
        dispatchTxAction(
          dispatch,
          approveGameCashAction({
            tokenAmount: tokenAmount,
            account: account,
          }),
          () => {
            purchase();
          },
          CONFIRMATION_DELAY * 1
        );
      } else {
        purchase();
      }
    } else if (listType === Market.ListType.AUCTION) {
      const bidItem = () => {
        dispatchTxAction(
          dispatch,
          bidOnAuctionItemAction({
            listingId: itemToBuy?.listingId,
            swapTokenId: swapTokenId,
            price: newPrice,
            tokenAmount: tokenAmount,
            tokenAddress: tokenAddress,
            account: account,
          }),
          () => {
            toastSuccess(Messages.MARKET.BASIC.MAKE_BID_SUCCESS);
            setOpenTokenListPopup(false);
            setOpenMarketAuctionPopup(false);
            getItemInfoById({ listingId: itemToBuy?.listingId });

            dispatch(updateCashBalance(account));
            dispatch(updateCashBalance(itemToBuy.listingInfo.seller));
          }
        );
      };

      if (itemToBuy.listingInfo.token === GAME_CASH_ADDRESS) {
        dispatchTxAction(
          dispatch,
          approveGameCashAction({
            tokenAmount: tokenAmount,
            account: account,
          }),
          () => {
            bidItem();
          },
          CONFIRMATION_DELAY * 1
        );
      } else {
        bidItem();
      }
    }
  };

  const handleCancelListing = (item: MarketListedItem) => {
    if (item?.listingId === undefined) return;

    const actionParam = {
      listingId: item?.listingId,
      account: account,
    };

    dispatchTxAction(dispatch, cancelListingAction(actionParam), () => {
      toastSuccess(Messages.MARKET.BASIC.CANCELED_LISTING_SUCCESS);
      getItemInfoById({ listingId: item?.listingId });

      if (myProfile.id) {
        dispatch(getMyListings({ address: account }));
        dispatch(getMyInventoryItems({ userId: myProfile.id }));
      }
    });
  };

  const handleCompleteAuction = (item: MarketListedItem) => {
    if (item?.listingId === undefined) return;

    const actionParam = {
      listingId: item?.listingId,
      account: account,
    };

    dispatchTxAction(dispatch, finishAuctionItemAction(actionParam), () => {
      toastSuccess(Messages.MARKET.BASIC.FINISH_AUCTION_SUCCESS);
      getItemInfoById({ listingId: item?.listingId });
      if (myProfile.id) {
        dispatch(getMyListings({ address: account }));
        dispatch(getMyInventoryItems({ userId: myProfile.id }));
      }
    });
  };

  useEffect(() => {
    dispatch(getSwapTokenInfo());
    dispatch(getMarketStatusInfo());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearItems());
  }, [dispatch, itemSortBy, itemSortDirection]);

  useEffect(() => {
    if (!showHistory && !showMyListing)
      dispatch(
        getListedItems({
          itemSortDirection: itemSortDirection,
          itemSortBy: itemSortBy,
          itemFilter: itemFilter.toString(),
          from: itemPerPage * (currentPage - 1),
          count: itemPerPage,
        })
      );
  }, [
    currentPage,
    dispatch,
    itemFilter,
    showHistory,
    itemSortBy,
    itemSortDirection,
    showMyListing,
  ]);

  useEffect(() => {
    dispatch(setListedItems([]));
  }, [dispatch, itemFilter, showHistory, showMyListing]);

  useEffect(() => {
    if (!myProfile.address) return;
    if (swapTokenInfo[0].length === 0) return;

    dispatch(getSwapTokenBalance({ account: myProfile.address }));
  }, [dispatch, myProfile.address, swapTokenInfo]);

  useEffect(() => {
    const newActionItem = listedItems.find(
      (item) => item.listingId === actionItem?.listingId
    );

    if (!newActionItem) return;

    setActionItem(newActionItem);
  }, [actionItem?.listingId, listedItems]);

  useEffect(() => {
    const eventSource = new EventSource(`${SERVER_URL}/event/market`);

    eventSource.onmessage = (e) => {
      if (e.data.includes("welcome")) {
      } else if (e.data.includes("eventName")) {
        const res = JSON.parse(e.data);
        const item = res.data;
        if (res.eventName === MarketSSEEventHeader.NewListing) {
          fetchItemInfoById(item.id);
        } else if (res.eventName === MarketSSEEventHeader.NewBid) {
          fetchItemInfoById(item.listingId);
        } else if (
          res.eventName === MarketSSEEventHeader.ItemPurchased ||
          res.eventName === MarketSSEEventHeader.CancelListing
        ) {
          removeItemInfo(item.listingId);
        }
      }
    };

    return () => {
      eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, listedItems]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (parseInt(queryParams.get("listItem") || "0") > 0) {
      setOpenMarketListItemPopup(true);
    }
  }, [location]);

  return (
    <Box className={classes.body}>
      <MarketHeader
        setOpenMarketRulesPopup={setOpenMarketRulesPopup}
        setOpenMarketListItemPopup={setOpenMarketListItemPopup}
        setItemFilter={setItemFilter}
      />

      {showMyListing && (
        <MarketMyListing
          setActionItem={setActionItem}
          handleCancelListing={handleCancelListing}
          handleCompleteAuction={handleCompleteAuction}
          rarityType={rarityType}
        />
      )}

      {showHistory && (
        <MarketHistoryList itemPerPage={itemPerPage} itemFilter={itemFilter} />
      )}

      {showItemList && (
        <MarketItemList
          itemPerPage={itemPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setOpenMarketAuctionPopup={setOpenMarketAuctionPopup}
          setActionItem={setActionItem}
          setOpenTokenListPopup={setOpenTokenListPopup}
          setPurchasingType={setPurchasingType}
          handleCancelListing={handleCancelListing}
          handleCompleteAuction={handleCompleteAuction}
          setShowingBids={setShowingBids}
          itemSortBy={itemSortBy}
          setItemSortBy={setItemSortBy}
          itemSortDirection={itemSortDirection}
          setItemSortDirection={setItemSortDirection}
          handleBuyItem={handleBuyItem}
        />
      )}

      {showItemDetails && (
        <MarketItemDetail
          setActionItem={setActionItem}
          setShowingBids={setShowingBids}
          setOpenMarketAuctionPopup={setOpenMarketAuctionPopup}
          setOpenTokenListPopup={setOpenTokenListPopup}
          setPurchasingType={setPurchasingType}
          handleCancelListing={handleCancelListing}
          handleCompleteAuction={handleCompleteAuction}
          handleBuyItem={handleBuyItem}
          rarityType={rarityType}
        />
      )}

      <MarketAuctionPopup
        purchasingItem={actionItem}
        openMarketAuctionPopup={openMarketAuctionPopup}
        setOpenMarketAuctionPopup={setOpenMarketAuctionPopup}
        setOpenTokenListPopup={setOpenTokenListPopup}
        setNewPrice={setNewPrice}
        showingBids={showingBids}
        handleBuyItem={handleBuyItem}
      />
      <MarketRulesPopup
        openMarketRulesPopup={openMarketRulesPopup}
        setOpenMarketRulesPopup={setOpenMarketRulesPopup}
      />
      <MarketListItemPopup
        openMarketListItemPopup={openMarketListItemPopup}
        setOpenMarketListItemPopup={setOpenMarketListItemPopup}
        fetchListedItems={fetchListedItems}
      />
      <TokenListPopup
        usdAmount={
          purchasingType === Market.ListType.FIXED
            ? actionItem?.listingInfo.currentPrice || 0
            : newPrice
        }
        swapTokenInfo={swapTokenInfo}
        swapTokenBalances={swapTokenBalances}
        openTokenListPopup={openTokenListPopup}
        setOpenTokenListPopup={setOpenTokenListPopup}
        handleTokenSelection={handleBuyItem}
      />
    </Box>
  );
}
