import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    display: "flex",
    gap: 32,
    flexDirection: "column",
    // marginTop: 64,
    alignItems: "center",
    marginBottom: 32,
  },

  body: {
    marginTop: -64,
    paddingBottom: 32,
    minHeight: 900,
    background: "url(/assets/imgs/exchange/bg.png) no-repeat",
    backgroundSize: "auto auto",
    backgroundPosition: "right top",
    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "right top",
    },
  },

  header: {
    display: "flex",
    flexDirection: "column",
    gap: 32,
    marginTop: 64,
  },

  actions: {
    display: "flex",
    gap: "16px",

    "& button": {
      background: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      fontFamily: "Philosopher",
      fontWeight: 900,
      textTransform: "none",
      borderRadius: 8,
      padding: "8px 32px",
      border: "1px solid",
      borderColor: "black",
      transition: "background 0.3s, color 0.3s",
    },

    "& .active": {
      borderColor: theme.palette.primary.main,
    },

    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
    },
  },

  buttonIcon: {
    width: 16,
    height: 16,
  },

  exchangeInfo: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 3fr",
    marginTop: 16,
    maxWidth: 1000,
    gridGap: "24px",
    width: "100%",
    padding: 16,

    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
      marginTop: 0,
      width: "100%",
      maxWidth: "100%",
      boxSizing: "border-box",
    },
  },

  exchangeCard: {
    width: "100%",
    maxWidth: "480px",
    margin: "auto",
    height: "100%",
    backgroundColor: theme.palette.background.cardBg,
    borderRadius: 16,
    padding: "24px 24px",
    boxSizing: "border-box",
    border: "1px solid",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",

    fontFamily: "Philosopher",
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      padding: 16,
      height: "auto",
      minHeight: "240px",
    },
  },

  fees: {
    display: "flex",
    flexDirection: "column",
    gap: 32,

    [theme.breakpoints.down("sm")]: {
      gap: 8,
      flexDirection: "row",
    },
  },

  feeInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.cardBg,
    borderRadius: 16,
    padding: "8px 16px",
    boxSizing: "border-box",
    border: "1px solid",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",

    fontFamily: "Philosopher",
    fontWeight: 900,
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      padding: "8px 20px",
      height: "auto",
    },
  },

  confirm: {
    borderRadius: 8,
    padding: theme.spacing(1, 4),
    fontFamily: "Philosopher",
    fontWeight: 700,
    background: theme.palette.background.defaultButton,
    color: theme.palette.text.primary,
    textTransform: "none",

    "&:disabled": {
      background: theme.palette.background.gray,
    },
  },

  addItemButton: {
    borderRadius: 8,
    padding: theme.spacing(1, 4),
    fontFamily: "Philosopher",
    fontWeight: 700,
    background: theme.palette.primary,
    color: theme.palette.text.primary,
    textTransform: "none",
  },

  depositTitle: {
    display: "flex",
    justifyContent: "space-between",
    color: "white",
    marginTop: 16,
    fontSize: "14px",
    fontFamily: "Philosopher",
    gap: 24,
    position: "absolute",
    left: -8,
    top: -24,
    width: "100%",
    paddingRight: "32px",
    alignItems: "center",
    flexWrap: "wrap",

    [theme.breakpoints.down("sm")]: {
      gap: 8,
      justifyContent: "space-between",
    },
  },

  chartLogo: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },

  priceLabelOnChart: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },

  depositInfo: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    maxWidth: 1270,
    gridGap: "16px",
    width: "100%",
    padding: "0 16px",

    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
      marginTop: 0,
      width: "100%",
      maxWidth: "100%",
      boxSizing: "border-box",
    },
  },

  depositCard: {
    width: "100%",
    maxWidth: "700px",
    margin: "auto",
    height: "100%",
    backgroundColor: theme.palette.background.cardBg,
    borderRadius: 16,
    padding: "24px 60px",
    boxSizing: "border-box",
    border: "1px solid black",
    color: "white",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",

    fontFamily: "Philosopher",
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      padding: 16,
      height: "auto",
      minHeight: "240px",
    },
  },

  chartBottomInfo: {
    maxWidth: "700px",
    padding: "0 90px",
    marginTop: "8px",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Philosopher",

    [theme.breakpoints.down("sm")]: {
      padding: 0,
      flexDirection: "column",
      gap: "8px",
      "&>div": {
        width: "100%",
        paddingLeft: "16px",
      },
    },

    "&>div": {
      display: "flex",
      gap: 2,
      alignItems: "center",

      "&>span": {
        opacity: 0.4,
      },
    },
  },

  ratioTextBox: {
    fontSize: "24px",
    display: "flex",
    alignItems: "center",
  },

  cashInput: {
    maxWidth: "120px",

    "& .MuiOutlinedInput-input": {
      padding: "8px",
      textAlign: "right",
      backgroundColor: "#2e3233",
      borderRadius: "8px",
    },
  },

  cashPerMafiaInput: {
    maxWidth: "80px",

    "& .MuiOutlinedInput-input": {
      padding: "0 8px",
      fontSize: "24px",
      backgroundColor: "#2e3233",
      borderRadius: "8px",
    },
  },

  positionStat: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    alignItems: "start",
    width: "100%",
    maxWidth: 1200,
    fontFamily: "Philosopher",
    gap: "40px",
  },

  positionTab: {
    display: "flex",
    gap: "24px",
    position: "relative",
    alignItems: "center",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      minWidth: "90%",
      overflowX: "auto",
    },
  },
  executionContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "16px",
  },
  refreshIcon: {
    // position: "absolute",
    right: 0,
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  tabItem: {
    borderRadius: "24px",
    padding: "12px 24px",
    fontSize: "20px",
    cursor: "pointer",
    transition: "0.3s",

    "&:hover": {
      backgroundColor: "#181a1c",
      transition: "0.3s",
    },

    [theme.breakpoints.down("sm")]: {
      minWidth: "140px",
    },
  },

  tabActive: {
    backgroundColor: "#181a1c",
  },

  tableContainer: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
      overflowX: "auto",
      marginLeft: "5%",
    },
  },

  statTable: {
    width: "100%",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      minWidth: "800px",
    },
  },

  tableHeader: {
    display: "grid",
    color: "#6c6565",

    alignItems: "center",
    paddingBottom: "12px",
  },

  tableBody: {
    display: "grid",
    alignItems: "center",
    borderBottom: "1px solid #202020",
    padding: "8px 0",
    minHeight: "42px",
    position: "relative",
  },

  amountCell: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    width: "250px",
  },

  cashBgColor: {
    minHeight: "44px",
    background:
      "linear-gradient(to bottom, rgba(11, 45, 12, 0.8), rgba(8, 30, 10, 0.9) 30%, rgba(8, 30, 10, 0.9) 70%), rgba(11, 45, 12, 0.8)",
    boxShadow: "0 0 2px rgba(173, 230, 173, 0.8)",
    border: "1px solid rgba(11, 45, 12, 0.7)",
    borderRadius: "4px",
    position: "absolute",
    zIndex: 0,
  },

  moneyIcon: {
    width: 24,
    marginLeft: "16px",
    zIndex: 1,
  },

  mafiaCell: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    zIndex: 1,
  },

  paginationBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
  },

  pagination: {
    "& .MuiPaginationItem-root": {
      backgroundColor: "transparent",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "#4caf50",
      color: "#fff",
    },
    "& .MuiPaginationItem-root.Mui-selected:hover": {
      backgroundColor: "#45a049",
    },
  },

  cancelButton: {
    textTransform: "none",
    minWidth: "100px",
    background:
      "linear-gradient(to right, rgb(150, 40, 70), rgb(220, 100, 110))",
    color: "#ffffff",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.4)",
      background:
        "linear-gradient(to right, rgb(130, 30, 60), rgb(200, 80, 90))",
    },
    "&:disabled": {
      background: "rgba(200, 200, 200, 0.3)",
      border: "1px solid rgba(150, 150, 150, 0.5)",
      color: "rgba(150, 150, 150, 0.7)",
    },
  },

  withdrawButton: {
    textTransform: "none",
    minWidth: "100px",
    background: "linear-gradient(to right, #10754b, #0f613f)",
    color: "#ffffff",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.6)",
      background: "linear-gradient(to right, #10754b, #0f613f)",
    },
  },

  swapPanel: {
    minWidth: "500px",
    marginTop: "16px",
    padding: "16px",
    color: "white",
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Philosopher",

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      paddingTop: 0,
      minWidth: "auto",
      maxWidth: "90%",
    },
  },

  sellPanel: {
    width: "100%",
    backgroundColor: "#1e1f21",
    borderRadius: "8px",
    padding: "54px 32px",
    gap: "12px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    position: "relative",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.4)",

    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
    },
  },

  buyPanel: {},

  sellText: {
    fontSize: "20px",
    color: "white",
    opacity: "0.4",
  },

  sellInput: {
    maxWidth: "160px",

    "& fieldset": {
      border: "none",
      outline: "none",
    },

    "& .MuiOutlinedInput-input": {
      padding: "14px 0",
      fontSize: "18px",
    },
  },

  swapItem: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },

  maxButton: {
    padding: "0",
    backgroundColor: "#353536",
    color: "#808080",

    "&:hover": {
      color: "white",
      backgroundColor: "#1976d2",
    },
  },

  activeButton: {
    color: "white",
    backgroundColor: "#1976d2",
  },

  swapButtonBox: {
    width: "100%",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.4)",

    [theme.breakpoints.down("sm")]: {},
  },

  swapButton: {
    width: "100%",
    padding: "12px",
    textTransform: "none",
    borderRadius: "8px",
    background: "linear-gradient(to right, #3a5d23, #1e3b0f)",
    color: "#ffffff",
    border: "none",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    "&:hover": {
      boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.6)",
      background: "linear-gradient(to right, #32491b, #122e0a)",
    },

    "&:disabled": {
      color: "rgba(255,255,255,0.4)",
      background: "#1e1f21",
    },
  },

  swapTokenSelect: {
    minWidth: "200px",
    borderRadius: "30px",

    "& .MuiSelect-select": {
      backgroundColor: "#262729",
      color: "white",
      borderRadius: "30px",
    },

    [theme.breakpoints.down("sm")]: {
      maxWidth: "160px",
      minWidth: "auto",
    },
  },

  selectItem: {
    minWidth: "220px",
    borderRadius: "30px",
    border: "none",

    [theme.breakpoints.down("sm")]: {
      minWidth: "160px",
    },

    "& fieldset": {
      border: "none",
      borderRadius: "30px",
    },

    "& .MuiOutlinedInput-input": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "&>div:nth-of-type(2)": {
        display: "none",
      },

      "&:focus": {
        borderRadius: "30px",
      },
    },
  },

  swapIconBox: {
    position: "absolute",
    bottom: -32,
    width: "50px",
    height: "50px",
    border: "4px solid #0d0f11",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
    left: "50%",
    marginLeft: "-25px",
    backgroundColor: "#1e1f21",

    "& svg": {
      fontSize: "32px",
    },
  },

  buttonsGroup: {
    display: "flex",
    gap: "8px",
    justifyContent: "center",
  },

  swapLinkButton: {
    textTransform: "none",
    minWidth: "100px",
    borderRadius: "16px",
    color: "white",

    "&:hover": {
      backgroundColor: "#1d1b1b",
    },
  },

  infoBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "16px",
    gap: "24px",
    padding: "0 80px",
    color: "white",
    fontSize: "20px",
    maxWidth: "600px",
    fontFamily: "Philosopher",

    "&>div": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
  },

  cashLabel: {
    position: "absolute",
    top: "24px",
    right: "18px",
    zIndex: 1,
    opacity: 0.4,
  },

  dateLabel: {
    position: "absolute",
    bottom: "56px",
    left: "-40px",
    zIndex: 1,
    opacity: 0.4,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  profileNameLink: {
    color: "#1c7995",
    opacity: "0.8",
    textDecoration: "none",
    "&:hover": {
      opacity: 1,
      textDecoration: "underline",
    },
  },

  moreButton: {
    opacity: 0.4,
    textDecoration: "underline",
    cursor: "pointer",
    bottom: "0",
    position: "absolute",
    right: -30,
    "&:hover": {
      opacity: 1,
    },

    [theme.breakpoints.down("sm")]: {
      right: 0,
    },
  },

  chartTitle: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    flex: "1 1 auto",
  },

  chartTitleOnPC: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  chartInfoOnMobile: {
    display: "none",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "12px",
    },
  },

  priceUpLabel: {
    color: "green",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: -8,

    [theme.breakpoints.down("sm")]: {
      marginLeft: -4,
    },
  },

  priceDownLabel: {
    color: "red",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: -8,

    [theme.breakpoints.down("sm")]: {
      marginRight: -4,
    },
  },

  maintenanceText: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    padding: "48px 0",
    fontSize: "24px",
    color: "white",
    opacity: "0.6",
  },
}));

export default styles;
