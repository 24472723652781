import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    display: "flex",
    gap: 16,
    flexDirection: "column",
    // marginTop: 64,
    alignItems: "center",
    marginBottom: 32,
  },

  body: {
    marginTop: -64,
    paddingBottom: 32,
    minHeight: 900,
    background: "url(/assets/imgs/exchange/bg.png) no-repeat",
    backgroundSize: "auto auto",
    backgroundPosition: "right top",

    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "left top",
      backgroundSize: "auto auto",
    },
  },

  header: {
    display: "flex",
    flexDirection: "column",
    gap: 32,
    marginTop: 64,
  },

  actions: {
    display: "flex",
    gap: "16px",

    "& button": {
      background: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      fontFamily: "Philosopher",
      fontWeight: 900,
      textTransform: "none",
      borderRadius: 8,
      padding: "8px 32px",
      border: "1px solid",
      borderColor: "black",
      transition: "background 0.3s, color 0.3s",
      height: "min-content",
    },

    "& .active": {
      borderColor: theme.palette.primary.main,
    },

    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
    },

    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
    },
  },

  buttonIcon: {
    width: 16,
    height: 16,
  },

  exchangeInfo: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 3fr",
    marginTop: 32,
    maxWidth: 1000,
    gridGap: "24px",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
      marginTop: 0,
      width: "100%",
      maxWidth: "100%",
      boxSizing: "border-box",
    },
  },

  exchangeCard: {
    width: "100%",
    maxWidth: "480px",
    margin: "auto",
    height: "100%",
    backgroundColor: theme.palette.background.cardBg,
    borderRadius: 16,
    padding: "24px 24px",
    boxSizing: "border-box",
    border: "1px solid",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",

    fontFamily: "Philosopher",
    fontWeight: 900,

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      padding: 16,
      height: "auto",
      maxWidth: "320px",
      minHeight: "240px",
    },
  },

  fees: {
    display: "flex",
    flexDirection: "column",
    gap: 32,

    [theme.breakpoints.down("sm")]: {
      gap: 8,
      flexDirection: "row",
    },
  },

  feeInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.cardBg,
    borderRadius: 16,
    padding: "8px 16px",
    boxSizing: "border-box",
    border: "1px solid",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",

    fontFamily: "Philosopher",
    fontWeight: 900,
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      padding: "8px 20px",
      height: "auto",
    },
  },

  confirm: {
    borderRadius: 8,
    padding: theme.spacing(1, 4),
    fontFamily: "Philosopher",
    fontWeight: 700,
    background: theme.palette.background.defaultButton,
    color: theme.palette.text.primary,
  },
}));

export default styles;
