import { Box } from "@mui/material";
import useStyles from "./index.styles";
import CashHistoryList from "../../components/CashHistoryList";

const CashHistory = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <CashHistoryList />
      </Box>
    </Box>
  );
};

export default CashHistory;
