import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },

    "& .MuiDialog-paper": {
      overflowY: "initial",
    },
  },

  modalContent: {
    position: "relative",
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 48,
    minWidth: 320,
    backgroundSize: "auto 100%",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "48px 0px",
    },
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    zIndex: 1,

    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },
  rouletteResultNumberBox: {
    display: "flex",
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    width: "min-content",

    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 24,
  },

  rouletteResultTitle: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 32,
  },

  rouletteResultDescription: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 20,
    display: "flex",
    alignItems: "center",
    gap: 16,

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 24,
    },
  },
}));

export default styles;
