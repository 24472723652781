import { useGSAP } from "@gsap/react";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import gsap from "gsap";
import millify from "millify";
import { useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import { Link, useParams } from "react-router-dom";

import Logo from "../Logo";
import NativeIcon from "../NativeIcon";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config, { CHAIN } from "../../config/config";
import { BNB_ADMIN_LIST, PULSE_ADMIN_LIST } from "../../constants/admins";
import { menLinksSmall, womenLinksSmall } from "../../constants/avatarLinks";
import {
  City,
  PREMIUM_KEY_CLAIM_CYCLE,
  RankCategory,
  WealthRange,
} from "../../constants/const";
import { ChainType } from "../../constants/enum/enum";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import { bnbMovieLinks, plsMovieLinks } from "../../constants/movieLinks";
import {
  calculatePayoutByPercent,
  convertDateTimeSimple,
  getFlagUrl,
  toastError,
  toastSuccess,
  toUSDFormat
} from "../../utils/utils";

import useCurrentTime from "../../hook/useCurrentTime";
import useVaultBalance from "../../hook/useVaultBalance";

import bulletIcon from "../../assets/imgs/family/bullets.png";
import cashIcon from "../../assets/imgs/inventory/cashitem.png";
import bscIcon from "../../assets/imgs/landing/explorer.png";
import femaleIcon from "../../assets/imgs/landing/female.png";
import linkIcon from "../../assets/imgs/landing/link.png";
import maleIcon from "../../assets/imgs/landing/male.png";
import adminBadgeIcon from "../../assets/imgs/profile/AdminBadge.png";
import goldenPlus from "../../assets/imgs/profile/GoldenPlus.png";
import badgeIcon from "../../assets/imgs/profile/OGbadge.png";
import purplePlus from "../../assets/imgs/profile/PurplePlus.png";
import infoIcon from "../../assets/imgs/profile/info.png";
import playIcon from "../../assets/imgs/profile/play.png";
import skullIcon from "../../assets/imgs/profile/skull.png";

import { getFamilyDetailById } from "../../reducers/family.slice";
import { getProfile } from "../../reducers/profile.slice";
import { FamilyDetail } from "../../types/FamilyDetails";

import useStyles from "./index.styles";

import {
  buttonFadeIn,
  buttonFadeOut,
  movieFadeInAnimation,
  movieFadeOutAnimation,
} from "./animation/animation";

import "./GlobalCssPaper.scss";

gsap.registerPlugin(useGSAP);

const ProfileCard = ({ isPrivateBullet }: { isPrivateBullet: boolean }) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { profileName } = useParams();
  const { profile, isLoadingProfile, premiumInfo } = useAppSelector(
    (state) => state.profile
  );
  const currentTime = useCurrentTime();
  const vaultBalance = useVaultBalance();
  const videoSelector = ".avatarVideo";
  const playButtonSelector = ".playButton";

  const [movieLink, setMovieLink] = useState("");
  const [moviePlaying, setMoviePlaying] = useState(false);

  const [avatarLoading, setAvatarLoading] = useState(true);
  const [familyDetail, setFamilyDetail] = useState<FamilyDetail>();
  const [profileCapoName, setProfileCapoName] = useState("");
  const [profileRole, setProfileRole] = useState("");
  const [flag, setFlag] = useState("");

  const [wealthAnchorEl, setWealthAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const [rankAnchorEl, setRankAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  const wealthOpen = Boolean(wealthAnchorEl);
  const wealthPopoverId = wealthOpen ? "wealth-popover" : undefined;

  const rankOpen = Boolean(rankAnchorEl);
  const rankPopoverId = wealthOpen ? "rank-popover" : undefined;

  const handleShowWealthPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setWealthAnchorEl(event.currentTarget);
  };

  const handleShowRankPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setRankAnchorEl(event.currentTarget);
  };

  const handleCloseWealthPopover = () => {
    setWealthAnchorEl(null);
  };

  const handleCloseRankPopover = () => {
    setRankAnchorEl(null);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(window.location.toString())
      .then(() => {
        toastSuccess(Messages.GLOBAL.TOKEN.CLIPBOARD_SUCCESS);
      })
      .catch((err) => {
        toastError(Errors.GLOBAL.TOKEN.CLIPBOARD_FAILED);
      });
  };

  const handleImageLoad = () => {
    setAvatarLoading(false);
  };

  const handleImageError = () => {
    setAvatarLoading(false);
  };

  const randomBulletAmount = useMemo(() => {
    return Math.floor(Math.random() * 100000);
  }, []);

  const premiumExpireAt = useMemo(() => {
    const premiumExpireAt =
      PREMIUM_KEY_CLAIM_CYCLE * 3 -
      (currentTime - premiumInfo.userInfo.startedAt);

    return premiumExpireAt;
  }, [currentTime, premiumInfo]);

  useEffect(() => {
    dispatch(getProfile(profileName || ""));

    let movieLinks: string[] = [];
    if (CHAIN === ChainType.PLS) {
      movieLinks = plsMovieLinks;
    } else if (CHAIN === ChainType.BNB) {
      movieLinks = bnbMovieLinks;
    }

    const link = movieLinks.find((movie, index) =>
      movie.toLowerCase().includes(`movie%2f${profileName?.toLowerCase()}.mp4`)
    );

    if (link) {
      setMovieLink(link);
    }
  }, [dispatch, profileName]);

  useEffect(() => {
    if (!profile || !profile.address) return;

    const getProfileFlag = () => {
      if (!profile.country) return;
      const flagUrl = getFlagUrl(profile.country);
      setFlag(flagUrl);
    };

    const getProfileFamilyInfo = () => {
      if (!profile.family || profile.family === -1) return;

      dispatch(getFamilyDetailById(profile.family))
        .unwrap()
        .then((familyInfo: FamilyDetail) => {
          setFamilyDetail(familyInfo);
        })
        .catch(() => {});
    };

    getProfileFlag();
    getProfileFamilyInfo();
  }, [dispatch, profile]);

  useEffect(() => {
    const getRole = () => {
      let role = "";
      if (familyDetail) {
        if (familyDetail.don?.name === profile.name) {
          role = "Don of";
        } else if (familyDetail.capodecina?.name === profile.name) {
          role = "Capodecina of";
        } else if (familyDetail.consigliere?.name === profile.name) {
          role = "Consigliere of";
        } else if (
          familyDetail.capoRegime.find(
            (capo, index) => capo.capoBoss === profile.name
          )
        ) {
          role = "Capo of";
        } else {
          role = "Family";
        }
      } else {
        role = "Family";
      }
      setProfileRole(role);
    };

    const getProfileCapoName = () => {
      if (!familyDetail) return;

      const profileCapoName = familyDetail?.capoRegime.find(
        (capo, index) => capo.capoId === profile.capo
      )?.capoBoss;

      setProfileCapoName(profileCapoName || "");
    };

    getRole();
    getProfileCapoName();
  }, [familyDetail, profile.capo, profile.name]);

  const playMovie = () => {
    const delay = 0.5;
    const movieFadeIn = movieFadeInAnimation(videoSelector, 0.5);
    const buttonFadeOutAnimation = buttonFadeOut(playButtonSelector, 0.5);
    const movieTimeLine = gsap.timeline({ delay: delay });
    movieTimeLine.add(buttonFadeOutAnimation);
    movieTimeLine.add(movieFadeIn);
    movieTimeLine.call(
      () => {
        setMoviePlaying(true);
      },
      [],
      delay
    );
  };

  const hideMovie = () => {
    const delay = 0;
    const movieFadeOut = movieFadeOutAnimation(videoSelector, 0.5);
    const buttonFadeInAnimation = buttonFadeIn(playButtonSelector, 0.5);
    const movieTimeLine = gsap.timeline({ delay: delay });
    movieTimeLine.add(buttonFadeInAnimation);
    movieTimeLine.add(movieFadeOut);
    movieTimeLine.call(
      () => {
        setMoviePlaying(false);
      },
      [],
      0
    );
  };

  const premiumEnabled = useMemo(() => {
    return (
      profile.premium === 1 &&
      premiumInfo.userInfo.startedAt + PREMIUM_KEY_CLAIM_CYCLE * 3 > currentTime
    );
  }, [currentTime, premiumInfo.userInfo.startedAt, profile.premium]);

  const isAdmin = useMemo(() => {
    if (!profileName) return;

    return CHAIN === ChainType.BNB
      ? BNB_ADMIN_LIST.indexOf(profileName) >= 0
      : PULSE_ADMIN_LIST.indexOf(profileName) >= 0;
  }, [profileName]);

  return (
    <Box className={classes.body}>
      <Box className={classes.container}>
        <Box className={classes.avatar}>
          <Box className={classes.name}>
            <Box className={classes.nameContainer}>
              {profileName}

              {isAdmin ? (
                <Box
                  component="img"
                  src={purplePlus}
                  className={classes.goldenPlus}
                ></Box>
              ) : (
                premiumEnabled && (
                  <Box
                    component="img"
                    src={goldenPlus}
                    className={classes.goldenPlus}
                  ></Box>
                )
              )}
            </Box>
            {isLoadingProfile ? (
              <Skeleton
                animation="wave"
                variant="rounded"
                sx={{ bgcolor: "#ffffff1a" }}
                width={120}
                height={20}
                style={{ bottom: "-16px" }}
              />
            ) : (
              <span>#{profile.id}</span>
            )}
          </Box>

          <Box className={classes.avatarContainer}>
            {avatarLoading && (
              <Skeleton
                animation="wave"
                variant="rounded"
                sx={{ bgcolor: "#ffffff1a" }}
                width="315px"
                height="472.5px"
              />
            )}

            <Box
              component="img"
              src={
                profile.gender === 0
                  ? menLinksSmall[!profile.imageId ? -1 : profile.imageId]
                  : womenLinksSmall[!profile.imageId ? -1 : profile.imageId]
              }
              onLoad={handleImageLoad}
              onError={handleImageError}
              style={{
                display: avatarLoading ? "none" : "block",
              }}
              className={classes.avatarImg}
            ></Box>

            <ReactPlayer
              url={movieLink}
              muted
              onReady={() => {
                if (!moviePlaying) playMovie();
                console.log("Ready");
              }}
              onStart={() => {
                console.log("Starting");
              }}
              onEnded={() => {
                hideMovie();
                console.log("Ended");
              }}
              playing={moviePlaying}
              width={315}
              height={472}
              playsinline
              className={clsx(classes.avatarVideo, "avatarVideo")}
            />

            <IconButton
              aria-label="delete"
              className={classes.playButton}
              onClick={() => {
                playMovie();
              }}
              disabled={moviePlaying}
            >
              <Box
                component="img"
                src={playIcon}
                className={clsx(classes.playButtonIcon, "playButton")}
              ></Box>
            </IconButton>
          </Box>

          {isAdmin ? (
            <Box
              component="img"
              src={adminBadgeIcon}
              className={classes.ogBadge}
            ></Box>
          ) : (
            premiumEnabled && (
              <Tooltip
                title={`Premium+ expire after ${convertDateTimeSimple(
                  premiumExpireAt
                )}`}
              >
                <Box
                  component="img"
                  src={badgeIcon}
                  className={classes.ogBadge}
                ></Box>
              </Tooltip>
            )
          )}
        </Box>

        <Box className={classes.info}>
          <Box className={classes.generalInfo}>
            <Box className={classes.left}>
              {isLoadingProfile ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  sx={{ bgcolor: "#ffffff1a" }}
                  width="48px"
                  height="32px"
                />
              ) : (
                <img
                  src={flag}
                  alt=""
                  srcSet=""
                  className={classes.countryInfo}
                />
              )}

              {isLoadingProfile ? (
                <Box className={classes.genderInfo}>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    sx={{ bgcolor: "#ffffff1a" }}
                    width="24px"
                    height="24px"
                  />
                </Box>
              ) : profile.gender === 0 ? (
                <Box className={classes.genderInfo}>
                  <Box component="img" src={maleIcon} />
                </Box>
              ) : (
                <Box className={classes.genderInfo}>
                  <Box component="img" src={femaleIcon} />
                </Box>
              )}
            </Box>

            <Box className={classes.right}>
              <Button
                variant="contained"
                color="primary"
                className={classes.copyButton}
                startIcon={
                  <Box
                    component="img"
                    src={linkIcon}
                    className={classes.linkIcon}
                  />
                }
                onClick={handleCopy}
              >
                Profile Link
              </Button>

              <Logo classNames={classes.logo} />

              {familyDetail?.logo ? (
                <Box
                  component="img"
                  src={familyDetail?.logo || ""}
                  className={classes.familySymbol}
                ></Box>
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Box className={classes.infoCard}>
            <Box className={classes.infoTable}>
              <Box className={classes.infoTableColumn}>
                <Box>Status</Box>
                {isLoadingProfile ? (
                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      sx={{ bgcolor: "#ffffff1a" }}
                      width={120}
                      height={20}
                    />
                  </Box>
                ) : (
                  <Box>
                    Alive
                    <span className={classes.statusDot}></span>
                  </Box>
                )}
              </Box>
              <Box className={classes.infoTableColumn}>
                <Box>Rank</Box>
                {isLoadingProfile ? (
                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      sx={{ bgcolor: "#ffffff1a" }}
                      width={80}
                      height={20}
                    />
                  </Box>
                ) : (
                  <Box>
                    {profile.rankId === 0 || !profile.rankId
                      ? "Nobody"
                      : RankCategory[profile.rankId]}
                  </Box>
                )}

                <Button
                  aria-describedby={rankPopoverId}
                  onClick={handleShowRankPopover}
                  onMouseEnter={handleShowRankPopover}
                  onMouseLeave={handleCloseRankPopover}
                >
                  <img src={infoIcon} alt="" srcSet="" />
                </Button>

                <Popover
                  sx={{
                    pointerEvents: "none",
                  }}
                  id="rank-popover"
                  anchorEl={rankAnchorEl}
                  open={rankOpen}
                  onClose={handleCloseRankPopover}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box className={classes.popoverContent}>
                    <Box>
                      <Logo />
                      <Box className={classes.popoverTitle}>Ranks</Box>
                    </Box>
                    <Box>
                      {RankCategory.map((rank, index) => {
                        return (
                          <Box
                            className={
                              index === profile.rankId ? "selected" : ""
                            }
                            key={index}
                          >
                            {rank}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Popover>
              </Box>
              <Box className={classes.infoTableColumn}>
                {profileRole === "Family" ? (
                  <Box>{profileRole}</Box>
                ) : profileRole === "Capo of" ? (
                  <Box className={classes.familyInfoTitleYellow}>
                    {profileRole}
                  </Box>
                ) : (
                  <Box className={classes.familyInfoTitlePurple}>
                    {profileRole}
                  </Box>
                )}

                {isLoadingProfile ? (
                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      sx={{ bgcolor: "#ffffff1a" }}
                      width={180}
                      height={20}
                    />
                  </Box>
                ) : (
                  <Box>
                    {profile.family === -1 ? (
                      "No Family"
                    ) : profile.id === 1 ? (
                      <p className={classes.familyInfoText}>
                        {familyDetail?.familyInfo?.name}
                      </p>
                    ) : (
                      <Link
                        to={`/family/${familyDetail?.familyInfo?.name}`}
                        style={
                          !profileCapoName
                            ? {
                                maxWidth: 200,
                              }
                            : {}
                        }
                      >
                        {familyDetail?.familyInfo?.name}
                      </Link>
                    )}

                    {profileCapoName && (
                      <>
                        <Typography
                          fontFamily="Philosopher"
                          marginLeft={1}
                          marginRight={1}
                        >
                          Capo:
                        </Typography>

                        <Link to={`/profile/${profileCapoName}`}>
                          {profileCapoName}
                        </Link>
                      </>
                    )}
                  </Box>
                )}
              </Box>
              <Box className={classes.infoTableColumn}>
                <Box>Wealth</Box>
                {isLoadingProfile ? (
                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      sx={{ bgcolor: "#ffffff1a" }}
                      width={60}
                      height={20}
                    />
                  </Box>
                ) : (
                  <Box>{profile.wealthCategory}</Box>
                )}

                <Button
                  aria-describedby={wealthPopoverId}
                  onClick={handleShowWealthPopover}
                  onMouseEnter={handleShowWealthPopover}
                  onMouseLeave={handleCloseWealthPopover}
                >
                  <img src={infoIcon} alt="" srcSet="" />
                </Button>

                <Popover
                  sx={{
                    pointerEvents: "none",
                  }}
                  id="wealth-popover"
                  anchorEl={wealthAnchorEl}
                  open={wealthOpen}
                  onClose={handleCloseWealthPopover}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box className={classes.popoverContent}>
                    <Box>
                      <Box
                        component="img"
                        src={cashIcon}
                        sx={{
                          width: "24px",
                          height: "24px",
                        }}
                      ></Box>
                      <Box className={classes.popoverTitle}>Wealth</Box>
                    </Box>
                    <Box>
                      {WealthRange.map((wealth, index) => {
                        return (
                          <Box
                            key={index}
                            className={
                              wealth.category === profile.wealthCategory
                                ? "selected"
                                : ""
                            }
                          >
                            <Box>+{toUSDFormat(wealth.from)}</Box>
                            <Box>{wealth.category}</Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Popover>
              </Box>
              <Box className={classes.infoTableColumn}>
                <Box>City</Box>
                {isLoadingProfile ? (
                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      sx={{ bgcolor: "#ffffff1a" }}
                      width={80}
                      height={20}
                    />
                  </Box>
                ) : (
                  <Box>
                    {profile.city === undefined
                      ? "Not defined"
                      : City[profile.city]}
                  </Box>
                )}
              </Box>
              <Box className={classes.infoTableColumn}>
                <Box>Worth</Box>
                {isLoadingProfile ? (
                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      sx={{ bgcolor: "#ffffff1a" }}
                      width={40}
                      height={20}
                    />
                  </Box>
                ) : (
                  <Box>{!profile.worth ? "0" : profile.worth}</Box>
                )}
              </Box>
              <Box className={classes.infoTableColumn}>
                <Box>Bullet amount</Box>
                {isLoadingProfile ? (
                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      sx={{ bgcolor: "#ffffff1a" }}
                      width={120}
                      height={20}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Box
                      component="img"
                      src={bulletIcon}
                      sx={{
                        width: "16px !important",
                        height: "16px !important",
                        marginRight: "8px",
                      }}
                    ></Box>

                    {isPrivateBullet ? (
                      <Box
                        component={"span"}
                        sx={{
                          filter: "blur(5px)",
                        }}
                      >
                        {millify(randomBulletAmount)}
                      </Box>
                    ) : !profile.bulletAmount ? (
                      0
                    ) : (
                      millify(profile.bulletAmount)
                    )}
                  </Box>
                )}
              </Box>

              <Box className={classes.infoTableColumn}>
                <Box>Vault Payout</Box>
                {isLoadingProfile ? (
                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      sx={{ bgcolor: "#ffffff1a" }}
                      width={120}
                      height={20}
                    />
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", gap: "4px" }}>
                    {profile.vaultPercentInfo?.isSuspended ? (
                      <>
                        <Box component={"del"} sx={{ opacity: 0.4 }}>
                          {millify(
                            calculatePayoutByPercent(
                              vaultBalance,
                              profile?.vaultPercentInfo?.originalValue || 0
                            ),
                            { precision: 2 }
                          )}
                        </Box>
                        {` ${millify(
                          calculatePayoutByPercent(
                            vaultBalance,
                            profile?.vaultPercentInfo?.value || 0
                          ),
                          { precision: 2 }
                        )} ${config.chainSymbol}`}
                      </>
                    ) : (
                      `${millify(
                        calculatePayoutByPercent(
                          vaultBalance,
                          profile?.vaultPercentInfo?.value || 0
                        ),
                        { precision: 2 }
                      )} ${config.chainSymbol}`
                    )}
                  </Box>
                )}
              </Box>
            </Box>

            <Box className={classes.actionList}>
              <Box className={classes.action}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                >
                  <Box className={classes.actionButtonIcon}>
                    <Logo classNames={classes.actionIcon} />

                    <Box>{config.symbol}</Box>
                  </Box>

                  <Box>
                    {isLoadingProfile ? (
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        sx={{ bgcolor: "#ffffff1a" }}
                        width={60}
                        height={20}
                      />
                    ) : (
                      toUSDFormat((profile.stMafia || 0) + (profile.mafia || 0))
                    )}
                  </Box>
                </Button>
              </Box>

              <Box className={classes.action}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                >
                  <Box
                    component="img"
                    src={skullIcon}
                    className={classes.actionIcon}
                  />
                  {<Box>0 Kills</Box>}
                </Button>
              </Box>

              <Box className={classes.action}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                  onClick={() => {
                    window.open(
                      `${config.explorerUrl}address/${profile.address}`,
                      "__target"
                    );
                  }}
                >
                  {CHAIN === ChainType.PLS ? (
                    <NativeIcon classNames={classes.actionIcon} />
                  ) : (
                    <Box
                      component="img"
                      src={bscIcon}
                      className={classes.actionIcon}
                    ></Box>
                  )}

                  <Box>View Address</Box>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileCard;
