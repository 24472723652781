import { Box, Typography } from "@mui/material";

import moneyIcon from "../../assets/imgs/exchange/money.png";

import { formatDate, toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useWallet from "../../hook/useWallet";
import { getCashHistory } from "../../reducers/profile.slice";

const CashHistoryList = () => {
  const { classes } = useStyles();
  const { account } = useWallet();
  const dispatch = useAppDispatch();
  const { cashHistory } = useAppSelector((state) => state.profile);

  useEffect(() => {
    if (!account) return;
    dispatch(getCashHistory({ address: account }));
  }, [account, dispatch]);

  return (
    <Box
      sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      <Box className={classes.header}>
        <Box
          component={"img"}
          src={moneyIcon}
          className={classes.moneyIcon}
        ></Box>

        <Typography
          color={"white"}
          fontSize={36}
          fontFamily={"Philosopher"}
          fontWeight={900}
        >
          Cash transactions
        </Typography>
      </Box>

      <Box className={classes.transactionList}>
        <Box className={classes.transactionListHeader}>
          <Typography
            color={"white"}
            fontSize={18}
            fontFamily={"Philosopher"}
            fontWeight={900}
          >
            Date/Time
          </Typography>
          <Typography
            color={"white"}
            fontSize={18}
            fontFamily={"Philosopher"}
            fontWeight={900}
          >
            Type
          </Typography>
          <Typography
            color={"white"}
            fontSize={18}
            fontFamily={"Philosopher"}
            fontWeight={900}
          >
            Amount
          </Typography>
        </Box>

        {cashHistory.map((transaction, index) => (
          <Box className={classes.transactionListItem} key={index}>
            <Typography
              color={"grey"}
              fontSize={16}
              fontFamily={"Philosopher"}
              fontWeight={900}
            >
              {formatDate(transaction.timestamp)}
            </Typography>
            <Typography
              color={"white"}
              fontSize={16}
              fontFamily={"Philosopher"}
              fontWeight={900}
            >
              {transaction.description}
            </Typography>
            <Typography
              color={transaction.direction === 0 ? "rgb(80, 169, 69)" : "red"}
              fontSize={16}
              fontFamily={"Philosopher"}
              fontWeight={900}
            >
              {transaction.direction === 0 ? "+" : "-"}{" "}
              {toUSDFormat(Math.abs(transaction.amount))}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CashHistoryList;
