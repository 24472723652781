import { Box } from "@mui/material";
import {
  createChart,
  LastPriceAnimationMode,
  LineData,
  SeriesMarker,
  Time,
} from "lightweight-charts";
import millify from "millify";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { toUSDFormat } from "../../utils/utils";

import isMobile from "is-mobile";
import useStyles from "./index.styles";
import { getWorthHistory } from "../../reducers/profile.slice";
import { WorthDetail } from "../../types/ProfileWorth";

const WorthChart = ({ name, sx }: { name: string; sx?: any }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { worthHistory, loadingWorthHistory, profile } = useAppSelector(
    (state) => state.profile
  );

  useEffect(() => {
    if (!name) return;
    dispatch(getWorthHistory({ name }));
  }, [dispatch, name]);

  useEffect(() => {
    if (loadingWorthHistory || !worthHistory?.userWorth?.length) return;

    const newChartElement = document.createElement("div");
    newChartElement.style.cssText =
      "width: 100%; height: 100%; min-height: 350px; position:relative";

    const chartContainer = document.getElementById("chart-container");
    if (!chartContainer) return;
    chartContainer.appendChild(newChartElement);

    const chart = createChart(newChartElement, {
      layout: {
        background: { color: "#171a1b" },
        textColor: "#DDD",
      },
      grid: {
        vertLines: { color: "#44444475" },
        horzLines: { color: "#44444475" },
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.1,
          bottom: 0,
        },
        borderVisible: false,
      },
      timeScale: {
        secondsVisible: true,
        tickMarkFormatter: (time: any, tickMarkType: any, locale: any) => {
          const date = new Date(parseInt(time) * 1000);
          return `${(date.getMonth() + 1).toString().padStart(2, "0")}.${date
            .getDate()
            .toString()
            .padStart(2, "0")}`;
        },
      },
    });

    const addSeries = (
      data: WorthDetail[],
      lineColor: string,
      markersColor: string,
      lineIndex: number
    ) => {
      const series = chart.addAreaSeries({
        lineColor,
        lastPriceAnimation: LastPriceAnimationMode.Continuous,
        priceLineVisible: false,
        lineWidth: 4,
        lineType: 2,
        lastValueVisible: false,
        priceFormat: {
          type: "custom",
          minMove: 1,
          formatter: (price: number) => {
            const millifyPrice = millify(price);
            return millifyPrice;
          },
        },
      });

      let seriesData: LineData<Time>[] = [];
      data.forEach((item, index) => {
        seriesData.push({
          time: item.timestamp as Time,
          value: item.worth,
        });
      });

      series.setData(seriesData);

      const step =
        Math.floor(data.length / 20) === 0 ? 1 : Math.floor(data.length / 20);
      const markers: SeriesMarker<Time>[] = [];
      seriesData.forEach((point, index) => {
        if (index === seriesData.length - 1) {
          markers.push({
            time: point.time,
            position: "inBar",
            color: markersColor,
            shape: "circle",
            size: 0.5,
            text: `${toUSDFormat(point.value, 0)}`,
          });
        } else if (index % step === 0) {
          markers.push({
            time: point.time,
            position: "inBar",
            color: markersColor,
            shape: "circle",
            size: 0.5,
            text: "",
          });
        }
      });

      series.setMarkers(markers);
    };

    addSeries(worthHistory.userWorth, "#e0a119", "yellow", 0);
    worthHistory.familyWorth &&
      profile.id !== 1 &&
      addSeries(worthHistory.familyWorth, "#f15c78", "#f15c78", 1);
    worthHistory.gameWorth &&
      addSeries(worthHistory.gameWorth, "#5cacf1", "#5cacf1", 2);

    const startTime = Number(worthHistory.userWorth[0].timestamp);
    const endTime = Number(
      worthHistory.userWorth[worthHistory.userWorth.length - 1].timestamp
    );
    const timeRange = endTime - startTime;
    const padding = timeRange * 0.15;

    // const series = chart.addLineSeries({
    //   color: "transparent",
    // });
    // let seriesData: LineData<Time>[] = [];
    // worthHistory.userWorth.forEach((item, index) => {
    //   seriesData.push({
    //     time: (item.timestamp + padding || 0) as Time,
    //     value: 0,
    //   });
    // });
    // series.setData(seriesData);

    chart.timeScale().setVisibleRange({
      from: (startTime - padding) as Time,
      to: (endTime + padding) as Time,
    });

    return () => {
      if (chartContainer && newChartElement) {
        chartContainer.removeChild(newChartElement);
      }
    };
  }, [loadingWorthHistory, worthHistory, dispatch, profile.id]);

  return (
    <Box className={classes.chartBody} sx={{ ...sx }}>
      <Box className={classes.body} id={"chart-container"}></Box>
      <Box className={classes.totUsd}></Box>
      <Box className={classes.chartTitle}>Worth chart</Box>
      <Box className={classes.dateLabel}>Date</Box>
      <Box className={classes.valueLabel}>Worth</Box>
    </Box>
  );
};

export default WorthChart;
