import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ethers } from "ethers";

import { getTokenBalance } from "../helper/contractFunctions";
import { approveGameCash } from "../helper/contractFunctions/MafiaGameBank";
import {
  bidOnAuctionItem,
  cancelListing,
  createListing,
  finishAuctionItem,
  getSwapTokens,
  purchaseFixedItem,
} from "../helper/contractFunctions/MafiaInventoryMarketplace";

import config, { SERVER_URL } from "../config/config";
import { RarityType } from "../constants/enum/map";
import { Market } from "../constants/enum/market";
import { parseMarketListedItem } from "../helper/market";
import { SwapToken } from "../types/Contract/SwapToken";
import { MarketItemDetail } from "../types/MarketItemDetail";
import { MarketListedItem } from "./../types/MarketListedItem";

// Define the initial state
export interface MarketState {
  swapTokenInfo: [SwapToken[], number[]];
  isLoadingSwapTokenInfo: boolean;

  swapTokenBalances: any;
  isLoadingSwapTokenBalances: boolean;

  listedItems: MarketListedItem[];
  isLoadingListedItems: boolean;

  historyItems: MarketListedItem[];
  isLoadingHistoryItems: boolean;

  myListings: MarketListedItem[];
  isLoadingMyListings: boolean;
  isLoadingMarketStatus: boolean;
  totalListedItems: number;

  isCreatingListing: boolean;
  isPurchasingFixedItem: boolean;
  isBidOnAuctionItem: boolean;
  isFinishAuctionItem: boolean;
  isCancelListing: boolean;

  itemSold: number;
  totalFee: number;
  totalUsd: number;

  itemDetail: MarketItemDetail;
  isLoadingItemDetail: boolean;
}

const initialState: MarketState = {
  swapTokenInfo: [[], []],
  isLoadingSwapTokenInfo: false,

  swapTokenBalances: {},
  isLoadingSwapTokenBalances: false,

  listedItems: [],
  isLoadingListedItems: false,

  historyItems: [],
  isLoadingHistoryItems: false,

  myListings: [],
  isLoadingMyListings: false,
  isLoadingMarketStatus: false,
  totalListedItems: 0,

  isCreatingListing: false,
  isPurchasingFixedItem: false,
  isBidOnAuctionItem: false,
  isFinishAuctionItem: false,
  isCancelListing: false,

  itemSold: 0,
  totalFee: 0,
  totalUsd: 0,

  itemDetail: {
    totalSupply: 0,
    priceUSD: 0,
    priceChangePercent: 0,
    fixedVolume: 0,
    auctionVolume: 0,

    activeItems: [],
    soldItems: [],
    expiredItems: [],
  },

  isLoadingItemDetail: false,
};

export const getSwapTokenBalance = createAsyncThunk(
  "market/getSwapTokenBalance",
  async ({ account }: { account: string }, { getState }) => {
    const state = (getState() as any).market as MarketState;

    const balanceArray = await Promise.all(
      state.swapTokenInfo[0].map(async (token) => {
        const balance = await getTokenBalance(token.tokenAddress, account);
        return { [token.name]: balance };
      })
    );

    const balances = balanceArray.reduce((acc, entry) => {
      const key = Object.keys(entry)[0];
      acc[key] = entry[key];
      return acc;
    }, {});

    return balances;
  }
);

export const getSwapTokenInfo = createAsyncThunk(
  "market/getSwapTokenInfo",
  async () => {
    const data = await getSwapTokens();

    const swapTokenList = data[0].map((tokenInfo) => {
      return {
        name: tokenInfo.name,
        tokenAddress: tokenInfo.tokenAddress,
        isStable: tokenInfo.isStable,
        isEnabled: tokenInfo.isEnabled,
        price: Number(tokenInfo.price),
        decimal: Number(tokenInfo.decimal),
      } as SwapToken;
    });

    const swapTokenPrice = data[1].map((price) => {
      return Number(ethers.utils.formatEther(price));
    });
    return { swapTokenList, swapTokenPrice };
  }
);

export const createListingAction = createAsyncThunk(
  "market/createListingAction",
  async ({
    itemId,
    startingPrice,
    listingType,
    listingToken,
    duration,
    account,
  }: {
    itemId: number;
    startingPrice: number;
    listingType: number;
    listingToken: string;
    duration: number;
    account: string;
  }) => {
    const data = await createListing(
      itemId,
      startingPrice,
      listingType,
      listingToken,
      duration,
      account
    );

    return data.transactionHash;
  }
);

export const approveGameCashAction = createAsyncThunk(
  "market/approveGameCashAction",
  async ({
    tokenAmount,
    account,
  }: {
    tokenAmount: number;
    account: string;
  }) => {
    const data = await approveGameCash(
      tokenAmount,
      config.marketAddress,
      account
    );

    return data.transactionHash || "";
  }
);

export const purchaseFixedItemAction = createAsyncThunk(
  "market/purchaseFixedItemAction",
  async ({
    listingId,
    swapTokenId,
    tokenAddress,
    tokenAmount,
    account,
  }: {
    listingId: number;
    swapTokenId: number;
    tokenAddress: string;
    tokenAmount: number;
    account: string;
  }) => {
    const data = await purchaseFixedItem(
      listingId,
      swapTokenId,
      tokenAddress,
      tokenAmount,
      account
    );

    return data.transactionHash;
  }
);

export const bidOnAuctionItemAction = createAsyncThunk(
  "market/bidOnAuctionItemAction",
  async ({
    listingId,
    swapTokenId,
    price,
    tokenAddress,
    tokenAmount,
    account,
  }: {
    listingId: number;
    swapTokenId: number;
    price: number;
    tokenAddress: string;
    tokenAmount: number;
    account: string;
  }) => {
    const data = await bidOnAuctionItem(
      listingId,
      swapTokenId,
      price,
      tokenAddress,
      tokenAmount,
      account
    );

    return data.transactionHash;
  }
);

export const finishAuctionItemAction = createAsyncThunk(
  "market/finishAuctionItemAction",
  async ({ listingId, account }: { listingId: number; account: string }) => {
    const data = await finishAuctionItem(listingId, account);

    return data.transactionHash;
  }
);

export const cancelListingAction = createAsyncThunk(
  "market/cancelListingAction",
  async ({ listingId, account }: { listingId: number; account: string }) => {
    const data = await cancelListing(listingId, account);

    return data.transactionHash;
  }
);

export const getListedItems = createAsyncThunk(
  "market/getListedItems",
  async ({
    itemSortDirection,
    itemSortBy,
    itemFilter,
    from,
    count,
  }: {
    itemSortDirection: boolean;
    itemSortBy: Market.SortBy;
    itemFilter: string;
    from: number;
    count: number;
  }) => {
    const sortIndex = Market.getSortByNumber(itemSortBy);
    const response = await axios.get(
      `${SERVER_URL}/market/items?status=${0}&direction=${itemSortDirection ? 0 : 1
      }&sortby=${sortIndex}&category=${itemFilter}&from=${from}&count=${count}`
    );

    return response.data;
  }
);

export const getHistoryItems = createAsyncThunk(
  "market/getHistoryItems",
  async ({
    showOnlyCompleted,
    itemFilter,
    from,
    count,
  }: {
    showOnlyCompleted: boolean;
    itemFilter: string;
    from: number;
    count: number;
  }) => {
    const response = await axios.get(
      `${SERVER_URL}/market/items?status=${showOnlyCompleted ? 1 : 2
      }&category=${itemFilter}&from=${from}&count=${count}`
    );

    return response.data;
  }
);

export const getMyListings = createAsyncThunk(
  "market/getMyListings",
  async ({ address }: { address: string }) => {
    const response = await axios.get(`${SERVER_URL}/market/myitem/${address}`);

    return response.data;
  }
);

export const getItemInfoById = createAsyncThunk(
  "market/getItemInfoById",
  async ({ listingId }: { listingId: number }) => {
    const response = await axios.get(`${SERVER_URL}/market/items/${listingId}`);

    return response.data;
  }
);

export const getMarketStatusInfo = createAsyncThunk(
  "market/getMarketStatusInfo",
  async () => {
    const response = await axios.get(`${SERVER_URL}/market/info`);

    return response.data;
  }
);

export const getMarketItemDetail = createAsyncThunk(
  "market/getMarketItemDetail",
  async ({
    categoryId,
    typeId,
    rarityType,
  }: {
    categoryId: number;
    typeId: number;
    rarityType: RarityType | null;
  }) => {
    const response = await axios.get(
      `${SERVER_URL}/market/item?categoryId=${categoryId}&typeId=${typeId}&rarityType=${rarityType}`
    );

    return response.data;
  }
);

export const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    handleCancelListingEvent: (state, { payload }) => { },
    handleNewBidEvent: (state, { payload }) => { },
    handleNewListingEvent: (state, { payload }) => { },
    handleItemPurchasedEvent: (state, { payload }) => { },
    setListedItems: (state, { payload }) => {
      state.listedItems = payload;
    },
    setHistoryItems: (state, { payload }) => {
      state.historyItems = payload;
    },

    removeItemInfoById: (state, { payload }) => {
      const listingId =
        typeof payload === "string" ? parseInt(payload, 10) : payload; // Ensure payload is a number

      const index = state.listedItems.findIndex(
        (item) => item.listingId === listingId
      );

      if (index !== -1) {
        // Using Immer's draft state to ensure immutability
        state.listedItems = state.listedItems.filter((_, i) => i !== index);
      } else {
        console.log("Item not found with listingId:", listingId);
      }
    },

    clearItems: (state) => {
      state.listedItems = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSwapTokenBalance.pending, (state) => {
      state.isLoadingSwapTokenBalances = true;
    });
    builder.addCase(getSwapTokenBalance.fulfilled, (state, { payload }) => {
      state.swapTokenBalances = payload;
      state.isLoadingSwapTokenBalances = false;
    });
    builder.addCase(getSwapTokenBalance.rejected, (state, { error }) => {
      state.isLoadingSwapTokenBalances = false;
      console.log(error);
    });

    builder.addCase(getSwapTokenInfo.pending, (state) => { });
    builder.addCase(getSwapTokenInfo.fulfilled, (state, { payload }) => {
      state.swapTokenInfo = [payload.swapTokenList, payload.swapTokenPrice];
    });
    builder.addCase(getSwapTokenInfo.rejected, (state, { error }) => { });

    builder.addCase(createListingAction.pending, (state) => {
      state.isCreatingListing = true;
    });
    builder.addCase(createListingAction.fulfilled, (state, { payload }) => {
      state.isCreatingListing = false;
    });
    builder.addCase(createListingAction.rejected, (state, { error }) => {
      state.isCreatingListing = false;
    });

    builder.addCase(approveGameCashAction.pending, (state) => {
      state.isPurchasingFixedItem = true;
    });
    builder.addCase(approveGameCashAction.fulfilled, (state, { payload }) => {
      state.isPurchasingFixedItem = false;
    });
    builder.addCase(approveGameCashAction.rejected, (state, { error }) => {
      state.isPurchasingFixedItem = false;
      console.log(error);
    });

    builder.addCase(purchaseFixedItemAction.pending, (state) => {
      state.isPurchasingFixedItem = true;
    });
    builder.addCase(purchaseFixedItemAction.fulfilled, (state, { payload }) => {
      state.isPurchasingFixedItem = false;
    });
    builder.addCase(purchaseFixedItemAction.rejected, (state, { error }) => {
      state.isPurchasingFixedItem = false;
      console.log(error);
    });

    builder.addCase(bidOnAuctionItemAction.pending, (state) => {
      state.isBidOnAuctionItem = true;
    });
    builder.addCase(bidOnAuctionItemAction.fulfilled, (state, { payload }) => {
      state.isBidOnAuctionItem = false;
    });
    builder.addCase(bidOnAuctionItemAction.rejected, (state, { error }) => {
      state.isBidOnAuctionItem = false;
      console.log(error);
    });

    builder.addCase(finishAuctionItemAction.pending, (state) => {
      state.isFinishAuctionItem = true;
    });
    builder.addCase(finishAuctionItemAction.fulfilled, (state, { payload }) => {
      state.isFinishAuctionItem = false;
    });
    builder.addCase(finishAuctionItemAction.rejected, (state, { error }) => {
      state.isFinishAuctionItem = false;
    });

    builder.addCase(cancelListingAction.pending, (state) => {
      state.isCancelListing = true;
    });
    builder.addCase(cancelListingAction.fulfilled, (state, { payload }) => {
      state.isCancelListing = false;
    });
    builder.addCase(cancelListingAction.rejected, (state, { error }) => {
      state.isCancelListing = false;
    });

    builder.addCase(getListedItems.pending, (state) => {
      state.isLoadingListedItems = true;
    });
    builder.addCase(getListedItems.fulfilled, (state, { payload }) => {
      state.totalListedItems = payload.totalCount;

      const items: MarketListedItem[] = payload.data.map(
        (item: any, index: number) => {
          return parseMarketListedItem(item);
        }
      );

      const updatedItems: MarketListedItem[] = [];
      items.forEach((item) => {
        const index = state.listedItems.findIndex(
          (it) => it.listingId === item.listingId
        );
        if (index === -1) {
          updatedItems.push(item);
        }
      });
      state.listedItems = [...state.listedItems, ...updatedItems];
      state.isLoadingListedItems = false;
    });
    builder.addCase(getListedItems.rejected, (state, { error }) => {
      state.isLoadingListedItems = false;
    });

    builder.addCase(getHistoryItems.pending, (state) => {
      state.isLoadingHistoryItems = true;
    });
    builder.addCase(getHistoryItems.fulfilled, (state, { payload }) => {
      state.totalListedItems = payload.totalCount;

      const items: MarketListedItem[] = payload.data.map(
        (item: any, index: number) => {
          return parseMarketListedItem(item);
        }
      );

      const updatedItems: MarketListedItem[] = [];
      items.forEach((item) => {
        const index = state.historyItems.findIndex(
          (it) => it.listingId === item.listingId
        );
        if (index === -1) {
          updatedItems.push(item);
        }
      });

      state.historyItems = [...state.historyItems, ...updatedItems];
      state.isLoadingHistoryItems = false;
    });
    builder.addCase(getHistoryItems.rejected, (state, { error }) => {
      state.isLoadingHistoryItems = false;
    });

    builder.addCase(getItemInfoById.pending, (state) => { });
    builder.addCase(getItemInfoById.fulfilled, (state, { payload }) => {
      const listingId = payload.id;
      const updatedItemInfo = parseMarketListedItem(payload);
      const itemIndex = state.listedItems.findIndex(
        (item) => item.listingId === listingId
      );

      if (itemIndex !== -1) {
        state.listedItems[itemIndex] = updatedItemInfo;
      } else {
        state.listedItems.unshift(updatedItemInfo);

        const currentTimestamp = new Date().getTime() / 1000;

        // Sort the list based on remaining time until expiration
        state.listedItems.sort((a, b) => {
          const aRemaining = a.listingInfo.expiresAt - currentTimestamp;
          const bRemaining = b.listingInfo.expiresAt - currentTimestamp;
          return aRemaining - bRemaining;
        });
      }
    });
    builder.addCase(getItemInfoById.rejected, (state, { error }) => { });

    builder.addCase(getMyListings.pending, (state) => {
      state.isLoadingMyListings = true;
    });
    builder.addCase(getMyListings.fulfilled, (state, { payload }) => {
      const items: MarketListedItem[] = payload.map(
        (item: any, index: number) => {
          return parseMarketListedItem(item);
        }
      );
      state.myListings = items;
      state.isLoadingMyListings = false;
    });
    builder.addCase(getMyListings.rejected, (state, { error }) => {
      state.isLoadingMyListings = false;
    });

    builder.addCase(getMarketStatusInfo.pending, (state) => {
      state.isLoadingMarketStatus = true;
    });
    builder.addCase(getMarketStatusInfo.fulfilled, (state, { payload }) => {
      state.totalFee = payload.fee;
      state.itemSold = payload.itemSold;
      state.totalUsd = payload.totalUsd;
      state.isLoadingMarketStatus = false;
    });
    builder.addCase(getMarketStatusInfo.rejected, (state, { error }) => {
      state.isLoadingMarketStatus = false;
    });

    builder.addCase(getMarketItemDetail.pending, (state) => {
      state.isLoadingItemDetail = true;
    });
    builder.addCase(getMarketItemDetail.fulfilled, (state, { payload }) => {
      const itemDetail: MarketItemDetail = {
        totalSupply: payload.totalSupply,
        priceUSD: payload.priceUSD,
        priceChangePercent: payload.priceChangePercent,
        fixedVolume: payload.fixedVolume,
        auctionVolume: payload.auctionVolume,

        activeItems: payload.activeItems.map((item: any, index: number) => {
          return parseMarketListedItem(item);
        }),
        soldItems: payload.soldItems.map((item: any, index: number) => {
          return parseMarketListedItem(item);
        }),
        expiredItems: payload.expiredItems.map((item: any, index: number) => {
          return parseMarketListedItem(item);
        }),
      };

      state.itemDetail = itemDetail;
      state.isLoadingItemDetail = false;
    });
    builder.addCase(getMarketItemDetail.rejected, (state, { error }) => {
      state.isLoadingItemDetail = false;
    });
  },
});

export const {
  handleCancelListingEvent,
  handleNewBidEvent,
  handleNewListingEvent,
  handleItemPurchasedEvent,
  setListedItems,
  setHistoryItems,
  removeItemInfoById,
  clearItems,
} = marketSlice.actions;

export default marketSlice.reducer;
