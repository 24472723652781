import { Box } from "@mui/material";
import {
  createChart,
  LastPriceAnimationMode,
  LineData,
  SeriesMarker,
  Time,
} from "lightweight-charts";
import millify from "millify";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useNativePrice from "../../hook/useNativePrice";
import useVaultBalance from "../../hook/useVaultBalance";
import { getVaultHistory } from "../../reducers/vault.slice";
import { toUSDFormat } from "../../utils/utils";

import isMobile from "is-mobile";
import { getMarketStatusInfo } from "../../reducers/market.slice";
import useStyles from "./index.styles";

const VaultChart = ({ sx }: { sx?: any }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const vaultBalance = useVaultBalance();
  const nativePrice = useNativePrice();

  const { history } = useAppSelector((state) => state.vault);
  const { totalFee } = useAppSelector((state) => state.market);

  useEffect(() => {
    dispatch(getVaultHistory());
    dispatch(getMarketStatusInfo());
  }, [dispatch]);

  useEffect(() => {
    if (
      history.timestamp.length === 0 ||
      !vaultBalance ||
      !nativePrice ||
      !totalFee
    )
      return;

    const vaultUSD = vaultBalance * nativePrice;

    const dbKeyUSD = history.key.reduce((acc, val) => acc + (val || 0), 0);
    const dbMarketUSD = history.market.reduce((acc, val) => acc + (val | 0), 0);
    const dbLandUSD = history.slot.reduce((acc, val) => acc + (val || 0), 0);
    const dbTaxUSD = history.tax.reduce((acc, val) => acc + (val || 0), 0);

    const realMarketUSD = totalFee * nativePrice;
    const realLandUSD = dbLandUSD;
    const realKeyUSD =
      ((vaultUSD - realMarketUSD - realLandUSD) / (dbKeyUSD + dbTaxUSD)) *
      dbKeyUSD;
    const realTaxUSD =
      ((vaultUSD - realMarketUSD - realLandUSD) / (dbKeyUSD + dbTaxUSD)) *
      dbTaxUSD;

    const realKeyData = history.key.map(
      (value) => (value / dbKeyUSD) * realKeyUSD
    );
    const realMarketData = history.market.map(
      (value) => (value / dbMarketUSD) * realMarketUSD
    );
    const realLandData = history.slot.map(
      (value) => (value / dbLandUSD) * realLandUSD
    );
    const realTaxData = history.tax.map(
      (value) => (value / dbTaxUSD) * realTaxUSD
    );

    const newChartElement = document.createElement("div");
    newChartElement.style.cssText =
      "width: 100%; height: 100%; min-height: 350px; position:relative";

    const chartContainer = document.getElementById("chart-container");
    if (!chartContainer) return;
    chartContainer.appendChild(newChartElement);

    const chart = createChart(newChartElement, {
      layout: {
        background: { color: "#171a1b" },
        textColor: "#DDD",
      },
      grid: {
        vertLines: { color: "#44444475" },
        horzLines: { color: "#44444475" },
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.1,
          bottom: 0,
        },
        borderVisible: false,
      },
      timeScale: {
        secondsVisible: true,
        tickMarkFormatter: (time: any, tickMarkType: any, locale: any) => {
          const date = new Date(parseInt(time) * 1000);
          return `${(date.getMonth() + 1).toString().padStart(2, "0")}.${date
            .getDate()
            .toString()
            .padStart(2, "0")}`;
        },
      },
    });

    const addSeries = (
      data: number[],
      lineColor: string,
      markersColor: string,
      lineIndex: number
    ) => {
      const series = chart.addAreaSeries({
        lineColor,
        lastPriceAnimation: LastPriceAnimationMode.Continuous,
        priceLineVisible: false,
        lineWidth: 4,
        lineType: 2,
        lastValueVisible: false,
        priceFormat: {
          type: "custom",
          minMove: 1,
          formatter: (usd: number) => {
            const usdStr = millify(usd);
            const percent = (usd / vaultUSD) * 100;
            return isMobile()
              ? percent.toFixed(0) + "%"
              : percent.toFixed(0) + "% ($" + usdStr + ")";
          },
        },
      });

      let usd = 0;
      let startSeries = false;
      let seriesData: LineData<Time>[] = [];
      data.forEach((item, index) => {
        usd += item || 0;
        if (usd > 0 && !startSeries) {
          startSeries = true;
        }
        if (startSeries) {
          seriesData.push({
            time: (history.timestamp[index] || 0) as Time,
            value: usd,
          });
        }
      });

      series.setData(seriesData);

      const step =
        Math.floor(data.length / 20) === 0 ? 1 : Math.floor(data.length / 20);
      const markers: SeriesMarker<Time>[] = [];
      seriesData.forEach((point, index) => {
        if (index === seriesData.length - 1) {
          markers.push({
            time: point.time,
            position: "inBar",
            color: markersColor,
            shape: "circle",
            size: 0.5,
            text: `${toUSDFormat(
              (point.value / vaultUSD) * 100,
              2
            )}%($${toUSDFormat(usd, 0)})`,
          });
        } else if (index % step === 0) {
          markers.push({
            time: point.time,
            position: "inBar",
            color: markersColor,
            shape: "circle",
            size: 0.5,
            text: "",
          });
        }
      });

      series.setMarkers(markers);
    };

    addSeries(realTaxData, "#e0a119", "yellow", 0);
    addSeries(realKeyData, "#f15c78", "#f15c78", 1);
    addSeries(realMarketData, "#5cacf1", "#5cacf1", 2);
    addSeries(realLandData, "#1ad207", "#1ad207", 3);

    const startTime = Number(history.timestamp[0]);
    const endTime = Number(history.timestamp[history.timestamp.length - 1]);
    const timeRange = endTime - startTime;
    const padding = timeRange * 0.15;

    const series = chart.addLineSeries({
      color: "transparent",
    });
    let seriesData: LineData<Time>[] = [];
    history.timestamp.forEach((item, index) => {
      seriesData.push({
        time: (item + padding || 0) as Time,
        value: 0,
      });
    });
    series.setData(seriesData);

    chart.timeScale().setVisibleRange({
      from: (startTime - padding) as Time,
      to: (endTime + padding) as Time,
    });

    return () => {
      if (chartContainer && newChartElement) {
        chartContainer.removeChild(newChartElement);
      }
    };
  }, [history, nativePrice, vaultBalance, totalFee]);

  return (
    <Box className={classes.chartBody} sx={{ ...sx }}>
      <Box className={classes.body} id={"chart-container"}></Box>
      <Box className={classes.totUsd}>
        ${toUSDFormat((nativePrice * vaultBalance).toFixed(2))}
      </Box>
      <Box className={classes.chartTitle}>Vault resource chart</Box>
      <Box className={classes.dateLabel}>Date</Box>
      <Box className={classes.valueLabel}>Value(%)</Box>
    </Box>
  );
};

export default VaultChart;
