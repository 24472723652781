import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    background: "url(/assets/imgs/map/city_selection_bg.png) no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: 900,
    margintop: -64,
    display: "flex",
    justifyContent: "center",
    fontFamily: "Philosopher",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "auto 100%",
      backgroundPosition: "center",
      minHeight: "500px",
      overflowX: "auto",
      justifyContent: "flex-start"
    },
  },

  container: {
    justifyContent: "center",
    padding: "0 16px",
    boxSizing: "border-box",
    maxWidth: 1920,
    margin: "auto",
    minHeight: 1000,

    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },

  header: {
    position: "fixed",
    width: "100%",
    padding: "16px 32px",
    background: "rgba(0, 0, 0, 0.70)",

    fontFamily: "Play",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },

  cityBox: {
    display: "flex",
    flexDirection: "column",
    padding: "120px",
    borderRadius: "4px",

    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
  },

  title: {
    fontSize: "40px",
    marginTop: "32px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },

  tableList: {
    display: "flex",
    gap: 0,
    justifyContent: "center",
  },

  scrollPart: {
    overflowX: "auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flex: 3,
    },
  },

  darkBackground: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "black",
    opacity: "0.5",
    zIndex: 2,
  },

  buttonList: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    flex: 1,
    maxWidth: "100px",
    minWidth: "80px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "40px",
    },
  },

  visitButton: {
    textTransform: "none",
    backgroundColor: "#18a86b",
    color: "white",
    borderRadius: "8px",

    "&:hover": {
      backgroundColor: "#32491b60",
    },
  },

  circularBox: {
    width: "8px",
    height: "8px",

    borderRadius: "50%",
  },

  greenBox: {
    backgroundColor: "#1ad307",
    boxShadow: "0 0 4px 8px #1ad30720",
  },

  yellowBox: {
    backgroundColor: "#FFA500",
    boxShadow: "0 0 4px 8px #FFA50020",
  },

  redBox: {
    backgroundColor: "#FF0000",
    boxShadow: "0 0 4px 8px #FF000020",
  },

  availableTile: {
    width: "100%",
    display: "flex",
    gap: "12px!important",
    alignItems: "center",
  },

  cityName: {
    fontWeight: 900,
    color: "white",
  },

  familyCell: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },

  familyLogo: {
    width: "30px",
    height: "40px",
  },

  tableContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 32,
    minWidth: 1250,

    backgroundColor: theme.palette.background.cardBgTransparent,
    padding: "16px 32px",
    borderRadius: "8px",

    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",

    "& a": {
      textDecoration: "none",
    },

    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      padding: "4px 4px",
      marginTop: 0
    },
  },
  tableBody: {
    [theme.breakpoints.down("sm")]: {},
  },
  tableHeader: {
    color: "white",
    display: "grid",
    fontSize: 18,
    fontWeight: 900,
    alignItems: "center",
    padding: "8px 16px",
    height: 40,
    gridTemplateColumns: "1fr 1.5fr 1.5fr 2fr 3fr 1fr 2fr 1.5fr 1fr",
    [theme.breakpoints.down("md")]: {
      minWidth: 1200,
    },
  },

  tableCol: {
    cursor: "pointer",
    fontSize: 18,
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr 1.5fr 2fr 3fr 1fr 2fr 1.5fr 1fr",
    alignItems: "center",
    color: "#808080",
    padding: "8px 16px 8px 16px",
    borderBottom: "1px solid",
    borderColor: theme.palette.background.darkBorder,
    transitionDuration: "300ms",

    [theme.breakpoints.down("md")]: {
      minWidth: 1200,
    },

    "&>div": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },

    "& a": {
      textDecoration: "none",
      color: theme.palette.text.linkColor,
    },

    "& img": {
      borderRadius: 4,
      width: 24,
      height: 24,
    },

    "&:hover": {
      backgroundColor: theme.palette.background.whiteTransparent1,
    },
  },
}));

export default styles;
