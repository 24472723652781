import { Box } from "@mui/material";

import DaoRoundListItem from "./DaoRoundListItem";

import { useAppSelector } from "../../app/hooks";

import useStyles from "./index.styles";

interface MarketingdaoRoundListProps {
  showVotePopup: () => void;
  spentTime: number;
  showManagePopup: () => void;
}

const MarketingDaoRoundList = ({
  showVotePopup,
  spentTime,
  showManagePopup,
}: MarketingdaoRoundListProps) => {
  const { classes } = useStyles();

  const { proposals, options } = useAppSelector((state) => state.dao);

  return (
    <Box className={classes.daoRoundList}>
      {proposals.map((proposal, index) => (
        <DaoRoundListItem
          key={index}
          roundNumber={proposal.id}
          userCount={proposal.totalUsers}
          totalVotes={proposal.totalMafia}
          timeLeft={
            proposal.openedAt +
            proposal.duration -
            Date.now() / 1000 -
            spentTime
          }
          roundStatus={proposal.status}
          options={options[index]}
          showVotePopup={showVotePopup}
          showManagePopup={showManagePopup}
        />
      ))}
    </Box>
  );
};

export default MarketingDaoRoundList;
