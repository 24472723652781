import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import useWallet from "../../hook/useWallet";
import {
  createProposalAction,
  getProposalsAction,
} from "../../reducers/dao.slice";
import { useAppDispatch } from "../../app/hooks";

import useStyles from "./index.styles";
import { toastError } from "../../utils/utils";
import { Errors } from "../../constants/errors";

interface CreateDaoPopupProps {
  openModal: boolean;
  handleClose: () => void;
}

interface DaoOption {
  id: number;
  title: string;
  deliveryLink: string;
  cost: string;
  adminFee: string;
  isEditing: boolean; // State to track if the group is expanded for editing
}

const CreateDaoPopup = ({ openModal, handleClose }: CreateDaoPopupProps) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { account, connectWallet } = useWallet();

  // State to manage form groups
  const [daoOptions, setDaoOptions] = useState<DaoOption[]>([
    {
      id: Date.now(),
      title: "",
      deliveryLink: "",
      cost: "",
      adminFee: "",
      isEditing: false, // Initially collapsed
    },
  ]);

  const [duration, setDuration] = useState(0);

  // Add a new form group
  const addFormGroup = () => {
    setDaoOptions([
      ...daoOptions,
      {
        id: Date.now(),
        title: "",
        deliveryLink: "",
        cost: "",
        adminFee: "",
        isEditing: false, // New groups start expanded
      },
    ]);
  };

  // Remove a form group by ID
  const removeFormGroup = (id: number) => {
    setDaoOptions(daoOptions.filter((group) => group.id !== id));
  };

  // Handle input changes
  const handleInputChange = (
    id: number,
    field: keyof DaoOption,
    value: string
  ) => {
    setDaoOptions(
      daoOptions.map((group) =>
        group.id === id ? { ...group, [field]: value } : group
      )
    );
  };

  const handleDurationChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let duration = parseInt(event.target.value);
    if (duration <= 0) {
      duration = 1;
    }
    setDuration(duration);
  };

  // Toggle expand/collapse for a form group
  const toggleExpandGroup = (id: number) => {
    setDaoOptions(
      daoOptions.map((group) =>
        group.id === id
          ? { ...group, isEditing: !group.isEditing }
          : { ...group, isEditing: false }
      )
    );
  };

  const handleCreateDao = () => {
    if (!account) {
      connectWallet();
      handleClose();
      return;
    }

    if (daoOptions.length === 0) {
      return toastError(Errors.DAO.CREATE.EMPTY_OPTION);
    }

    if (duration <= 0) {
      return toastError(Errors.DAO.CREATE.DURATION_INVALID);
    }

    dispatch(
      createProposalAction({
        account,
        duration: duration * 24 * 3600,
        // duration: duration,
        options: daoOptions.map((group) => ({
          title: group.title,
          delieveryLink: group.deliveryLink,
          cost: group.cost,
          adminFee: group.adminFee,
        })),
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getProposalsAction());
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Dialog open={openModal} onClose={handleClose} className={classes.body}>
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Typography className={classes.modalTitle}>
            Create Marketing DAO
          </Typography>

          <Box className={classes.durationInput}>
            <TextField
              placeholder="Duration"
              value={duration}
              onChange={(e) => handleDurationChange(e)}
              className={classes.inputField}
              type="number"
            />
            <Typography className={classes.durationText}>Days</Typography>
          </Box>

          <Box className={classes.optionLists}>
            {daoOptions.map((group) => (
              <Box key={group.id} className={classes.formGroup}>
                <Box
                  className={classes.collapsedView}
                  onClick={() => toggleExpandGroup(group.id)}
                >
                  <Typography>{group.title || "Unnamed Option"}</Typography>
                  <Box sx={{ display: "flex", gap: "8px" }}>
                    <IconButton
                      className={classes.actionButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleExpandGroup(group.id);
                      }}
                    >
                      <EditIcon
                        sx={{ color: "white", width: "16px", height: "16px" }}
                      />
                    </IconButton>

                    <IconButton
                      className={classes.actionButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFormGroup(group.id);
                      }}
                    >
                      <DeleteIcon
                        sx={{ color: "white", width: "16px", height: "16px" }}
                      />
                    </IconButton>
                  </Box>
                </Box>

                {group.isEditing && (
                  // Expanded view
                  <Box className={classes.expandedView}>
                    <TextField
                      variant="outlined"
                      placeholder="Title"
                      value={group.title}
                      onChange={(e) =>
                        handleInputChange(group.id, "title", e.target.value)
                      }
                      className={classes.inputField}
                    />
                    <TextField
                      placeholder="Delivery Link"
                      value={group.deliveryLink}
                      onChange={(e) =>
                        handleInputChange(
                          group.id,
                          "deliveryLink",
                          e.target.value
                        )
                      }
                      className={classes.inputField}
                    />
                    <TextField
                      placeholder="Cost"
                      value={group.cost}
                      onChange={(e) =>
                        handleInputChange(group.id, "cost", e.target.value)
                      }
                      className={classes.inputField}
                      type="number"
                    />
                    <TextField
                      placeholder="Admin Fee"
                      value={group.adminFee}
                      onChange={(e) =>
                        handleInputChange(group.id, "adminFee", e.target.value)
                      }
                      className={classes.inputField}
                      type="number"
                    />
                  </Box>
                )}
              </Box>
            ))}
          </Box>

          <Box className={classes.addOption}>
            <IconButton
              onClick={addFormGroup}
              className={classes.addOptionButton}
            >
              <AddIcon />
            </IconButton>

            <Typography className={classes.addOptionText}>
              Add Option
            </Typography>
          </Box>
          <Button className={classes.createDaoButton} onClick={handleCreateDao}>
            Create
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateDaoPopup;
