import { useEffect, useState } from "react";
import Web3 from "web3";
import config from "../config/config";

const web3 = new Web3(config.providerList[0]);

const useDaoBalance = () => {
  const [vault, setVault] = useState(0);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        // Get balance returns the value in wei
        const balanceWei= await web3.eth.getBalance(config.daoAddress);

        // Convert wei to ETH
        const balance = parseFloat(web3.utils.fromWei(balanceWei, "ether"));

        // Calculate total balance
        const totalBalance = balance;

        // Set the total balance
        setVault(parseFloat(totalBalance.toFixed(2)));
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchBalance();
  }, []);

  return vault;
};

export default useDaoBalance;
