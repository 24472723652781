import { Box } from "@mui/material";
import { ItemCategoryInfoList } from "../../constants/const";
import { BuildingTypeOptions } from "../../constants/map";
import { BuildingType } from "../../constants/enum/map";
import { ItemCategory } from "../../constants/enum/enum";

interface InventoryItemIconProps {
  className?: string;
  sx?: object;
  categoryId: number;
  typeId: number;
  bottomBorderHeight?: number;
  bottomBorderColor?: string | null;
  slotSubType?: number | null;
}

const InventoryItemIcon = ({
  categoryId,
  typeId,
  sx,
  className,
  bottomBorderHeight,
  bottomBorderColor,
  slotSubType,
}: InventoryItemIconProps) => {
  const category = ItemCategoryInfoList[Math.floor(categoryId)];
  const icon =
    category.icons && category.icons.length > 0
      ? category.icons[typeId]
      : category.icon;

  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {bottomBorderHeight && bottomBorderColor ? (
        <Box
          className={className}
          component="img"
          src={
            slotSubType !== null &&
            slotSubType !== undefined &&
            categoryId === ItemCategory.LANDSLOT
              ? `/assets/buildings/${
                  BuildingTypeOptions[slotSubType as BuildingType].logo
                }`
              : `/assets/imgs/inventory/${icon}`
          }
          sx={{
            flex: 1,
            borderBottomLeftRadius: "0!important",
            borderBottomRightRadius: "0!important",
          }}
        ></Box>
      ) : (
        <Box
          className={className}
          component="img"
          src={`/assets/imgs/inventory/${icon}`}
          sx={{ flex: 1 }}
        ></Box>
      )}
      {bottomBorderHeight && bottomBorderColor ? (
        <Box
          sx={{
            backgroundColor: bottomBorderColor,
            minHeight: bottomBorderHeight,
            borderBottomLeftRadius: bottomBorderHeight,
            borderBottomRightRadius: bottomBorderHeight,
            width: "100%",
          }}
        ></Box>
      ) : null}
    </Box>
  );
};

export default InventoryItemIcon;
