import { useGSAP } from "@gsap/react";
import { Box } from "@mui/material";
import gsap from "gsap";
import { useEffect, useRef, useState } from "react";
import useSound from "use-sound";

import wheelSfx from "../../../assets/sfxs/Roulettte/wheel.mp3";

import { RouletteBetStatus } from "../../../constants/enum/roulette";
import { RouletteNumbers } from "../../../constants/roulette";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import useWallet from "../../../hook/useWallet";

import { updateBetStatus } from "../../../reducers/roulette.slice";

import useStyles from "./wheel.styles";

gsap.registerPlugin(useGSAP);

const spinCount = 10;
const duration = 11.5;
const initialAngle = 360 / 38 / 2;

const RouletteWheel = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { account } = useWallet();
  const { betResult, betStatus } = useAppSelector((state) => state.roulette);

  const [ballContainerAngle, setBallContainerAngle] = useState(initialAngle);
  const [ballOffsetY, setBallOffsetY] = useState(100);

  const [playWheelSfx] = useSound(wheelSfx, {
    loop: false,
    volume: 1,
  });

  const tl = useRef<gsap.core.Timeline | null>(null);

  const startAnimate = (nonce: number) => {
    const nonceIndex = RouletteNumbers.findIndex((value) => value === nonce);

    tl.current = gsap
      .timeline()
      .call(
        () => {
          setBallContainerAngle(
            -360 * spinCount - 180 + (360 / 38) * nonceIndex + initialAngle
          );

          playWheelSfx();
        },
        [],
        0
      )
      .call(
        () => {
          setBallOffsetY(72);
        },
        [],
        duration
      )
      .call(
        () => {
          dispatch(updateBetStatus(RouletteBetStatus.FINISHED));
        },
        [],
        duration + 1
      )
      .call(
        () => {
          setBallContainerAngle(initialAngle);
          setBallOffsetY(100);
        },
        [],
        duration + 2
      );
  };

  useEffect(() => {
    if (!betResult) return;

    startAnimate(betResult.nonce);
  }, [account, betResult]);

  return (
    <Box className={classes.wheelBox}>
      <Box className={classes.wheelContainer}>
        <Box className={classes.rouletteWheel}>
          <Box className={classes.rouletteInner}>
            {betStatus === RouletteBetStatus.SPINNING && (
              <Box
                className={classes.ballContainer}
                sx={{
                  transform: `rotate(${ballContainerAngle}deg) translateY(120px) translateX(120px)`,
                }}
              >
                <Box
                  className={classes.ball}
                  sx={{ transform: `translate(0, ${ballOffsetY}px)` }}
                ></Box>
              </Box>
            )}

            <svg width={300} height={300}>
              <circle
                cx={300 / 2}
                cy={300 / 2}
                r={300 / 2}
                style={{ touchAction: "none" }}
              ></circle>
            </svg>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RouletteWheel;
