import { BUSINESS_CATEGORY_ID } from "../constants/const";
import { ItemCategory } from "../constants/enum/enum";
import InventorySlotInfo from "../types/InventorySlotInfo";
import { ItemInfo } from "../types/ItemInfo";

export const parseOTCOfferItems = (payload: any) => {
  return payload.map((item: any) => {
    return {
      ...item,
      offerItemDataList: item.offerItemDataList.map((itemData: any) => {
        return {
          ...itemData,
          cityId: itemData.city,
          x: itemData.Map?.slotX,
          y: itemData.Map?.slotY,
          mapCityId: itemData.Map?.city,
          slotSubType: itemData.Map?.slotSubType,
        };
      }),
      requestItems: item.requestItems.map((item: any) => {
        return {
          ...item,
          cityId:
            item.categoryId === BUSINESS_CATEGORY_ID ||
            item.categoryId === ItemCategory.LANDSLOT
              ? item.cityId
              : -1,
          mapCityId: item.Map?.city,
          slotSubType: item.Map?.slotSubType,
        };
      }),
    };
  });
};

export const parseInventoryItems = (payload: any) => {
  return {
    inventoryItems: payload.inventoryData.map((item: any, index: number) => {
      return {
        itemId: item.id,
        categoryId: item.itemDetail.category_id,
        typeId: item.itemDetail.type_id,
        cityId: item.city,
        inventoryId: item.item_id,
      };
    }),
    slotItems: payload.slotData.map((item: any, index: number) => {
      return item as InventorySlotInfo;
    }),
  };
};

export const hasAllItems = (
  myInventoryItems: ItemInfo[],
  itemInfos: ItemInfo[],
  mySlotItems: InventorySlotInfo[]
): [boolean, number[]] => {
  // Create a count map for itemInfos
  const itemCount: Record<string, number> = {};

  // Count occurrences of each item in itemInfos
  for (const item of itemInfos) {
    // if type of ognft and credit is from 0 to 6, format as 0
    let typeId = item.typeId;
    if (
      (item.categoryId === 11 || item.categoryId === 12) &&
      item.typeId >= 0 &&
      item.typeId < 6
    ) {
      typeId = 0;
    }

    const key =
      item.categoryId === ItemCategory.LANDSLOT
        ? `${item.cityId}-${item.slotX}-${item.slotY}`
        : `${item.categoryId}-${typeId}-${item.cityId}`;
    itemCount[key] = (itemCount[key] || 0) + 1;
  }

  // Array to hold matching itemIds
  const matchingItemIds: number[] = [];
  const inventoryItemsCopy = [...myInventoryItems];

  for (const item of itemInfos) {
    let typeId = item.typeId;
    if (
      (item.categoryId === 11 || item.categoryId === 12) &&
      item.typeId >= 0 &&
      item.typeId < 6
    ) {
      typeId = 0;
    }

    const key =
      item.categoryId === ItemCategory.LANDSLOT
        ? `${item.cityId}-${item.slotX}-${item.slotY}`
        : `${item.categoryId}-${typeId}-${item.cityId}`;

    const index = inventoryItemsCopy.findIndex((invItem) => {
      let invTypeId = invItem.typeId;
      if (
        (invItem.categoryId === 11 || invItem.categoryId === 12) &&
        invItem.typeId >= 0 &&
        invItem.typeId < 6
      ) {
        invTypeId = 0;
      }

      const slotData = mySlotItems.find(
        (it) => it.inventoryItemId === invItem.itemId
      );
      const invKey =
        invItem.categoryId === ItemCategory.LANDSLOT
          ? `${slotData?.city}-${slotData?.slotX}-${slotData?.slotY}`
          : `${invItem.categoryId}-${invTypeId}-${invItem.cityId}`;

      return key === invKey;
    });
    if (index !== -1) {
      const matchedItem = inventoryItemsCopy[index];
      matchingItemIds.push(matchedItem.itemId);
      inventoryItemsCopy.splice(index, 1);
    } else {
      return [false, []];
    }
  }
  return [true, matchingItemIds];
};
