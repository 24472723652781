import { Box, Button, Skeleton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CONFIRMATION_DELAY } from "../../../constants/const";
import { Messages } from "../../../constants/messages";
import { RouletteInitialMinBet } from "../../../constants/roulette";
import { dispatchTxAction } from "../../../helper/dispatchTxAction";
import useWallet from "../../../hook/useWallet";
import {
  addLiquidityAction,
  approveGameCashAction,
  getRouletteLiquidityAction,
  getRoulettesAction,
  removeLiquidityAction,
  updateRouletteSettingsAction,
} from "../../../reducers/roulette.slice";
import { toastInfo, toastSuccess, toUSDFormat } from "../../../utils/utils";

import cashIcon from "../../../assets/imgs/exchange/money.png";
import userIcon from "../../../assets/imgs/layout/user.png";

import CustomDropDown from "../../CustomDropdown/CustomDropDown";

import useStyles from "./index.styles";
import useGameBankBalance from "../../../hook/useGameBankBalance";
import { getTokenAllowance } from "../../../helper/contractFunctions";
import config from "../../../config/config";

const RouletteManageBoard = () => {
  const { account } = useWallet();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { signMsg, signature } = useAppSelector((state) => state.auth);

  const { updateBalance } = useGameBankBalance(account, signMsg, signature);

  const {
    currentRouletteId,
    roulettes,
    isRouletteListLoading,
    currentRouletteLiquidity,
    isGettingRouletteLiquidity,
  } = useAppSelector((state) => state.roulette);

  const [isOpened, setIsOpened] = useState(false);
  const [minBet, setMinBet] = useState(0);
  const [maxBet, setMaxBet] = useState(0);

  const [addLiquidity, setAddLiquidity] = useState(0);
  const [removeLiquidity, setRemoveLiquidity] = useState(0);

  const handleApplyChange = async () => {
    if (!account) return;

    // update settings
    if (
      roulettes[currentRouletteId].isOpened !== isOpened ||
      roulettes[currentRouletteId].minBet !== minBet ||
      roulettes[currentRouletteId].maxBet !== maxBet
    ) {
      if (!(minBet >= roulettes[currentRouletteId].minBetBottom && minBet <= roulettes[currentRouletteId].minBetTop)) {
        toastInfo(Messages.ROULETTE.UPDATE_SETTINGS.MIN_BET_ERROR, {
          minBet: roulettes[currentRouletteId].minBetBottom.toString(),
          maxBet: roulettes[currentRouletteId].minBetTop.toString(),
        });
        return;
      }
      if (!(maxBet >= roulettes[currentRouletteId].maxBetBottom && maxBet <= roulettes[currentRouletteId].maxBetTop)) {
        toastInfo(Messages.ROULETTE.UPDATE_SETTINGS.MAX_BET_ERROR, {
          minBet: roulettes[currentRouletteId].maxBetBottom.toString(),
          maxBet: roulettes[currentRouletteId].maxBetTop.toString(),
        });
        return;
      }

      dispatchTxAction(
        dispatch,
        updateRouletteSettingsAction({
          account,
          isOpened,
          minBet,
          maxBet,
          rouletteId: currentRouletteId,
        }),
        () => {
          dispatch(getRoulettesAction());
          toastSuccess(Messages.ROULETTE.UPDATE_SETTINGS.UPDATED_SUCCESS);
        },
        CONFIRMATION_DELAY
      );
    }

    // add liquidity
    if (addLiquidity > 0) {
      dispatchTxAction(
        dispatch,
        approveGameCashAction({
          tokenAmount: addLiquidity,
          account,
        }),
        () => {
          dispatchTxAction(
            dispatch,
            addLiquidityAction({
              account,
              rouletteId: currentRouletteId,
              amount: addLiquidity,
            }),
            () => {
              dispatch(
                getRouletteLiquidityAction({
                  rouletteId: currentRouletteId,
                  signature,
                  message: signMsg,
                })
              );
              setAddLiquidity(0);

              toastSuccess(Messages.ROULETTE.LIQUIDITY.ADDED_SUCCESS);
              updateBalance(account, signMsg, signature);
            },
            CONFIRMATION_DELAY
          );
        },
        CONFIRMATION_DELAY
      );
    }

    if (removeLiquidity > 0) {
      dispatchTxAction(
        dispatch,
        removeLiquidityAction({
          account,
          rouletteId: currentRouletteId,
          amount: removeLiquidity,
        }),
        () => {
          dispatch(
            getRouletteLiquidityAction({
              rouletteId: currentRouletteId,
              signature,
              message: signMsg,
            })
          );
          setRemoveLiquidity(0);
          toastSuccess(Messages.ROULETTE.LIQUIDITY.REMOVED_SUCCESS);
          updateBalance(account, signMsg, signature);
        },
        CONFIRMATION_DELAY
      );
    }
  };

  useEffect(() => {
    if (!roulettes[currentRouletteId]) {
      return;
    }

    setIsOpened(roulettes[currentRouletteId].isOpened);
    setMinBet(roulettes[currentRouletteId].minBet);
    setMaxBet(roulettes[currentRouletteId].maxBet);
  }, [currentRouletteId, roulettes]);

  useEffect(() => {
    dispatch(
      getRouletteLiquidityAction({
        rouletteId: currentRouletteId,
        signature,
        message: signMsg,
      })
    );
  }, [currentRouletteId, dispatch, signMsg, signature]);

  return !isRouletteListLoading && roulettes[currentRouletteId] ? (
    <Box className={classes.manageBoard}>
      <Box className={classes.manageBoardLpInfoBox}>
        <Typography className={classes.lpInfoTitle}>Table Liquidity</Typography>
        <Box className={classes.lpInfo}>
          <Box className={classes.lpCashIcon} component="img" src={cashIcon} />
          {isGettingRouletteLiquidity ? (
            <Skeleton
              width={80}
              height={24}
              variant="rounded"
              animation="wave"
            />
          ) : (
            <Typography>{toUSDFormat(currentRouletteLiquidity)}</Typography>
          )}
        </Box>
      </Box>

      <Box className={classes.manageOptionList}>
        <Box className={classes.optionListItem}>
          <Typography>Status:</Typography>
          <CustomDropDown
            items={["Open", "Close"]}
            value={isOpened ? "Open" : "Close"}
            handleChange={(value) => {
              setIsOpened(value.target.value === "Open");
            }}
          />
        </Box>

        <Box className={classes.optionListItem}>
          <Typography>Min Bet:</Typography>
          <Box>
            <Box component="img" src={cashIcon}></Box>

            <TextField
              value={minBet}
              className={classes.optionInput}
              placeholder="0.0"
              onChange={(e) => {
                setMinBet(Number(e.target.value) || 0);
              }}
            />
          </Box>
        </Box>

        <Box className={classes.optionListItem}>
          <Typography>Max Bet:</Typography>
          <Box>
            <Box component="img" src={cashIcon}></Box>

            <TextField
              value={maxBet}
              className={classes.optionInput}
              placeholder="0.0"
              onChange={(e) => {
                setMaxBet(Number(e.target.value) || 0);
              }}
            />
          </Box>
        </Box>

        <Box className={classes.optionListItem}>
          <Typography>Add liquidity:</Typography>
          <Box>
            <Box component="img" src={cashIcon}></Box>

            <TextField
              value={addLiquidity}
              className={classes.optionInput}
              placeholder="Amount"
              onChange={(e) => {
                setAddLiquidity(Number(e.target.value) || 0);
                setRemoveLiquidity(0);
              }}
            />
          </Box>
        </Box>

        <Box className={classes.optionListItem}>
          <Typography>Remove liquidity:</Typography>
          <Box>
            <Box component="img" src={cashIcon}></Box>

            <TextField
              value={removeLiquidity}
              className={classes.optionInput}
              placeholder="Amount"
              onChange={(e) => {
                setRemoveLiquidity(Number(e.target.value) || 0);

                setAddLiquidity(0);
              }}
            />
          </Box>
        </Box>

        <Box className={classes.optionListItem}>
          <Typography>Transfer owner:</Typography>
          <Box>
            <Box component="img" src={userIcon}></Box>

            <TextField
              value={""}
              className={classes.optionInput}
              placeholder="User address"
            />
          </Box>
        </Box>
      </Box>

      <Button
        className={classes.applyButton}
        onClick={handleApplyChange}
        disabled={false}
      >
        Confirm Changes
      </Button>
    </Box>
  ) : (
    <></>
  );
};

export default RouletteManageBoard;
