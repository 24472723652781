import { Box } from "@mui/material";
import { Link } from "react-router-dom";

import { useAppSelector } from "../../app/hooks";
import { City, CitySimple, ItemCategoryInfoList } from "../../constants/const";
import { toUSDFormat } from "../../utils/utils";

import businessIcon from "../../assets/imgs/family/business.png";

import useStyles from "./index.styles";
import { BusinessInfo } from "../../types/FamilyBusinessInfo";
import { BusinessEffectiveness } from "../../constants/map";
import { goToMapSlotPage } from "../../helper/map";

const FamilyDetailBusiness = () => {
  const { classes } = useStyles();

  const { familyDetail, familyMapData } = useAppSelector(
    (state) => state.family
  );

  const getSlotByBusinessInfo = (business: BusinessInfo) => {
    const mapData = familyMapData.businessTiles.find(
      (item) => item.inventoryItemId === business.id
    );
    if (mapData) {
      return (
        <Box
          sx={{ color: "#1c7995", cursor: "pointer" }}
          onClick={() =>
            goToMapSlotPage(mapData.city, mapData.slotX, mapData.slotY)
          }
        >
          #{CitySimple[mapData.city]}-{mapData.slotX}-{mapData.slotY}
        </Box>
      );
    }
    return "N/A";
  };

  const getEffective = (business: BusinessInfo) => {
    const mapData = familyMapData.businessTiles.find(
      (item) => item.inventoryItemId === business.id
    );
    if (mapData) {
      for (let index = 0; index < BusinessEffectiveness.length; index++) {
        if (mapData.connectors >= index) {
          return BusinessEffectiveness[index].toString() + "%";
        }
      }
    }
    return "N/A";
  };

  return (
    <Box className={classes.businessCard}>
      <Box className={classes.businessCardHeader}>
        <Box className={classes.cardTitle}>
          <Box component="img" src={businessIcon}></Box>
          <Box>Business objects</Box>
        </Box>
        <Box className={classes.ownedTitle}>
          {familyDetail.businessObjects?.length} Owned
        </Box>
      </Box>

      {familyDetail.businessObjects?.length === 0 ? (
        <Box className={classes.businessEmpty}>
          <Box className={classes.detailsBody}>
            Family Controls no business objects
          </Box>
        </Box>
      ) : (
        <Box className={classes.businessContent}>
          <Box className={classes.detailHeader}>
            <Box>Type</Box>
            <Box>Owner</Box>
            <Box>City</Box>
            <Box>Tile ID</Box>
            <Box>Effectiveness</Box>
            <Box>Profit total</Box>
            <Box>Worth</Box>
          </Box>

          <Box className={classes.detailList}>
            {familyDetail.businessObjects
              ?.slice()
              .sort(
                (a, b) =>
                  ItemCategoryInfoList[b.itemDetail.categoryId].inventoryWorth[
                    b.itemDetail.typeId
                  ] -
                  ItemCategoryInfoList[a.itemDetail.categoryId].inventoryWorth[
                    a.itemDetail.typeId
                  ]
              )
              .map((business, index) => {
                const category =
                  ItemCategoryInfoList[business.itemDetail.categoryId];

                const icon =
                  category.icons.length === 0
                    ? category.icon
                    : category.icons[business.itemDetail.typeId];

                return (
                  <Box className={classes.detailListItem} key={index}>
                    <Box className={classes.detailName}>
                      <Box
                        component="img"
                        className={classes.detailIcon}
                        src={`/assets/imgs/inventory/${icon}`}
                      ></Box>
                      {category.values[business.itemDetail.typeId]}
                    </Box>
                    <Box className={classes.detailOwner}>
                      <Link to={`/profile/${business.owner}`}>
                        {business.owner}
                      </Link>
                    </Box>
                    <Box className={classes.detailCity}>
                      {City[business.city]}
                    </Box>
                    <Box className={classes.detailCity}>
                      {getSlotByBusinessInfo(business)}
                    </Box>
                    <Box className={classes.detailCity}>
                      {getEffective(business)}
                    </Box>
                    <Box className={classes.detailCity}>N/A</Box>
                    <Box className={classes.detailWorth}>
                      {toUSDFormat(
                        category.inventoryWorth[business.itemDetail.typeId]
                      )}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FamilyDetailBusiness;
