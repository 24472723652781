import { Box, Typography } from "@mui/material";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getRouletteBetHistoryAction } from "../../../reducers/roulette.slice";

import cashIcon from "../../../assets/imgs/exchange/money.png";

import useStyles from "./index.styles";
import { RouletteBetHistory } from "../../../types/RouletteBetHistory";
import { toUSDFormat } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";

const RouletteHistoryItem = ({ item }: { item: RouletteBetHistory }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleToProfile = (name: string) => {
    navigate(`/profile/${name}`);
  };

  return (
    <Box className={classes.rouletteHistoryItem}>
      <Box className={classes.historyItemNonce}>
        <Typography>{item.nonce}</Typography>
      </Box>
      <Box>
        <Typography
          sx={{ color: "#1c7995", cursor: "pointer" }}
          onClick={() => handleToProfile(item.User?.name || "")}
        >
          {item.User?.name}
        </Typography>
      </Box>
      <Box>
        <Typography>Bet: {toUSDFormat(item.betAmount, 0)}</Typography>
      </Box>
      <Box>
        <Typography>
          {(item.rewardReceived / item.betAmount).toFixed(2)}x
        </Typography>
      </Box>
      <Box className={classes.historyItemAmount}>
        <Box
          component="img"
          src={cashIcon}
          className={classes.historyIcon}
        ></Box>
        <Typography
          color={item.rewardReceived >= item.betAmount ? "#3db338" : "red"}
        >
          {item.rewardReceived >= item.betAmount
            ? `+${toUSDFormat(item.rewardReceived, 0)}`
            : `-${toUSDFormat(item.betAmount, 0)}`}
        </Typography>
      </Box>
    </Box>
  );
};

const RouletteHistoryBoard = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { rouletteBetHistory, currentRouletteId } = useAppSelector(
    (state) => state.roulette
  );

  useEffect(() => {
    dispatch(getRouletteBetHistoryAction({ rouletteId: currentRouletteId }));
  }, [dispatch, currentRouletteId]);

  return (
    <Box className={classes.historyList}>
      {rouletteBetHistory.map((item, index) => (
        <RouletteHistoryItem key={index} item={item} />
      ))}
    </Box>
  );
};

export default RouletteHistoryBoard;
