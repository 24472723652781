import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography } from "@mui/material";

import cashIcon from "../../../assets/imgs/exchange/money.png";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { clearBets } from "../../../reducers/roulette.slice";

import useStyles from "./index.styles";
import { toUSDFormat } from "../../../utils/utils";

const NumberBoardHeader = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const handleClearAll = () => {
    dispatch(clearBets());
  };

  const { defaultBetAmount } = useAppSelector((state) => state.roulette);

  return (
    <Box className={classes.numberBoardHeader}>
      <Button
        onClick={handleClearAll}
        startIcon={
          <CloseIcon
            htmlColor="white"
            sx={{
              width: 20,
              height: 20,
            }}
          />
        }
      >
        Clear all selections
      </Button>

      <Box className={classes.defaultBet}>
        <Typography className={classes.defaultBetLabel}>
          Default bet value
        </Typography>

        <Box className={classes.inputBox}>
          <Typography>{toUSDFormat(defaultBetAmount)}</Typography>
          <Box
            component="img"
            src={cashIcon}
            sx={{ width: 20, height: 20 }}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NumberBoardHeader;
