import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  rouletteHeader: {
    maxWidth: 1440,
    margin: "auto",
    marginTop: -64,
    display: "flex",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },

  headerTitle: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 40,
    color: theme.palette.text.primary,

    [theme.breakpoints.down("md")]: {
      marginTop: 12,
      marginBottom: 12,
      fontSize: 40,
    },
  },

  rouletteHeaderBox: {
    paddingTop: 48,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: 8,

    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },

  rouletteHeaderBoxRight: {
    paddingTop: 96,
    display: "flex",
    gap: 16,
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      paddingTop: 16,
      justifyContent: "center",
      flexDirection: "column",
      gap: 8,
    },
  },

  rouletteInfo: {
    padding: "16px 32px",
    backgroundColor: "#101010B0",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    gap: 16,
    width: "min-content",

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 16,
      color: theme.palette.text.primary,
      whiteSpace: "nowrap",

      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },

    [theme.breakpoints.down("sm")]: {
      gap: 4,
      padding: "16px 16px",
      backgroundColor: "#10101099",
    },
  },

  rouletteHeaderButton: {
    background: theme.palette.background.modalBg,
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",
    borderRadius: 8,
    padding: "8px 16px",
    border: "1px solid",
    borderColor: "black",
    transition: "background 0.3s, color 0.3s",
    height: "min-content",

    "&:hover": {
      background: theme.palette.background.modalBg,
      opacity: 0.8,
      transition: "300ms",
    },

    [theme.breakpoints.down("sm")]: {
      width: 200,
      gap: 4,
      padding: "8px 16px",
      fontSize: 12,
    },
  },

  rouletteHeaderSelect: {
    background: theme.palette.background.modalBg,
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",
    borderRadius: 8,
    padding: "8px 16px",
    border: "1px solid",
    borderColor: "black",
    transition: "background 0.3s, color 0.3s",
    height: "min-content",

    "&:hover": {
      background: theme.palette.background.modalBg,
      opacity: 0.8,
      transition: "300ms",
    },

    [theme.breakpoints.down("sm")]: {
      width: 200,
      boxSizing: "border-box",
      gap: 4,
      padding: "6px 16px",
      fontSize: 12,
    },
  },
}));

export default styles;
