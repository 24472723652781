import { Box, Typography } from "@mui/material";

import useStyles from "./index.styles";
import { useAppSelector } from "../../../app/hooks";
import { menLinksSmall, womenLinksSmall } from "../../../constants/avatarLinks";
import { CitySimple } from "../../../constants/const";
import { convertDateTimeSimple, toUSDFormat } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { goToMapSlotPage } from "../../../helper/map";
import cashIcon from "../../../assets/imgs/exchange/money.png";

const RouletteProfileBoard = () => {
  const { classes } = useStyles();
  const { rouletteProfile, roulettes, currentRouletteId } = useAppSelector(
    (state) => state.roulette
  );
  const navigate = useNavigate();

  const handleToProfile = (name: string) => {
    navigate(`/profile/${name}`);
  };

  return (
    <Box className={classes.panel}>
      <Box className={classes.panelBody}>
        {rouletteProfile?.ownerImageId ? (
          <Box
            component="img"
            src={
              rouletteProfile?.ownerGender === 1
                ? womenLinksSmall[
                    !rouletteProfile.ownerImageId
                      ? -1
                      : rouletteProfile.ownerImageId
                  ]
                : menLinksSmall[
                    !rouletteProfile?.ownerImageId
                      ? -1
                      : rouletteProfile.ownerImageId
                  ]
            }
          ></Box>
        ) : (
          <Box sx={{ minWidth: 100 }}></Box>
        )}

        <Box className={classes.profileInfo}>
          <Box className={classes.profileInfoLabel}>
            <Typography>Owner:</Typography>
            <Typography>Family:</Typography>
            <Typography>Location:</Typography>
            <Typography>Fee's Paid:</Typography>
            <Typography>Time owned:</Typography>
          </Box>

          <Box className={classes.profileInfoValue}>
            <Typography
              sx={{ color: "#1c7995", cursor: "pointer" }}
              onClick={() => handleToProfile(rouletteProfile?.ownerName || "")}
            >
              {rouletteProfile?.ownerName}
            </Typography>
            <Typography>{rouletteProfile?.familyInfo?.name || " "}</Typography>
            <Typography
              sx={{ cursor: "pointer", color: "#1c7995" }}
              onClick={() =>
                rouletteProfile &&
                rouletteProfile.map &&
                goToMapSlotPage(
                  rouletteProfile.cityId,
                  rouletteProfile.map?.slotX,
                  rouletteProfile.map?.slotY
                )
              }
            >
              {rouletteProfile &&
                rouletteProfile?.cityId >= 0 &&
                `#${CitySimple[rouletteProfile.cityId]}-${
                  rouletteProfile.map?.slotX
                }-${rouletteProfile.map?.slotY}`}
            </Typography>
            <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <Box
                component={"img"}
                src={cashIcon}
                sx={{
                  width: "20px!important",
                  height: "20px!important",
                }}
              ></Box>
              {toUSDFormat(rouletteProfile?.feeAmount || 0, 0)}
            </Box>
            <Typography>
              {roulettes.length > 0 &&
                roulettes[currentRouletteId]?.ownedAt &&
                convertDateTimeSimple(
                  new Date().getTime() / 1000 -
                    roulettes[currentRouletteId].ownedAt
                )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RouletteProfileBoard;
