import { useEffect, useState } from "react";
import Web3 from "web3";
import config from "../config/config";
import { ethers } from "ethers";
import { getTokenBalance } from "../helper/contractFunctions";

const web3 = new Web3(config.providerList[0]);

const useBalance = (address: string, tokenAddress: string) => {
  const [balance, setBalance] = useState(0);
  const fetchBalance = async () => {
    try {
      // Get balance returns the value in wei
      let balanceWei;
      if (tokenAddress == ethers.constants.AddressZero) {
        balanceWei = await web3.eth.getBalance(address);
      } else {
        balanceWei = await getTokenBalance(tokenAddress, address);
      }
      // Convert wei to ETH
      const balance = parseFloat(web3.utils.fromWei(balanceWei, "ether"));

      // Set the total balance
      setBalance(parseFloat(balance.toFixed(2)));
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  useEffect(() => {
    fetchBalance();
  }, []);

  return { balance, updateBalance: fetchBalance  };
};

export default useBalance;
