import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    marginTop: "32px",

    [theme.breakpoints.down("sm")]: {
      "& p": {
        fontSize: "24px",
      },
    },
  },

  moneyIcon: {
    width: "80px",
    height: "80px",

    [theme.breakpoints.down("sm")]: {
      width: "40px",
      height: "40px",
    },
  },

  transactionList: {
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    maxWidth: 600,
    width: "100%",
  },

  transactionListHeader: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 1fr",
    alignItems: "center",
    color: "white",
    fontSize: "16px",
    fontWeight: 900,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    padding: "8px 16px",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "3fr 3fr 2fr",
      padding: "8px",
      borderRadius: "4px 4px 0 0",
      "& p": {
        fontSize: "12px",
      },
    },
  },

  transactionListItem: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 1fr",
    alignItems: "center",
    color: "white",
    fontSize: "14px",
    padding: "16px 16px",

    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
    backgroundColor: "rgba(0, 0, 0, 0.2)",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "3fr 3fr 2fr",
      padding: "8px",
      "& p": {
        fontSize: "12px",
      },
    },
  },
}));

export default styles;
