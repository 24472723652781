import { Box, Button } from "@mui/material";
import clsx from "clsx";
import { useEffect, useMemo } from "react";

import Dot from "../Dot";
import { ListingTokenType } from "../MarketListItemPopup";
import ActiveListingItem from "./ActiveListingItem";
import ActiveListingLoadingSkeleton from "./ActiveListingLoadingSkeleton";
import CompletedListingItem from "./CompletedListingItem";
import CompletedListingLoadingSkeleton from "./CompletedListingLoadingSkeleton";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import { GAME_CASH_ADDRESS } from "../../constants/const";
import { Market } from "../../constants/enum/market";
import useCurrentTime from "../../hook/useCurrentTime";
import useWallet from "../../hook/useWallet";
import { getMyListings } from "../../reducers/market.slice";
import { MarketListedItem } from "../../types/MarketListedItem";

import handShakeIcon from "../../assets/imgs/landing/handshake.png";

import useStyles from "./index.styles";
import { RarityType } from "../../constants/enum/map";

interface MarketMyListingProps {
  setActionItem: React.Dispatch<
    React.SetStateAction<MarketListedItem | undefined>
  >;
  handleCancelListing: (item: MarketListedItem) => void;
  handleCompleteAuction: (item: MarketListedItem) => void;
  rarityType: RarityType | null;
}

const MarketMyListing: React.FC<MarketMyListingProps> = ({
  setActionItem,
  handleCancelListing,
  handleCompleteAuction,
  rarityType,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { account } = useWallet();
  const currentTime = useCurrentTime();

  const { myListings, isLoadingMyListings } = useAppSelector(
    (state) => state.market
  );

  const parsedItem = useMemo(() => {
    return myListings.map((item) => {
      return {
        ...item,
        currency:
          item.listingInfo.token === config.mafiaAddress
            ? ListingTokenType.Mafia
            : item.listingInfo.token === GAME_CASH_ADDRESS
            ? ListingTokenType.GameCash
            : ListingTokenType.Native,
      };
    });
  }, [myListings]);

  const renderActionButton = (item: (typeof myListings)[0]) => {
    const isSeller =
      account.toLowerCase() === item.listingInfo.seller.toLowerCase();

    const isExpired = item.listingInfo.expiresAt <= new Date().getTime() / 1000;

    if (isSeller) {
      if (isExpired) {
        if (item.listingInfo.listingType === Market.ListType.AUCTION) {
          return item.listingInfo.currentPrice !==
            item.listingInfo.startingPrice ? (
            <Button
              className={classes.buyButton}
              onClick={() => {
                setActionItem(item);
                handleCompleteAuction(item);
              }}
            >
              Complete
            </Button>
          ) : (
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        } else {
          return (
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        }
      }
    } else {
      if (isExpired) {
        if (item.listingInfo.listingType === Market.ListType.AUCTION) {
          return item.listingInfo.currentPrice !==
            item.listingInfo.startingPrice ? (
            <Button
              className={classes.buyButton}
              onClick={() => {
                setActionItem(item);
                handleCompleteAuction(item);
              }}
            >
              Complete
            </Button>
          ) : (
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        } else {
          return (
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setActionItem(item);
                handleCancelListing(item);
              }}
            >
              Cancel
            </Button>
          );
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getMyListings({ address: account }));
  }, [account, dispatch]);

  return (
    <Box className={classes.container}>
      <Box className={classes.list}>
        <Box className={classes.itemListing}>
          <Box className={classes.listingHeader}>
            <Dot width={12} color="red" isShadow={true} />
            <Box>Active Listing</Box>
          </Box>

          <Box className={classes.listingPanel}>
            <Box className={classes.listingPanelHeader}>
              <Box>Item</Box>
              <Box>Type</Box>
              <Box>Price</Box>
              <Box>Payout</Box>
              <Box>Expires/Action</Box>
            </Box>

            {isLoadingMyListings
              ? [...new Array(12)].map((item, index) => {
                  return <ActiveListingLoadingSkeleton key={index} />;
                })
              : [
                  ...parsedItem.filter(
                    (item, index) => item.listingInfo.status === 0
                  ),
                ].map((item, index) => {
                  return (
                    <ActiveListingItem
                      item={item}
                      currentTime={currentTime}
                      renderActionButton={renderActionButton}
                      rarityType={rarityType}
                      key={index}
                    />
                  );
                })}
          </Box>
        </Box>

        <Box className={classes.itemListing}>
          <Box className={classes.listingHeader}>
            <Box component="img" src={handShakeIcon} sx={{ width: 48 }}></Box>
            <Box>Completed / Expired</Box>
          </Box>

          <Box className={classes.listingPanel}>
            <Box
              className={clsx(
                classes.listingPanelHeader,
                classes.completedPanelHeader
              )}
            >
              <Box>Item</Box>
              <Box>Type</Box>
              <Box>Price</Box>
              <Box>Buyer</Box>
              <Box>Status</Box>
            </Box>

            {isLoadingMyListings
              ? [...new Array(12)].map((item, index) => {
                  return <CompletedListingLoadingSkeleton key={index} />;
                })
              : [
                  ...parsedItem.filter(
                    (item, index) => item.listingInfo.status !== 0
                  ),
                ].map((item, index) => {
                  return (
                    <CompletedListingItem
                      item={item}
                      key={index}
                      rarityType={rarityType}
                    />
                  );
                })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MarketMyListing;
