import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    justifyContent: "center",
    padding: "0 16px",
    boxSizing: "border-box",
    maxWidth: 1920,
    margin: "auto",
    marginTop: -64,
    minHeight: 1000,
    background: "url(/assets/imgs/dao/dao_bg.png) no-repeat",
    backgroundSize: "contain 1024px",
    backgroundPosition: "right top",
    WebkitMaskImage:
      "-webkit-gradient(linear, left 85%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",

    [theme.breakpoints.down("sm")]: {
      padding: "16px",
      background: "url(/assets/imgs/dao/dao_bg_mobile.png) no-repeat",
      backgroundSize: "auto 1200px",
      backgroundPosition: "top",
      WebkitMaskImage:
        "-webkit-gradient(linear, left 85%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
    },
  },

  body: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    display: "flex",
    flexDirection: "column",
    maxWidth: 900,
    margin: "auto",
    paddingTop: 64,
  },

  header: {
    marginTop: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "16px",
      alignItems: "center",
      padding: "8px 16px",
    },
  },

  tokenBalanceContainer: {
    paddingTop: 24,
    paddingBottom: 24,
    display: "flex",
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
  },

  tokenBalance: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 32px",
    borderRadius: 8,
    minWidth: 120,
    color: "rgb(141, 166, 98)",
    backgroundColor: "rgb(23, 25, 28)",

    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 18,
    lineHeight: 1.5,
  },

  viewExplorerBox: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",
  },

  daoRoundList: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",
    padding: "16px 32px",
  },

  daoRoundListItem: {
    background: theme.palette.background.cardBg,
    borderRadius: 16,
    padding: "32px 32px",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      padding: "16px 32px",
    },
  },

  daoRoundListItemHeader: {
    width: "100%",
    display: "flex",
    gap: 8,
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  headerLeftPanel: {
    display: "flex",
    gap: 16,

    [theme.breakpoints.down("sm")]: {
      gap: 8,
      flexDirection: "column",
      alignItems: "center",
    },
  },

  roundInfoPanel: {
    display: "flex",
    gap: 16,

    [theme.breakpoints.down("sm")]: {},
  },

  daoRoundListItemRoundId: {
    fontFamily: "Philosopher",
    fontSize: 24,
    fontWeight: 900,
    color: "white",
    minWidth: "110px",

    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      minWidth: "auto",
    },
  },

  headerUserCount: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",
    minWidth: "50px",

    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
    },
  },

  headerMafia: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",
    minWidth: "100px",

    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
    },
  },

  headerTimeLeft: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    justifyContent: "flex-start",
    minWidth: "200px",

    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
    },
  },

  headerStatus: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",

    "& p": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
  },

  daoRoundDetailOptionList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      justifyContent: "center",
      overflowX: "auto",
    },
  },

  optionHeader: {
    display: "grid",
    gridTemplateColumns: "4fr 2fr 1fr 1fr 1fr",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    borderBottom: `1px solid rgb(99, 99, 99, 0.2)`,
    padding: "8px 0px 8px 0px",
    boxSizing: "border-box",

    "& p": {
      fontFamily: "Philosopher",
      color: "rgb(99,99,99)",
      fontSize: 18,
      fontWeight: 900,

      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },

    minWidth: 600,
  },

  optionList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 600,
  },

  optionListItem: {
    display: "grid",
    gridTemplateColumns: "4fr 2fr 1fr 1fr 1fr",
    gap: 8,
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    padding: "8px 0px 8px 0px",
    boxSizing: "border-box",
  },

  openButton: {
    textTransform: "none",
    borderRadius: "50%",
    padding: "8px",
    minWidth: "auto",
  },

  voteButton: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    color: "white",
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",
    borderRadius: "16px",
    padding: "4px 16px",
    fontSize: "12px",
  },
}));

export default styles;
