export enum SlotType {
  UpgradableSlot = 1,
  Protocol = 2,
  Business = 3,
  FamilyHQ = 4,
  RaidSpot = 5,
}

export enum BuildingType {
  Grass,
  Shed,
  House,
  Villa,
  Office,
  Apartment,
  Mansion,
  Hotel,
}

export enum RarityType {
  Strategic,
  Common,
  Upper,
  Ellite,
}

export enum BusinessType {
  CarCrusher,
  ShopOwner,
  Bank,
  Hospital,
  DetectiveAgency,
  BoozeWareHouse,
  Narcotics,
  SLotmachine,
  Roulette,
  BulletFactory,
}
