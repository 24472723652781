import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  panel: {
    background: theme.palette.background.modalBg,
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",
    borderRadius: 16,
    padding: "0 0 8px 0",
    border: "1px solid",
    borderColor: "black",
    transition: "background 0.3s, color 0.3s",
    height: "min-content",
    minWidth: "320px",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },

  singlePanelHeader: {
    display: "grid",
    gridTemplateColumns: "1fr",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  panelHeader: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  myHistory: {
    background: "#00000050",
    borderRadius: 0,

    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 14,
    color: "#FFFFFF80",
    textTransform: "none",

    borderBottom: "1px solid",
    borderColor: "#FFFFFF10",
    padding: "12px 0",
  },

  manageTable: {
    background: "#00000050",
    borderRadius: 0,
    borderTopRightRadius: 16,

    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 14,
    color: "#FFFFFF80",
    textTransform: "none",
    padding: "12px 0",
    borderBottom: "1px solid",
    borderColor: "#FFFFFF10",
  },

  tableHistory: {
    background: "#00000050",
    borderRadius: 0,
    borderTopLeftRadius: 16,

    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 14,
    color: "#FFFFFF80",
    textTransform: "none",
    padding: "12px 0",
    borderBottom: "1px solid",
    borderColor: "#FFFFFF10",
  },

  selected: {
    background: "transparent",
  },

  historyList: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "400px",
    overflow: "auto",
  },

  historyPanelBody: {
    maxWidth: "400px",
    minHeight: "min(max(100vh, 280px), 360px)",
    padding: "16px 0px",
    boxSizing: "border-box",
    width: "400px",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "360px",
    },
  },

  managePanelBody: {
    // padding: "16px 0px",
    maxWidth: "400px",
    // boxSizing: "border-box",
    // width: "100%",
    minHeight: "min(max(100vh, 280px), 360px)",
  },

  historyItem: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr 2fr 2fr",
    padding: "8px 16px",
    alignItems: "center",
    borderBottom: "1px solid",
    borderColor: "#FFFFFF10",

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 14,
    },
  },
  rouletteHistoryItem: {
    display: "grid",
    gridTemplateColumns: "0.8fr 1.5fr 2fr 1fr 1.5fr",
    padding: "8px 16px",
    borderBottom: "1px solid",
    borderColor: "#FFFFFF10",

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 14,
    },
  },
  historyItemAmount: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  historyItemNonce: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    width: 24,
  },

  historyIcon: {
    width: 24,
    height: 24,
  },

  manageBoard: {
    width: "100%",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
    boxSizing: "border-box",
  },

  manageBoardLpInfoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "6px 32px",
    background: "#00000050",
    borderRadius: 16,
    width: "100%",
    boxSizing: "border-box",
  },

  lpCashIcon: {
    width: 24,
    height: 24,
  },

  lpInfoTitle: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 12,
    color: "#FFFFFF60",
  },

  lpInfo: {
    display: "flex",
    alignItems: "center",
    gap: 16,

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 16,
      color: "#FFFFFF",
    },
  },

  manageOptionList: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    width: "100%",
    marginTop: 24,
  },

  optionListItem: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 16,
      color: "#FFFFFF",
      whiteSpace: "nowrap",
    },

    "& img": {
      width: 16,
      height: 16,
    },

    "& .MuiSelect-select": {
      padding: 4,
    },

    "&>div": {
      display: "flex",
      alignItems: "center",
      background: "#282828",
      borderRadius: 4,
      padding: "0 12px",
    },
    "& .MuiInputBase-formControl": {
      width: "100%",
    },
  },

  optionInput: {
    "& input": {
      padding: "0 8px",
      // width: 100,
      textAlign: "right",
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 14,
    },
    "& fieldset": {
      outline: "none",
      border: "none",
    },
  },

  applyButton: {
    background: "#377934",
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",
    borderRadius: 8,
    padding: "8px 32px",
    width: "100%",
    transition: "background 0.3s, color 0.3s",
    height: "min-content",
    marginTop: 24,

    "&:hover": {
      background: "#377934",
      opacity: 0.8,
      transition: "300ms",
    },

    "&:disabled": {
      background: theme.palette.background.lightGray,
    },
  },
}));

export default styles;
