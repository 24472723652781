import { Box, Theme, Typography, useTheme } from "@mui/material";
import isMobile from "is-mobile";
import millify from "millify";
import { useMemo } from "react";

import Logo from "../Logo";
import NativeIcon from "../NativeIcon";

import { useAppSelector } from "../../app/hooks";
import useVaultBalance from "../../hook/useVaultBalance";

import allegianceIcon from "../../assets/imgs/family/allegiance.png";

import { CustomTypeText } from "../../theme";

import config from "../../config/config";
import useStyles from "./index.styles";
import { Link } from "react-router-dom";

const FamilyDetailAllegiance = () => {
  const { classes } = useStyles();
  const theme = useTheme<Theme>();
  const vaultBalance = useVaultBalance();

  const { allegianceDetail } = useAppSelector((state) => state.family);

  const totalMembers = useMemo(() => {
    if (!allegianceDetail) return 0;
    return allegianceDetail.familyData.reduce(
      (sum, family) => sum + (family.members || 0),
      0
    );
  }, [allegianceDetail]);

  const text = theme.palette.text as CustomTypeText;

  return (
    <Box className={classes.businessCard}>
      <Box className={classes.businessCardHeader}>
        <Box className={classes.cardTitle}>
          {allegianceDetail.allegianceData ? (
            <>
              <Box
                component="img"
                src={
                  allegianceDetail.allegianceData?.symbol
                    ? allegianceDetail.allegianceData?.symbol
                    : allegianceIcon
                }
              ></Box>
              <Box>{allegianceDetail.allegianceData?.name}</Box>
            </>
          ) : (
            <>
              <Box
                component="img"
                src={allegianceIcon}
                sx={{
                  height: "24px!important",
                }}
              ></Box>
              <Box>{"No Allegiance"}</Box>
            </>
          )}
        </Box>
        <Box className={classes.description}>{totalMembers} members</Box>
      </Box>

      <Box className={classes.businessContent}>
        <Box className={classes.detailHeader}>
          <Box>Family</Box>
          <Box>{isMobile() ? "Share" : "Vault Share"} </Box>
          <Box>Members</Box>
          <Box>{isMobile() ? "Payout" : "Vault Payout"} </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            overflowY: "scroll",
            maxHeight: "220px",
          }}
        >
          {allegianceDetail?.familyData.map((family, index) => {
            return (
              <Box className={classes.allegianceListItem} key={index}>
                <Box className={classes.allegianceListFamily}>
                  {family.logo ? (
                    <Box
                      component={"img"}
                      src={family.logo}
                      className={classes.allegianceIcon}
                    />
                  ) : (
                    <Logo classNames={classes.allegianceIcon} />
                  )}

                  <Link to={`/family/${family.name}`} className={classes.link}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: text.linkColor,
                        fontFamily: "Philosopher",
                      }}
                    >
                      {family.name}
                    </Typography>
                  </Link>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: text.secondary,
                    fontFamily: "Philosopher",
                  }}
                >
                  {family.allegianceSharePercent}%
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: text.secondary,
                    fontFamily: "Philosopher",
                  }}
                >
                  {family.members}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Philosopher",
                    fontWeight: 700,
                    color: "white",
                  }}
                >
                  <NativeIcon
                    sx={{
                      width: 12,
                    }}
                  />{" "}
                  {millify(
                    (family?.vaultPercentInfo?.value || 0) * vaultBalance
                  )}{" "}
                  {config.chainSymbol}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default FamilyDetailAllegiance;
