import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import useStyles from "./index.styles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DaoProposalStatus } from "../../constants/enum/enum";
import {
  closeProposalAction,
  getProposalsAction,
  getUserVoteAction,
  handleCancelProposalAction,
  openProposalAction,
} from "../../reducers/dao.slice";
import useWallet from "../../hook/useWallet";

interface ManageDaoPopupProps {
  openModal: boolean;
  handleClose: () => void;
  spentTime: number;
  showEditPopup: () => void;
}

const ManageDaoPopup = ({
  openModal,
  handleClose,
  spentTime,
  showEditPopup,
}: ManageDaoPopupProps) => {
  const { classes } = useStyles();

  const { manageRoundId, proposals, completedIds } = useAppSelector(
    (state) => state.dao
  );
  const proposalInfo = proposals.find((item) => item.id === manageRoundId);

  const { account } = useWallet();
  const dispatch = useAppDispatch();

  const { signature, signMsg } = useAppSelector((state) => state.auth);

  const handleOpenProposal = () => {
    if (!proposalInfo) return;
    dispatch(openProposalAction({ account, id: proposalInfo.id }))
      .unwrap()
      .then(() => {
        dispatch(getProposalsAction());
        dispatch(
          getUserVoteAction({ signature, signMsg, account, completedIds })
        );
        handleClose();
      })
      .catch(() => {});
  };

  const handleCloseProposal = () => {
    if (!proposalInfo) return;
    dispatch(closeProposalAction({ account, id: proposalInfo.id }))
      .unwrap()
      .then(() => {
        dispatch(getProposalsAction());
        dispatch(
          getUserVoteAction({ signature, signMsg, account, completedIds })
        );
        handleClose();
      })
      .catch(() => {});
  };

  const handleEditProposal = () => {
    showEditPopup();
    handleClose();
  };

  const handleCancelProposal = () => {
    if (!proposalInfo) return;
    dispatch(handleCancelProposalAction({ account, roundId: proposalInfo.id }))
      .unwrap()
      .then(() => {
        dispatch(getProposalsAction());
        dispatch(
          getUserVoteAction({ signature, signMsg, account, completedIds })
        );
        handleClose();
      })
      .catch();
  };

  return (
    <Dialog open={openModal} onClose={handleClose} className={classes.body}>
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Typography className={classes.modalTitle}>
            Manage Marketing DAO
          </Typography>
          Round {(proposalInfo?.id || 0) + 1}
          <Box
            className={classes.optionLists}
            sx={{ marginTop: "24px", minHeight: "auto!important" }}
          >
            <Button
              className={classes.editManageButton}
              disabled={
                !proposalInfo ||
                proposalInfo.status !== DaoProposalStatus.Pending
              }
              onClick={handleOpenProposal}
            >
              Open
            </Button>
            <Button
              className={classes.editManageButton}
              disabled={!proposalInfo}
              onClick={handleEditProposal}
            >
              Edit
            </Button>
            <Button
              className={classes.editManageButton}
              disabled={
                !proposalInfo ||
                proposalInfo.status !== DaoProposalStatus.Opened
              }
              onClick={handleCancelProposal}
            >
              Cancel
            </Button>
            <Button
              className={classes.editManageButton}
              disabled={
                !proposalInfo ||
                proposalInfo.status !== DaoProposalStatus.Opened ||
                proposalInfo.openedAt +
                  proposalInfo.duration -
                  spentTime -
                  new Date().getTime() / 1000 >
                  0
              }
              onClick={handleCloseProposal}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ManageDaoPopup;
