import config from "../config/config";

export const goToMapSlotPage = (
  cityId: number,
  slotX: number,
  slotY: number
) => {
  if (cityId === -1 || slotX === -1 || slotY === -1) return;

  window.open(
    `https://${config.domain}/map/${cityId}?slotX=${slotX}&slotY=${slotY}`,
    "_self"
  );
};
