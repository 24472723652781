import { Box, Button } from "@mui/material";
import clsx from "clsx";
import millify from "millify";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AvailableSlotsColors } from "../../constants/const";
import { toUSDFormat } from "../../utils/utils";

import Logo from "../../components/Logo";
import { getWorldMapInfoAction } from "../../reducers/map.slice";
import useStyles from "./index.styles";

const Map = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { familySummaries } = useAppSelector((state) => state.family);
  const { worldMapInfo } = useAppSelector((state) => state.map);

  const getFamilyInfo = (id: number) => {
    return familySummaries.find((item) => item.id === id);
  };

  const handleSwitchCity = (index: number) => {
    navigate(`/map/${index}`);
  };

  useEffect(() => {
    dispatch(getWorldMapInfoAction());
  }, [dispatch]);

  return (
    <Box>
      <Box className={classes.container}>
        <Box className={classes.body}>
          <Box className={classes.tableContainer}>
            <Box className={clsx(classes.tableHeader)}>
              <Box></Box>
              <Box>Available</Box>
              <Box>Worth</Box>
              <Box>Name</Box>
              <Box>#1 Family</Box>
              <Box>Players</Box>
              <Box>City developer</Box>
              <Box>Buildings</Box>
              <Box>stMafia</Box>
            </Box>

            {[...worldMapInfo]
              .sort((a, b) => b.cityWorth - a.cityWorth)
              .map((item, index) => (
                <Box
                  className={clsx(classes.tableBody, classes.tableCol)}
                  key={index}
                >
                  <Box>
                    <Button
                      className={classes.visitButton}
                      variant="contained"
                      onClick={() => handleSwitchCity(item.id)}
                    >
                      Visit
                    </Button>
                  </Box>
                  <Box className={classes.availableTile}>
                    <Box
                      className={clsx(
                        classes.circularBox,
                        item.availableSlotsCount >= AvailableSlotsColors.Green
                          ? classes.greenBox
                          : item.availableSlotsCount >=
                            AvailableSlotsColors.Orange
                          ? classes.yellowBox
                          : classes.redBox
                      )}
                    ></Box>
                    <Box>{item.availableSlotsCount} tiles</Box>
                  </Box>
                  <Box>{toUSDFormat(item.cityWorth)}</Box>
                  <Box className={classes.cityName}>{item.cityName}</Box>
                  <Box className={classes.familyCell}>
                    {getFamilyInfo(item.highestFamilyId)?.logo ? (
                      <Box
                        component={"img"}
                        src={getFamilyInfo(item.highestFamilyId)?.logo}
                        className={classes.familyLogo}
                      />
                    ) : (
                      <Box className={classes.familyLogo}></Box>
                    )}
                    {getFamilyInfo(item.highestFamilyId)?.name}
                  </Box>
                  <Box>{item.userCount}</Box>
                  <Box color={"#034457"} sx={{ cursor: "pointer" }}>
                    <NavLink to={`/profile/${item.developer}`}>
                      {item.developer}
                    </NavLink>
                  </Box>
                  <Box>{item.buildingCount}</Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                    }}
                  >
                    <Logo sx={{ width: "24px" }}></Logo>
                    {millify(item.stMafia)}
                  </Box>

                  <Box
                    className={clsx(classes.tableBody)}
                    sx={{ justifyContent: "right" }}
                    key={item.id}
                  ></Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Map;
