import { useGSAP } from "@gsap/react";
import { Box, Button, SelectChangeEvent, Typography } from "@mui/material";
import gsap from "gsap";
import isMobile from "is-mobile";
import { useEffect, useRef, useState } from "react";

import CustomDropDown from "../CustomDropdown/CustomDropDown";
import NativeIcon from "../NativeIcon";
import PrizeReel from "../PrizeReel";

import config, { CHAIN } from "../../config/config";
import {
  CrateScaleDownDuration,
  PlayingStartTime,
  ReelScaleUpDelay,
  ReelScaleUpDuration,
  SpeedDownDuration,
  SpinningCount,
  SpinningCountMobile,
  SpinningSubDuration,
} from "../../constants/animationDurations";
import { CRATE_PRICE } from "../../constants/const";
import { AnimationStatus, ChainType } from "../../constants/enum/enum";
import useNativePrice from "../../hook/useNativePrice";
import { ItemInfo } from "../../types/ItemInfo";
import { toUSDFormat } from "../../utils/utils";

import chainlinkIcon from "../../assets/imgs/inventory/chainlink.png";
import crateIcon from "../../assets/imgs/inventory/crate.png";
import keyIcon from "../../assets/imgs/inventory/key.png";
import plspIcon from "../../assets/imgs/inventory/plsp.png";

import useStyles from "./index.styles";

gsap.registerPlugin(useGSAP);

const CrateOpeningHeader = ({
  onStartAnimation,
  onEndAnimation,
  handleOpenCrate,
  itemInfo,
  isUserPending,
  ogCrateBalance,
  purchaseCrateCount,
  setPurchaseCrateCount,
  setOpenTokenListPopup,
  businessAvailable,
  extraBusinessAvailable,
}: {
  onStartAnimation: () => void;
  onEndAnimation: () => void;
  handleOpenCrate: () => void;
  itemInfo: ItemInfo;
  isUserPending: boolean;
  ogCrateBalance: number;
  purchaseCrateCount: string;
  setPurchaseCrateCount: React.Dispatch<React.SetStateAction<string>>;
  setOpenTokenListPopup: React.Dispatch<React.SetStateAction<boolean>>;
  businessAvailable: boolean;
  extraBusinessAvailable: boolean;
}) => {
  const { classes } = useStyles();
  const nativePrice = useNativePrice();
  const [cratePriceInNative, setCratePriceInNative] = useState(0);

  const [animationStatus, setAnimationStatus] = useState<AnimationStatus>(
    AnimationStatus.Stopped
  );
  const [reelWidth, setReelWidth] = useState(0);

  const tl = useRef<gsap.core.Timeline | null>(null);

  const container = useRef();
  const crateRef = useRef();
  const reelRef = useRef<HTMLDivElement>();

  const startAnimation = () => {
    if (!crateRef.current || !reelRef.current) return;

    tl.current = gsap
      .timeline()
      .to(
        crateRef.current,
        {
          scale: 0,
          opacity: 0,
          ease: "expo.in",
          duration: CrateScaleDownDuration,
        },
        0
      )
      .to(
        reelRef.current,
        {
          scale: 1,
          opacity: 1,
          ease: "expo.in",
          duration: ReelScaleUpDuration,
          delay: ReelScaleUpDelay,
        },
        0
      )
      .call(
        () => {
          setAnimationStatus(AnimationStatus.SpeedUp);
        },
        [],
        ReelScaleUpDelay + ReelScaleUpDuration
      )
      .call(
        () => {
          setAnimationStatus(AnimationStatus.Playing);
        },
        [],
        PlayingStartTime
      )
      .call(
        () => {
          setAnimationStatus(AnimationStatus.SpeedDown);
        },
        [],
        PlayingStartTime +
          SpinningSubDuration *
            (isMobile() ? SpinningCountMobile : SpinningCount)
      )
      .call(
        () => {
          setAnimationStatus(AnimationStatus.Stopped);

          onEndAnimation();
          endAnimation();
        },
        [],
        PlayingStartTime +
          SpinningSubDuration *
            (isMobile() ? SpinningCountMobile : SpinningCount) +
          SpeedDownDuration +
          1
      )
      .pause();

    onStartAnimation();
    tl.current.play();
  };

  const endAnimation = () => {
    if (!crateRef.current || !reelRef.current) return;

    tl.current = gsap
      .timeline()
      .to(
        crateRef.current,
        {
          scale: 1,
          opacity: 1,
          ease: "expo.in",
          duration: CrateScaleDownDuration,
          delay: ReelScaleUpDelay,
        },
        0
      )
      .to(
        reelRef.current,
        {
          scale: 0,
          opacity: 0,
          ease: "expo.in",
          duration: ReelScaleUpDuration,
        },
        0
      )
      .pause();

    tl.current.play();
  };

  const handlePurchaseCrate = () => {
    setOpenTokenListPopup(true);
  };

  const handleCrateCountChange = (event: SelectChangeEvent) => {
    setPurchaseCrateCount(event.target.value);
  };

  const updateReelWidth = () => {
    if (reelRef.current) {
      setReelWidth(reelRef.current.clientWidth);
    }
  };

  useEffect(() => {
    updateReelWidth();
    window.addEventListener("resize", updateReelWidth);

    return () => {
      window.removeEventListener("resize", updateReelWidth);
    };
  }, []);

  useEffect(() => {
    // Ensure the ref is current and then set the width
    if (reelRef.current) {
      setReelWidth(reelRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    if (nativePrice > 0)
      setCratePriceInNative(parseFloat((CRATE_PRICE / nativePrice).toFixed(6)));
  }, [nativePrice]);

  useEffect(() => {
    if (
      itemInfo.itemId === 0 &&
      itemInfo.categoryId === 0 &&
      itemInfo.typeId === 0
    )
      return;

    startAnimation();
  }, [itemInfo]);

  return (
    <Box className={classes.container} ref={container}>
      <Box className={classes.headerSection}>
        <Typography className={classes.headerTitle}>
          Original Gangster NFT Crate
        </Typography>

        <Box>
          <Typography className={classes.headerChainLinkText}>
            Secured by
          </Typography>

          {CHAIN === ChainType.BNB ? (
            <Box component="img" src={chainlinkIcon}></Box>
          ) : (
            <>
              <Box component="img" src={plspIcon}></Box>
              <Typography className={classes.headerChainLinkText}>
                PulsePot
              </Typography>
            </>
          )}
        </Box>
      </Box>

      <Box className={classes.animationSection}>
        <Box className={classes.crate} ref={crateRef}>
          <Box
            component="img"
            src={crateIcon}
            className={classes.crateImage}
          ></Box>
        </Box>

        <Box className={classes.reel} ref={reelRef}>
          <PrizeReel
            itemInfo={itemInfo}
            animationStatus={animationStatus}
            width={reelWidth}
            businessAvailable={businessAvailable}
            extraBusinessAvailable={extraBusinessAvailable}
          />
        </Box>
      </Box>

      <Box className={classes.purchaseSection}>
        <Box className={classes.openButton}>
          <Button
            startIcon={<Box component="img" src={keyIcon}></Box>}
            onClick={handleOpenCrate}
          >
            {isMobile()
              ? "Open"
              : isUserPending
              ? "Get Random Item"
              : "Use Key"}
          </Button>
        </Box>

        <Box className={classes.purchaseButton}>
          <Button onClick={handlePurchaseCrate}>
            {isMobile() ? "Purchase" : "Purchase keys"}
          </Button>

          <Box className={classes.priceDescription}>
            <NativeIcon />
            <Typography>
              {toUSDFormat(cratePriceInNative)} {config.chainSymbol}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.selectCombo}>
          <CustomDropDown
            sx={{
              padding: "8px 8px",
              backgroundColor: "transparent!important",
              border: "1px solid #23272a",
            }}
            value={purchaseCrateCount}
            items={["1", "5", "10", "25"]}
            handleChange={handleCrateCountChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CrateOpeningHeader;
