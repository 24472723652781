// src/components/BackgroundMusic.tsx
import { useEffect } from "react";
import useSound from "use-sound";

const BackgroundMusic = ({
  backgroundMusic,
  muted,
  volume = 0.05,
}: {
  backgroundMusic: string;
  muted: boolean;
  volume?: number;
}) => {
  const [play, { pause }] = useSound(backgroundMusic, {
    loop: true,
    volume: volume,
  });

  useEffect(() => {
    console.log("Start playing background music.");

    if (muted) pause();
    else play();

    return () => {
      pause();
    };
  }, [play, muted, pause]);

  return <div></div>;
};

export default BackgroundMusic;
