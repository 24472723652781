import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import MarketingDaoHeader from "../../components/MarketingDao/MarketingDaoHeader";
import MarketingDaoRoundList from "../../components/MarketingDao/MarketingDaoRoundList";
import useStyles from "../../components/MarketingDao/index.styles";
import CreateDaoPopup from "../../components/MarketingDaoAdmin/CreateDaoPopup";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getIsAdminAction,
  getProposalsAction,
  getUserVoteAction,
  setCompletedIds,
} from "../../reducers/dao.slice";
import VotePopup from "../../components/MarketingDaoAdmin/VotePopup";
import useWallet from "../../hook/useWallet";
import ManageDaoPopup from "../../components/MarketingDaoAdmin/ManageDaoPopup";
import { DaoProposalStatus } from "../../constants/enum/enum";
import EditPopup from "../../components/MarketingDaoAdmin/EditPopup";

const MarketingDao = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { account } = useWallet();

  const [showCreateDaoModal, setShowCreateDaoModal] = useState(false);
  const [showVoteModal, setShowVoteModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [spentTime, setSpentTime] = useState(0);
  const { proposals, isAdmin, completedIds } = useAppSelector(
    (state) => state.dao
  );
  const { signature, signMsg } = useAppSelector((state) => state.auth);

  useEffect(() => {
    setSpentTime(0);
  }, [proposals]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSpentTime((spentTime) => spentTime + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    dispatch(getProposalsAction());
  }, [dispatch]);

  useEffect(() => {
    if (!account) return;
    dispatch(getIsAdminAction({ account }));
  }, [dispatch, account]);

  useEffect(() => {
    if (!signature || !signMsg || !account) return;
    dispatch(getUserVoteAction({ signature, signMsg, account, completedIds }));
  }, [account, dispatch, signature, signMsg, completedIds]);

  useEffect(() => {
    const ids = proposals
      .filter(
        (proposal) =>
          proposal.status === DaoProposalStatus.Closed ||
          proposal.status === DaoProposalStatus.Canceled ||
          proposal.status === DaoProposalStatus.Opened
      )
      .map((proposal) => proposal.id);

    dispatch(setCompletedIds(ids));
  }, [proposals, dispatch]);

  return (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <MarketingDaoHeader
          showCreateDaoModal={() => setShowCreateDaoModal(true)}
        />

        <MarketingDaoRoundList
          showVotePopup={() => setShowVoteModal(true)}
          spentTime={spentTime}
          showManagePopup={() => setShowManageModal(true)}
        />

        <ManageDaoPopup
          openModal={showManageModal && isAdmin}
          handleClose={() => setShowManageModal(false)}
          spentTime={spentTime}
          showEditPopup={() => setShowEditModal(true)}
        />

        <CreateDaoPopup
          openModal={showCreateDaoModal && isAdmin}
          handleClose={() => setShowCreateDaoModal(false)}
        />

        <VotePopup
          openModal={showVoteModal}
          handleClose={() => setShowVoteModal(false)}
        />

        <EditPopup
          openModal={showEditModal}
          handleClose={() => setShowEditModal(false)}
        />
      </Box>
    </Box>
  );
};

export default MarketingDao;
