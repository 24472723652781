import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import Dot from "../../components/Dot";

import useStyles from "./index.styles";

import BankIcon from "../../assets/imgs/business/bank.png";
import BriefcaseIcon from "../../assets/imgs/business/briefcase.png";
import CasinoRouletteIcon from "../../assets/imgs/business/casino-roulette.png";
import GunIcon from "../../assets/imgs/business/gun.png";
import CashIcon from "../../assets/imgs/exchange/money.png";
import { toUSDFormat } from "../../utils/utils";
import { BusinessStatus } from "../../constants/enum/business";
import { useEffect, useMemo, useState } from "react";
import { City, ItemCategoryInfoList } from "../../constants/const";
import { ItemCategory } from "../../constants/enum/enum";
import { getBusinessList } from "../../reducers/business.slice";
import { BusinessListType } from "../../types/Business/BusinessListType";

const Business = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { businessList } = useAppSelector((state) => state.business);

  const [selectedStatus, setSelectedStatus] = useState<BusinessStatus>(
    BusinessStatus.All
  );
  const [selectedCity, setSelectedCity] = useState<number>(-1);

  const handleGoToDetail = (item: BusinessListType) => {
    navigate(
      `/${ItemCategoryInfoList[item.categoryId]?.businessLinks![item.typeId]}/${
        item.city
      }`
    );
  };

  useEffect(() => {
    dispatch(getBusinessList());
  }, [dispatch]);

  console.log(businessList);

  const updatedItems = useMemo(
    () =>
      businessList
        .filter(
          (item) =>
            (selectedCity === -1 ||
              (item.categoryId === ItemCategory.BUSINESS &&
                item.city === selectedCity)) &&
            (selectedStatus === BusinessStatus.All ||
              ItemCategoryInfoList[item.categoryId].businessStatus![
                item.typeId
              ] === selectedStatus)
        )
        .sort(
          (item1, item2) =>
            item2.status - item1.status ||
            item2.enabledStatus - item1.enabledStatus ||
            item2.profit - item1.profit
        ),
    [selectedCity, selectedStatus, businessList]
  );

  return (
    <Box>
      <Box className={classes.container}>
        <Box className={classes.bodyContainer}>
          <Box className={classes.body}>
            <Box className={classes.businessHeader}>
              <Box className={classes.headerLabel}>
                <Box className={classes.pageLabel}>Businesses</Box>
                <Box className={classes.descriptionLabel}>
                  What are you looking for?
                </Box>
              </Box>
              <Box className={classes.headerRightPanel}>
                <Box className={classes.allCitiesSelection}>
                  <Select
                    onChange={(e: SelectChangeEvent<number>) =>
                      setSelectedCity(Number(e.target.value))
                    }
                    className={classes.selectCity}
                    value={selectedCity}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: "#1e1f21",
                        },
                      },
                    }}
                    SelectDisplayProps={{}}
                    IconComponent={(props) => (
                      <KeyboardArrowDownIcon
                        {...props}
                        sx={{
                          color: "#808080 !important",
                          marginRight: "24px",
                          fontSize: "24px",
                        }}
                      />
                    )}
                  >
                    <MenuItem
                      value={-1}
                      key={-1}
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      All cities
                    </MenuItem>
                    {City.map((city, index) => (
                      <MenuItem
                        value={index}
                        key={index}
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {city}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box className={classes.typeSelectionBox}>
                  <Box
                    className={clsx(
                      classes.selectBox,
                      selectedStatus === BusinessStatus.All
                        ? classes.selectBoxActived
                        : null
                    )}
                    onClick={() => setSelectedStatus(BusinessStatus.All)}
                  >
                    <Box className={classes.selectLabel}>Show all</Box>
                    <Box
                      component={"img"}
                      src={BriefcaseIcon}
                      className={classes.selectIcon}
                    />
                  </Box>
                  <Box
                    className={clsx(
                      classes.selectBox,
                      selectedStatus === BusinessStatus.Casinos
                        ? classes.selectBoxActived
                        : null
                    )}
                    onClick={() => setSelectedStatus(BusinessStatus.Casinos)}
                  >
                    <Box className={classes.selectLabel}>Casinos</Box>
                    <Box
                      component={"img"}
                      src={CasinoRouletteIcon}
                      className={classes.selectIcon}
                    />
                  </Box>
                  <Box
                    className={clsx(
                      classes.selectBox,
                      selectedStatus === BusinessStatus.Weapons
                        ? classes.selectBoxActived
                        : null
                    )}
                    onClick={() => setSelectedStatus(BusinessStatus.Weapons)}
                  >
                    <Box className={classes.selectLabel}>Weapons</Box>
                    <Box
                      component={"img"}
                      src={GunIcon}
                      className={classes.selectIcon}
                    />
                  </Box>
                  <Box
                    className={clsx(
                      classes.selectBox,
                      selectedStatus === BusinessStatus.Services
                        ? classes.selectBoxActived
                        : null
                    )}
                    onClick={() => setSelectedStatus(BusinessStatus.Services)}
                  >
                    <Box className={classes.selectLabel}>Services</Box>
                    <Box
                      component={"img"}
                      src={BankIcon}
                      className={classes.selectIcon}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.tableContainer}>
              <Box className={classes.tableBox}>
                <Box className={clsx(classes.tableHeader)}>
                  <Box>Go to</Box>
                  <Box>Business</Box>
                  <Box>Status</Box>
                  <Box>City</Box>
                  <Box>Owner</Box>
                  <Box>Family</Box>
                  <Box>Profit 24h</Box>
                </Box>

                <Box className={classes.tableBodyContainer}>
                  {updatedItems.map((item, index) => (
                    <Box
                      className={clsx(classes.tableBody, classes.tableCol)}
                      key={index}
                    >
                      <Box>
                        <Button
                          className={classes.visitButton}
                          variant="contained"
                          disabled={
                            !ItemCategoryInfoList[item.categoryId]
                              ?.activeStatus![item.typeId]
                          }
                          onClick={() => handleGoToDetail(item)}
                        >
                          Visit
                        </Button>
                      </Box>
                      <Box className={classes.businessItemCol}>
                        <Box
                          component={"img"}
                          src={`/assets/imgs/inventory/${
                            ItemCategoryInfoList[item.categoryId].icons[
                              item.typeId
                            ]
                          }`}
                        />
                        <Box>
                          {
                            ItemCategoryInfoList[item.categoryId].values[
                              item.typeId
                            ]
                          }
                        </Box>
                      </Box>
                      <Box className={classes.statusCol}>
                        <Dot
                          width={8}
                          color={item.status ? "green" : "red"}
                          isShadow
                        />
                        {item.status ? "Open" : "Closed"}
                      </Box>
                      <Box>
                        {item.categoryId === ItemCategory.BUSINESS
                          ? City[item.city]
                          : "-"}
                      </Box>
                      <Box className={classes.ownerCol}>
                        <Link to={`/profile/${item.ownerName}`}>
                          {item.ownerName ? item.ownerName : "-"}
                        </Link>
                      </Box>
                      <Box className={classes.familyCol}>
                        {item.familyInfo.logo && (
                          <Box component={"img"} src={item.familyInfo.logo} />
                        )}
                        {item.familyInfo.name ? item.familyInfo.name : "-"}
                      </Box>
                      <Box className={classes.profitCol}>
                        <Box component={"img"} src={CashIcon} />
                        <Box>
                          {item.profit > 0 ? (
                            <Box component={"span"} color={"green"}>
                              +{toUSDFormat(item.profit)}
                            </Box>
                          ) : item.profit < 0 ? (
                            <Box component={"span"} color={"red"}>
                              {toUSDFormat(item.profit)}
                            </Box>
                          ) : (
                            0
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Business;
