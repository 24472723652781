import DoneIcon from "@mui/icons-material/Done";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import useStyles from "./index.styles";
import { DaoProposalStatus } from "../../constants/enum/enum";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getProposalsAction,
  getUserVoteAction,
  withdrawVotesTokenAction,
} from "../../reducers/dao.slice";
import useWallet from "../../hook/useWallet";

interface DaoRoundOptionItemProps {
  optionTitle: string;
  cost: string;
  status: DaoProposalStatus;
  adminFee: string;
  deliveryLink: string;
  isDelivered: boolean;
  handleShowVotePopup: () => void;
  roundId: number;
  optionId: number;
}

const DaoRoundOptionItem: React.FC<DaoRoundOptionItemProps> = ({
  optionTitle,
  cost,
  status,
  adminFee,
  deliveryLink,
  isDelivered,
  handleShowVotePopup,
  roundId,
  optionId,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { account } = useWallet();

  const { userVotes, completedIds, proposals, votes } = useAppSelector(
    (state) => state.dao
  );
  const { signature, signMsg } = useAppSelector((state) => state.auth);

  const isPossibleWithdraw = () => {
    if (
      status !== DaoProposalStatus.Closed &&
      status !== DaoProposalStatus.Canceled
    )
      return false;

    if (completedIds.length === 0 || userVotes.length === 0) return false;

    const completedIdIndex = completedIds.findIndex((id) => id === roundId);
    if (completedIdIndex === -1) return false;

    const voteInfo = userVotes[completedIdIndex];
    if (
      !voteInfo ||
      voteInfo.amount === 0 ||
      !voteInfo.isVoted ||
      voteInfo.isWithdrawn ||
      voteInfo.optionId !== optionId
    )
      return false;

    return true;
  };

  const isPossibleVote = () => {
    if (status !== DaoProposalStatus.Opened) {
      return false;
    }
    if (completedIds.length === 0 || userVotes.length === 0) return false;

    const voteIndex = completedIds.findIndex((id) => id === roundId);
    if (voteIndex === -1) return false;

    const voteInfo = userVotes[voteIndex];
    if (!voteInfo || voteInfo.isVoted) {
      return false;
    }

    return true;
  };

  const handleWithdraw = () => {
    dispatch(withdrawVotesTokenAction({ roundId, account }))
      .unwrap()
      .then(() => {
        dispatch(getProposalsAction());
        dispatch(
          getUserVoteAction({ signature, signMsg, account, completedIds })
        );
      })
      .catch();
  };

  const getPercent = () => {
    let value = 100;

    const proposal = proposals.find((pro) => pro.id === roundId);
    value = value / (proposal?.optionCount || 1);

    if (
      proposals.length > 0 &&
      votes.length > 0 &&
      proposal &&
      (proposal.status === DaoProposalStatus.Closed ||
        proposal.status === DaoProposalStatus.Canceled)
    ) {
      const vote = votes[roundId][optionId];

      if (proposal && proposal.totalMafia) {
        value = (vote / (proposal?.totalMafia || 0)) * 100;
      }
    }

    return value;
  };

  return (
    <Box className={classes.optionListItem}>
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        {isPossibleVote() && (
          <Button className={classes.voteButton} onClick={handleShowVotePopup}>
            Vote
          </Button>
        )}

        {isPossibleWithdraw() && (
          <Button className={classes.voteButton} onClick={handleWithdraw}>
            Withdraw
          </Button>
        )}

        <Typography fontFamily="Philosopher" fontWeight={900} color="white">
          {optionTitle}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Philosopher",
            position: "absolute",
            left: 8,
            zIndex: 1,
            fontWeight: "bold",
            color: "black",
          }}
        >
          {`${Math.round(getPercent())}%`}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={getPercent()}
          sx={{
            width: "100%",
            height: "32px",
            backgroundColor: "transparent",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "rgb(74, 94, 67)",
            },
          }}
        />
      </Box>
      <Typography
        fontFamily="Philosopher"
        color="rgb(99, 99, 99)"
        fontWeight={900}
      >
        $ {cost}
      </Typography>
      <Typography
        fontFamily="Philosopher"
        color="rgb(99, 99, 99)"
        fontWeight={900}
      >
        $ {adminFee}
      </Typography>
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <Link to={deliveryLink} style={{ textDecoration: "none" }}>
          <OpenInNewIcon
            sx={{ color: "white", width: "16px", height: "16px" }}
          />
        </Link>

        {isDelivered && (
          <DoneIcon
            sx={{
              color: "rgb(106, 217, 126)",
              width: "20px",
              height: "20px",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default DaoRoundOptionItem;
