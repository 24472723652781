import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "8px 32px",
    boxSizing: "border-box",

    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },

  body: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    maxWidth: "1200px",
    width: "100%",
    borderRadius: 16,
    backgroundColor: theme.palette.background.cardBg,
    border: "1px solid",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
    display: "flex",
    flexDirection: "column",
    gap: 24,
    padding: "32px 48px",
    minHeight: 400,

    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px",
      boxSizing: "border-box",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column",

      gap: "16px",
      alignItems: "center",
      padding: "8px 16px",

      "& p": {
        width: "100%",
      },
    },
  },

  headerDetail: {
    display: "flex",
    gap: 6,
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",

      "&>div": {
        width: "100%",
        gap: "4px",
        flexDirection: "column",
      },
    },
  },

  dealType: {
    display: "flex",
    color: "white",
    gap: "12px",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gap: 0,
      fontSize: "12px",
    },
  },

  dealTabItem: {
    transition: ".5s",
    padding: "12px 24px",
    borderRadius: "32px",
    cursor: "pointer",
    textAlign: "center",

    "&:hover": {
      backgroundColor: "#2a2c2d",
      transition: ".5s",
    },

    [theme.breakpoints.down("sm")]: {
      width: 88,
      gap: 0,
      fontSize: "12px",
    },
  },

  tabActive: {
    backgroundColor: "#2a2c2d",
  },

  title: {
    display: "flex",
    gap: "24px",
    alignItems: "end",
    "&>div:nth-of-type(1)": {
      fontSize: "30px",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: "25px",
      },
    },
    "&>div:nth-of-type(2)": {
      fontSize: "17px",
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      width: "100%",
    },
  },

  confirm: {
    borderRadius: 8,
    padding: theme.spacing(0.5, 1.5),
    fontSize: 14,
    fontFamily: "Philosopher",
    fontWeight: 700,
    background: theme.palette.background.defaultButton,
    color: theme.palette.text.primary,

    "&:disabled": {
      background: theme.palette.background.lightGray,
    },
  },

  cancelBulk: {
    borderRadius: 8,
    padding: theme.spacing(0.5, 1.5),
    fontSize: 14,
    fontFamily: "Philosopher",
    fontWeight: 700,
    background: theme.palette.background.dangerButton,
    color: theme.palette.text.primary,

    "&:disabled": {
      background: theme.palette.background.lightGray,
    },
  },

  accept: {
    borderRadius: 8,
    padding: theme.spacing(0.5, 2),
    fontFamily: "Philosopher",
    fontWeight: 700,
    background: theme.palette.background.defaultButton,
    color: theme.palette.text.primary,
    width: 120,

    "&:disabled": {
      background: theme.palette.background.lightGray,
    },
  },

  cancel: {
    borderRadius: 8,
    padding: theme.spacing(0.5, 2),
    fontFamily: "Philosopher",
    fontWeight: 700,
    background: theme.palette.background.dangerButton,
    color: theme.palette.text.primary,
    width: 120,

    "&:disabled": {
      background: theme.palette.background.lightGray,
    },
  },

  tableContainer: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "100%",
      overflowX: "auto",
    },
  },
  tableBody: {
    [theme.breakpoints.down("sm")]: {},
  },
  tableHeader: {
    color: theme.palette.text.grayColor,
    display: "grid",
    fontSize: "17px",
    padding: "0 0 0 16px",
    gridTemplateColumns: "2fr 3fr 1fr 3fr 3fr 1.5fr",
    [theme.breakpoints.down("md")]: {
      minWidth: 1000,
    },
  },

  tableDetailsBody: {
    marginTop: "8px",
  },

  detail: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 1fr 3fr 3fr 1.5fr",
    gap: "4px",
    alignItems: "center",
    color: "#474747",
    padding: "12px 0 12px 16px",
    borderBottom: "1px solid",
    borderColor: theme.palette.background.darkBorder,
    transitionDuration: "300ms",

    [theme.breakpoints.down("md")]: {
      minWidth: 1000,
    },

    "&>div": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },

    "& a": {
      textDecoration: "none",
      color: theme.palette.text.linkColor,
    },

    "&:hover": {
      backgroundColor: theme.palette.background.whiteTransparent1,
    },
  },

  detailMobile: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    borderRadius: 16,
    alignItems: "center",
    color: "#474747",
    padding: "12px 12px 12px 16px",
    borderBottom: "1px solid",
    borderColor: theme.palette.background.darkBorder,
    transitionDuration: "300ms",

    "&>div": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },

    "& a": {
      textDecoration: "none",
      color: theme.palette.text.linkColor,
    },

    "&:hover": {
      backgroundColor: theme.palette.background.whiteTransparent1,
    },
  },

  itemIcon: {
    width: 32,
    height: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: 4,
    borderRadius: 8,
    boxShadow:
      "inset 0 -3px 6px 0px rgb(0 0 0 / 35%), inset 3px 0 6px 0px rgb(0 0 0 / 35%), inset 0 3px 6px 0px rgb(0 0 0 / 35%), inset -3px 0 6px 0px rgb(0 0 0 / 35%)",
    fontSize: 10,
    color: "white",

    "& img": {
      width: 24,
      height: 24,
    },
  },

  modalBody: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },

    "& .MuiDialog-paper": {
      overflowY: "initial",
    },
  },

  modalContent: {
    position: "relative",
  },

  contentHeader: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    paddingTop: 32,
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: "36px 32px 24px 32px",
    minWidth: 320,
    backgroundSize: "auto 100%",

    [theme.breakpoints.down("sm")]: {
      padding: "16px",
      maxHeight: 400,
      overflowY: "auto",
    },
  },

  searchSelectBody: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 16,
    minWidth: 320,
    backgroundSize: "auto 100%",
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 16,
    top: 16,
    zIndex: 1,

    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },

  inventoryItemIcon: {
    width: 24,
    height: 24,
    borderRadius: 4,
    maxHeight: 20,
  },

  slotItemIcon: {
    width: 24,
    height: "auto",
    borderRadius: 4,
  },

  link: {
    color: theme.palette.text.linkColor,
    textDecoration: "none",
  },

  offerContent: {
    display: "flex",
    flexDirection: "column",
    gap: "4",
    minWidth: 200,
    height: 200,

    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
}));

export default styles;
