import { Box, Button, SelectChangeEvent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import CustomDropDown from "../../CustomDropdown/CustomDropDown";
import Dot from "../../Dot";

import { City } from "../../../constants/const";

import cashIcon from "../../../assets/imgs/exchange/money.png";
import cartIcon from "../../../assets/imgs/roulette/cart.png";

import { useAppSelector } from "../../../app/hooks";
import { toUSDFormat } from "../../../utils/utils";

import useStyles from "./index.styles";
import { useState } from "react";

const RouletteHeader = ({ id }: { id: number }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [cityId, setCityId] = useState(id);

  const {
    currentRouletteId,
    roulettes,
    isRouletteListLoading: rouletteListLoading,
  } = useAppSelector((state) => state.roulette);

  const isLoaded = !rouletteListLoading && roulettes[currentRouletteId];

  const handleChangeCity = (e: SelectChangeEvent) => {
    const cityId = City.findIndex((city) => city === e.target.value);
    if (cityId >= 0) {
      setCityId(cityId);
      navigate(`/roulette/${cityId}`);
    }
  };

  return (
    <Box className={classes.rouletteHeader}>
      <Box className={classes.rouletteHeaderBox}>
        <Typography className={classes.headerTitle}>
          {City[cityId]} Roulette
        </Typography>

        {isLoaded ? (
          <Box className={classes.rouletteInfo}>
            {roulettes[currentRouletteId].isOpened ? (
              <Dot width={10} color="#23f028" isShadow />
            ) : (
              <Dot width={10} color="#f02828" isShadow />
            )}

            <Typography>
              {roulettes[currentRouletteId].isOpened ? "Open" : "Closed"}
            </Typography>

            <Typography>
              Min bet: {toUSDFormat(roulettes[currentRouletteId].minBet)}
            </Typography>
            <Typography>
              Max bet: {toUSDFormat(roulettes[currentRouletteId].maxBet)}
            </Typography>

            <Typography>Profit:</Typography>
            <Box
              color={
                roulettes[currentRouletteId].profit > 0
                  ? "green"
                  : roulettes[currentRouletteId].profit < 0
                  ? "red"
                  : "white"
              }
            >
              {roulettes[currentRouletteId].profit > 0
                ? `+${toUSDFormat(roulettes[currentRouletteId].profit)}`
                : roulettes[currentRouletteId].profit < 0
                ? toUSDFormat(roulettes[currentRouletteId].profit)
                : 0}
            </Box>
          </Box>
        ) : (
          <Box className={classes.rouletteInfo}>
            <Dot width={10} color="#f02828" isShadow />
            <Typography>Loading...</Typography>

            <Typography>Min bet: Loading...</Typography>
            <Typography>Max bet: Loading...</Typography>

            <Typography>24h profit:</Typography>
            <Typography>Loading...</Typography>
          </Box>
        )}
      </Box>

      <Box className={classes.rouletteHeaderBoxRight}>
        <Button
          className={classes.rouletteHeaderButton}
          startIcon={
            <Box
              component="img"
              src={cartIcon}
              sx={{
                width: 20,
                height: 20,
              }}
            ></Box>
          }
          endIcon={
            <Box
              component="img"
              src={cashIcon}
              sx={{
                width: 20,
                height: 20,
              }}
            ></Box>
          }
          onClick={() => navigate("/exchange")}
        >
          Buy more cash
        </Button>

        <CustomDropDown
          items={City}
          placeholder="Switch Table"
          className={classes.rouletteHeaderSelect}
          handleChange={handleChangeCity}
          value={City[cityId]}
        />

        {/* <Button className={classes.rouletteHeaderButton}>Exit Table</Button> */}
      </Box>
    </Box>
  );
};

export default RouletteHeader;
