import {
  approveToken,
  getProvider,
  getTokenAllowance,
  getWeb3,
  waitForConfirmation,
} from ".";
import config from "../../config/config";
import MafiaMarketingProposalAbi from "../../abis/MafiaMarketingProposal.json";
import { ethers } from "ethers";

export const getMafiaMarketingProposalContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.proposalAddress;
  const MafiaMarketingProposalContract = new web3.eth.Contract(
    MafiaMarketingProposalAbi,
    contractAddress
  );
  return MafiaMarketingProposalContract;
};

export const createProposal = async (
  duration: number,
  options: any[],
  account: string
) => {
  const contract = getMafiaMarketingProposalContract(true);
  const data = await contract.methods
    .createProposal(duration, options)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const openProposal = async (proposalId: number, account: string) => {
  const contract = getMafiaMarketingProposalContract(true);
  const data = await contract.methods
    .openProposal(proposalId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const voteOnProposal = async (
  proposalId: number,
  optionId: number,
  amount: number,
  account: string
) => {
  const contract = getMafiaMarketingProposalContract(true);

  const data = await contract.methods
    .voteOnProposal(
      proposalId,
      optionId,
      ethers.utils.parseUnits(amount.toFixed(18), 18).toString()
    )
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const withdrawVoteToken = async (
  proposalId: number,
  account: string
) => {
  const contract = getMafiaMarketingProposalContract(true);
  const data = await contract.methods
    .withdrawVoteToken(proposalId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const closeProposal = async (proposalId: number, account: string) => {
  const contract = getMafiaMarketingProposalContract(true);
  const data = await contract.methods
    .closeProposal(proposalId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const getProposals = async () => {
  const contract = getMafiaMarketingProposalContract();
  const data = await contract.methods.getProposals().call();

  return data;
};

export const getIsAdmin = async (account: string) => {
  const contract = getMafiaMarketingProposalContract();
  const data = await contract.methods.isAdmin(account).call();

  return Boolean(data);
};

export const approveMafiaToVote = async (account: string, amount: number) => {
  const allowance = await getTokenAllowance(
    config.mafiaAddress,
    account,
    config.proposalAddress
  );

  if (Number(allowance) < amount) {
    await approveToken(
      config.mafiaAddress,
      amount,
      config.proposalAddress,
      account
    );
  }
};

export const getProposalIds = async () => {
  const contract = getMafiaMarketingProposalContract();
  const data = await contract.methods.proposalIds().call();
  return Number(data);
};

export const getUserVotes = async (
  signature: string,
  signMsg: string,
  account: string,
  completedIds: number[]
) => {
  const contract = getMafiaMarketingProposalContract();

  const data = await contract.methods
    .getUserVotes(account, completedIds, JSON.stringify(signMsg), signature)
    .call();
  return data;
};

export const editDelivery = async (
  account: string,
  optionIds: number[],
  links: string[],
  roundId: number
) => {
  const contract = getMafiaMarketingProposalContract(true);

  const data = await contract.methods
    .updateDelieveryLinks(roundId, optionIds, links)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const cancelProposal = async (account: string, roundId: number) => {
  const contract = getMafiaMarketingProposalContract(true);

  const data = await contract.methods
    .cancelProposal(roundId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
  return data;
};
