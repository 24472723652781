import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  panel: {
    background: theme.palette.background.modalBg,
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",
    borderRadius: 16,
    padding: "16px 16px",
    border: "1px solid",
    borderColor: "black",
    transition: "background 0.3s, color 0.3s",
    height: "min-content",
    width: "110px",
    boxSizing: "border-box",

    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },

  panelBody: {
    display: "flex",
    gap: "0px",
    alignItems: "center",
    flexDirection: "column",

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      whiteSpace: "nowrap",
    },
  },

  timerLabel: {
    fontSize: "14px",
    color: "#FFFFFF50",
  },

  timerSecs: {
    fontSize: "20px",
    color: "#FFFFFF",
  },
}));

export default styles;
