import { Box, Checkbox, FormControlLabel } from "@mui/material";
import clsx from "clsx";
import { useMemo, useState } from "react";

import CompletedListingItem from "../MarketMyListing/CompletedListingItem";
import CompletedListingLoadingSkeleton from "../MarketMyListing/CompletedListingLoadingSkeleton";

import { useAppSelector } from "../../app/hooks";
import config from "../../config/config";

import useStyles from "./ActiveItemListing.styles";
import { ListingTokenType } from "../MarketListItemPopup";
import { GAME_CASH_ADDRESS } from "../../constants/const";
import { RarityType } from "../../constants/enum/map";

const CompletedItemListing = ({
  rarityType,
}: {
  rarityType: RarityType | null;
}) => {
  const { classes } = useStyles();

  const [showOnlyCompleted, setShowOnlyCompleted] = useState(false);

  const { itemDetail, isLoadingItemDetail } = useAppSelector(
    (state) => state.market
  );

  const parsedItem = useMemo(() => {
    return (
      showOnlyCompleted
        ? itemDetail.soldItems
        : [...itemDetail.soldItems, ...itemDetail.expiredItems]
    )
      .map((item) => {
        return {
          ...item,
          currency:
            item.listingInfo.token === config.mafiaAddress
              ? ListingTokenType.Mafia
              : item.listingInfo.token === GAME_CASH_ADDRESS
              ? ListingTokenType.GameCash
              : ListingTokenType.Native,
        };
      })
      .filter((item) => item.listingInfo.token !== GAME_CASH_ADDRESS);
  }, [itemDetail.expiredItems, itemDetail.soldItems, showOnlyCompleted]);

  return (
    <Box className={classes.itemListing}>
      <Box
        className={clsx(classes.listingHeader, classes.completedListingHeader)}
      >
        <Box>Completed / Expired</Box>

        <FormControlLabel
          onChange={() => {
            setShowOnlyCompleted((prev: boolean) => !prev);
          }}
          value={showOnlyCompleted}
          control={<Checkbox />}
          label="Show only completed"
          sx={{
            "& span": {
              fontFamily: "Philosopher",
              fontWeight: 900,
            },
          }}
        />
      </Box>

      <Box className={classes.listingPanel}>
        <Box
          className={clsx(
            classes.listingPanelHeader,
            classes.completedPanelHeader
          )}
        >
          <Box>Item</Box>
          <Box>Type</Box>
          <Box>Price</Box>
          <Box>Buyer</Box>
          <Box>Status</Box>
        </Box>

        {isLoadingItemDetail
          ? [...new Array(12)].map((item, index) => {
              return <CompletedListingLoadingSkeleton key={index} />;
            })
          : parsedItem.map((item, index) => {
              return <CompletedListingItem item={item} key={index} rarityType={rarityType} />;
            })}
      </Box>
    </Box>
  );
};

export default CompletedItemListing;
