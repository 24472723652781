import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  panel: {
    background: theme.palette.background.modalBg,
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",
    borderRadius: 16,
    padding: "32px 32px",
    border: "1px solid",
    borderColor: "black",
    transition: "background 0.3s, color 0.3s",
    height: "min-content",
    minWidth: "320px",
    width: "100%",
    boxSizing: "border-box",

    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },

  panelBody: {
    display: "flex",
    gap: "16px",
    alignItems: "center",

    "& img": {
      width: 100,
      height: 120,
      objectFit: "cover",
      borderRadius: 8,
      objectPosition: "top",
    },
  },

  profileInfo: {
    display: "flex",
    gap: "8px",
  },

  profileInfoLabel: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 16,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "#ffffff55",

      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
  },

  profileInfoValue: {
    display: "grid",
    gridTemplateRows: "1fr 1fr 1fr 1fr 1fr",
    gap: "4px",

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 16,

      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",

      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
  },
}));

export default styles;
