import { Box, Typography } from "@mui/material";
import isMobile from "is-mobile";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../app/hooks";
import { RouletteBetStatus } from "../../../constants/enum/roulette";
import {
  DefaultNumberBoardStatus,
  DefaultRouletteData,
} from "../../../constants/roulette";
import {
  addBet,
  IRouletteBet,
  removeBet,
} from "../../../reducers/roulette.slice";
import { RouletteBetData } from "../../../types/RouletteBetData";

import useStyles from "./index.styles";

import "./index.css";

interface IRouletteNumberBoardProps {
  betStatus: RouletteBetStatus;
  betDisabled: boolean;
}

const RouletteNumberBoard = ({
  betStatus,
  betDisabled,
}: IRouletteNumberBoardProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { isRouletteListLoading, defaultBetAmount, bets } = useAppSelector(
    (state) => state.roulette
  );
  const [numberBoardStatus, setNumberBoardStatus] = useState(
    DefaultNumberBoardStatus
  );
  const [rouletteData, setRouletteData] = useState(DefaultRouletteData);

  const onCallBet = (
    type: string,
    typeText: string,
    text: string,
    backColor: string,
    multi: number,
    addOrRemove: boolean
  ) => {
    if (isRouletteListLoading || betDisabled) return;

    const bet: IRouletteBet = {
      type: type,
      typeText: typeText,
      text: text,
      value: defaultBetAmount,
      multi: multi,
      backColor: backColor,
    };
    dispatch(addOrRemove ? addBet(bet) : removeBet(bet));
  };

  const onClickBoard = (value: string) => {
    if (isRouletteListLoading || betDisabled) return;

    if (value === "R1") {
      onCallBet("R1", "R1", "Row 1", "#a6a6a6", 3, !numberBoardStatus[value]);
    } else if (value === "R2") {
      onCallBet("R2", "R2", "Row 2", "#a6a6a6", 3, !numberBoardStatus[value]);
    } else if (value === "R3") {
      onCallBet("R3", "R3", "Row 3", "#a6a6a6", 3, !numberBoardStatus[value]);
    } else if (value === "S1") {
      onCallBet(
        "S1",
        "S1",
        "Nr. 1-12",
        "#a6a6a6",
        3,
        !numberBoardStatus[value]
      );
    } else if (value === "S2") {
      onCallBet(
        "S2",
        "S2",
        "Nr. 13-24",
        "#a6a6a6",
        3,
        !numberBoardStatus[value]
      );
    } else if (value === "S3") {
      onCallBet(
        "S3",
        "S3",
        "Nr. 25-36",
        "#a6a6a6",
        3,
        !numberBoardStatus[value]
      );
    } else if (value === "H1") {
      onCallBet(
        "H1",
        "H1",
        "Nr. 1-18",
        "#a6a6a6",
        2,
        !numberBoardStatus[value]
      );
    } else if (value === "H2") {
      onCallBet(
        "H2",
        "H2",
        "Nr. 19-36",
        "#a6a6a6",
        2,
        !numberBoardStatus[value]
      );
    } else if (value === "EVEN") {
      onCallBet("EVEN", "E", "EVEN", "#a6a6a6", 2, !numberBoardStatus[value]);
    } else if (value === "ODD") {
      onCallBet("ODD", "O", "ODD", "#a6a6a6", 2, !numberBoardStatus[value]);
    } else if (value === "RED") {
      onCallBet("RED", "", "Red", "red", 2, !numberBoardStatus[value]);
    } else if (value === "BLACK") {
      onCallBet("BLACK", "", "BLACK", "black", 2, !numberBoardStatus[value]);
    } else if (value === "ZERO") {
      onCallBet("ZERO", "0", "Number", "green", 36, !numberBoardStatus[value]);
    } else if (value === "DZERO") {
      onCallBet(
        "DZERO",
        "00",
        "Number",
        "green",
        36,
        !numberBoardStatus[value]
      );
    }
  };

  const onClickNumber = (number: number, value: boolean, onSingle: string) => {
    if (isRouletteListLoading || betDisabled) return;
    if (onSingle) {
      const data = {
        type: number,
        typeText: number,
        text: "Number",
        value: defaultBetAmount,
        multi: 36,
        backColor: onSingle,
      };
      if (value) {
        dispatch(addBet(data));
      } else {
        dispatch(removeBet(data));
      }
    }
  };

  useEffect(() => {
    const cloneObject = (obj: any) => JSON.parse(JSON.stringify(obj));
    const newNumberBoardStatus = cloneObject(DefaultNumberBoardStatus);
    const newRouletteData: RouletteBetData[] = cloneObject(DefaultRouletteData);

    bets.forEach(({ type }) => {
      if (typeof type == "string") {
        //Clicked Group Area
        newNumberBoardStatus[type] = true;
        if (type === "R1") {
          newRouletteData.forEach(
            (val) => val.number % 3 === 0 && (val.bg = val.bg + 1)
          );
        } else if (type === "R2") {
          newRouletteData.forEach(
            (val) => val.number % 3 === 2 && (val.bg = val.bg + 1)
          );
        } else if (type === "R3") {
          newRouletteData.forEach(
            (val) => val.number % 3 === 1 && (val.bg = val.bg + 1)
          );
        } else if (type === "S1") {
          newRouletteData.forEach(
            (val) => val.number > 0 && val.number < 13 && (val.bg = val.bg + 1)
          );
        } else if (type === "S2") {
          newRouletteData.forEach(
            (val) => val.number > 12 && val.number < 25 && (val.bg = val.bg + 1)
          );
        } else if (type === "S3") {
          newRouletteData.forEach(
            (val) => val.number > 24 && val.number < 37 && (val.bg = val.bg + 1)
          );
        } else if (type === "H1") {
          newRouletteData.forEach(
            (val) => val.number > 0 && val.number < 19 && (val.bg = val.bg + 1)
          );
        } else if (type === "H2") {
          newRouletteData.forEach(
            (val) => val.number > 18 && val.number < 37 && (val.bg = val.bg + 1)
          );
        } else if (type === "EVEN") {
          newRouletteData.forEach(
            (val) => val.number % 2 === 0 && (val.bg = val.bg + 1)
          );
        } else if (type === "ODD") {
          newRouletteData.forEach(
            (val) => val.number % 2 === 1 && (val.bg = val.bg + 1)
          );
        } else if (type === "RED") {
          newRouletteData.forEach(
            (val) => val.color === "red" && (val.bg = val.bg + 1)
          );
        } else if (type === "BLACK") {
          newRouletteData.forEach(
            (val) => val.color === "black" && (val.bg = val.bg + 1)
          );
        }
      } else {
        newRouletteData.forEach(
          (val) => val.number === type && (val.selected = true)
        );
      }
    });
    setNumberBoardStatus(newNumberBoardStatus);
    setRouletteData(newRouletteData);
  }, [bets]);

  return (
    <Box className={classes.rouletteNumberBoardBox}>
      <Box className={classes.rouletteNumberBoard}>
        <Box
          className={
            isMobile()
              ? `rotate roulette-number-board-rotate`
              : `roulette-number-board-rotate `
          }
        >
          <Box className="number-board-container">
            <Box className="number-board">
              <Box sx={{ display: "flex" }}>
                <Box
                  className={`cursor-pointer`}
                  sx={{
                    width: "fit-content",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    className={`h-50 w-100 flex relative ${
                      numberBoardStatus.DZERO ? "zero-selected" : ""
                    }`}
                    onClick={() => onClickBoard("DZERO")}
                  >
                    <Box className="box-triangle"></Box>
                    <Box className="number-zero flex-center h-100 ">
                      <Typography
                        style={{ fontWeight: 800, color: "white" }}
                        variant="h6"
                      >
                        00
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className={`h-50 w-100 flex relative ${
                      numberBoardStatus.ZERO ? "zero-selected" : ""
                    }`}
                    onClick={() => onClickBoard("ZERO")}
                    sx={{ position: "relative" }}
                  >
                    <Box className="box-triangle"></Box>
                    <Box className="number-zero flex-center h-100">
                      <Typography
                        style={{ fontWeight: 800, color: "white" }}
                        variant="h6"
                      >
                        0
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="roulette-box "
                  sx={{ flexGrow: 1, flexShrink: 1 }}
                >
                  {rouletteData.map((val, key) => {
                    return (
                      <Box
                        className={`roulette-number-box relative ${
                          val.bg > 0 || val.selected ? "bg-teal" : ""
                        }`}
                        onClick={(e) => {
                          onClickNumber(val.number, !val.selected, val.color);
                        }}
                        key={key}
                      >
                        <Box
                          className={`roulette-number-circle-box ${
                            val.selected ? "outline-yellow" : ""
                          }`}
                          sx={{ backgroundColor: val.color }}
                        >
                          <Typography
                            style={{ fontWeight: 800 }}
                            variant="h6"
                            className={isMobile() ? `rotate` : ``}
                          >
                            {val.number}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40px",
                  }}
                >
                  <Box
                    onClick={() => onClickBoard("R1")}
                    className={`border-white cursor-pointer`}
                    sx={{ flexShrink: 1, flexGrow: 1, position: "relative" }}
                  >
                    <Box
                      className={`flex-center h-100 w-100  ${
                        numberBoardStatus.R1 ? "outline-yellow bg-teal" : ""
                      }`}
                    >
                      <Typography
                        align="center"
                        style={{
                          rotate: "-90deg",
                          fontWeight: 800,
                          color: "white",
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          transformOrigin: "0% 0%",
                        }}
                        variant="body2"
                      >
                        2To1
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    onClick={() => onClickBoard("R2")}
                    className={`border-white cursor-pointer`}
                    sx={{ flexShrink: 1, flexGrow: 1, position: "relative" }}
                  >
                    <Box
                      className={`flex-center h-100 w-100 ${
                        numberBoardStatus.R2 ? "outline-yellow bg-teal" : ""
                      }`}
                    >
                      <Typography
                        align="center"
                        style={{
                          rotate: "-90deg",
                          fontWeight: 800,
                          color: "white",
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          transformOrigin: "0% 0%",
                        }}
                        variant="body2"
                      >
                        2To1
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    onClick={() => onClickBoard("R3")}
                    className={`border-white cursor-pointer`}
                    sx={{ flexShrink: 1, flexGrow: 1, position: "relative" }}
                  >
                    <Box
                      className={`flex-center h-100 w-100 ${
                        numberBoardStatus.R3 ? "outline-yellow bg-teal" : ""
                      }`}
                    >
                      <Typography
                        align="center"
                        style={{
                          rotate: "-90deg",
                          fontWeight: 800,
                          color: "white",
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          transformOrigin: "0% 0%",
                        }}
                        variant="body2"
                      >
                        2To1
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className="roulette-bottom-12-box">
                <Box
                  className={`border-white cursor-pointer flex-center relative`}
                  onClick={() => onClickBoard("S1")}
                  sx={{ position: "relative" }}
                >
                  <Box
                    className={`flex-center h-100 w-100   ${
                      numberBoardStatus.S1 ? "outline-yellow bg-teal" : ""
                    }`}
                  >
                    <Typography
                      align="center"
                      style={{ fontWeight: 800, color: "white" }}
                      variant="h6"
                    >
                      1 ST 12
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className={`border-white cursor-pointer flex-center relative`}
                  onClick={() => onClickBoard("S2")}
                  sx={{ position: "relative" }}
                >
                  <Box
                    className={`flex-center h-100 w-100 ${
                      numberBoardStatus.S2 ? "outline-yellow bg-teal" : ""
                    }`}
                  >
                    <Typography
                      align="center"
                      style={{ fontWeight: 800, color: "white" }}
                      variant="h6"
                    >
                      2 ND 12
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className={`border-white cursor-pointer flex-center relative`}
                  onClick={() => onClickBoard("S3")}
                  sx={{ position: "relative" }}
                >
                  <Box
                    className={`flex-center h-100 w-100 ${
                      numberBoardStatus.S3 ? "outline-yellow bg-teal" : ""
                    }`}
                  >
                    <Typography
                      align="center"
                      style={{ fontWeight: 800, color: "white" }}
                      variant="h6"
                    >
                      3 RD 12
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="roulette-bottom-box">
                <Box
                  className={`border-white cursor-pointer flex-center relative`}
                  onClick={() => onClickBoard("H1")}
                >
                  <Box
                    className={`flex-center h-100 w-100 ${
                      numberBoardStatus.H1 ? "outline-yellow bg-teal" : ""
                    }`}
                  >
                    <Typography
                      align="center"
                      style={{ fontWeight: 800, color: "white" }}
                      variant="h6"
                    >
                      1 TO 18
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className={`border-white cursor-pointer flex-center relative`}
                  onClick={() => onClickBoard("EVEN")}
                >
                  <Box
                    className={`flex-center h-100 w-100 ${
                      numberBoardStatus.EVEN ? "outline-yellow bg-teal" : ""
                    }`}
                  >
                    <Typography
                      align="center"
                      style={{ fontWeight: 800, color: "white" }}
                      variant="h6"
                    >
                      EVEN
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className={`border-white cursor-pointer flex-center relative`}
                  onClick={() => onClickBoard("RED")}
                >
                  <Box
                    className={`flex-center h-100 w-100 position-relative ${
                      numberBoardStatus.RED ? "outline-yellow bg-teal" : ""
                    }`}
                  >
                    <Box className="diamond-narrow"></Box>
                    <Typography
                      align="center"
                      style={{
                        position: "absolute",
                        fontWeight: 800,
                        color: "white",
                        fontSize: "15px",
                      }}
                      variant="h6"
                    >
                      RED
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className={`border-white cursor-pointer flex-center relative`}
                  onClick={() => onClickBoard("BLACK")}
                >
                  <Box
                    className={`flex-center h-100 w-100 position-relative ${
                      numberBoardStatus.BLACK ? "outline-yellow bg-teal" : ""
                    }`}
                  >
                    <Box className="diamond-narrow black"></Box>
                    <Typography
                      align="center"
                      style={{
                        position: "absolute",
                        fontWeight: 800,
                        color: "white",
                        fontSize: "15px",
                      }}
                      variant="h6"
                    >
                      BLACK
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className={`border-white cursor-pointer flex-center relative`}
                  onClick={() => onClickBoard("ODD")}
                >
                  <Box
                    className={`flex-center h-100 w-100 ${
                      numberBoardStatus.ODD ? "outline-yellow bg-teal" : ""
                    }`}
                  >
                    <Typography
                      align="center"
                      style={{ fontWeight: 800, color: "white" }}
                      variant="h6"
                    >
                      ODD
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className={`border-white cursor-pointer flex-center relative`}
                  onClick={() => onClickBoard("H2")}
                >
                  <Box
                    className={`flex-center h-100 w-100 ${
                      numberBoardStatus.H2 ? "outline-yellow bg-teal" : ""
                    }`}
                  >
                    <Typography
                      align="center"
                      style={{ fontWeight: 800, color: "white" }}
                      variant="h6"
                    >
                      19 TO 36
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RouletteNumberBoard;
