import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import BackgroundMusic from "../../components/BackgroundMusic";
import RouletteHeader from "../../components/Roulette/Header/RouletteHeader";
import RouletteHistoryManageBoard from "../../components/Roulette/HistoryManageBoard/HistoryMangeBoard";
import RouletteMain from "../../components/Roulette/Main/RouletteMain";
import RouletteNumberBoard from "../../components/Roulette/NumberBoard/NumberBoard";
import NumberBoardHeader from "../../components/Roulette/NumberBoard/NumberBoardHeader";
import RouletteProfileBoard from "../../components/Roulette/ProfileBoard/RouletteProfileBoard";
import RouletteResultPopup from "../../components/RouletteResultPopup";

import bgMusic from "../../assets/sfxs/Roulettte/bgm.mp3";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearBets,
  getRouletteProfileAction,
  getRoulettesAction,
  setBetResult,
  setCurrentRouletteId,
  updateBetDisabled,
  updateBetStatus,
  updateDefaultBetAmount,
} from "../../reducers/roulette.slice";

import { RouletteBetStatus } from "../../constants/enum/roulette";

import useStyles from "./index.styles";

const Roulette = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { id } = useParams();

  const { currentRouletteId, roulettes, betResult, betStatus, betDisabled } =
    useAppSelector((state) => state.roulette);

  const [muted, setMuted] = useState(localStorage.getItem("mute") === "true");

  const handleMuteEvent = (event: any) => {
    if (event.type === "mute") {
      setMuted(event.detail.newValue);
    }
  };

  useEffect(() => {
    dispatch(getRoulettesAction());
    // Add event listeners
    window.addEventListener("mute", handleMuteEvent);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mute", handleMuteEvent);
    };
  }, [dispatch]);

  useEffect(() => {
    const rouletteId = Number(id);

    dispatch(setCurrentRouletteId(rouletteId));
  }, [dispatch, id]);

  useEffect(() => {
    if (!roulettes[currentRouletteId]) return;

    dispatch(updateDefaultBetAmount(roulettes[currentRouletteId].minBet));
  }, [currentRouletteId, dispatch, roulettes]);

  useEffect(() => {
    dispatch(getRouletteProfileAction({ itemId: 48, cityId: Number(id) }));
  }, [dispatch, id]);

  return (
    <Box className={classes.body}>
      <BackgroundMusic muted={muted} backgroundMusic={bgMusic} volume={0.2} />

      <RouletteHeader id={Number(id)} />

      <Box className={classes.rouletteBody}>
        <Box className={classes.rouletteBodyBox}>
          <Box className={classes.rouletteBodyBoxInner}>
            <RouletteMain />
          </Box>

          <Box className={classes.rouletteBodyBox2}>
            <NumberBoardHeader />

            <RouletteNumberBoard
              betDisabled={betDisabled}
              betStatus={betStatus}
            />
          </Box>
        </Box>

        <Box className={classes.rouletteBodyBox} sx={{ gap: 2 }}>
          <RouletteProfileBoard />

          <RouletteHistoryManageBoard />
        </Box>
      </Box>

      <RouletteResultPopup
        openPopup={betStatus === RouletteBetStatus.FINISHED && !!betResult}
        handleClose={() => {
          dispatch(updateBetStatus(RouletteBetStatus.NOT_REQUESTED));
          dispatch(updateBetDisabled(false));
          dispatch(clearBets());
          dispatch(setBetResult(null));
        }}
      />
    </Box>
  );
};

export default Roulette;
