import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import familyReducer from "../reducers/family.slice";
import notificationReducer from "../reducers/notification.slice";
import playerReducer from "../reducers/player.slice";
import profileReducer from "../reducers/profile.slice";
import crateReducer from "../reducers/crate.slice";
import marketReducer from "../reducers/market.slice";
import vaultReducer from "../reducers/vault.slice";
import authReducer from "../reducers/auth.slice";
import exchangeReducer from "../reducers/exchange.slice";
import mapReducer from "../reducers/map.slice";
import daoReducer from "../reducers/dao.slice";
import rouletteReducer from "../reducers/roulette.slice";
import businessReducer from "../reducers/business.slice";

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    family: familyReducer,
    player: playerReducer,
    notification: notificationReducer,
    crate: crateReducer,
    market: marketReducer,
    vault: vaultReducer,
    auth: authReducer,
    exchange: exchangeReducer,
    map: mapReducer,
    dao: daoReducer,
    roulette: rouletteReducer,
    business: businessReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
