import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  numberBoardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,

    "& button": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 14,
      color: "white",
      textTransform: "none",
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      gap: 8,
    },
  },
  defaultBet: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },
  defaultBetLabel: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 14,
    color: "#ffffff",
    lineHeight: "14px",
    marginRight: 8,
  },

  inputBox: {
    display: "flex",
    alignItems: "center",
    background: "#282828",
    borderRadius: 4,
    padding: "4px 12px",
    gap: 16,

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 14,
      color: "#ffffff",
    },
  },

  input: {
    padding: "0 8px",
    // width: 100,
    textAlign: "right",
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 14,
  },

  rouletteNumberBoard: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "min-content",
      height: 630,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  rouletteNumberBoardBox: {
    background: theme.palette.background.modalBg,
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",
    borderRadius: 16,
    padding: "16px",
    border: "1px solid",
    borderColor: "black",
    transition: "background 0.3s, color 0.3s",
    height: "min-content",
    minWidth: "320px",
    width: "100%",
    boxSizing: "border-box",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

export default styles;
