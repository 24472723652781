import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  panel: {
    background: theme.palette.background.modalBg,
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",
    borderRadius: 16,
    padding: "24px 24px",
    border: "1px solid",
    borderColor: "black",
    transition: "background 0.3s, color 0.3s",
    height: "min-content",
    minWidth: "320px",
    width: "min-content",
    boxSizing: "border-box",
  },

  panelHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& p": {
      color: "#ffffff55",
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 16,
    },
  },

  panelBody: {
    width: "100%",
    marginBottom: "16px",

    "& p": {
      color: "#ffffff",
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 20,
      textAlign: "center",
      padding: "32px 16px",
    },
  },

  spinButton: {
    background: "#377934",
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    textTransform: "none",
    borderRadius: 8,
    padding: "8px 32px",
    width: "100%",
    transition: "background 0.3s, color 0.3s",
    height: "min-content",

    "&:hover": {
      background: "#377934",
      opacity: 0.8,
      transition: "300ms",
    },

    "&:disabled": {
      background: theme.palette.background.lightGray,
    },
  },
  betEmpty: {
    margin: "8px 0",
    padding: "8px 0",
    maxHeight: "160px",
    minHeight: "160px",
  },
  betList: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    margin: "8px 0",
    padding: "8px 0",
    maxHeight: "160px",
    minHeight: "160px",
    overflowY: "scroll",
  },

  betItem: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr 1fr",
    justifyItems: "end",
  },

  betItemInfo: {
    display: "flex",
    alignItems: "center",
  },

  betItemNumber: {
    display: "flex",
    alignItems: "center",
  },

  betItemText: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },

  betItemClose: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
  },

  betItemInput: {
    display: "flex",
    gap: 4,
    alignItems: "center",

    "& input": {
      padding: "4px 8px",

      "&:disabled": {
        WebkitTextFillColor: "#FFFFFF80",
      },
    },
  },
}));

export default styles;
