import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  bodyContainer: {
    display: "flex",
    justifyContent: "center",
    background: "url(/assets/imgs/business/business-bg.png) no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "top",
    marginTop: "-64px",
    [theme.breakpoints.down("md")]: {
      background: "url(/assets/imgs/business/business-bg-mobile.png) no-repeat",
      backgroundSize: "auto 100%",
      backgroundPosition: "center",
      minHeight: "500px",
      overflowX: "auto",
      justifyContent: "flex-start",
    },
  },

  body: {
    minHeight: 900,
    minWidth: 1250,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    fontFamily: "Philosopher",
    paddingTop: "32px",
    marginTop: "64px",

    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      minWidth: "100%",
      minHeight: "auto",
    },
  },

  headerLabel: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      alignItems: "baseline",
    },
  },

  businessHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "8px",
    },
  },

  pageLabel: {
    fontFamily: "Philosopher",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
  },

  descriptionLabel: {
    fontSize: "12px",
    color: "white",
    opacity: "0.7",
  },

  container: {
    justifyContent: "center",
    boxSizing: "border-box",
    maxWidth: 1920,
    margin: "auto",
    minHeight: 1000,

    [theme.breakpoints.down("sm")]: {
      padding: "16px",
      minHeight: "auto",
    },
  },

  headerRightPanel: {
    display: "flex",
    alignItems: "center",
    gap: "32px",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "0",
      width: "100%",
    },
  },

  allCitiesSelection: {},

  typeSelectionBox: {
    display: "flex",
    gap: "16px",

    [theme.breakpoints.down("md")]: {
      gap: "4px",
      width: "100%",
      justifyContent: "space-between",
    },
  },

  selectBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    backgroundColor: "#181a1b",
    padding: "16px 8px",
    borderRadius: "8px",
    minWidth: "130px",
    border: "1px solid black",
    opacity: "0.9",
    cursor: "pointer",
    transition: "0.3s",

    "&:hover": {
      backgroundColor: "#2a2b2d",
      transition: "0.3s",
    },

    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      padding: "8px 4px",
      minWidth: "70px",
      gap: "4px",
    },
  },

  selectBoxActived: {
    backgroundColor: "#2a2b2d",
  },

  selectLabel: {
    color: "white",
  },

  selectIcon: {
    height: "30px",
    width: "30px",
  },

  visitButton: {
    textTransform: "none",
    backgroundColor: "#18a86b",
    color: "white",
    borderRadius: "8px",

    "&:hover": {
      backgroundColor: "#32491b60",
    },

    "&:disabled": {
      color: "#3b4244",
    },
  },

  tableContainer: {
    minWidth: 1250,

    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
      maxWidth: "100%",
      overflowX: "auto",
    },
  },

  tableBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: 32,
    backgroundColor: theme.palette.background.cardBgTransparent,
    padding: "16px 32px",
    borderRadius: "8px",

    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",

    "& a": {
      textDecoration: "none",
    },

    [theme.breakpoints.down("md")]: {
      padding: "4px 4px",
      marginTop: "8px",
      minWidth: "900px",
    },
  },

  tableBodyContainer: {
    height: 600,
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {},
  },
  tableBody: {
    [theme.breakpoints.down("sm")]: {},
  },
  tableHeader: {
    display: "grid",
    fontSize: 18,
    fontWeight: 900,
    alignItems: "center",
    padding: "8px 16px",
    color: "#808080",
    height: 40,
    gridTemplateColumns: "1fr 2fr 1fr 1fr 2fr 2.5fr 2fr",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },

  tableCol: {
    cursor: "pointer",
    color: "white",
    fontSize: 18,
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr 1fr 2fr 2.5fr 2fr",
    alignItems: "center",
    padding: "8px 16px 8px 16px",
    borderBottom: "1px solid",
    borderColor: theme.palette.background.darkBorder,
    transitionDuration: "300ms",

    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },

    "&>div": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },

    "& a": {
      textDecoration: "none",
      color: theme.palette.text.linkColor,
    },

    "& img": {
      borderRadius: 4,
      width: 24,
      height: 32,
    },

    "&:hover": {
      backgroundColor: theme.palette.background.whiteTransparent1,
    },
  },

  businessItemCol: {
    display: "flex",
    gap: 8,
  },

  statusCol: {
    display: "flex",
    gap: 8,
  },

  ownerCol: {
    color: "#1c7995",
  },

  familyCol: {},

  profitCol: {},

  selectCity: {
    minWidth: "160px",
    borderRadius: "30px",
    border: "none",
    fontFamily: "Philosopher",

    [theme.breakpoints.down("sm")]: {
      minWidth: "160px",
    },

    "& fieldset": {
      border: "none",
      borderRadius: "30px",
    },

    "& .MuiOutlinedInput-input": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "&>div:nth-of-type(2)": {
        display: "none",
      },

      "&:focus": {
        borderRadius: "30px",
      },
    },
  },
}));

export default styles;
