import { Box } from "@mui/material";
import clsx from "clsx";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import MarketItemDetailChart from "../MarketItemDetailChart";
import ActiveItemListing from "./ActiveItemListing";
import CompletedItemListing from "./CompletdItemList";
import ItemDetailPanel from "./ItemDetailPanel";

import { useAppDispatch } from "../../app/hooks";
import { getMarketItemDetail } from "../../reducers/market.slice";
import { MarketListedItem } from "../../types/MarketListedItem";
import { Market } from "../../constants/enum/market";

import useStyles from "./index.styles";
import { RarityType } from "../../constants/enum/map";

interface MarketItemDetailProps {
  setActionItem: React.Dispatch<
    React.SetStateAction<MarketListedItem | undefined>
  >;
  setShowingBids: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenMarketAuctionPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPurchasingType: React.Dispatch<
    React.SetStateAction<Market.ListType | undefined>
  >;
  setOpenTokenListPopup: React.Dispatch<React.SetStateAction<boolean>>;

  handleCancelListing: (item: MarketListedItem) => void;
  handleCompleteAuction: (item: MarketListedItem) => void;
  handleBuyItem?: (
    swapTokenId: number,
    item: MarketListedItem,
    itemListType: Market.ListType
  ) => void;

  rarityType: RarityType | null;
}

const MarketItemDetail: React.FC<MarketItemDetailProps> = ({
  setActionItem,
  setShowingBids,
  setOpenMarketAuctionPopup,
  setPurchasingType,
  setOpenTokenListPopup,
  handleCancelListing,
  handleCompleteAuction,
  handleBuyItem,
  rarityType,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  let { itemId } = useParams();

  useEffect(() => {
    const categoryId = Math.floor(parseInt(itemId || "0") / 10);
    const typeId = parseInt(itemId || "0") % 10;

    dispatch(
      getMarketItemDetail({
        categoryId: categoryId,
        typeId: typeId,
        rarityType,
      })
    );
  }, [dispatch, itemId, rarityType]);

  return (
    <Box className={classes.container}>
      <Box className={clsx(classes.row, classes.third)}>
        <MarketItemDetailChart
          sx={{
            minHeight: "470px",

            "#chart-container": {
              minHeight: "470px",
            },
          }}
        />

        <ItemDetailPanel
          itemId={parseInt(itemId || "0")}
          rarityType={rarityType}
        />
      </Box>

      <Box className={classes.row}>
        <ActiveItemListing
          setActionItem={setActionItem}
          setShowingBids={setShowingBids}
          setOpenMarketAuctionPopup={setOpenMarketAuctionPopup}
          handleCancelListing={handleCancelListing}
          handleCompleteAuction={handleCompleteAuction}
          setOpenTokenListPopup={setOpenTokenListPopup}
          setPurchasingType={setPurchasingType}
          handleBuyItem={handleBuyItem}
          rarityType={rarityType}
        />

        <CompletedItemListing rarityType={rarityType} />
      </Box>
    </Box>
  );
};

export default MarketItemDetail;
