import { useEffect, useState } from "react";
import { getWeb3 } from "../helper/contractFunctions";
import { CONFIRMATION_DELAY } from "../constants/const";

const useCurrentBlock = () => {
  const [currentBlock, setCurrentBlock] = useState<number>(-1);

  useEffect(() => {
    const web3 = getWeb3();

    const fetchCurrentBlock = async () => {
      try {
        const blockNumber = await web3.eth.getBlockNumber();
        setCurrentBlock(Number(blockNumber));
      } catch (error) {
        console.error("Error fetching current block number:", error);
      }
    };

    fetchCurrentBlock();

    const interval = setInterval(fetchCurrentBlock, CONFIRMATION_DELAY / 2);

    return () => clearInterval(interval);
  }, []);

  return currentBlock;
};

export default useCurrentBlock;
