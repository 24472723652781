import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_URL } from "../config/config";
import axios from "axios";
import { BusinessListType } from "../types/Business/BusinessListType";
import { ItemCategoryInfoList } from "../constants/const";

export interface BusinessState {
  isLoadingBusinessList: boolean;
  businessList: BusinessListType[];
}

const initialState: BusinessState = {
  isLoadingBusinessList: false,
  businessList: [],
};

export const getBusinessList = createAsyncThunk(
  "business/getBusinessList",
  async () => {
    const response = await axios.get(`${SERVER_URL}/business`);
    return response.data;
  }
);

export const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getBusinessList.pending, (state, { payload }) => {
      state.isLoadingBusinessList = true;
    });
    builder.addCase(getBusinessList.fulfilled, (state, { payload }) => {
      state.isLoadingBusinessList = false;
      state.businessList = payload.map((data: any) => ({
        id: data.id,
        userId: data.user_id,
        address: data.address,
        itemId: data.item_id,
        categoryId: Math.floor(data.item_id / 10),
        typeId: data.item_id % 10,
        city: data.city,
        removed: data.removed,
        status: data.status ? 1 : 0,
        enabledStatus: ItemCategoryInfoList[Math.floor(data.item_id / 10)]
          ?.activeStatus![data.item_id % 10]
          ? 1
          : 0,
        timestamp: data.timestamp,
        ownerName: data.ownerName,
        familyInfo: {
          id: data.familyInfo?.id || -1,
          name: data.familyInfo?.name || "",
          logo: data.familyInfo?.logo || "",
        },
        profit: data.profit,
      })) as BusinessListType[];
    });
    builder.addCase(getBusinessList.rejected, (state) => {
      state.isLoadingBusinessList = false;
    });
  },
});

export default businessSlice.reducer;
