import styled from "@emotion/styled";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  TextField,
} from "@mui/material";
import clsx from "clsx";
import EmojiPicker from "emoji-picker-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import "firebase/firestore";
import {
  DocumentData,
  collection,
  getFirestore,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

import Logo from "../Logo";

import { useAppSelector } from "../../app/hooks";
import useWallet from "../../hook/useWallet";

import {
  CHAT_COUNT_PER_PAGE,
  FIRESTORE_GLOBAL_CHAT_DOCUMENT,
} from "../../constants/const";
import { ChatType, FamilyRole } from "../../constants/enum/enum";
import { FamilyInfo } from "../../types/FamilyInfo";
import { ChatMessage } from "../../types/Firebase/ChatMessage";
import { firebaseApp } from "../../utils/firebase";

import emojiIcon from "../../assets/imgs/emoji.png";
import messenger from "../../assets/imgs/family/messenger.png";
import messengerDark from "../../assets/imgs/family/messenger_dark.png";
import person from "../../assets/imgs/family/person.png";
import personDark from "../../assets/imgs/family/person_dark.png";

import { toast } from "react-toastify";
import { Messages } from "../../constants/messages";
import { useStyles } from "./index.styles";

const firestore = getFirestore(firebaseApp);

interface ChatPanelProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const EmojiPickerContainer = styled.div`
  position: absolute;
  bottom: 85px;
  .emoji-picker-react {
    left: 0;
    z-index: 5;
    --epr-category-label-bg-color: #373b519c;
    --epr-bg-color: #393b47;
    --epr-search-input-bg-color: #4b4f619c;
    --epr-search-input-bg-color-active: #373b519c;
  }
`;

const ChatHistoryItem = ({ message }: { message: DocumentData }) => {
  const { classes } = useStyles();

  const chatMessage = useMemo(() => {
    return message.data() as ChatMessage;
  }, [message]);

  return (
    <Box className={classes.chatList}>
      <Box component={"span"} className={classes.role}>
        {chatMessage.role !== -1 && chatMessage.role >= 4
          ? "Capo"
          : FamilyRole[chatMessage.role]}
      </Box>{" "}
      <Box
        component={"img"}
        src={chatMessage.family?.logo ? chatMessage.family?.logo : ""}
        className={classes.logoImg}
      />{" "}
      <Box component={"span"} className={classes.name}>
        <Link to={`/profile/${chatMessage.userName}`}>
          {chatMessage.userName}:{" "}
        </Link>
      </Box>
      <Box component={"span"} className={classes.chatText}>
        {chatMessage.message}
      </Box>
    </Box>
  );
};

const GlobalChat = ({
  messages,
  loadMore,
  loading,
}: {
  messages: any;
  loadMore: () => void;
  loading: boolean;
}) => {
  const { classes } = useStyles();
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const [messagesList, setMessagesList] = useState<DocumentData[]>([]);

  const handleScroll = () => {
    if (chatBodyRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatBodyRef.current;

      // Check if the scroll is at the top
      if (-scrollTop + clientHeight === scrollHeight - 1) {
        if (!loading) loadMore();
      }
    }
  };

  useEffect(() => {
    const element = chatBodyRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading]); // Dependency array includes loading to manage adding/removing listeners properly

  useEffect(() => {
    if (!messages?.docs) return;

    setMessagesList(messages.docs);
  }, [messages]);

  return (
    <Box className={classes.chatBody}>
      <Box
        className={clsx(classes.chatBodyContainer, classes.containerMain)}
        ref={chatBodyRef}
      >
        {messagesList.map((document: DocumentData, index: number) => {
          return <ChatHistoryItem key={document.id} message={document} />;
        })}
      </Box>

      {loading && (
        <Box className={classes.containerLoading}>
          <CircularProgress size={32} />
        </Box>
      )}
    </Box>
  );
};

const FamilyChat = ({
  messages,
  loading,
  loadMore,
  setMessageCollection,
}: {
  messages: any;
  loading: boolean;
  loadMore: () => void;
  setMessageCollection: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { classes } = useStyles();

  const [family, setFamily] = useState<FamilyInfo>();
  const [expanded, setExpanded] = useState(false);

  const { familyList } = useAppSelector((state) => state.family);
  const { myProfile } = useAppSelector((state) => state.profile);

  const chatBodyRef = useRef<HTMLDivElement>(null);
  const [messagesList, setMessagesList] = useState<DocumentData[]>([]);

  const handleScroll = useCallback(() => {
    if (chatBodyRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatBodyRef.current;

      // Check if the scroll is at the top
      if (-scrollTop + clientHeight === scrollHeight - 1) {
        if (!loading) loadMore();
      }
    }
  }, [loadMore, loading]);

  useEffect(() => {
    const element = chatBodyRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, handleScroll]); // Dependency array includes loading to manage adding/removing listeners properly

  useEffect(() => {
    if (!messages?.docs) return;

    setMessagesList(messages.docs);
  }, [messages]);

  useEffect(() => {
    if (!myProfile.userFamily?.name) return;

    const familyName = myProfile.userFamily?.name;

    const familyIndex =
      familyList.findIndex((family, index) => family.name === familyName) || 0;

    setFamily(familyList[familyIndex]);
    setMessageCollection(familyList[familyIndex].name || "");
  }, []);

  const handleAccordionToggle = () => {
    setExpanded(!expanded);
  };

  const handleChangeFamily = (index: number) => {
    handleAccordionToggle();

    setFamily(familyList[index]);

    setMessageCollection(familyList[index].name || "");
  };

  return (
    <Box className={classes.chatBody}>
      <Box className={classes.selectFamilyBox}>
        <Accordion
          className={classes.selectFamily}
          expanded={expanded}
          onChange={handleAccordionToggle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={clsx(
              classes.dropdownValue,
              expanded ? null : classes.fullBorderR
            )}
          >
            {family ? (
              <Box className={classes.valueBox}>
                <Box></Box>
                <Box>
                  {family.logo ? (
                    <Box component={"img"} src={family.logo} />
                  ) : (
                    <Logo />
                  )}

                  {family.name}
                </Box>
                <Box>{family.members}</Box>
              </Box>
            ) : (
              <Box>Select family</Box>
            )}
          </AccordionSummary>

          <AccordionDetails className={classes.detailsBody}>
            {familyList.map((family, index) => {
              return (
                <Box
                  className={classes.selectDetail}
                  onClick={() => {
                    handleChangeFamily(index);
                  }}
                  key={index}
                >
                  <Box></Box>
                  <Box>
                    {family.logo ? (
                      <Box component={"img"} src={family.logo} />
                    ) : (
                      <Logo />
                    )}
                    {family.name}
                  </Box>
                  <Box>{family.members}</Box>
                </Box>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box
        className={clsx(classes.chatBodyContainer, classes.containerFamily)}
        ref={chatBodyRef}
      >
        {messagesList.map((document: DocumentData, index: number) => {
          return <ChatHistoryItem key={document.id} message={document} />;
        })}
      </Box>

      {loading && (
        <Box className={classes.containerLoading}>
          <CircularProgress size={32} />
        </Box>
      )}
    </Box>
  );
};

const ChatPanel = (props: ChatPanelProps) => {
  const { classes } = useStyles();
  const { myProfile } = useAppSelector((state) => state.profile);
  const { account, connectWallet } = useWallet();

  const [chatType, setChatType] = useState<ChatType>(ChatType.Global);
  const [message, setMessage] = useState("");
  const [messageCount, setMessageCount] = useState(CHAT_COUNT_PER_PAGE);
  const [messageCollection, setMessageCollection] = useState(
    FIRESTORE_GLOBAL_CHAT_DOCUMENT
  );
  const [messageEnabled, setMessageEnabled] = useState(true);
  const [showEmojiPanel, setShowEmojiPanel] = useState(false);

  const collectionRef = collection(firestore, messageCollection);
  const [messages, loading] = useCollection(
    query(collectionRef, orderBy("timestamp", "desc"), limit(messageCount))
  );

  const onEmojiClick = (emoji: any, event: any) => {
    setMessage((prevValue) => prevValue + emoji.emoji);

    setShowEmojiPanel(false);
  };

  const onShowEmojiClick = () => {
    setShowEmojiPanel(!showEmojiPanel);
  };

  const handleSendMessage = async () => {
    // Check if wallet is connect, if not show connect wallet modal
    if (!account) {
      props.setOpen(false);
      connectWallet();
      return;
    }

    toast.info(Messages.GLOBAL.CHAT.CHAT_DISABLED);
    return;

    // if (!myProfile.name) return;

    // if (message === "") return;

    // if (!messageEnabled) return;

    // setMessage("");

    // const messageData: ChatMessage = {
    //   message: message,
    //   userName: myProfile.name,
    //   userId: myProfile.id || 0,
    //   role: myProfile.position || 0,
    //   family: myProfile.userFamily || {},
    //   timestamp: new Date().getTime() / 1000,
    // };

    // addDoc(collectionRef, messageData);
  };

  const loadMore = () => {
    setMessageCount((prevValue) => prevValue + CHAT_COUNT_PER_PAGE);
  };

  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    if (chatType === ChatType.Global) {
      setMessageCollection(FIRESTORE_GLOBAL_CHAT_DOCUMENT);
    } else {
      setMessageCollection(myProfile.userFamily?.name || "Empty");
    }
    setMessageCount(CHAT_COUNT_PER_PAGE);
  }, [chatType, myProfile.userFamily?.name]);

  useEffect(() => {
    if (chatType === ChatType.Family) {
      if (messageCollection === "Empty") {
        setMessageEnabled(false);
      } else {
        setMessageEnabled(true);
      }
    } else {
      setMessageEnabled(true);
    }
  }, [messageCollection, chatType]);

  return (
    <div>
      <React.Fragment key={"bottom"}>
        <Drawer anchor="right" open={props.open} className={classes.body}>
          <Box className={classes.container} role="presentation">
            <Box className={classes.header}>
              <Box className={classes.expandIcon}>
                <Button
                  onClick={() => props.setOpen(false)}
                  onKeyDown={() => props.setOpen(false)}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </Box>

              <Box className={classes.subHeader} onClick={() => setChatType(0)}>
                <Box
                  component={"img"}
                  src={chatType === 0 ? messenger : messengerDark}
                  className={classes.messengerImg}
                />
                <Box
                  className={clsx(chatType === 1 ? classes.disabledText : null)}
                >
                  Main chat
                </Box>
              </Box>
              <Divider orientation="vertical" className={classes.divider} />
              <Box className={classes.subHeader} onClick={() => setChatType(1)}>
                <Box
                  component={"img"}
                  src={chatType === 1 ? person : personDark}
                  className={classes.messengerImg}
                />
                <Box
                  className={clsx(chatType === 0 ? classes.disabledText : null)}
                >
                  Family chat
                </Box>
              </Box>
            </Box>
            <Box className={classes.dividerHorizontalBox}>
              <Divider className={classes.dividerHorizontal} />
            </Box>

            {chatType === 0 ? (
              <GlobalChat
                messages={messages}
                loadMore={loadMore}
                loading={loading}
              />
            ) : (
              <FamilyChat
                messages={messages}
                loadMore={loadMore}
                loading={loading}
                setMessageCollection={setMessageCollection}
              />
            )}

            {showEmojiPanel ? (
              <EmojiPickerContainer>
                <EmojiPicker onEmojiClick={onEmojiClick} />
              </EmojiPickerContainer>
            ) : (
              ""
            )}
            <Box className={classes.inputBox}>
              <TextField
                value={message}
                onChange={handleMessageChange}
                className={classes.chatInput}
                placeholder={
                  !account
                    ? "Please connect your wallet"
                    : !myProfile.name
                    ? "No profile"
                    : "Type message..."
                }
                disabled={!myProfile.name}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
              />

              <Box
                component={"img"}
                onClick={onShowEmojiClick}
                src={emojiIcon}
                alt="Emoji Button"
                className={classes.emojiIcon}
              />
              <Button
                className={classes.sendBut}
                onClick={handleSendMessage}
                disabled={!messageEnabled}
              >
                {!account ? "Connect" : !myProfile.name ? "No profile" : "Send"}
              </Button>
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default ChatPanel;
