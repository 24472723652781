// Define an enum for the bet types
export enum RouletteBetType {
  ZERO = "ZERO",
  DZERO = "DZERO",
  R1 = "R1",
  R2 = "R2",
  R3 = "R3",
  S1 = "S1",
  S2 = "S2",
  S3 = "S3",
  H1 = "H1",
  H2 = "H2",
  BLACK = "BLACK",
  RED = "RED",
  EVEN = "EVEN",
  ODD = "ODD",
}

export enum RouletteBetStatus {
  NOT_REQUESTED,
  REQUESTED,
  FINISHING,
  SPINNING,
  FINISHED,
}
