import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  wheelContainer: {
    position: "relative",
    height: "250px",
    width: "min-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    padding: "8px",
    background: theme.palette.background.modalBg,
    border: "1px solid",
    borderColor: "black",
    boxShadow:
      "0px 0px 2px 1px rgba(0, 0, 0, 0.4), 0px 0px 4px 8px rgba(0, 0, 0, 0.4)",
  },

  wheelBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },

  rouletteWheel: {
    borderRadius: "50%",
    background: `url("../../assets/imgs/roulette/roulette_1.png")`,
    backgroundSize: "cover",
    shapeOutside: "circle(125px)",
    boxShadow:
      "0px 0px 2px 1px rgba(0, 0, 0, 0.4), 0px 0px 4px 8px rgba(0, 0, 0, 0.4)",
    overflow: "visible",
    position: "relative",
    transition: "transform 11.5s ease-out",
    width: "250px",
    height: "250px",
  },

  rouletteInner: {
    animation: "$rotate 24s infinite linear",
    width: "100%",
    height: "100%",
    position: "relative",
    background: `url("../../assets/imgs/roulette/roulette_2.png")`,
    backgroundSize: "100% 100%",
    willChange: "transform",

    "& svg": {
      position: "absolute",
      top: 0,
      left: 0,
    },

    "& circle": {
      cursor: "pointer",
      fill: "transparent",
    },
  },
  layer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    willChange: "transform",
  },
  ball: {
    position: "absolute",
    borderRadius: "50%",
    background: "#fff radial-gradient(circle at 5px 5px, #fff, #444)",
    boxShadow: "1px 1px 4px #000",
    willChange: "transform",
    transition: "transform 0.5s ease-in",
    width: "10px",
    height: "10px",
  },
  ballContainer: {
    width: "10px",
    height: "10px",
    transformOrigin: "125px 125px",
    willChange: "transform",
    transition: "transform 11.5s ease-out",
  },

  svg: {
    position: "absolute",
    top: 0,
    left: 0,
  },

  circle: {
    cursor: "pointer",
    fill: "transparent",
  },

  "@keyframes rotate": {
    "0%": {
      transform: "rotateZ(0deg)",
    },
    "100%": {
      transform: "rotateZ(360deg)",
    },
  },
}));

export default styles;
