import { createTheme, TypeBackground, TypeText } from "@mui/material/styles";

export interface CustomTypeBackground extends TypeBackground {
  defaultBg?: string;
  containerBg?: string;
  cardBg?: string;
  cardBgTransparent?: string;
  textBg?: string;
  profileItemBg?: string;
  transparent?: string;
  modalBg?: string;
  whiteTransparent?: string;
  whiteTransparent1?: string;

  blackBorder?: string;
  darkBorder?: string;
  defaultBorder?: string;
  secondaryBorder?: string;
  profileItemBorder?: string;

  createButtonBg?: string;
  createButtonBgHover?: string;
  createButtonGradient?: string;

  gray?: string;
  grayHover?: string;
  graySelected?: string;

  lightGray?: string;
  lightGrayHover?: string;

  statusAlive?: string;

  defaultButton?: string;
  secondaryButton?: string;
  dangerButton?: string;
  brightRed?: string;

  orangeGradient?: string;
  yellowGradient?: string;
  redGradient?: string;
  grayGradient?: string;
  blueGradient?: string;
  greenGradient?: string;
}

export interface CustomTypeText extends TypeText {
  grayColor?: string;
  brightGray?: string;
  linkColor?: string;
  containerColor?: string;
  brightYellow?: string;
  purple?: string;
  primaryGradient?: string;
  secondaryGradient?: string;
  goldenGradient?: string;
}

declare module "@mui/material/styles" {
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPaletteOptions {}
}

interface CustomPalette {
  background: CustomTypeBackground;
  text: CustomTypeText;
}

interface CustomPaletteOptions {
  background?: CustomTypeBackground;
  text?: CustomTypeText;
}

const theme: any = createTheme({
  palette: {
    background: {
      defaultBg: "#0d0f11",
      containerBg: "#16191a",
      cardBg: "#171a1b",
      cardBgTransparent: "#171a1bee",
      textBg: "#232727",
      profileItemBg: "#15181a",
      transparent: "#00000000",
      modalBg: "#1d1d1d",
      whiteTransparent: "#ffffff47",
      whiteTransparent1: "#ffffff12",

      blackBorder: "#151515",
      darkBorder: "#23272a70",
      defaultBorder: "#23272a",
      secondaryBorder: "#292929",
      profileItemBorder: "#464545",

      createButtonBg: "#2eb282",
      createButtonBgHover: "#50d5a5",
      createButtonGradient:
        "linear-gradient(135deg, rgba(37,58,75,1) 0%, rgba(80,125,160,1) 100%)",

      gray: "#1c1e1f",
      grayHover: "#2c2f31",
      graySelected: "#4c5154",

      lightGray: "#363a3b",
      lightGrayHover: "#4a5051",

      statusAlive: "#0cac10",

      defaultButton: "#18a86b",
      secondaryButton: "#0b679f",
      dangerButton: "#9f0b0b",
      brightRed: "#ff0017",

      orangeGradient: "linear-gradient(135deg, #fbb27a 0%, #b83b35 100%);",
      yellowGradient: "linear-gradient(135deg, #fab17b 0%, #f6c76d 100%);",
      blueGradient:
        "linear-gradient(135deg, rgba(8,196,156,1) 0%, rgba(0,138,185,1) 100%);",
      redGradient:
        "linear-gradient(135deg, rgba(255,136,124,1) 0%, rgba(182,50,95,1) 100%);",

      grayGradient:
        "linear-gradient(135deg, rgba(74,74,74,1) 0%, rgba(51,51,51,1) 100%);",

      greenGradient:
        "linear-gradient(180deg, rgba(0,173,115,1) 0%, rgba(17,179,132,1) 100%);",
    } as CustomTypeBackground,

    text: {
      primary: "#ffffff",
      secondary: "#646464",

      grayColor: "#999999",
      containerColor: "#394246",
      linkColor: "#1c7995",
      brightGray: "#818181",

      brightYellow: "#EFB221",
      purple: "#743699",
      primaryGradient: "linear-gradient(90deg, #caf881 0%, #a0e99a 100%)",
      secondaryGradient: "linear-gradient(90deg, #d09292 0%, #c82270 100%)",
      goldenGradient:
        "linear-gradient(135deg, rgba(108,101,4,1) 0%, rgba(212,210,155,1) 50%, rgba(108,101,4,1) 100%)",
    } as CustomTypeText,
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1400,
    },
  },
});

export default theme;
