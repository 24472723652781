import config from "../config/config";
import { ItemCategoryInfo } from "../types/ItemCategoryInfo";
import { BusinessStatus } from "./enum/business";
import { ItemCategory } from "./enum/enum";

export const FAMILY_BANK_WITHDRAW_COOLDOWN = 604800;

export const GAME_CASH_ADDRESS = "0x0000000000000000000000000000000000000001";

export const OTC_TRADE_MAX_ITEM = 5;

export const PREMIUM_KEY_CLAIM_COUNT = [2, 3, 4, 5, 6, 7, 8, 9, 10];

export const ALLEGIANCE_CREATE_CRATE_COST = 25;

export const PREMIUM_KEY_CLAIM_CYCLE = 60 * 60 * 24 * 30; // 30 days

export const MAX_PROFILE_DESCRIPTION_LEN = 2000;

export const AUTH_TOKEN_HEADER = "AUTH_";

export const ACTIVITY_TRACKING_TS_KEY = "activity_tracking_ts";

export const ACTIVITY_LIMIT_DURATION = 60;

export const FAMILY_PREMIUM_THRESHOLD = 50;

export const BUSINESS_CATEGORY_ID = 4;

export const MARKET_ITEM_LISTING_DURATION = [0.5, 3, 12, 72];

export const BULLET_AIRDROP_INTERVAL = 60 * 60 * 24 * 7; // 1 week

export const BULLET_AIRDROP_INTERVAL_TEMP = 60 * 60 * 3; // 3 hours

export const MAFIA_BUY_FEE = 4.7;

export const MAFIA_SELL_FEE = 16.6;

export const PROFILE_PRICE_IN_MAFIA = 2000;

export const FAMILY_PRICE_IN_MAFIA = 500000;

export const CRATE_PRICE = 19.99;

export const MAX_PROFILE_NAME_LEN = 14;

export const MAX_FAMILY_NAME_LEN = 20;

export const LOGO_WIDTH = 500;

export const LOGO_HEIGHT = 800;

export const BG_WIDTH = 1920;

export const BG_HEIGHT = 750;

// Distribution for top 5 families
export const VaultDistributionRatio = [30, 25, 20, 15, 10, 5, 5, 5];

export const TotalVaultPayoutPercent = 0.1;

// Payout for 3 bosses, DON, Consigliere, Capodecina
export const VAULT_PAYOUT_RATIO = [55, 25, 20];

export const CONFIRMATION_DELAY = 3000;

// Maximum invitation count per specific duration
export const INVITE_LIMIT_COUNT = 10;

// Invitation limit duration
export const INVITE_LIMIT_DURATION = 3600;

// Family Bank Yield amount
export const FAMILY_BANK_YIELD_PERCENT = 0.5;

// Document name for global chat
export const FIRESTORE_GLOBAL_CHAT_DOCUMENT = "global";

// Chat message count per page from firebase
export const CHAT_COUNT_PER_PAGE = 15;

export const NOTIFICATION_COUNT_PER_PAGE = 10;

export const ItemCategoryInfoList: ItemCategoryInfo[] = [
  {
    id: 0,
    name: "Cash",
    icon: "cashitem.png",
    values: [
      50000, 150000, 250000, 350000, 500000, 1000000, 2500000, 4000000, 5000000,
      10000000,
    ],
    icons: [],
    currency: "$",
    chances: [28, 18, 15, 12, 9, 7, 5, 3, 2, 1],
    inventoryWorth: [5, 15, 25, 35, 50, 100, 250, 400, 500, 1000],
    profileWorth: [2.5, 7.5, 12.5, 17.5, 25, 50, 125, 200, 250, 500],
  },

  {
    id: 1,
    name: "Bullet",
    icon: "bulletitem.png",
    icons: [],

    values: [250, 500, 750, 1500, 3500, 5000, 7000, 15000, 20000, 30000],
    chances: [28, 18, 15, 12, 9, 7, 5, 3, 2, 1],
    inventoryWorth: [25, 50, 75, 150, 350, 500, 700, 1500, 2000, 3000],
    profileWorth: [2.5, 5, 7.5, 15, 35, 50, 70, 150, 200, 300],
  },

  {
    id: 2,
    name: "Health",
    icon: "healthitem.png",
    icons: [],

    values: [50, 100, 150, 200, 250, 300, 350, 500, 750, 1000],
    chances: [28, 18, 15, 12, 9, 7, 5, 3, 2, 1],
    inventoryWorth: [100, 200, 300, 400, 500, 600, 700, 1000, 1500, 2000],
    profileWorth: [10, 20, 30, 40, 50, 60, 70, 100, 150, 200],
  },

  {
    id: 3,
    name: "ShopItem",
    icon: "shopitem.png",
    values: [
      "Hand Gun Colt",
      "Remington",
      "Thompson",
      "Molotov cocktail",
      "Grenade",
      "Motorcycle",
      "Bullet proof vest",
      "Bullet proof suit",
      "Armored car",
      "Douglas M-3",
    ],
    icons: [
      "shop-item-colt.png",
      "shop-item-remington.png",
      "shop-item-tommygun.png",
      "shop-item-molotov.png",
      "shop-item-grenade.png",
      "shop-item-rmotorcycle.png",
      "shop-item-vest.png",
      "shop-item-suit.png",
      "shop-item-amorcar.png",
      "shop-item-douglas.png",
    ],
    chances: [15, 15, 14, 12, 11, 10, 8, 7, 5, 3],
    inventoryWorth: [5, 15, 24, 35, 50, 100, 250, 500, 750, 850],
    profileWorth: [0.5, 1.5, 2.4, 3.5, 5, 10, 25, 50, 75, 85],
  },

  {
    id: 4,
    name: "Business",
    icon: "businessitem.png",
    icons: [
      "carcrusheritem.png",
      "shopitem-new.png",
      "bankitem.png",
      "hospitalitem.png",
      "detectiveagencyitem.png",
      "boozeitem.png",
      "narcoticsitem.png",
      "slotmachineitem.png",
      "rouletteitem.png",
      "bulletfactoryitem.png",
    ],
    values: [
      "Car crusher",
      "Gunstore",
      "Bank",
      "Hospital",
      "Detective Agency",
      "Booze warehouse",
      "Narcotics warehouse",
      "Slotmachine",
      "Roulette",
      "Bullet factory",
    ],
    businessStatus: [
      BusinessStatus.Weapons,
      BusinessStatus.Weapons,
      BusinessStatus.Services,
      BusinessStatus.Services,
      BusinessStatus.Services,
      BusinessStatus.Services,
      BusinessStatus.Services,
      BusinessStatus.Casinos,
      BusinessStatus.Casinos,
      BusinessStatus.Weapons,
    ],
    activeStatus: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      true,
      false,
    ],
    businessLinks: ["", "", "", "", "", "", "", "", "roulette", ""],

    chances: [17, 17, 16, 16, 12, 8, 6, 3, 3, 2, 0.1, 0.1],
    inventoryWorth: [
      1500, 5000, 3000, 5000, 2000, 8000, 8000, 9500, 11000, 15000, 15000,
      15000,
    ],
    profileWorth: [
      150, 500, 300, 500, 200, 800, 800, 950, 1100, 1500, 1500, 1500,
    ],
  },

  {
    id: 5,
    name: "Bodyguards",
    icon: "bodyguarditem.png",
    values: [
      "Lvl 3 - Johnny",
      "Lvl 3 - Jim",
      "Lvl 3 - Sam",
      "Lvl 5 - Johnny",
      "Lvl 6 - Jim",
      "Lvl 6 - Sam",
      "Lvl 7 - Frank",
      "Lvl 8 - Johnny",
      "Lvl 10 - Sam",
      "Lvl 10 - Frank",
    ],
    icons: [
      "johnny-bgsmall.png",
      "jim-bgsmall.png",
      "sam-bgsmall.png",
      "johnny-bgsmall.png",
      "jim-bgsmall.png",
      "sam-bgsmall.png",
      "frank-bgsmall.png",
      "johnny-bgsmall.png",
      "sam-bgsmall.png",
      "frank-bgsmall.png",
    ],
    chances: [10, 10, 10, 20, 20, 10, 10, 5, 4, 1],
    inventoryWorth: [100, 150, 200, 300, 400, 600, 1000, 1500, 2000, 2500],
    profileWorth: [10, 15, 20, 30, 40, 60, 100, 150, 200, 250],
  },

  {
    id: 6,
    name: "Helper Credits",
    icon: "helperitem.png",
    icons: [],

    values: [100, 250, 500, 1000, 1500, 2000, 2500, 3000, 3500, 5000],
    chances: [10, 10, 10, 20, 20, 10, 10, 5, 4, 1],
    inventoryWorth: [10, 25, 50, 100, 150, 200, 250, 300, 350, 500],
    profileWorth: [1, 2.5, 5, 10, 15, 20, 25, 30, 35, 50],
  },

  {
    id: 7,
    name: "Car",
    icon: "carsitem.png",
    currency: "$",
    values: [
      5000, 15000, 35000, 75000, 100000, 150000, 250000, 350000, 450000, 500000,
    ],
    icons: [
      "car-item-5.png",
      "car-item-15.png",
      "car-item-35.png",
      "car-item-75.png",
      "car-item-100.png",
      "car-item-150.png",
      "car-item-250.png",
      "car-item-350.png",
      "car-item-450.png",
      "car-item-500.png",
    ],
    chances: [10, 10, 10, 20, 20, 10, 10, 5, 4, 1],
    inventoryWorth: [20, 50, 100, 150, 200, 250, 450, 500, 550, 600],
    profileWorth: [2, 5, 10, 15, 20, 25, 45, 50, 55, 60],
  },

  {
    id: 8,
    name: "Wrapped NFTs",
    icon: "key.png",
    values: ["Game Key", "Game OG"],
    icons: ["key.png", "OGNFTIMAGE.png"],
    chances: [10, 10],
    inventoryWorth: [150, 750],
    profileWorth: [15, 75],
  },

  {
    id: 9,
    name: "Keys",
    icon: "key.png",
    values: [
      "1 Game Key",
      "3 Game Keys",
      "5 Game Keys",
      "7 Game Keys",
      "10 Game Keys",
      "15 Game Keys",
      "25 Game Keys",
      "50 Game Keys",
      "75 Game Keys",
      "100 Game Keys",
    ],
    icons: [
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
      "key.png",
    ],
    chances: [20, 20, 14, 12, 11, 10.5, 8, 3, 1, 0.5],
    inventoryWorth: [150, 450, 750, 1050, 1500, 2250, 3250, 7500, 11250, 15000],
    profileWorth: [15, 45, 75, 1050, 150, 225, 325, 750, 1125, 1500],
  },

  {
    id: 10,
    name: "MAFIA",
    icon: "logo.png",
    values: [
      10000, 15000, 20000, 30000, 50000, 100000, 250000, 500000, 1000000,
      5000000,
    ],
    icons: [],
    chances: [22, 20, 17, 14, 11, 10.5, 3.4, 1.5, 0.5, 0.1],
    inventoryWorth: [4.5, 6.75, 9, 13.5, 22.5, 45, 112.5, 225, 450, 2250],
    profileWorth: [0.45, 0.675, 0.9, 1.35, 2.25, 4.5, 11.25, 22.5, 45, 225],
  },

  {
    id: 11,
    name: "OG NFTs",
    icon: "OGNFTIMAGE.png",
    values: [
      "1 OG NFT",
      "1 OG NFT",
      "1 OG NFT",
      "1 OG NFT",
      "1 OG NFT",
      "1 OG NFT",
      "3 OG NFTs",
      "3 OG NFTs",
      "7 OG NFTs",
      "10 OG NFTs",
    ],
    icons: [
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
      "OGNFTIMAGE.png",
    ],
    chances: [22, 20, 17, 14, 11, 10.5, 3.4, 1.5, 0.5, 0.1],
    inventoryWorth: [4.5, 6.75, 9, 13.5, 22.5, 45, 112.5, 225, 450, 2250],
    profileWorth: [0.45, 0.675, 0.9, 1.35, 2.25, 4.5, 11.25, 22.5, 45, 225],
  },

  {
    id: 12,
    name: "Noti Credits",
    icon: "notificationitem.png",
    values: [
      "1 Noti Credit",
      "1 Noti Credit",
      "1 Noti Credit",
      "1 Noti Credit",
      "1 Noti Credit",
      "1 Noti Credit",
      "3 Noti Credits",
      "3 Noti Credits",
      "7 Noti Credits",
      "10 Noti Credits",
    ],
    icons: [
      "notificationitem.png",
      "notificationitem.png",
      "notificationitem.png",
      "notificationitem.png",
      "notificationitem.png",
      "notificationitem.png",
      "notificationitem.png",
      "notificationitem.png",
      "notificationitem.png",
      "notificationitem.png",
    ],
    chances: [22, 20, 17, 14, 11, 10.5, 3.4, 1.5, 0.5, 0.1],
    inventoryWorth: [10, 25, 50, 100, 150, 200, 250, 300, 350, 500],
    profileWorth: [1, 2.5, 5, 10, 150, 20, 25, 30, 35, 50],
  },

  {
    id: ItemCategory.LANDSLOT,
    name: "Land Slot",
    icon: "land.png",
    values: ["Land Slot"],
    icons: ["land.png"],
    chances: [22, 20, 17, 14, 11, 10.5, 3.4, 1.5, 0.5, 0.1],
    inventoryWorth: [10, 25, 50, 100, 150, 200, 250, 300, 350, 500],
    profileWorth: [1, 2.5, 5, 10, 150, 20, 25, 30, 35, 50],
  },

  {
    id: ItemCategory.BUSINESS_EXTRA,
    name: "Business",
    icon: "businessitem.png",
    icons: [
      "jackpothall.png",
      "lotteryhall.png",
      "bankitem.png",
      "hospitalitem.png",
      "detectiveagencyitem.png",
      "boozeitem.png",
      "narcoticsitem.png",
      "slotmachineitem.png",
      "rouletteitem.png",
      "bulletfactoryitem.png",
    ],
    values: [
      "Jackpot hall",
      "Lottery hall",
      "Bank",
      "Hospital",
      "Detective Agency",
      "Booze warehouse",
      "Narcotics warehouse",
      "Slotmachine",
      "Roulette",
      "Bullet factory",
    ],
    businessStatus: [BusinessStatus.Casinos, BusinessStatus.Casinos],

    activeStatus: [false, false],

    chances: [0.1, 0.1, 17, 17, 16, 16, 12, 8, 6, 3, 3, 2, 0.1, 0.1],
    inventoryWorth: [
      15000, 15000, 3000, 5000, 2000, 8000, 8000, 9500, 11000, 15000, 15000,
      15000,
    ],
    profileWorth: [
      1500, 1500, 300, 500, 200, 800, 800, 950, 1100, 1500, 1500, 1500,
    ],
  },
];

export const WealthRange = [
  {
    from: 0,
    to: 100000,
    category: "Straydog",
  },

  {
    from: 100000,
    to: 500000,
    category: "Poor",
  },

  {
    from: 500000,
    to: 5000000,
    category: "Nouveau Rich",
  },

  {
    from: 5000000,
    to: 10000000,
    category: "Rich",
  },

  {
    from: 10000000,
    to: 25000000,
    category: "Very Rich",
  },

  {
    from: 25000000,
    to: 100000000,
    category: "Too rich to be true",
  },

  {
    from: 100000000,
    to: 500000000,
    category: "Filthy rich",
  },

  {
    from: 500000000,
    to: 100000000000,
    category: "Richer than God",
  },
];

export const City = [
  "Chicago",
  "Detroit",
  "New York",
  "Miami",
  "Las Vegas",
  "Medellin",
  "Bogota",
  "Caracas",
  "Palermo",
  "Messina",
  "Napoli",
];

export const CitySimple = [
  "CHI",
  "DET",
  "NYC",
  "MIA",
  "LVG",
  "MED",
  "BOG",
  "CAR",
  "PAL",
  "MES",
  "NAP",
];

export const WealthCategory = [
  "Straydog",
  "Poor",
  "Nouveau Rich",
  "Rich",
  "Very rich",
  "Too rich to be true",
  "Filthy rich",
  "Richer than God",
];

export const RankCategory = [
  "Nobody",
  "Errand boy",
  "Pickpocket",
  "Thief",
  "Associate",
  "Soldier",
  "Mobster",
  "Swindler",
  "Made man",
  "Hitman",
  "Assassin",
  "Local Chief",
  "Chief",
  "Consigliere",
  "Capo bastone",
  "Don",
  "Godfather",
];

export const PromotionMoney = [
  ["Errand boy", 1000],
  ["Pickpocket", 5000],
  ["Thief", 10000],
  ["Associate", 25000],
  ["Soldier", 35000],
  ["Mobster", 45000],
  ["Swindler", 50000],
  ["Made man", 75000],
  ["Hitman", 100000],
  ["Assassin", 250000],
  ["Local Chief", 500000],
  ["Chief", 1000000],
  ["Consigliere", 2000000],
  ["Capo bastone", 5000000],
];

export const WalletErrorMsg = {
  Rejected: "Returned error: User rejected the request.",
  Indexing: "Returned error: transaction indexing is in progress",
};

export const NavBarPages: {
  title: string;
  path?: string;
  icon: string;
  plsDisabled?: boolean;
  blank?: boolean;
  new?: boolean;
  subNav?: {
    title: string;
    path?: string;
    icon: string;
    blank?: boolean;
    new?: boolean;
  }[];
}[] = [
  {
    title: "Players & Families",
    path: "/family",
    icon: "player.png",
  },
  {
    title: "The Map",
    path: "/map",
    icon: "map.png",
    plsDisabled: false,
    new: false,
  },
  {
    title: "The Businesses",
    path: "/businesses",
    icon: "business.png",
    plsDisabled: false,
    new: true,
  },
  {
    title: "Crate",
    path: "/crate",
    icon: "crate.png",
    plsDisabled: false,
  },
  {
    title: "Exchange",
    path: "/exchange",
    icon: "exchange.png",
  },
  {
    title: "Market",
    path: "/market",
    icon: "market.png",
  },
  {
    title: "More",
    icon: "more.png",
    subNav: [
      {
        title: "Vault",
        path: "/vault",
        icon: "vault.png",
      },
      {
        title: "Game Wiki",
        path: config.gitbookUrl,
        icon: "docs.png",
        blank: true,
      },
      {
        title: "Buy $MAFIA",
        path: "/buy",
        icon: "buy.png",
      },

      {
        title: "Marketing DAO",
        path: "/dao",
        icon: "dao.png",
        new: true,
      },
    ],
  },
  // { title: "The Vault", path: "/vault", icon: "vault.png" },
  // {
  //   title: "Game Wiki",
  //   path: config.gitbookUrl,
  //   icon: "docs.png",
  //   blank: true,
  // },
  // {
  //   title: "Buy $MAFIA",
  //   path: "/buy",
  //   icon: "buy.png",
  // },

  // {
  //   title: "The game",
  //   path: "/game",
  //   icon: "game.png",
  //   new: true,
  // },
  // {
  //   title: "Profiles",
  //   path: "/profiles",
  //   icon: "profiles.png",
  // },
];

export const shortCountryName = [
  [
    "Virgin Islands of the United States",
    "Saint Helena, Ascension and Tristan da Cunha",
  ],

  ["Virgin Islands", "Saint Helena"],
];

export const CashExchangeAmount = {
  0: [
    50000, 150000, 250000, 350000, 500000, 1000000, 2500000, 4000000, 5000000,
    10000000,
  ], // Cash
  1: [
    125000, 250000, 375000, 750000, 1750000, 2500000, 3500000, 7500000,
    10000000, 15000000,
  ], // Bullets
  2: [
    300000, 600000, 900000, 1200000, 1500000, 1800000, 2100000, 3000000,
    4500000, 6000000,
  ], // Health
  3: [
    22500, 67500, 108000, 157500, 225000, 450000, 1125000, 2250000, 3375000,
    3825000,
  ], // Shop Item
  5: [
    400000, 600000, 800000, 1200000, 1600000, 2400000, 4000000, 6000000,
    8000000, 10000000,
  ], // Bodyguards
  6: [
    75000, 187500, 375000, 750000, 1125000, 1500000, 1875000, 2250000, 2625000,
    3750000,
  ], // Helper credits
  7: [
    200000, 500000, 1000000, 1500000, 2000000, 2500000, 4500000, 5000000,
    5500000, 6000000,
  ], // Car
  12: [
    75000, 187500, 375000, 750000, 1125000, 1500000, 1875000, 2250000, 2625000,
    3750000,
  ], // Notification Credit
};

export const missingFlags: Record<string, string> = {
  Scotland:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASIAAACuCAMAAAClZfCTAAAAKlBMVEUAZb3///8AWLkAYrwAXbqEp9cAVLi5zOfk7PZCfsZFgMcAYLsAU7ewxuQ2gFSfAAAEa0lEQVR4nO2d0W4jIQxFZ5K0TZvm/393W7Va9wFhwAbfi3yeowgMBxsyTI5T5fm4HZtyezz1/h/6R87z5fUR3ZkZXF9fWnrfFKLz/nGJ7o8/l/d7U+fbQnSeb9fNbLs93hq73hqizWxrdKwzRDvZ1upYb4i2sa3dsf4Qfdt2je6glR7HRkJ03t/JbetybChEX7YxV5Kdjg2GiDi3dTs2HCLW3Nbv2HiIKHPbiGOWENHZNuaYLURcuW3QMWOIiGwbdswcIhLbDI45hIjBNotjHiGCryRtjvmE6Mu2T9h92/XT5thPiBoObzVgbTM79s3T52sgc5uDY7/Db13wf4DLbb7d8gk31r7NXY7dbJsx6F7TEiK3zVo6/Ba3aHyUKJZ7E796IXMX1g1sc6kVq11gt22JCMy2rRpg2kpy5TLBWUkunv58leT6YSWzLaa5TLZdPoImPUtuixxMp+k790xyfq1YB7+SBJjqAE2ogDGEwLkNZ0uJmtugijeoxvyCNnBwtsE16EAbNMRpDdUsrOH6C0gCQXRMQChDsAs1gAYiDJJG6JaIYtN4RC6W0VO4p6khxzO4eaxEQFLBzmMlVg8pTlHWwcqFgcsxYVklyeeYsKZIIcpjMc1nqBXrTK4kQbaFRmYupeSOze8Iv2PClJSzh2OCv22UtWId3y6x1op1HG1jrhXreC2vGzom+Fzc2flqk1claQb4gtzhZJsNhFqxjott4wA7JvgkpEEQ81iJMNtQ81iJENvgasU6AbaxOCYsto3JMWGhbWSOCcts43NMWGIbfq1YZ7ptFLVincm24ZwrWphoG2ceKzHJNto8VmKKbcx5rIS7bfs4JrjatpVjgqNtuzkmNP1hgs7GfymRIdJI0TRyuVbIpK+QpaNGbkAUchurkIchGnmkppAHswp5vK+RPxIp5E+NCvmDtUY+9qCQD88o5CNYGvkgn0I+DqqQDxVr5KPpCnnBQSGvyWjkZSuFvLKnkBc/NfL6sEJeQlfIVxlo5AsxFPK1Kgr5ch6NfMUTWKPpKsl83ZxGvrQQtqkkuS1foKoRPZDwlSRCA6MHqQrIhgk4t+GUJqC5DatZOMP1H7jJDdcgwEHDmtZQjfkDzMDBTWn/phlTLHQZglCoATRBIXgIKbZEoZtGbMeEsHSCmsdKhBxgAecxkObClBzNLJ70TI4JC5dOMsf8G67mNpY8VmJJGYdfK9aZPsAgmx4LkytJZseEicmG3TFh0lBv4JgwJSnz1Yp13G3jrBXruA46ba1Yx7FbuzkmuCWgjS/uOF1tOuzfAXz9y6WSNIcovFas42CbMUSwjglm22whgstjJay5zRIiyDxWwmbbeIgIHBMstg2HiMIxwWDbYIhoHBOGbRsKEdh+rJVB20ZCROaYMGZbf4jAa8U6I7b1hogqj5Xot60zRCDniha6besKEWEeK9FpW0eISPNYiS7b2kNEm8dK9NjWGqJNHBPabWsL0UaOCa22NYVoK8eERtsaQrTvHya0/aXEPzCYdzNZoaxhAAAAAElFTkSuQmCC",
};

export const AvailableSlotsColors = {
  Green: 200,
  Orange: 50,
  Red: 0,
};
