const configBsc = {
  mafiaAddress: "0x3cb3F4f43D4Be61AA92BB4EEFfe7142A13bf4111",
  pairAddress: "0xdE6e6378623C4F2c1102F2CcD35507d5bAf7924d",
  vaultAddress: "0xB88Aa2B2345eb37ab21Ed9359AF1c937ca6D07aF",
  vault1Address: "0xCABDd520EeDC6736F4C057Cba275673d74766A6b",
  profileTicketAddress: "0xa08D627E071cB4b53C6D0611d77dbCB659902AA4",
  // profileTicketAddress: "0x7B95Cbd82c7C250b4aE0752f2aF89729ACa7F329",

  mafiaFamilyAddress: "0x1bC581fe134BdC7432eF8ba75BCeEd242F90BcD2",
  familyBankAddress: "0xA7AB556Aac595A8425dDF584f3CA11bbD1772B8b",
  ogCrateAddress: "0x16B11C057cA6d354E81D58B375CB118f7930807c",
  crateMinterAddress: "0x1F4Eb51E87C4e2368316dba8e478Cd561FEb8B77",
  inventoryAddress: "0x2CB8352Be090846d4878Faa92825188D7bf50654",
  marketAddress: "0x1fb8C9F810afd99A6FAE3E81aBe0806f8796ba73",
  ogNFTAddress: "0xaf46bd44259b89f01B861C056C813228ADdfaD22",
  ogPremiumAddress: "0xA7AcE7F549BDE0b915EB06A6dAb3C9292cCa8B45",
  familyAllegianceAddress: "0x6fC9ba91179207764eDb537dD313C7cd3DAAaDEB",

  gamebankAddress: "0x376554F7BbcdeB348fa4b8371135B87eC6b29c38",
  exchangeAddress: "0x605694A29c5258D6c7Aed642D01111c4b7036966",
  depositAddress: "0xB081EC0763360a9Ad4D09AF2C9ec7DC1ED5190Ae",
  vaultSplitterAddress: "0x46f3F348a21BFEE36E1EacA57E91C08c733b73eD",

  mapAddress: "0x1c88060e4509c59b4064A7a9818f64AeC41ef19E",

  daoAddress: "0xD772D9f127b15d81c46582D49426d319044a541d",

  proposalAddress: "0x727405987580B9C44052f8F1f82Fa268C966Ba09",
  // rouletteAddress: "0x07E39F7C9E9BEf1E8483723cc7370F0C984Df7F1", //test contract
  rouletteAddress: "0x53e579dC9BE49B6Bac08c6F9ffA83D981A9A19F3",

  chainSymbolFull: "BNB",
  chainSymbol: "BNB",
  chainName: "Binance Smart Chain",
  chainId: 56,
  chainIdHex: 0x38,
  blockTime: 3000,

  providerList: [
    "https://bsc-dataseed2.bnbchain.org	",
    "https://bsc-dataseed2.defibit.io",
    "https://bsc-dataseed4.ninicoin.io",
  ],

  explorerUrl: "https://bscscan.com/",
  nativePriceUrl:
    "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd",
  mafiaPriceUrl: "https://api.dexscreener.com/latest/dex/pairs/bsc/",

  domain: "bnbmafia.io",
  mapDomain: "https://map.bnbmafia.io",
  title: "BnbMafia.io",
  gitbookUrl: "http://bnbmafia.gitbook.io/",

  // serverEndpoint: "https://0w0vnklj-3000.use.devtunnels.ms",
  serverEndpoint: "https://backend.bnbmafia.io",
  mockServerEndpoint: "http://localhost:5000",
  testServerEndpoint: "https://0w0vnklj-3000.use.devtunnels.ms",

  newFirebaseConfig: {
    apiKey: "AIzaSyArUN3H-Toru5EBdJGano9yrg5YvjOcY3Q",
    authDomain: "bnbmafia-4d1b3.firebaseapp.com",
    projectId: "bnbmafia-4d1b3",
    storageBucket: "bnbmafia-4d1b3.appspot.com",
    messagingSenderId: "4235046852",
    appId: "1:4235046852:web:bbbd2bb1b51fc82e4dbe91",
    measurementId: "G-34J5STVC8V",
  },

  storageBucket: "gs://bnbmafia-4d1b3.appspot.com",

  symbol: "$MAFIA",
};

export default configBsc;
