import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    padding: "0 16px",

    maxWidth: 1920,
    margin: "auto",
  },

  itemListing: {
    padding: "16px 32px",
    borderRadius: 16,
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.cardBgTransparent,

    [theme.breakpoints.down("sm")]: {
      padding: "12px 12px",
    },
  },

  listingHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
    lineHeight: "48px",

    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontSize: 20,
    fontWeight: 900,
    padding: "12px 0 24px 0",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },

  completedListingHeader: {
    justifyContent: "space-between",
  },

  listingPanel: {
    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    minHeight: 360,
    maxHeight: 360,
    borderRadius: 8,
    padding: 24,
    overflowY: "scroll",

    [theme.breakpoints.down("sm")]: {
      padding: "12px 12px",
    },
  },

  listingPanelHeader: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 1fr 1fr 2fr",
    color: theme.palette.text.secondary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    padding: "0 0 16px 0",

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      gap: 8,
    },
  },

  listingPanelItem: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 1fr 1fr 2fr",
    color: theme.palette.text.primary,
    fontFamily: "Philosopher",
    fontWeight: 900,
    padding: "8px 0",
    borderBottom: "1px solid",
    borderColor: theme.palette.background.defaultBorder,

    "& div": {
      gap: 16,
      display: "flex",
      alignItems: "center",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      gap: 8,

      "& div": {
        gap: 8,
      },
    },
  },

  completedPanelHeader: {
    gridTemplateColumns: "3fr 1fr 1fr 2fr 2fr!important",
    gap: 16,
    alignItems: "center",

    "& div:first-of-type": {
      width: "100%",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  completedPanelItem: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 1fr 2fr 2fr!important",
    justifyItems: "center",
    alignItems: "center",
    gap: 16,

    "& div:first-of-type": {
      width: "100%",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  listingType: {
    color: theme.palette.text.secondary,
  },

  listingItemIcon: {
    width: 24,
    height: 24,
    borderRadius: 4,
  },

  listingRemainTimeCritical: {
    color: "#794c32",
  },

  listingRemainTimeNormal: {
    color: "#578635",
  },

  buyer: {
    color: theme.palette.text.secondary,
    textAlign: "center",
    justifyContent: "center",
  },

  link: {
    textDecoration: "none",
    color: theme.palette.text.linkColor,
    display: "flex",
  },

  buyButton: {
    fontFamily: "Philosopher",
    fontWeight: "bold",
    fontSize: 12,
    padding: "6px 24px",
    background: theme.palette.background.defaultButton,
    color: theme.palette.text.primary,
    transition: "300ms",

    "&:hover": {
      background: theme.palette.background.defaultButton,
      filter: "brightness(1.2)",
    },
  },

  cancelButton: {
    fontFamily: "Philosopher",
    fontWeight: "bold",
    fontSize: 12,
    padding: "6px 24px",
    background: theme.palette.background.dangerButton,
    color: theme.palette.text.primary,
    transition: "300ms",

    "&:hover": {
      background: theme.palette.background.dangerButton,
      filter: "brightness(1.2)",
    },
  },

  bidButton: {
    fontFamily: "Philosopher",
    fontWeight: "bold",
    fontSize: 12,
    padding: "6px 24px",
    background: theme.palette.background.createButtonGradient,
    color: theme.palette.text.primary,
    transition: "300ms",

    "&:hover": {
      background: theme.palette.background.createButtonGradient,
      filter: "brightness(1.2)",
    },
  },

  viewBidsButton: {
    background: theme.palette.background.secondaryButton,

    "&:hover": {
      background: theme.palette.background.secondaryButton,
      filter: "brightness(1.2)",
    },
  },

  listingStatusNormal: {
    color: "#578635",
  },
}));

export default styles;
