import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    borderRadius: "16px",
    position: "relative",
    padding: "24px 36px",
    maxWidth: 920,
    boxSizing: "border-box",
    margin: "auto",
    fontFamily: "Philosopher",
    backgroundColor: theme.palette.background.cardBgTransparent,
    border: "1px solid",
    borderColor: theme.palette.background.defaultBorder,
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    [theme.breakpoints.down("sm")]: {
      width: 320,
      padding: "64px 16px 16px 16px",
    },
  },

  title: {
    fontSize: "20px",
    color: "white",
    fontFamily: "Philosopher",
    margin: 0,
  },

  buildingPanel: {
    display: "flex",
    gap: "8px",
    overflowX: "scroll",
    minHeight: "90px",
  },

  buildingBox: {
    borderRadius: "6px",
    minWidth: "70px",
    width: "70px",
    height: "70px",
    backgroundColor: "#1c1c1c",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    transition: "0.2s",
    position: "relative",
    boxShadow:
      "inset 0 -3px 6px 0px rgb(0 0 0 / 35%),inset 3px 0 6px 0px rgb(0 0 0 / 35%),inset 0 3px 6px 0px rgb(0 0 0 / 35%),inset -3px 0 6px 0px rgb(0 0 0 / 35%)",

    "&:hover": {
      transform: "scale(1.05)",
      transition: "0.2s",
    },
  },

  topBuildingPart: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "6px",
    borderRadius: "8px",
    justifyContent: "space-around",
    width: "95%",

    "div:nth-of-type(1)": {
      fontSize: "11px",
    },

    "div:nth-of-type(2)": {
      fontSize: "9px",
      opacity: ".4",
      marginTop: "1px",
    },

    "&>img": {
      width: "56px",
      height: "24px",
      marginTop: "2px",
    },
  },

  businessPart: {
    height: "100%",
  },

  buildingPart: {
    height: "calc(100%-18px)",
  },

  bottomBuildingPart: {
    color: "white",
    height: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    fontSize: "10px",
    width: "95%",
    position: "absolute",
    bottom: 0,
  },

  buildingName: {
    textAlign: "center",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default styles;
