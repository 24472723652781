import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  SelectChangeEvent,
  Skeleton,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import isMobile from "is-mobile";
import React, { useEffect, useMemo, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import CustomDropDown from "../CustomDropdown/CustomDropDown";
import CustomFormField from "../CustomInputField/CustomFormField";
import NativeIcon from "../NativeIcon";
import Logo from "../Logo";
import { storage } from "../../utils/firebase";

import { CustomTypeBackground, CustomTypeText } from "../../theme";

import config from "../../config/config";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import allegianceIcon from "../../assets/imgs/family/allegiance.png";

import { Messages } from "../../constants/messages";

import useStyles from "./index.styles";
import {
  getAllegianceDetailById,
  getFamilyDetailByName,
  setAllegianceDetail,
  signData,
  updateAllegianceLogo,
  updateAllegianceSharingAction,
} from "../../reducers/family.slice";
import useWallet from "../../hook/useWallet";
import { Errors } from "../../constants/errors";
import millify from "millify";
import useVaultBalance from "../../hook/useVaultBalance";
import { toastError, toastInfo, toastSuccess } from "../../utils/utils";
import { LOGO_HEIGHT, LOGO_WIDTH } from "../../constants/const";

const Input = styled("input")({
  display: "none",
});

interface ManageAllegianceProps {
  handleInviteAllegiance: (familyId: number, callback: () => void) => void;
  handleKickMember: (
    allegianceId: number,
    familyId: number,
    callback: () => void
  ) => void;
  handleExitAllegiance: (
    allegianceId: number,
    familyId: number,
    callback: () => void
  ) => void;
  setOpenManageModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ManageAllegiance: React.FC<ManageAllegianceProps> = ({
  handleInviteAllegiance,
  handleKickMember,
  handleExitAllegiance,
  setOpenManageModal,
}) => {
  const theme = useTheme<Theme>();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { account } = useWallet();
  const vaultBalance = useVaultBalance();
  const { myProfile } = useAppSelector((state) => state.profile);

  const { familyList, allegianceDetail, familyDetail } = useAppSelector(
    (state) => state.family
  );

  const [familyToInvite, setFamilyToInvite] = useState("");
  const [familySharing, setFamilySharing] = useState("");
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [logo, setLogo] = useState("");

  const background = theme.palette.background as CustomTypeBackground;
  const text = theme.palette.text as CustomTypeText;

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const { width, height } = img;
          if (width > LOGO_WIDTH || height > LOGO_HEIGHT) {
            toastInfo(Messages.FAMILY.BASIC.IMAGE_SIZE_INVALID, {
              width: LOGO_WIDTH.toString(),
              height: LOGO_HEIGHT.toString(),
            });
            return;
          } else {
            const storageRef = ref(
              storage,
              `custom/${familyDetail.familyInfo?.name}-allegiance-${file.name}`
            );
            const uploadTask = uploadBytesResumable(storageRef, file);
            setIsUploadingLogo(true);
            uploadTask.on(
              "state_changed",
              (snapshot) => {},
              (error) => {},
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((logoUrl) => {
                  console.log("File available at", logoUrl);
                  setIsUploadingLogo(false);
                  setLogo(logoUrl);
                });
              }
            );
          }
        };
        img.onerror = () => {
          toastError(Errors.FAMILY.VALIDATE.INVALID_IMAGE);
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleConfirmLogo = () => {
    const signMsg = {
      address: myProfile.address,
      familyId: myProfile.userFamily?.id,
      logo,
    };

    dispatch(signData({ signMsg }))
      .unwrap()
      .then((signature) => {
        dispatch(
          updateAllegianceLogo({
            signature,
            signMsg,
          })
        )
          .unwrap()
          .then(() => {
            toastSuccess(Messages.FAMILY.BASIC.ALLEGIANCE_LOGO_UPDATED);
            dispatch(
              getFamilyDetailByName(familyDetail.familyInfo?.name || "")
            );
          })
          .catch((error) => {
            toastError(Errors.FAMILY.ALLEGIANCE.UPDATE_ALLEGIANCE_LOGO_FAILED);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const invitableFamilyList = useMemo(() => {
    return [...familyList]
      .filter((family, index) => !family.allegiance)
      .map((family, index) => {
        return family.name || "";
      });
  }, [familyList]);

  const handleChangeInvitingFamily = (e: SelectChangeEvent) => {
    const familyName = e.target.value;
    const familyId = familyList.find(
      (familyInfo, index) => familyInfo.name === familyName
    )?.id;

    handleInviteAllegiance(familyId || 0, () => {
      toastSuccess(Messages.FAMILY.ALLEGIANCE.INVITE_SUCCESS);
      setFamilyToInvite(e.target.value);
    });
  };

  const kickMember = (familyId?: number) => {
    const allegianceId = allegianceDetail?.allegianceData?.allegianceId;
    if (!familyId || !allegianceId) return;

    handleKickMember(allegianceId, familyId, () => {
      toastSuccess(Messages.FAMILY.ALLEGIANCE.KICK_MEMBER_SUCCESS);

      dispatch(getAllegianceDetailById({ allegianceId }))
        .unwrap()
        .then((allegianceDetail: any) => {
          dispatch(setAllegianceDetail(allegianceDetail));
        })
        .catch((error) => {});
    });
  };

  const exitAllegiance = () => {
    const allegianceId = allegianceDetail?.allegianceData?.allegianceId;
    const familyId = myProfile.userFamily?.id;
    const creatorFamilyId = allegianceDetail.allegianceData?.creatorFamilyId;

    if (!familyId || !allegianceId) return;

    handleExitAllegiance(allegianceId, familyId, () => {
      toastSuccess(
        creatorFamilyId === familyId
          ? Messages.FAMILY.ALLEGIANCE.DISSOLVE_ALLEGIANCE_SUCCESS
          : Messages.FAMILY.ALLEGIANCE.KICK_MEMBER_SUCCESS
      );
      setOpenManageModal(false);
    });
  };

  const updateSharing = () => {
    const allegianceId = allegianceDetail?.allegianceData?.allegianceId;
    const familyId = myProfile.userFamily?.id;
    const address = myProfile.address;
    const newPercent = parseFloat(familySharing);
    if (!allegianceId) return;

    const signMsg = {
      address,
      familyId,
      newPercent,
    };

    dispatch(signData({ signMsg }))
      .unwrap()
      .then((signature) => {
        dispatch(
          updateAllegianceSharingAction({
            signature,
            signMsg,
          })
        )
          .unwrap()
          .then(() => {
            toastSuccess(Messages.FAMILY.ALLEGIANCE.UPDATE_ALLEGIANCE_SHARING);

            dispatch(getAllegianceDetailById({ allegianceId }))
              .unwrap()
              .then((allegianceDetail: any) => {
                dispatch(setAllegianceDetail(allegianceDetail));
              })
              .catch((error) => {});
          })
          .catch((error) => {
            toastError(
              Errors.FAMILY.ALLEGIANCE.UPDATE_ALLEGIANCE_SHARING_FAILED
            );
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const totalMembers = useMemo(() => {
    if (!allegianceDetail) return 0;
    return allegianceDetail.familyData.reduce(
      (sum, family) => sum + (family.members || 0),
      0
    );
  }, [allegianceDetail]);

  const isCreator = useMemo(() => {
    return (
      account.toLowerCase() ===
      allegianceDetail.allegianceData?.creator.toLowerCase()
    );
  }, [account, allegianceDetail.allegianceData?.creator]);

  useEffect(() => {
    const allegianceSharePercent = allegianceDetail.familyData.find(
      (family, index) => family.id === myProfile.userFamily?.id
    )?.allegianceSharePercent;

    setFamilySharing(
      allegianceSharePercent === 0
        ? ""
        : (allegianceSharePercent || "")?.toString()
    );
  }, [allegianceDetail, myProfile.userFamily?.id]);

  useEffect(() => {
    if (familyDetail) {
      setLogo(familyDetail.allegiance?.symbol || "");
    }
  }, [familyDetail]);

  return (
    <Box className={classes.contentBody}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box component="img" src={allegianceIcon} sx={{ height: 32, mb: 1 }} />
        <Typography
          variant={isMobile() ? "h5" : "h4"}
          align="center"
          sx={{
            fontWeight: 700,
            fontFamily: "Philosopher",
            color: text.primary,
          }}
        >
          {allegianceDetail?.allegianceData?.name}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          sx={{
            fontWeight: 700,
            marginBottom: 4,
            color: text.secondary,
            fontFamily: "Philosopher",
          }}
        >
          {totalMembers} members
        </Typography>
      </Box>

      <Box className={classes.allegianceList}>
        <Box className={classes.allegianceListHeader}>
          <Box>Family</Box>
          <Box>{isMobile() ? "Share" : "Vault Share"} </Box>
          <Box>Members</Box>
          <Box>{isMobile() ? "Payout" : "Vault Payout"} </Box>
          <Box></Box>
        </Box>

        {allegianceDetail?.familyData.map((family, index) => {
          return (
            <Box className={classes.allegianceListItem} key={index}>
              <Box className={classes.allegianceListFamily}>
                {family.logo ? (
                  <Box
                    component={"img"}
                    src={family.logo}
                    className={classes.allegianceIcon}
                  />
                ) : (
                  <Logo classNames={classes.allegianceIcon} />
                )}

                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    color: text.primary,
                    fontFamily: "Philosopher",
                  }}
                >
                  {family.name}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  color: text.secondary,
                  fontFamily: "Philosopher",
                }}
              >
                {family.allegianceSharePercent}%
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: text.secondary,
                  fontFamily: "Philosopher",
                }}
              >
                {family.members}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Philosopher",
                }}
              >
                <NativeIcon
                  sx={{
                    width: 12,
                  }}
                />{" "}
                {millify((family?.vaultPercentInfo?.value || 0) * vaultBalance)}{" "}
                {isMobile() ? "" : config.chainSymbol}
              </Typography>
              {isCreator &&
                family.id !==
                  allegianceDetail.allegianceData?.creatorFamilyId && (
                  <IconButton
                    onClick={() => {
                      kickMember(family.id);
                    }}
                  >
                    <CloseIcon
                      sx={{
                        color: theme.palette.error.main,
                      }}
                    />
                  </IconButton>
                )}
            </Box>
          );
        })}
      </Box>

      <Box className={classes.manageSection}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            marginBottom: 4,
            color: text.primary,
            fontFamily: "Philosopher",
            textAlign: "center",
          }}
        >
          Manage allegiance
        </Typography>

        <Box className={classes.uploadSection}>
          <Box className={classes.uploadPart}>
            <label htmlFor="logo-upload">
              <Input
                accept="image/*"
                id="logo-upload"
                type="file"
                onChange={handleLogoUpload}
              />
              <Button className={classes.uploadButton} component="span">
                <Box
                  component={"img"}
                  src="/assets/imgs/family/upload.png"
                ></Box>
                Upload
              </Button>
            </label>

            <Box className={classes.previewLogo}>
              {isUploadingLogo ? (
                <CircularProgress size={24} />
              ) : logo ? (
                <Box className={classes.logoPart}>
                  <Box
                    component="img"
                    src={logo}
                    sx={{ width: "100%", height: "100%" }}
                  ></Box>
                  <Button
                    sx={{
                      backgroundColor: background.lightGray,
                      textTransform: "none",
                      borderRadius: 2,
                      color: "white",
                      minWidth: "auto",
                    }}
                    startIcon={
                      <CheckIcon
                        sx={{
                          color: "#00d100 !important",
                        }}
                      />
                    }
                    onClick={handleConfirmLogo}
                  >
                    Confirm
                  </Button>
                </Box>
              ) : (
                <Skeleton className={classes.emptyLogo} variant="rectangular" />
              )}
            </Box>
          </Box>
          <Box className={classes.resolutionText}>
            Logo: 500 {"\u00D7"} 800px
          </Box>
        </Box>

        <Box className={classes.manageOptions}>
          {isCreator && (
            <Box className={classes.listOption}>
              <Typography
                sx={{
                  color: text.secondary,
                  fontFamily: "Philosopher",
                  textAlign: "center",
                  fontSize: isMobile() ? "12px" : "1rem",
                }}
              >
                Invite family:
              </Typography>

              <CustomDropDown
                placeholder="Select Family"
                sx={{
                  padding: "8px 8px",
                  backgroundColor: "#363a3b!important",
                  border: "1px solid #23272a",
                  width: 186,
                  boxSizing: "border-box",
                }}
                value={familyToInvite}
                items={invitableFamilyList}
                handleChange={handleChangeInvitingFamily}
              />
            </Box>
          )}

          <Box className={classes.listOption}>
            <Typography
              sx={{
                color: text.secondary,
                fontFamily: "Philosopher",
                textAlign: "center",
                fontSize: isMobile() ? "12px" : "1rem",
              }}
            >
              Vault Sharing:
            </Typography>

            <CustomFormField
              label=""
              value={familySharing}
              onChange={(e) => {
                setFamilySharing(e.target.value);
              }}
              placeholder="Input amount"
              icon={
                <CheckIcon
                  sx={{
                    color: "#00d100 !important",
                  }}
                />
              }
              classes={classes}
              text={text}
              sx={{
                marginBottom: "0px",
              }}
              onFinish={updateSharing}
            />
          </Box>
        </Box>

        <Button
          variant="contained"
          className={classes.dissolveButton}
          sx={{
            backgroundColor: background.lightGray,
            textTransform: "none",
            borderRadius: 4,
          }}
          startIcon={
            <CloseIcon
              sx={{
                color: theme.palette.error.main,
              }}
            />
          }
          onClick={exitAllegiance}
        >
          {isCreator ? "Dissolve Allegiance" : "Exit Allegiance"}
        </Button>
      </Box>
    </Box>
  );
};

export default ManageAllegiance;
