export const Messages = {
  GLOBAL: {
    TOKEN: {
      CLIPBOARD_SUCCESS: "Address copied to clipboard!",
    },
    ACCOUNT: {
      CREATED_SUCCESS: "Account created successfully.",
    },
    PROFILE: {
      SET_ROLE: "Set {profileName} as {role} successfully!",
      CREATE_SUCCESS: "Account created successfully",
      DESCRIPTION_EDIT_SUCCESS: "Updated profile description successfully.",
      INVENTORY_DISPLAY_SETTING_SUCCESS:
        "Updated profile inventory display setting successfully.",
      CLAIMED_KEY_SUCCESS: "Claimed key successfully",
    },
    CHAT: {
      CHAT_DISABLED: "Chat disabled.",
    },
  },
  FAMILY: {
    ALLEGIANCE: {
      INVITE_SUCCESS: "Invited to allegiance successfully.",
      REQUEST_SUCCESS: "Requested to join allegiance successfully.",
      INVITATION_EXPIRED: "Allegiance invitation expired.",
      REQUEST_EXPIRED: "Allegiance join request expired.",
      REGISTER_SUCCESS: "Registered allegiance successfully!",

      INVITE_ACCEPT_SUCCESS: "Joined allegiance successfully!",
      INVITE_REJECT_SUCCESS: "Rejected allegiance invitation!",

      REQUEST_ACCEPT_SUCCESS: "Accepted join allegiance request.",
      REQUEST_REJECT_SUCCESS: "Rejected join allegiance request.",

      KICK_MEMBER_SUCCESS: "Kicked a family successfully.",
      DISSOLVE_ALLEGIANCE_SUCCESS: "Dissolved allegiance successfully!",
      EXIT_ALLEGIANCE_SUCCESS: "Exited allegiance successfully!",

      UPDATE_ALLEGIANCE_SHARING: "Allegiance vault sharing percent updated.",
      INSUFFICIENT_NFT: "Insufficient NFT Key Balance.",
      BE_PREMIUM: "Family have to be Family+.",
    },
    CAPO_REGIME: {
      MEMBER_UPDATED: "Member capo regime updated.",
    },
    BASIC: {
      CREATED_SUCCESS: "Family created successfully!",

      LEAVED_SUCCESS: "Leaved family successfully!",
      KICKED: "Kicked {name} from family",
      REJECTED_KICK: "Rejected kick member confirmation",

      IMAGE_SIZE_INVALID:
        "The logo's size should be less than {width}\u00D7{height}px.",
      BACK_SIZE_INVALID:
        "The background's size should be less than {width}\u00D7{height}px.",
      TELEGRAM_INVALID:
        "Please ensure that Telegram account name is entered correctly. ex: @username123",
      DISCORD_INVALID:
        "Please ensure that the Discord username is entered correctly. Example: https://discord.gg/abc123",
      TWITTER_INVALID:
        "Please ensure that Twitter account name is entered correctly. ex: @username123",

      LEAVE_FEE_INVALID: "Input family leave fee correctly.",
      UPDATE_LEAVE_FEE_SUCCESS: "Updated family leave fee.",
      UPDATE_LEAVE_FEE_REJECTED: "Rejected update family leave fee",

      SHARING_UPDATED: "Family sharing updated.",
      BRANDING_UPDATED: "Family branding updated.",
      ALLEGIANCE_LOGO_UPDATED: "Alleginace logo updated.",
      PROMOTION_MONEY_UPDATED: "Family promotion money Updated",
    },
    INVITE: {
      JOINED_SUCCESS: "Joined family successfully!",
      REJECTED: "Rejected Invitation!",
      REJECTED_JOIN: "Rejected join family",
      INVITED: "Invited {name} to the family.",
    },
    STAKE: {
      STAKED_SUCCESS: "Staked to family bank successfully.",
      WITHDRAW_SUCCESS: "Successfully withdrew from the family bank.",
    },
    INVENTORY: {
      TRANSFERRED_SUCCESS: "Item transferred successfully!",
      TRANSFERRED_REJECTED: "Rejected transfer item",
      NOT_ENOUGH: "You've got only {crateBalance} NFT Keys",
      NFT_TRANSFERRED_SUCCESS: "NFT Key transferred.",
      NFT_TRANSFERRED_REJECT: "Rejected transfer NFT Key.",
      OG_NFT_TRANSFERRED_SUCCESS: "OG NFT transferred",
      OG_NFT_TRANSFER_REJECTED: "Rejected transfer OG NFT.",
      DEPOSITED_NFT_SUCCESS: "Deposited NFT Key into the game.",
      DEPOSIT_NFT_REJECTED: "Rejected deposit NFT Key.",
      DEPOSITED_OG_NFT_SUCCESS: "Deposited OG NFT into the game.",
      DEPOSIT_OG_NFT_REJECTED: "Rejected deposit OG NFT.",
      WITHDRAW_NFT: "Withdraw NFT Key.",
      WITHDRAW_NFT_REJECTED: "Rejected withdraw NFT Key.",
      WITHDRAW_OG_NFT: "Withdraw OG NFT",
      WITHDRAW_OG_NFT_REJECTED: "Rejected withdraw OG NFT",
      UNWRAP_GAMEKEY_ITEM_SUCCESS: "Unwrapped Game Key Item.",
      UNWRAP_GAMEKEY_ITEM_REJECTED: "Rejected unwrapping Game Key Item.",
      UNWRAP_MAFIA_ITEM_SUCCESS: "Unwrapped MAFIA token Item.",
      UNWRAP_MAFIA_ITEM_REJECTED: "Rejected unwrapping MAFIA token Item.",
      UNWRAP_OG_NFT_SUCCESS: "Unwrapped OG NFT Item.",
      UNWRAP_OG_NFT_REJECTED: "Rejected unwrapping OG NFT Item.",
      BOUGHT_PREMIUM: "Bought premium.",
      BUY_PREMIUM_REJECTED: "Rejected buying premium.",
      PURCHASED_SUCCESS: "Successfully purchased",
      PURCHASED_REJECT: "Rejected buy NFT Key.",
      NOT_PURCHASE: "No Game Key to use, please purchase first.",
      OPENED_CRATE: "Opened a crate using NFT Key",
    },
  },
  MARKET: {
    BASIC: {
      LISTED_SUCCESS: "Listed item successfully.",
      LIST_REJECTED: "Rejected list item.",

      PURCHASED_ITEM_SUCCESS: "Purchased item successfully!",
      PURCHASING_ITEM_REJECTED: "Rejected purchase item.",
      MAKE_BID_SUCCESS: "Made a bid successfully!",
      CANCELED_LISTING_SUCCESS: "Canceled listing successfully!",
      CANCEL_LISTING_REJECTED: "Rejected cancel listing item.",
      FINISH_AUCTION_SUCCESS: "Finished an auction successfully!",
    },
  },
  VAULT: {
    BASIC: {
      CONVERT_SUCCESS: "Converted $MAFIA to {chain} and sent to vault.",
    },
  },
  EXCHANGE: {
    BASIC: {
      ITEM_SELECT_LIMIT: "You can select up to 5 items",
    },
    CONVERT: {
      ITEM_CONVERTED: "Item converted successfully.",
    },
    OTC: {
      TRADE_CREATED: "OTC trade created successfully.",
      TRADE_SUCCESSFUL: "OTC Trade success!",
      TRADE_CANCELED: "OTC trade cancelled successfully.",
    },
    DEPOSIT: {
      ADDED_NEW_LP: "Added new liquidity successfully",
      CANCELED_SUCCEED: "Successfully done",
      WITHDRAWN_SUCCEED: "Withdrawn MAFIA successfully",
    },
    SWAP: {
      SWAP_SUCCEED: "Successfully swapped {cash} Cash",
      VAULT_SPLITTER_SWAP_SUCCEED:
        "Successfully swapped $ {usdAmount} into Cash",
    },
  },

  DAO: {
    VOTE: {
      VOTED_SUCCESS: "Voted successfully",
    },

    CREATE: {
      CREATED_SUCCESS: "Created proposal successfully",
    },

    OPEN: {
      OPENED_SUCCESS: "Opened proposal successfully",
    },

    CLOSE: {
      CLOSED_SUCCESS: "Closed proposal successfully",
    },
  },

  ROULETTE: {
    LIQUIDITY: {
      REMOVED_SUCCESS: "Removed liquidity successfully",
      ADDED_SUCCESS: "Added liquidity successfully",
    },
    UPDATE_SETTINGS: {
      UPDATED_SUCCESS: "Updated settings successfully",
      MIN_BET_ERROR: "Minimum bet should be in range between {minBet} and {maxBet}",
      MAX_BET_ERROR: "Maximum bet should be in range between {minBet} and {maxBet}",
    },

    INITIALIZE_BET: {
      CLOSE_ERROR: "This roulette table is currently closed.",
      MIN_BET_ERROR: "Minimum sum of bets should be greater than {minBet}",
      MAX_BET_ERROR: "Maximum sum of bets should be less than {maxBet}",
      INITIALIZED_FAILED: "Failed to initialize bet",
      INITIALIZED_SUCCESS: "Initialized bet successfully",
    },
  },
};

export type MessageType = typeof Messages;
