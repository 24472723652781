import { Box, Button, Tooltip } from "@mui/material";
import isMobile from "is-mobile";
import millify from "millify";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import EditNoteIcon from "@mui/icons-material/EditNote";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import Logo from "../Logo";
import NativeIcon from "../NativeIcon";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import { menLinksSmall, womenLinksSmall } from "../../constants/avatarLinks";
import {
  BULLET_AIRDROP_INTERVAL,
  City,
  FAMILY_BANK_YIELD_PERCENT,
  FAMILY_PREMIUM_THRESHOLD,
  PREMIUM_KEY_CLAIM_CYCLE,
} from "../../constants/const";
import { FamilyRole } from "../../constants/enum/enum";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import useCurrentTime from "../../hook/useCurrentTime";

import { isFamilyMember } from "../../helper/contractFunctions/MafiaFamily";
import { getLatestAirdropTimestamp } from "../../helper/contractFunctions/MafiaInventory";
import { dispatchTxAction } from "../../helper/dispatchTxAction";

import {
  approveFamilyAction,
  leaveFamilyAction,
} from "../../reducers/family.slice";
import {
  calculatePayout,
  calculatePayoutByPercent,
  convertDateTime,
  toastSuccess,
  toUSDFormat,
} from "../../utils/utils";

import bulletIcon from "../../assets/imgs/family/bullets.png";
import crownIcon from "../../assets/imgs/family/crown.png";
import telegramLogo from "../../assets/imgs/family/telegram.png";
import twitterLogo from "../../assets/imgs/family/twitter.png";
import vaultIcon from "../../assets/imgs/family/vault.png";
import discordLogo from "../../assets/imgs/family/white_discord.png";
import goldenPlus from "../../assets/imgs/profile/GoldenPlus.png";
import badgeIcon from "../../assets/imgs/profile/OGbadge.png";

import useStyles from "./index.styles";

interface FamilyDetailHeaderProps {
  vaultBalance: number;
  setOpenManageModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenStakingModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAllegianceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenEndStakingModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const FamilyDetailHeader: React.FC<FamilyDetailHeaderProps> = ({
  vaultBalance,
  setOpenManageModal,
  setOpenInviteModal,
  setOpenStakingModal,
  setOpenAllegianceModal,
  setOpenEndStakingModal,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { familyName } = useParams();

  const currentTime = useCurrentTime();
  const { familyDetail } = useAppSelector((state) => state.family);
  const { myProfile } = useAppSelector((state) => state.profile);

  const [isInFamily, setIsInFamily] = useState(false);
  const [latestAirdropTimestamp, setLatestAirdropTimestamp] = useState(
    new Date().getTime() / 1000
  );
  const [bankBalance, setBankBalance] = useState(0);

  const familyPremiumPercentage = useMemo(() => {
    return (
      (familyDetail.members.filter(
        (member, index) =>
          member.premium === 1 &&
          (member.premiumStart || 0) + PREMIUM_KEY_CLAIM_CYCLE * 3 > currentTime
      ).length /
        familyDetail.members.length) *
      100
    );
  }, [currentTime, familyDetail.members]);

  const handleLeaveFamily = async () => {
    const leaveFamily = (account: string, familyId: number) => {
      dispatchTxAction(
        dispatch,
        leaveFamilyAction({
          account,
          familyId,
        }),
        () => {
          toastSuccess(Messages.FAMILY.BASIC.LEAVED_SUCCESS);
          setTimeout(() => {
            navigate("/family");
          }, 2000);
        },
        0,
        Errors.FAMILY.VALIDATE.LEAVING_FAILED
      );
    };

    const approve = () => {
      if (!myProfile.address) return;

      dispatchTxAction(
        dispatch,
        approveFamilyAction({
          account: myProfile.address,
          approveAmount: Number(familyDetail.leaveFee),
        }),
        () => {
          if (!myProfile.address || !familyDetail.id) return;
          leaveFamily(myProfile.address, familyDetail.id);
        },
        0
      );
    };

    approve();
  };

  const renderTooltip = () => {
    return (
      <Box className={classes.vaultTooltip}>
        <Box>
          <Box component="img" src={vaultIcon}></Box>
          <Box>The vault</Box>
        </Box>

        <Box>
          Estimated amount of {config.chainSymbol} this user would receive
          weekly if the game was live
        </Box>
      </Box>
    );
  };

  const renderManageButton = () => {
    if (
      myProfile.name &&
      (myProfile.name === familyDetail.don?.name ||
        myProfile.name === familyDetail.capodecina?.name ||
        myProfile.name === familyDetail.consigliere?.name)
    ) {
      return (
        <Button
          onClick={() => {
            setOpenManageModal(true);
          }}
        >
          <EditNoteIcon color="primary"></EditNoteIcon>
          Manage Family
        </Button>
      );
    }
  };

  const renderInviteButton = () => {
    if (
      myProfile.name &&
      (myProfile.name === familyDetail.don?.name ||
        myProfile.name === familyDetail.capodecina?.name ||
        myProfile.name === familyDetail.consigliere?.name ||
        familyDetail.capoRegime.find(
          (capo, index) => capo.capoBoss === myProfile.name
        ))
    ) {
      return (
        <Button
          onClick={() => {
            setOpenInviteModal(true);
          }}
        >
          <PersonAddIcon color="primary"></PersonAddIcon>
          Invite Member
        </Button>
      );
    }
  };

  const renderStakeButton = () => {
    if (myProfile.name && isInFamily)
      return (
        <>
          <Button
            onClick={() => {
              setOpenStakingModal(true);
            }}
          >
            <LockOutlinedIcon color="primary"></LockOutlinedIcon>
            Stake Mafia
          </Button>
          <Button
            onClick={() => {
              setOpenEndStakingModal(true);
            }}
          >
            <LockOpenIcon color="primary"></LockOpenIcon>
            End Staking
          </Button>
        </>
      );
  };

  const renderLeaveButton = () => {
    if (myProfile.name && isInFamily)
      return (
        <Button onClick={handleLeaveFamily}>
          <ExitToAppIcon color="primary"></ExitToAppIcon>
          Leave Family
        </Button>
      );
  };

  const renderManageAllegianceButton = () => {
    if (myProfile.name && myProfile.name === familyDetail.don?.name) {
      return (
        <Button
          onClick={() => {
            setOpenAllegianceModal(true);
          }}
        >
          <EditNoteIcon color="primary"></EditNoteIcon>
          Manage Allegiance
        </Button>
      );
    }
  };

  useEffect(() => {
    const fetchIsInFamily = async () => {
      if (!myProfile.address) return;
      const inInFamily = await isFamilyMember(
        myProfile.address,
        familyDetail.id || 0
      );

      setIsInFamily(inInFamily);
    };

    fetchIsInFamily();
  }, [familyDetail.id, myProfile.address]);

  useEffect(() => {
    setBankBalance(familyDetail.bankInfo?.bank || 0);
    const intervalCount = 10;

    const intervalId = setInterval(() => {
      setBankBalance(
        (prevBalance) =>
          prevBalance +
          ((familyDetail.bankInfo?.bank || 0) * FAMILY_BANK_YIELD_PERCENT) /
            100 /
            86400 /
            intervalCount
      );
    }, 1000 / intervalCount);

    return () => {
      clearInterval(intervalId);
    };
  }, [familyDetail.bankInfo?.bank]);

  useEffect(() => {
    const fetchLatestAirdropTimestamp = async () => {
      const latestAirdropTimestamp = await getLatestAirdropTimestamp();
      setLatestAirdropTimestamp(latestAirdropTimestamp);
    };

    fetchLatestAirdropTimestamp();
  }, []);

  const familyDetailHeaderStyles = useMemo(
    () =>
      familyDetail.background
        ? isMobile()
          ? {
              background: `linear-gradient(#0d0f1100, #0d0f1100), url(${familyDetail.background}) no-repeat !important`,
            }
          : {
              background: `linear-gradient(#0d0f1100, #0d0f11b0), url(${familyDetail.background}) no-repeat !important`,
              backgroundSize: "100% auto!important",
              backgroundPosition: "bottom",
            }
        : {},
    [familyDetail.background]
  );

  return (
    <Box className={classes.container} sx={familyDetailHeaderStyles}>
      <Box className={classes.familyDetailHeader}>
        <Box className={classes.familyInfo}>
          <Box className={classes.familySymbol}>
            <Box>
              {familyDetail.logo ? (
                <Box component="img" src={familyDetail.logo}></Box>
              ) : (
                <>
                  <Logo />
                  <Box>No Family Logo</Box>
                </>
              )}

              {familyPremiumPercentage >= FAMILY_PREMIUM_THRESHOLD && (
                <Box
                  component="img"
                  src={badgeIcon}
                  className={classes.familyBadge}
                ></Box>
              )}
            </Box>

            <Box>
              {renderManageButton()}
              {renderInviteButton()}
              {renderStakeButton()}
              {renderLeaveButton()}
              {renderManageAllegianceButton()}
            </Box>
          </Box>
          <Box className={classes.familyDetail}>
            <Box className={classes.familyName}>
              {familyDetail.familyInfo?.name || familyName}

              {familyPremiumPercentage >= FAMILY_PREMIUM_THRESHOLD && (
                <Box
                  component="img"
                  src={goldenPlus}
                  className={classes.goldenPlus}
                ></Box>
              )}
            </Box>

            <Box className={classes.familyBossInfos}>
              <Box>
                <Box>Don:</Box>
                <Link to={`/profile/${familyDetail.don?.name}`}>
                  {familyDetail.don?.name}
                </Link>
              </Box>

              <Box>
                <Box>Consigliere:</Box>
                <Box>
                  <Link to={`/profile/${familyDetail.consigliere?.name}`}>
                    {familyDetail.consigliere?.name}
                  </Link>
                </Box>
              </Box>

              <Box>
                <Box>Capodecina:</Box>
                <Link to={`/profile/${familyDetail.capodecina?.name}`}>
                  {familyDetail.capodecina?.name}
                </Link>
              </Box>
            </Box>

            <Box className={classes.familyDetailNumbers}>
              <Box>
                <Box>Worth:</Box>
                <Box>
                  {toUSDFormat(familyDetail.familyInfo?.worth || 0)}
                  <Tooltip title="Family rank">
                    <span>{familyDetail.familyInfo?.rank}</span>
                  </Tooltip>
                </Box>
              </Box>
              <Box>
                <Box>City:</Box>
                <Box>{City[familyDetail.familyInfo?.city || 0]}</Box>
              </Box>
              <Box>
                <Box>Members:</Box>
                <Box>{familyDetail.familyInfo?.members}</Box>
              </Box>
              <Box>
                <Box>Family Bank:</Box>
                <Box sx={{ color: "#349121!important" }}>
                  ${toUSDFormat(bankBalance, 0)}
                </Box>
              </Box>
              <Box>
                <Box>Bullet:</Box>
                <Box>
                  <Box
                    component="img"
                    src={bulletIcon}
                    className={classes.familyBullet}
                  ></Box>
                  {toUSDFormat(familyDetail.familyInfo?.bullets || 0)}
                </Box>
              </Box>
              <Box>
                <Box>Liquid $MAFIA:</Box>
                <Box>{toUSDFormat(familyDetail.familyInfo?.mafia || 0)}</Box>
              </Box>
              <Box>
                <Box>Staked $MAFIA:</Box>
                <Box>{toUSDFormat(familyDetail.familyInfo?.stMafia || 0)}</Box>
              </Box>
              <Box>
                <Box>Socials:</Box>
                <Box className={classes.socialIcons}>
                  <Link
                    to={`https://x.com/${familyDetail.twitter}`}
                    target="_blank"
                  >
                    <Box component={"img"} src={twitterLogo} width={18} />
                  </Link>
                  <Link to={`${familyDetail.discord}`} target="_blank">
                    <Box component={"img"} src={discordLogo} width={18} />
                  </Link>
                  <Link
                    to={`https://t.me/${familyDetail.telegram?.slice(1)}`}
                    target="_blank"
                  >
                    <Box component={"img"} src={telegramLogo} width={18} />
                  </Link>
                </Box>
              </Box>

              <Box>
                <Box>Capo Payout:</Box>
                <Box>
                  {convertDateTime(
                    latestAirdropTimestamp === 0
                      ? 0
                      : latestAirdropTimestamp +
                          BULLET_AIRDROP_INTERVAL -
                          currentTime
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.familyBosses}>
          {familyDetail.capodecina ? (
            <Box>
              <Link to={`/profile/${familyDetail.capodecina?.name}`}>
                <Box
                  component="img"
                  src={
                    familyDetail.capodecina?.gender === 0
                      ? menLinksSmall[familyDetail.capodecina?.imageId || 0]
                      : womenLinksSmall[familyDetail.capodecina?.imageId || 0]
                  }
                ></Box>
              </Link>
              <Box>{familyDetail.capodecina?.name}</Box>

              <Tooltip title={renderTooltip()}>
                <Box className={classes.payoutInfo}>
                  {familyDetail.vaultPercentInfo?.rankIndex &&
                  familyDetail.vaultPercentInfo?.rankIndex <= 8 ? (
                    <>
                      <NativeIcon />
                      <Box>
                        {millify(
                          calculatePayout(
                            vaultBalance,
                            familyDetail.vaultPercentInfo?.rankIndex,
                            familyDetail.topPercent,
                            FamilyRole.Capodecina
                          ),
                          {
                            precision: 2,
                          }
                        )}{" "}
                        {config.chainSymbol}
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </Tooltip>
            </Box>
          ) : (
            <Box></Box>
          )}

          {familyDetail.consigliere ? (
            <Box>
              <Link to={`/profile/${familyDetail.consigliere?.name}`}>
                <Box
                  component="img"
                  src={
                    familyDetail.consigliere?.gender === 0
                      ? menLinksSmall[familyDetail.consigliere?.imageId || 0]
                      : womenLinksSmall[familyDetail.consigliere?.imageId || 0]
                  }
                ></Box>
              </Link>
              <Box>{familyDetail.consigliere?.name}</Box>

              <Tooltip title={renderTooltip()}>
                <Box className={classes.payoutInfo}>
                  {familyDetail.vaultPercentInfo?.rankIndex &&
                  familyDetail.vaultPercentInfo?.rankIndex <= 8 ? (
                    <>
                      <NativeIcon />
                      <Box>
                        {millify(
                          calculatePayout(
                            vaultBalance,
                            familyDetail.vaultPercentInfo?.rankIndex,
                            familyDetail.topPercent,
                            FamilyRole.Consigliere
                          ),
                          {
                            precision: 2,
                          }
                        )}{" "}
                        {config.chainSymbol}
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </Tooltip>
            </Box>
          ) : (
            <Box></Box>
          )}

          {familyDetail.don ? (
            <Box>
              <Link to={`/profile/${familyDetail.don?.name}`}>
                <Box
                  component="img"
                  src={
                    familyDetail.don?.gender === 0
                      ? menLinksSmall[familyDetail.don?.imageId || 0]
                      : womenLinksSmall[familyDetail.don?.imageId || 0]
                  }
                ></Box>
              </Link>
              <Box
                component="img"
                src={crownIcon}
                className={classes.crownIcon}
              ></Box>
              <Box>{familyDetail.don?.name}</Box>

              <Tooltip title={renderTooltip()}>
                <Box className={classes.payoutInfo}>
                  <Box>
                    {familyDetail.vaultPercentInfo?.rankIndex &&
                    familyDetail.vaultPercentInfo?.rankIndex <= 8 ? (
                      <>
                        <NativeIcon />
                        <Box>
                          {millify(
                            calculatePayout(
                              vaultBalance,
                              familyDetail.vaultPercentInfo?.rankIndex,
                              familyDetail.topPercent,
                              FamilyRole.Don
                            ),
                            {
                              precision: 2,
                            }
                          )}{" "}
                          {config.chainSymbol}
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Tooltip>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FamilyDetailHeader;
