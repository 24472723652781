import { Box, Typography } from "@mui/material";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import useWallet from "../../../hook/useWallet";
import { getBetHistoryAction } from "../../../reducers/roulette.slice";

import cashIcon from "../../../assets/imgs/exchange/money.png";

import useStyles from "./index.styles";
import { RouletteBetHistory } from "../../../types/RouletteBetHistory";
import { toUSDFormat } from "../../../utils/utils";

const RouletteHistoryItem = ({ item }: { item: RouletteBetHistory }) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.historyItem}>
      <Box className={classes.historyItemNonce}>
        <Typography>{item.nonce}</Typography>
      </Box>
      <Box>
        <Typography>Bet: {toUSDFormat(item.betAmount, 0)}</Typography>
      </Box>
      <Box>
        <Typography>
          {(item.rewardReceived / item.betAmount).toFixed(2)}x
        </Typography>
      </Box>
      <Box className={classes.historyItemAmount}>
        <Box
          component="img"
          src={cashIcon}
          className={classes.historyIcon}
        ></Box>
        <Typography
          color={item.rewardReceived >= item.betAmount ? "#3db338" : "red"}
        >
          {item.rewardReceived >= item.betAmount && "+"}
          {toUSDFormat(item.rewardReceived - item.betAmount, 0)}
        </Typography>
      </Box>
    </Box>
  );
};

const RouletteHistoryBoard = () => {
  const { account } = useWallet();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { betHistory } = useAppSelector((state) => state.roulette);
  console.log(betHistory);

  useEffect(() => {
    if (!account) return;

    dispatch(getBetHistoryAction({ account }));
  }, [account, dispatch]);

  return (
    <Box className={classes.historyList}>
      {betHistory.map((item, index) => (
        <RouletteHistoryItem key={index} item={item} />
      ))}
    </Box>
  );
};

export default RouletteHistoryBoard;
