import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    marginTop: -64,

    background: "url(/assets/imgs/roulette/roulette_bg.png) no-repeat",
    backgroundSize: "100% auto",
    // backgroundPosition: "center",
    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "top",
    },
  },

  rouletteBody: {
    maxWidth: 1440,
    margin: "auto",
    display: "flex",
    width: "100%",
    marginTop: 16,
    gap: 32,
    justifyContent: "space-between",
    padding: "0 16px",
    boxSizing: "border-box",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },

  rouletteBodyBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",

    "&:first-of-type": {
      flexGrow: 1,
    },
  },

  rouletteBodyBox2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: 16,
    width: "100%",
    boxSizing: "border-box",

    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
      gap: 32,
    },
  },

  rouletteBodyBoxInner: {
    display: "flex",
    width: "100%",
    padding: "0 0 16px 0",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: 48,
      padding: "8px 0",
    },
  },
}));

export default styles;
