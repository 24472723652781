import isMobile from "is-mobile";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config/config";

const MapGame = () => {
  const { city } = useParams(); // Extract the city parameter from the route
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const [iframeSrc, setIframeSrc] = React.useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Build the base iframe URL
    let iframeUrl = `${
      false ? "http://localhost:8080" : config.mapDomain
    }/?city=${city}`;

    // Append slotX and slotY if available
    const slotX = searchParams.get("slotX");
    const slotY = searchParams.get("slotY");
    if (slotX && slotY) {
      const queryParams = new URLSearchParams();
      if (slotX) queryParams.append("slotX", slotX);
      if (slotY) queryParams.append("slotY", slotY);
      iframeUrl += `&${queryParams.toString()}`;
    }

    setIframeSrc(iframeUrl);
  }, [city, searchParams]);

  useEffect(() => {
    const handleMessageFromIFrame = (event: MessageEvent) => {

      const { cityId } = event.data;
      if (cityId !== undefined && cityId !== null) {
        navigate(`/map/${cityId}`);
      }
    };

    window.addEventListener("message", handleMessageFromIFrame);

    return () => {
      window.removeEventListener("message", handleMessageFromIFrame);
    };
  }, [city, navigate]);

  return (
    <div style={{ width: "100%", height: "calc(100vh - 107px)" }}>
      <iframe
        src={iframeSrc}
        title="City Map"
        style={{
          width: "100%",
          height: isMobile() ? "calc(100% + 74px)" : "calc(100% + 60px)",
          border: "none",
          marginTop: "-64px",
        }}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default MapGame;
