import { CitySimple, ItemCategoryInfoList } from "../../constants/const";
import { ItemCategory } from "../../constants/enum/enum";

interface InventoryItemNameProps {
  categoryId: number;
  typeId: number;
  cityId: number;
  x: number;
  y: number;
}

const InventoryItemName = ({
  categoryId,
  typeId,
  cityId,
  x,
  y,
}: InventoryItemNameProps) => {
  const category = ItemCategoryInfoList[Math.floor(categoryId)];
  const itemName =
    categoryId === ItemCategory.LANDSLOT
      ? `${CitySimple[cityId]}-${x}-${y}`
      : typeof category.values[typeId] === "string"
      ? category.values[typeId]
      : `${category.name} ${category.values[typeId]}`;

  return <>{itemName}</>;
};

export default InventoryItemName;
