import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../config/config";
import { WorldInfo } from "../types/WorldInfo";
import InventorySlotInfo from "../types/InventorySlotInfo";

export interface MapState {
  worldMapInfo: WorldInfo[];
  isLoadingWorldMapInfo: boolean;

  ownedSlots: InventorySlotInfo[];
  loadingOwnedSlots: boolean;
}

const initialState: MapState = {
  worldMapInfo: [],
  isLoadingWorldMapInfo: false,

  ownedSlots: [],
  loadingOwnedSlots: false,
};

export const getWorldMapInfoAction = createAsyncThunk(
  "map/getWorldMapInfoAction",
  async () => {
    const response = await axios.get(`${SERVER_URL}/map/world`);
    return response.data;
  }
);

export const getOwnedSlots = createAsyncThunk(
  "map/getOwnedSlots",
  async ({ cityId }: { cityId: number }) => {
    const response = await axios.get(`${SERVER_URL}/map/owned/${cityId}`);
    return response.data;
  }
);

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWorldMapInfoAction.pending, (state) => {
      state.isLoadingWorldMapInfo = true;
    });
    builder.addCase(getWorldMapInfoAction.fulfilled, (state, { payload }) => {
      state.worldMapInfo = payload.map((item: any) => ({
        id: item.id,
        cityName: item.cityName,
        developer: item?.developer?.name || "",
        cityWorth: item.cityWorth,
        availableSlotsCount: item.availableSlotsCount,
        highestFamilyId: item.highestFamilyId,
        userCount: item.userCount,
        buildingCount: item.buildingCount,
        stMafia: item.stMafia,
      }));
      state.isLoadingWorldMapInfo = false;
    });
    builder.addCase(getWorldMapInfoAction.rejected, (state) => {
      state.isLoadingWorldMapInfo = false;
    });

    builder.addCase(getOwnedSlots.pending, (state, { payload }) => {
      state.loadingOwnedSlots = true;
    });
    builder.addCase(getOwnedSlots.fulfilled, (state, { payload }) => {
      state.ownedSlots = payload.mapData as InventorySlotInfo[];
      state.loadingOwnedSlots = false;
    });
    builder.addCase(getOwnedSlots.rejected, (state) => {
      state.loadingOwnedSlots = false;
    });
  },
});

export default mapSlice.reducer;
