import { Box } from "@mui/material";

const CircleSingal = ({
  width,
  height,
  color,
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  return (
    <Box
      width={width}
      height={height}
      borderRadius={"50%"}
      sx={{ backgroundColor: color }}
    ></Box>
  );
};

export default CircleSingal;
