import {
  Box,
  Button,
  Dialog,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { City, CitySimple, OTC_TRADE_MAX_ITEM } from "../../../constants/const";
import { ItemCategory } from "../../../constants/enum/enum";
import { Messages } from "../../../constants/messages";
import { ItemInfo } from "../../../types/ItemInfo";
import { toastInfo } from "../../../utils/utils";

import checkIcon from "../../../assets/imgs/exchange/check.png";

import useStyles from "./index.styles";
import CustomDropDown from "../../CustomDropdown/CustomDropDown";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getOwnedSlots } from "../../../reducers/map.slice";
import { BuildingTypeOptions } from "../../../constants/map";
import { BuildingType, SlotType } from "../../../constants/enum/map";
import InventorySlotInfo from "../../../types/InventorySlotInfo";

interface OTCSlotSelectionProps {
  openOTCSlotSelection: boolean;
  handleClose: () => void;
  handleAddItems: (items: ItemInfo[]) => void;
}

const OTCSlotSelection: React.FC<OTCSlotSelectionProps> = ({
  openOTCSlotSelection,
  handleClose,
  handleAddItems,
}: OTCSlotSelectionProps) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const [items, setItems] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [city, setCity] = useState("");

  const { ownedSlots, loadingOwnedSlots } = useAppSelector(
    (state) => state.map
  );

  const onSelectItem = (itemId: number) => {
    setItems((prev) =>
      prev.includes(itemId)
        ? prev.filter((prevItem) => prevItem !== itemId)
        : [...prev, itemId]
    );
  };

  const handleChangeCity = (e: SelectChangeEvent) => {
    setCity(e.target.value);
  };

  const onAddItems = () => {
    const selectedItems = items.map((itemId, index) => {
      const slotInfo = ownedSlots.find(
        (slot) => slot.inventoryItemId === itemId
      );

      return {
        categoryId: ItemCategory.LANDSLOT,
        typeId: 0,
        cityId: slotInfo?.city,
        itemId: itemId,
        slotX: slotInfo?.slotX,
        slotY: slotInfo?.slotY,
      } as ItemInfo;
    });

    if (selectedItems.length > OTC_TRADE_MAX_ITEM) {
      toastInfo(Messages.EXCHANGE.BASIC.ITEM_SELECT_LIMIT);
      return;
    }

    handleAddItems(selectedItems);
    handleClose();

    setItems([]);
  };

  const getSlotName = (slotInfo: InventorySlotInfo) => {
    if (slotInfo.slotType === SlotType.UpgradableSlot) {
      return `${
        BuildingTypeOptions[slotInfo.slotSubType as BuildingType].name
      } - #${CitySimple[slotInfo.city]}-${slotInfo.slotX}-${slotInfo.slotY}`;
    }
    return "";
  };

  useEffect(() => {
    if (!city) return;

    const cityId = City.findIndex((ci) => ci === city);
    dispatch(getOwnedSlots({ cityId }));
  }, [city, dispatch]);

  return (
    <Dialog
      open={openOTCSlotSelection}
      onClose={handleClose}
      className={classes.modalBody}
    >
      <Box className={classes.modalContent}>
        <Box className={classes.contentBody}>
          <Box className={classes.searchSelectBody}>
            <TextField
              variant="outlined"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              sx={{
                "& .MuiInputBase-input": {
                  padding: "8px 8px",
                },
                maxWidth: "160px",
              }}
            />

            <CustomDropDown
              sx={{
                padding: "8px 8px",
                backgroundColor: "transparent!important",
                border: "1px solid #23272a",
                maxWidth: "160px",
              }}
              value={city}
              items={City}
              placeholder="Select city"
              handleChange={handleChangeCity}
            />
          </Box>

          <Box
            sx={{
              height: "360px",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {loadingOwnedSlots ? (
              <CircularProgress />
            ) : (
              ownedSlots
                .filter((slotInfo) =>
                  getSlotName(slotInfo)
                    .toLowerCase()
                    .replace(/\s+/g, "")
                    .includes(searchTerm.toLowerCase().replace(/\s+/g, ""))
                )
                .sort((a, b) => {
                  if (a.slotSubType !== b.slotSubType) {
                    return b.slotSubType - a.slotSubType;
                  }
                  if (a.slotX !== b.slotX) {
                    return a.slotX - b.slotX;
                  }
                  return a.slotY - b.slotY;
                })
                .map((slotInfo, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        padding: "0 16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        transitionDuration: "300ms",
                        width: "90%",

                        "&:hover": {
                          cursor: "pointer",
                          borderRadius: "8px",
                          backgroundColor: "#ffffff0d",
                        },
                      }}
                      onClick={() => {
                        onSelectItem(slotInfo.inventoryItemId);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          padding: "8px",
                        }}
                      >
                        <Box
                          className={classes.slotItemIcon}
                          component="img"
                          src={`/assets/buildings/${
                            BuildingTypeOptions[
                              slotInfo.slotSubType as BuildingType
                            ].logo
                          }`}
                        ></Box>
                        <Box>{getSlotName(slotInfo)}</Box>
                      </Box>

                      {items.includes(slotInfo.inventoryItemId) && (
                        <Box
                          className={classes.inventoryItemIcon}
                          component="img"
                          src={checkIcon}
                        ></Box>
                      )}
                    </Box>
                  );
                })
            )}
          </Box>

          <Button className={classes.confirm} onClick={onAddItems}>
            Add Items
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default OTCSlotSelection;
