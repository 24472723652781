import { Box, Button, Dialog, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useAppSelector } from "../../app/hooks";
import { getRouletteNumberColor } from "../../helper/roulette";
import { toUSDFormat } from "../../utils/utils";

import cashIcon from "../../assets/imgs/exchange/money.png";

import useStyles from "./index.styles";

interface IRouletteResultPopupProps {
  handleClose: () => void;
  openPopup: boolean;
}

const RouletteResultPopup = ({
  handleClose,
  openPopup,
}: IRouletteResultPopupProps) => {
  const { betResult } = useAppSelector((state) => state.roulette);
  const { classes } = useStyles();

  const currentNonce = betResult ? betResult.nonce : 0;

  const isWon = betResult && betResult?.totalReward > 0;

  return (
    <Dialog open={openPopup} onClose={handleClose} className={classes.body}>
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        {betResult && (
          <Box className={classes.contentBody}>
            <Box
              className={classes.rouletteResultNumberBox}
              style={{ background: getRouletteNumberColor(currentNonce) }}
            >
              {currentNonce === 37 ? "00" : currentNonce}
            </Box>

            <Typography
              className={classes.rouletteResultTitle}
              gutterBottom
              align="center"
            >
              {isWon ? "Congratulations!" : "Unfortunately!"}
            </Typography>

            <Box className={classes.rouletteResultDescription}>
              {isWon ? "You won " : "You lost "}

              <Box
                component={"img"}
                src={cashIcon}
                sx={{
                  width: 20,
                  height: 20,
                }}
              />

              <span
                className={`result-badge ${
                  isWon ? "success-badge" : "fail-badge"
                }`}
              >
                {toUSDFormat(
                  (isWon ? betResult?.totalReward : betResult?.betAmount) || 0
                )}
              </span>

              <span style={{ fontSize: "15px", padding: "0px 10px" }}>
                {isWon
                  ? toUSDFormat(
                      betResult.totalReward / (betResult?.betAmount || 1)
                    )
                  : 0}
                x
              </span>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default RouletteResultPopup;
