import { Box, Tooltip } from "@mui/material";
import clsx from "clsx";
import millify from "millify";
import { Link } from "react-router-dom";

import InventoryItemIcon from "../InventoryItemIcon";

import { useAppSelector } from "../../app/hooks";
import { Market } from "../../constants/enum/market";
import { getInventoryItemName } from "../../helper/inventory";
import { toUSDFormat } from "../../utils/utils";

import { City, CitySimple, GAME_CASH_ADDRESS } from "../../constants/const";
import useStyles from "./index.styles";
import { BuildingTypeOptions, Rarity } from "../../constants/map";
import { BuildingType, RarityType, SlotType } from "../../constants/enum/map";
import { goToMapSlotPage } from "../../helper/map";

const CompletedListingItem = ({
  item,
  rarityType,
}: {
  item: any;
  rarityType: RarityType | null;
}) => {
  const { classes } = useStyles();
  const profileNames = useAppSelector((state) => state.profile.profileNames);
  const itemName = getInventoryItemName(
    item.categoryId,
    item.typeId,
    item.cityId
  );

  const buyerProfile = profileNames.find(
    (profile, index) =>
      profile.address?.toLowerCase() === item.listingInfo.buyer.toLowerCase()
  );

  return (
    <Box className={clsx(classes.listingPanelItem, classes.completedPanelItem)}>
      <Tooltip
        title={
          item.slotData !== null &&
          item.slotData.slotType === SlotType.UpgradableSlot
            ? `#${CitySimple[item.slotData?.cityId]}-${item.slotData?.slotX}-${
                item.slotData?.slotY
              }`
            : ""
        }
      >
        <Box
          sx={{
            fontSize: 14,
            whitespace: "nowrap",
            display: "flex",
            gap: "4px",
            cursor:
              item.slotData !== null &&
              item.slotData.slotType === SlotType.UpgradableSlot
                ? "pointer"
                : "auto",
          }}
          onClick={() =>
            item.slotData !== null &&
            item.slotData.slotType === SlotType.UpgradableSlot &&
            goToMapSlotPage(
              item.slotData.cityId,
              item.slotData.slotX,
              item.slotData.slotY
            )
          }
        >
          <InventoryItemIcon
            categoryId={item.categoryId}
            typeId={item.typeId}
            className={classes.listingItemIcon}
            bottomBorderColor={
              item.slotData !== null &&
              item.slotData.slotType === SlotType.UpgradableSlot
                ? Rarity[item.slotData.slotRarity as RarityType].color
                : ""
            }
            bottomBorderHeight={4}
            slotSubType={item.slotData?.slotSubType}
          />
          {item.slotData && item.slotData.slotType === SlotType.UpgradableSlot
            ? BuildingTypeOptions[item.slotData.slotSubType as BuildingType]
                .name
            : itemName}{" "}
          {item.slotData !== null &&
          item.slotData.slotType === SlotType.UpgradableSlot
            ? ` - ${City[item.slotData.cityId]}`
            : ""}
        </Box>
      </Tooltip>

      <Box>
        {item.listingInfo.listingType === Market.ListType.FIXED
          ? "Fixed"
          : "Auction"}
      </Box>

      {item.listingInfo.token === GAME_CASH_ADDRESS
        ? millify(item.listingInfo.currentPrice)
        : `$${toUSDFormat(item.listingInfo.currentPrice)}`}

      <Link
        to={`/profile/${buyerProfile?.name}`}
        target="_blank"
        className={classes.link}
      >
        <Box>{buyerProfile?.name}</Box>
      </Link>

      <Box>
        {item.listingInfo.status === 1 ? (
          <Box className={classes.listingRemainTimeNormal}>Completed</Box>
        ) : (
          <Box className={classes.listingRemainTimeCritical}>Expired</Box>
        )}
      </Box>
    </Box>
  );
};

export default CompletedListingItem;
