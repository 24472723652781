import { ethers } from "ethers";
import { getProvider, getWeb3, waitForConfirmation } from ".";

import MafiaRouletteAbi from "../../abis/MafiaRoulette.json";
import config from "../../config/config";
import { Roulette } from "../../types/Contract/Roulette/Roulette";
import { RouletteBet } from "../../types/Contract/Roulette/RouletteBet";
import { RouletteBetInfo } from "./../../types/Contract/Roulette/RouletteBetInfo";

export const getMafiaRouletteContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.rouletteAddress;
  const MafiaRouletteContract = new web3.eth.Contract(
    MafiaRouletteAbi,
    contractAddress
  );
  return MafiaRouletteContract;
};

export const depositLiquidity = async (
  account: string,
  rouletteId: number,
  amount: number
) => {
  const MafiaRouletteContract = getMafiaRouletteContract(true);
  const web3 = getWeb3();

  const data = await MafiaRouletteContract.methods
    .depositLiquidity(
      rouletteId,
      web3.utils.toWei(amount.toString(), "ether").toString()
    )
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const removeLiquidity = async (
  account: string,
  rouletteId: number,
  amount: number
) => {
  const MafiaRouletteContract = getMafiaRouletteContract(true);
  const web3 = getWeb3();

  const data = await MafiaRouletteContract.methods
    .removeLiquidity(
      rouletteId,
      web3.utils.toWei(amount.toString(), "ether").toString()
    )
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const updateRouletteSettings = async (
  account: string,
  rouletteId: number,
  isOpened: boolean,
  minBet: number,
  maxBet: number
) => {
  const web3 = getWeb3();
  const MafiaRouletteContract = getMafiaRouletteContract(true);

  const data = await MafiaRouletteContract.methods
    .updateRouletteSettings(
      rouletteId,
      isOpened,
      web3.utils.toWei(minBet.toString(), "ether").toString(),
      web3.utils.toWei(maxBet.toString(), "ether").toString()
    )
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const initializeBet = async (
  account: string,
  rouletteId: number,
  bets: RouletteBet[]
) => {
  const web3 = getWeb3();
  const MafiaRouletteContract = getMafiaRouletteContract(true);

  const data = await MafiaRouletteContract.methods
    .initializeBet(
      rouletteId,
      bets.map((bet) => {
        return {
          ...bet,
          amount: web3.utils.toWei(bet.amount.toString(), "ether").toString(),
        };
      })
    )
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const finishBet = async (account: string, rouletteId: number) => {
  const MafiaRouletteContract = getMafiaRouletteContract(true);

  const data = await MafiaRouletteContract.methods
    .finishBet(rouletteId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const getRouletteLiquidity = async (
  rouletteId: number,
  message: string,
  signature: string
) => {
  const MafiaRouletteContract = getMafiaRouletteContract(false);

  const liquidity: number = await MafiaRouletteContract.methods
    .getRouletteLiquidity(rouletteId, JSON.stringify(message), signature)
    .call();

  return parseInt(ethers.utils.formatUnits(liquidity, 18));
};

export const getRoulettes = async () => {
  const MafiaRouletteContract = getMafiaRouletteContract(false);

  const roulettes: any[] = await MafiaRouletteContract.methods
    .getRoulettes()
    .call();

  const parsedRoulettes: Roulette[] = roulettes.map((roulette: any) => {
    return {
      id: Number(roulette.id),
      isOpened: roulette.isOpened,
      minBet: parseInt(ethers.utils.formatUnits(roulette.minBet, 18)),
      maxBet: parseInt(ethers.utils.formatUnits(roulette.maxBet, 18)),
      minBetBottom: parseInt(ethers.utils.formatUnits(roulette.minBetBottom, 18)),
      minBetTop: parseInt(ethers.utils.formatUnits(roulette.minBetTop, 18)),
      maxBetBottom: parseInt(ethers.utils.formatUnits(roulette.maxBetBottom, 18)),
      maxBetTop: parseInt(ethers.utils.formatUnits(roulette.maxBetTop, 18)),
      ownedAt: Number(roulette.ownedAt),
      feesPaid: parseInt(ethers.utils.formatUnits(roulette.feesPaid, 18)),
      inventoryItemId: Number(roulette.inventoryItemId),
      profit: parseInt(ethers.utils.formatUnits(roulette.profit, 18)),
    } as Roulette;
  });

  return parsedRoulettes;
};

export const getUserBetInfo = async (account: string, rouletteId: number) => {
  const MafiaRouletteContract = getMafiaRouletteContract(false);

  const betInfo: RouletteBetInfo = await MafiaRouletteContract.methods
    .userBetInfo(account, rouletteId)
    .call({ from: account });

  return {
    isPending: betInfo.isPending,
    requestBlock: Number(betInfo.requestBlock),
    totalAmount: betInfo.totalAmount,
  } as RouletteBetInfo;
};