import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: "#171a1b",
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },

    "& .MuiDialog-paper": {
      maxWidth: 960,
    },
  },

  modalContent: {
    transition: "all 0.3s ease-in-out",
    position: "relative",
    minWidth: 480,
    maxWidth: "960px!important",

    [theme.breakpoints.down("sm")]: {
      minWidth: "320px",
    },
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: "32px 48px",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
    },
  },

  optionLists: {
    minHeight: 320,
    display: "flex",
    flexDirection: "column",
    gap: 8,
    alignItems: "center",
    width: "100%",
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },

  inputField: {
    width: "100%",
    "& input": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 14,
      color: theme.palette.text.primary,
      padding: "8px 12px",
    },

    "& .MuiInputBase-root": {
      color: theme.palette.text.primary,
      background: theme.palette.background.inputField,
      borderRadius: "8px",
      border: "1px solid #FFFFFF15", // Base border
      boxShadow: `0 0 0px ${theme.palette.primary.main}`, // Subtle glow effect
      transition: "all 0.3s ease-in-out",

      "&:hover": {
        outline: "none",
        background: theme.palette.background.inputField,
        border: `1px solid ${theme.palette.primary.light}`, // Highlight border on hover
      },

      "&:focus-within": {
        outline: "none",
        background: theme.palette.background.inputField,
        border: `1px solid ${theme.palette.primary.main}`, // Highlight border on focus
        boxShadow: `0 0 4px ${theme.palette.primary.main}`, // Subtle glow effect
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none", // Remove default Material-UI outline
    },
  },

  amountBox: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },

  editBox: {
    display: "grid",
    gap: "8px",
    gridTemplateColumns: "2fr 3fr",
    alignItems: "center",
  },

  modalTitle: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },

  addOption: {
    display: "flex",
    alignItems: "center",
    gap: 16,
  },

  addOptionButton: {
    padding: "8px",
    background: theme.palette.background.defaultButton,
    transition: "300ms",
    color: "#FFFFFF!important",

    "&:hover": {
      background: theme.palette.background.defaultButton,
      filter: "brightness(1.2)",
    },
  },

  addOptionText: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 14,
    color: theme.palette.text.primary,
  },

  editManageButton: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 14,
    padding: "6px 24px",
    background: theme.palette.background.defaultButton,
    transition: "300ms",
    color: "#FFFFFF!important",
    minWidth: "200px",

    "&:hover": {
      background: theme.palette.background.defaultButton,
      filter: "brightness(1.2)",
    },

    "&:disabled": {
      background: "#303536",
    },
  },

  createDaoButton: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 14,
    padding: "6px 24px",
    background: theme.palette.background.defaultButton,
    transition: "300ms",
    color: "#FFFFFF!important",

    "&:hover": {
      background: theme.palette.background.defaultButton,
      filter: "brightness(1.2)",
    },
  },

  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    width: "100%",
  },
  input: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "100%",
  },
  actionButton: {
    borderRadius: "50%",
    padding: "4px",
    color: "#fff",

    "&:hover": {
      background: "#FFFFFF40",
    },
  },
  addButton: {
    background: "green",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "10px",
  },
  submitButton: {
    background: "blue",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    width: "100%",
  },

  collapsedView: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: "#00000080",
    borderRadius: theme.shape.borderRadius * 2,
    cursor: "pointer",

    "& p": {
      fontFamily: "Philosopher",
      fontWeight: 900,
      fontSize: 16,
      color: "#ffffffaa",
    },

    "&:hover": {
      backgroundColor: "#000000AA",
      transition: ".5s",
    },
  },
  expandedView: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: "#00000080",
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(2),
  },

  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },

  doneButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    textTransform: "none", // Keeps the text as-is (not uppercase)
  },
  durationInput: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    width: "100%",
  },
  durationText: {
    fontFamily: "Philosopher",
    fontWeight: 900,
    fontSize: 16,
    color: theme.palette.text.primary,
  },
}));

export default styles;
