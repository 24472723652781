import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "./index.styles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useWallet from "../../hook/useWallet";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import {
  approveMafiaToVoteAction,
  getProposalsAction,
  getUserVoteAction,
  voteOnProposalAction,
} from "../../reducers/dao.slice";
import { toastError, toastSuccess } from "../../utils/utils";
import { Messages } from "../../constants/messages";
import { Errors } from "../../constants/errors";
import { useState } from "react";
import Logo from "../Logo";

interface VoteDaoPopupProps {
  openModal: boolean;
  handleClose: () => void;
}

const formatNumber = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const VotePopup = ({ openModal, handleClose }: VoteDaoPopupProps) => {
  const { classes } = useStyles();
  const { account, connectWallet } = useWallet();
  const dispatch = useAppDispatch();

  const { targetOption, completedIds } = useAppSelector((state) => state.dao);
  const { signature, signMsg } = useAppSelector((state) => state.auth);

  const [amount, setAmount] = useState("");

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, "");
    if (/^\d*$/.test(rawValue)) {
      // Ensure it's numeric
      setAmount(formatNumber(rawValue));
    }
  };

  const handleVote = () => {
    if (!account) {
      connectWallet();
      return;
    }

    if (targetOption.roundId === -1 || targetOption.optionId === -1) return;

    const amountNum = Number(amount.replace(/,/g, ""));

    if (!(amountNum > 0)) {
      toastError(Errors.DAO.VOTE.AMOUNT_FAILED);
      return;
    }

    dispatchTxAction(
      dispatch,
      approveMafiaToVoteAction({
        account,
        amount: amountNum,
      }),
      () => {
        dispatch(
          voteOnProposalAction({
            optionId: targetOption.optionId,
            account,
            id: targetOption.roundId,
            amount: amountNum,
          })
        )
          .unwrap()
          .then(() => {
            toastSuccess(Messages.DAO.VOTE.VOTED_SUCCESS);
            dispatch(getProposalsAction());
            dispatch(
              getUserVoteAction({ signature, signMsg, account, completedIds })
            );
            handleClose();
          })
          .catch((error) => {
            console.log(error);
            toastError(Errors.DAO.VOTE.VOTE_FAILED);
          });
      },
      3000
    );
  };

  return (
    <Dialog open={openModal} onClose={handleClose} className={classes.body}>
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Typography className={classes.modalTitle}>
            Vote Marketing DAO
          </Typography>

          <Box className={classes.amountBox}>
            <TextField
              placeholder="Amount"
              value={amount}
              onChange={handleChangeAmount}
              className={classes.inputField}
              type="text"
            />
            <Logo sx={{ width: "30px" }}></Logo>$MAFIA
          </Box>

          <Button className={classes.createDaoButton} onClick={handleVote}>
            Vote
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default VotePopup;
