import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FamilyRole } from "../../constants/enum/enum";
import { Messages } from "../../constants/messages";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import {
  getFamilyDetailByName,
  updateFamilyLeaderAction,
  updateFamilySuccessorAction,
} from "../../reducers/family.slice";
import { FamilyMember } from "../../types/FamilyMember";
import { toastSuccess } from "../../utils/utils";

import useStyles from "./index.styles";

interface LeaderDropdownProps {
  readonly sx?: React.CSSProperties;
  readonly members?: FamilyMember[];
  readonly value?: string;
  readonly disabled?: boolean;
  readonly role: string;
  readonly leaderIndex: number;
  readonly account: string;
}

export default function LeaderDropDown({
  sx,
  members,
  value,
  role,
  disabled = false,
  leaderIndex,
  account,
}: LeaderDropdownProps) {
  const { classes } = useStyles();
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const { familyDetail } = useAppSelector((state) => state.family);
  const [profile, setProfile] = useState(value);

  const handleChange = (event: SelectChangeEvent) => {
    const profileName = event.target.value;
    const profile = members?.find(
      (member, index) => member.name === profileName
    );

    if (profile) {
      confirm({
        description: `Do you wanna set ${profileName} as ${role}?`,
        dialogProps: {
          PaperProps: {
            sx: { backgroundColor: "#171a1b", color: "white" },
            className: classes.paper,
          },
        },
      })
        .then(() => {
          const familyId = familyDetail.id;
          const memberAddress = profile.address;
          if (!familyId || !memberAddress) return;

          if (role === FamilyRole[FamilyRole.Successor]) {
            dispatchTxAction(
              dispatch,
              updateFamilySuccessorAction({
                account,
                familyId,
                successor: memberAddress,
              }),
              () => {
                toastSuccess(Messages.GLOBAL.PROFILE.SET_ROLE, {
                  profileName,
                  role,
                });
                setProfile(event.target.value);
                dispatch(
                  getFamilyDetailByName(familyDetail.familyInfo?.name || "")
                );
              },
              1000
            );
          } else {
            dispatchTxAction(
              dispatch,
              updateFamilyLeaderAction({
                account,
                familyId,
                memberAddress,
                leaderIndex,
              }),
              () => {
                toastSuccess(Messages.GLOBAL.PROFILE.SET_ROLE, {
                  profileName,
                  role,
                });
                setProfile(event.target.value);
                dispatch(
                  getFamilyDetailByName(familyDetail.familyInfo?.name || "")
                );
              }
            );
          }
        })
        .catch(() => {});
    }
  };

  return (
    <FormControl
      className={classes.formControl}
      sx={{
        ...sx,
      }}
    >
      <Select
        disabled={disabled}
        value={profile}
        onChange={handleChange}
        displayEmpty
        IconComponent={ExpandMoreIcon}
        inputProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                display: "block",
                padding: 0,
                backgroundColor: "#282828",
                color: "white",
              },
            },
            children: {
              sx: {
                fontSize: "12px",
              },
            },
          },
        }}
      >
        {members?.map((member, index) => (
          <MenuItem
            key={index}
            value={member.name}
            className={classes.menuItem}
          >
            <Box className={classes.detail}>{member.name}</Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
