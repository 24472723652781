import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import config from "../../config/config";

import NativeIcon from "../NativeIcon";

import useDaoBalance from "../../hook/useDaoBalance";
import useNativePrice from "../../hook/useNativePrice";
import { toUSDFormat } from "../../utils/utils";

import useStyles from "./index.styles";

interface MarketingDaoHeaderProps {
  showCreateDaoModal: () => void;
}

const MarketingDaoHeader = ({
  showCreateDaoModal,
}: MarketingDaoHeaderProps) => {
  const { classes } = useStyles();

  const daoBalance = useDaoBalance();
  const nativePrice = useNativePrice();

  const isAdminWallet = true;

  return (
    <>
      <Box className={classes.header}>
        <Typography
          fontFamily={"Philosopher"}
          fontSize={20}
          color={"grey"}
          fontWeight={900}
        >
          MAFIA
        </Typography>
        <Typography
          fontFamily={"Philosopher"}
          fontSize={28}
          color={"white"}
          fontWeight={900}
        >
          Marketing DAO
        </Typography>
      </Box>

      <Box className={classes.tokenBalanceContainer}>
        <NativeIcon
          sx={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            backgroundColor: "black",
            padding: "8px",
          }}
        />
        <Typography
          fontFamily={"Philosopher"}
          fontSize={28}
          color={"white"}
          fontWeight={900}
        >
          {toUSDFormat(daoBalance)} {config.chainSymbol}
        </Typography>

        {isAdminWallet ? (
          <Button className={classes.tokenBalance} onClick={showCreateDaoModal}>
            $ {toUSDFormat(daoBalance * nativePrice)}
          </Button>
        ) : (
          <Box className={classes.tokenBalance}>
            $ {toUSDFormat(daoBalance * nativePrice)}
          </Box>
        )}

        <Box className={classes.viewExplorerBox}>
          <NativeIcon sx={{ width: "16px", height: "16px" }} />
          <Link
            to={`${config.explorerUrl}address/${config.daoAddress}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Typography
              fontFamily={"Philosopher"}
              fontSize={14}
              color={"grey"}
              fontWeight={900}
            >
              View on explorer
            </Typography>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default MarketingDaoHeader;
