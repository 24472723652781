import { RouletteBetData } from "../types/RouletteBetData";

export const DefaultRouletteInfo = {
  minBet: 0,
  maxBet: 0,
  profit: 0,
  liquidity: 0,
  owner: "",
  tokenAddress: "",
  tokenName: "",
  fee: 0,
  tokenId: 0,
  initialMaxBet: 0,
  price: 0,
};

export const RouletteNumbers = [
  0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 37, 5,
  24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26,
];

export const DefaultRouletteData: RouletteBetData[] = [
  { number: 3, color: "red", selected: false, bg: 0 },
  { number: 6, color: "black", selected: false, bg: 0 },
  { number: 9, color: "red", selected: false, bg: 0 },
  { number: 12, color: "red", selected: false, bg: 0 },
  { number: 15, color: "black", selected: false, bg: 0 },
  { number: 18, color: "red", selected: false, bg: 0 },
  { number: 21, color: "red", selected: false, bg: 0 },
  { number: 24, color: "black", selected: false, bg: 0 },
  { number: 27, color: "red", selected: false, bg: 0 },
  { number: 30, color: "red", selected: false, bg: 0 },
  { number: 33, color: "black", selected: false, bg: 0 },
  { number: 36, color: "red", selected: false, bg: 0 },
  { number: 2, color: "black", selected: false, bg: 0 },
  { number: 5, color: "red", selected: false, bg: 0 },
  { number: 8, color: "black", selected: false, bg: 0 },
  { number: 11, color: "black", selected: false, bg: 0 },
  { number: 14, color: "red", selected: false, bg: 0 },
  { number: 17, color: "black", selected: false, bg: 0 },
  { number: 20, color: "black", selected: false, bg: 0 },
  { number: 23, color: "red", selected: false, bg: 0 },
  { number: 26, color: "black", selected: false, bg: 0 },
  { number: 29, color: "black", selected: false, bg: 0 },
  { number: 32, color: "red", selected: false, bg: 0 },
  { number: 35, color: "black", selected: false, bg: 0 },
  { number: 1, color: "red", selected: false, bg: 0 },
  { number: 4, color: "black", selected: false, bg: 0 },
  { number: 7, color: "red", selected: false, bg: 0 },
  { number: 10, color: "black", selected: false, bg: 0 },
  { number: 13, color: "black", selected: false, bg: 0 },
  { number: 16, color: "red", selected: false, bg: 0 },
  { number: 19, color: "red", selected: false, bg: 0 },
  { number: 22, color: "black", selected: false, bg: 0 },
  { number: 25, color: "red", selected: false, bg: 0 },
  { number: 28, color: "black", selected: false, bg: 0 },
  { number: 31, color: "black", selected: false, bg: 0 },
  { number: 34, color: "red", selected: false, bg: 0 },
];

export const DefaultNumberBoardStatus = {
  ZERO: false,
  DZERO: false,
  S1: false,
  S2: false,
  S3: false,
  R1: false,
  R2: false,
  R3: false,
  H1: false,
  H2: false,
  RED: false,
  BLACK: false,
  ODD: false,
  EVEN: false,
};

export const RouletteInitialMinBet = 5000;
export const RouletteInitialMaxBet = 1000000;

export const RouletteWaitBlock = 2;
