import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../config/config";
import { VaultHistory } from "../types/Vault";

export interface VaultState {
  history: VaultHistory;
  loadingHistory: boolean;
}

const initialState: VaultState = {
  history: { tax: [], key: [], market: [], slot: [], timestamp: [] },
  loadingHistory: false,
};

export const getVaultHistory = createAsyncThunk(
  "vault/getVaultHistory",
  async () => {
    const res = await axios.get(`${SERVER_URL}/vault/history`);
    return res.data.result as VaultHistory;
  }
);

export const vaultSlice = createSlice({
  name: "vault",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVaultHistory.pending, (state) => {
      state.loadingHistory = true;
    });
    builder.addCase(getVaultHistory.fulfilled, (state, { payload }) => {
      state.loadingHistory = false;
      state.history = payload;
    });
    builder.addCase(getVaultHistory.rejected, (state, { error }) => {
      state.loadingHistory = false;
      console.log(error);
    });
  },
});

export default vaultSlice.reducer;
