import { Box, Typography } from "@mui/material";
import millify from "millify";
import { useMemo } from "react";

import { ItemCategoryInfoList } from "../../constants/const";
import { ItemCategoryInfo } from "../../types/ItemCategoryInfo";
import { toUSDFormat } from "../../utils/utils";

import { useAppSelector } from "../../app/hooks";
import config from "../../config/config";
import { ItemCategory } from "../../constants/enum/enum";
import useMafiaBalance from "../../hook/useInventoryBalance";

import useStyles from "./index.styles";

const CratePrizeListItem = ({
  category,
  stocks,
  inventoryBalance,
}: {
  category: ItemCategoryInfo;
  stocks: number;
  inventoryBalance: number;
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.prize}>
      <Box className={classes.prizeInfo}>
        <Box
          component="img"
          src={`/assets/imgs/inventory/${category.icon}`}
          sx={{
            width:
              category.id === ItemCategory.OGNFT
                ? "24px!important"
                : "32px!important",
            height:
              category.id === ItemCategory.OGNFT
                ? "24px!important"
                : "32px!important",
          }}
        ></Box>
        <Typography>{category.name}</Typography>
      </Box>

      <Box className={classes.prizeCard}>
        {category.values.map((value, index) => {
          return (
            <Box key={index}>
              {typeof value === "number"
                ? category.currency
                  ? `${category.currency} ${toUSDFormat(value)}`
                  : `${toUSDFormat(value)}`
                : value}
            </Box>
          );
        })}
      </Box>
      {category.id === ItemCategory.BUSINESS && (
        <Box className={classes.stock}>({stocks} Remaining)</Box>
      )}

      {category.id === ItemCategory.BUSINESS_EXTRA && (
        <Box className={classes.stock}>({stocks} Remaining)</Box>
      )}

      {category.id === ItemCategory.MAFIA && (
        <Box className={classes.stock}>
          ({millify(inventoryBalance || 0)} $MAFIA Remaining)
        </Box>
      )}
    </Box>
  );
};

const CrateOpeningPrizeList = ({
  businessAvailable,
  extraBusinessAvailable,
}: {
  businessAvailable: boolean;
  extraBusinessAvailable: boolean;
}) => {
  const { classes } = useStyles();

  const inventoryBalance = useMafiaBalance(config.inventoryAddress);
  const { inventoryItemStocks } = useAppSelector((state) => state.profile);

  const filteredItemCategoryList = useMemo(() => {
    let itemCategoryList = ItemCategoryInfoList.filter((category) => {
      return (
        category.id !== ItemCategory.KEY &&
        category.id !== ItemCategory.LANDSLOT
      );
    });

    if (!businessAvailable) {
      itemCategoryList = itemCategoryList.filter((category) => {
        return category.id !== ItemCategory.BUSINESS;
      });
    }

    if (!extraBusinessAvailable) {
      itemCategoryList = itemCategoryList.filter((category) => {
        return category.id !== ItemCategory.BUSINESS_EXTRA;
      });
    }

    return itemCategoryList;
  }, [businessAvailable, extraBusinessAvailable]);

  return (
    <Box className={classes.container}>
      <Box className={classes.prizeList}>
        {filteredItemCategoryList
          .slice(0, ItemCategory.CREDIT)
          .map((itemCategory, index) => {
            // Change this line
            return (
              <CratePrizeListItem
                category={itemCategory}
                stocks={inventoryItemStocks[itemCategory.id] || 0}
                key={index}
                inventoryBalance={inventoryBalance}
              />
            );
          })}

        {extraBusinessAvailable && (
          <CratePrizeListItem
            category={ItemCategoryInfoList[ItemCategory.BUSINESS_EXTRA]}
            stocks={inventoryItemStocks[ItemCategory.BUSINESS_EXTRA] || 0}
            inventoryBalance={inventoryBalance}
          />
        )}

        {filteredItemCategoryList
          .slice(ItemCategory.CREDIT, filteredItemCategoryList.length - 1)
          .map((itemCategory, index) => {
            // Change this line
            return (
              <CratePrizeListItem
                category={itemCategory}
                stocks={inventoryItemStocks[itemCategory.id] || 0}
                key={index}
                inventoryBalance={inventoryBalance}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default CrateOpeningPrizeList;
