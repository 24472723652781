import { Box } from "@mui/material";
import { Link } from "react-router-dom";

import { useAppSelector } from "../../app/hooks";

import businessIcon from "../../assets/imgs/family/map.png";

import { CitySimple } from "../../constants/const";
import {
  BuildingType,
  BusinessType,
  RarityType,
  SlotType,
} from "../../constants/enum/map";
import {
  BasicSlotWorth,
  BoostPercentage,
  BuildingTypeOptions,
  BusinessOptions,
  ConnectorsBoost,
  Rarity,
} from "../../constants/map";
import { goToMapSlotPage } from "../../helper/map";
import { FamilyDetail } from "../../types/FamilyDetails";
import InventorySlotInfo from "../../types/InventorySlotInfo";
import { toUSDFormat } from "../../utils/utils";

import CircleSingal from "../CircleSignal";

import useStyles from "./index.styles";

const FamilyDetailSlots = ({ family }: { family: FamilyDetail }) => {
  const { classes } = useStyles();

  const { familyMapData } = useAppSelector((state) => state.family);

  const getBuildingInfo = (item: InventorySlotInfo) => {
    const data =
      item.slotType === SlotType.UpgradableSlot
        ? BuildingTypeOptions[item.slotSubType as BuildingType]
        : SlotType.Business
        ? BusinessOptions[item.slotSubType as BusinessType]
        : null;

    return data;
  };

  const updatedSlotItems = () => {
    const basicSortedItems = [...familyMapData.familyOwnedTiles].sort(
      (a, b) => b.slotSubType - a.slotSubType
    );
    const sortedItems = [
      ...basicSortedItems.filter((item) => item.slotType === SlotType.Business),
      ...basicSortedItems.filter(
        (item) =>
          item.slotType === SlotType.UpgradableSlot &&
          item.slotRarity === RarityType.Strategic
      ),
      ...basicSortedItems.filter(
        (item) =>
          item.slotType === SlotType.UpgradableSlot &&
          item.slotRarity === RarityType.Ellite
      ),
      ...basicSortedItems.filter(
        (item) =>
          item.slotType === SlotType.UpgradableSlot &&
          item.slotRarity === RarityType.Upper
      ),
      ...basicSortedItems.filter(
        (item) =>
          item.slotType === SlotType.UpgradableSlot &&
          item.slotRarity === RarityType.Common
      ),
    ];
    return sortedItems;
  };

  const calculateWorth = (
    buildingType: BuildingType,
    connectors: number,
    rarity: RarityType
  ) => {
    const connectorBoost =
      connectors <= 8
        ? ConnectorsBoost[connectors]
        : ConnectorsBoost[ConnectorsBoost.length - 1];
    const totalPercent = 1 + BoostPercentage[rarity] + connectorBoost;

    return toUSDFormat(BasicSlotWorth[buildingType] * totalPercent);
  };

  const getHQConnectors = () => {
    if (family.familyInfo?.premium) {
      return Math.floor((family.hqSize - 12) / 3);
    } else {
      return Math.floor((family.hqSize - 5) / 3);
    }
  };

  return (
    <Box className={classes.businessCard}>
      <Box className={classes.businessCardHeader}>
        <Box className={classes.cardTitle}>
          <Box component="img" src={businessIcon}></Box>
          <Box>Family owned tiles</Box>
        </Box>
        <Box className={classes.ownedTitle}>
          {getHQConnectors()} HQ Connectors
        </Box>
      </Box>
      {familyMapData.familyOwnedTiles.length === 0 ? (
        <Box className={classes.businessEmpty}>
          <Box className={classes.detailsBody}>
            Family Controls no business objects
          </Box>
        </Box>
      ) : (
        <Box className={classes.businessContent}>
          <Box className={classes.detailHeader}>
            <Box>ID</Box>
            <Box>Type</Box>
            <Box>Owner</Box>
            <Box>Rarity</Box>
            <Box>Connectors</Box>
            <Box>HQ</Box>
            <Box>Defense</Box>
            <Box>Active</Box>
            <Box>Worth</Box>
          </Box>

          <Box className={classes.detailList}>
            {updatedSlotItems().map((tile, index) => {
              // const category = ItemCategoryInfoList[tile.type]
              return (
                <Box className={classes.detailListItem} key={index}>
                  <Box
                    className={classes.detailName}
                    onClick={() =>
                      goToMapSlotPage(tile.city, tile.slotX, tile.slotY)
                    }
                  >
                    #{CitySimple[tile.city]}-{tile.slotX}-
                    {tile.slotY}
                  </Box>
                  <Box className={classes.detailCity}>
                    <Box
                      component={"img"}
                      src={`/assets/buildings/${getBuildingInfo(tile)?.logo}`}
                    />
                    {getBuildingInfo(tile)?.name}
                  </Box>
                  <Box className={classes.detailOwner}>
                    <Link to={`/profile/${tile.User?.name}`}>
                      {tile.User?.name}
                    </Link>
                  </Box>
                  <Box className={classes.detailCity}>
                    <CircleSingal
                      width={8}
                      height={8}
                      color={Rarity[tile.slotRarity as RarityType].color}
                    />
                    {Rarity[tile.slotRarity as RarityType].name}
                  </Box>
                  <Box>{tile.connectors || 0}/8</Box>
                  <Box>
                    {tile.slotType === SlotType.FamilyHQ ? "Yes" : "No"}
                  </Box>
                  <Box className={classes.detailCity}>
                    {tile.defensePower || 0}
                  </Box>
                  <Box className={classes.detailCity}>
                    <CircleSingal
                      width={8}
                      height={8}
                      color={tile.isOperating ? "#45d923" : "#f03d3d"}
                    />
                    {tile.isOperating ? "Yes" : "No"}
                  </Box>
                  <Box className={classes.detailCity}>
                    {calculateWorth(
                      tile.slotSubType,
                      tile.connectors,
                      tile.slotRarity
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FamilyDetailSlots;
