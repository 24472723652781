import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../app/hooks";
import {
  IRouletteBet,
  removeBet,
  updateBet,
} from "../../../reducers/roulette.slice";
import { allowOnlyNumber, toUSDFormat } from "../../../utils/utils";

import cashIcon from "../../../assets/imgs/exchange/money.png";

import { RouletteBetStatus } from "../../../constants/enum/roulette";
import { RouletteBetInfo } from "../../../types/Contract/Roulette/RouletteBetInfo";

import useStyles from "./index.styles";

interface IRouletteBetBoardParam {
  finishBet: () => void;
  initializeBet: () => void;
  remainBlock: number;
  betDisabled: boolean;
  betInfo?: RouletteBetInfo;
  betStatus: RouletteBetStatus;
}

const RouletteBetBoardItem = ({
  bet,
  betDisabled,
}: {
  bet: IRouletteBet;
  betDisabled: boolean;
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const onChangeUSDValue = (e: any) => {
    dispatch(updateBet({ ...bet, value: Number(e.target.value) }));
  };

  const removeBetBoardItem = () => {
    dispatch(removeBet(bet));
  };

  return (
    <Box className={classes.betItem}>
      <Box className={classes.betItemInfo}>
        <Box className={classes.betItemNumber}>
          <Box
            sx={{
              backgroundColor: bet.backColor,
              width: "20px",
              height: "20px",
              padding: "4px",
              marginRight: "8px",
              borderRadius: "4px",
            }}
          >
            <Typography
              align="center"
              style={{ color: "white" }}
              fontSize={"14px!important"}
              padding={"0!important"}
            >
              {bet.typeText}
            </Typography>
          </Box>

          <Box className={classes.betItemText}>
            <Typography
              variant="body2"
              align="center"
              style={{ color: "white" }}
              fontSize={"14px!important"}
              padding={"0!important"}
            >
              {bet.text}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className={classes.betItemInfo}>
        <Box className={classes.betItemInput}>
          <TextField
            value={bet.value}
            placeholder="0"
            onKeyDown={allowOnlyNumber}
            inputProps={{ style: { color: "white" } }}
            onChange={(e) => onChangeUSDValue(e)}
            disabled={betDisabled}
          />
        </Box>
      </Box>

      <Box className={classes.betItemInfo}>
        <Box
          component={"img"}
          src={cashIcon}
          sx={{ width: 20, height: 20, marginRight: "8px", marginLeft: "8px" }}
        ></Box>
        <CloseIcon
          htmlColor="white"
          sx={{
            width: 10,
            height: 10,
          }}
        />
        <Typography
          variant="body2"
          align="center"
          style={{
            color: "white",
            marginLeft: "8px",
            fontWeight: "800",
          }}
          fontSize="14px!important"
          padding="0!important"
        >
          {bet.multi}
        </Typography>
      </Box>

      <Box className={classes.betItemInfo}>
        <IconButton
          className={classes.betItemClose}
          onClick={removeBetBoardItem}
          disabled={betDisabled}
        >
          <CloseIcon htmlColor="white" />
        </IconButton>
      </Box>
    </Box>
  );
};

const RouletteBetBoard = ({
  finishBet,
  initializeBet,
  betDisabled,
  betInfo,
  remainBlock,
  betStatus,
}: IRouletteBetBoardParam) => {
  const { classes } = useStyles();
  const { bets } = useAppSelector((state) => state.roulette);

  const totalBetAmout = bets.reduce((sum, bet) => (sum += bet.value), 0);
  const { roulettes, currentRouletteId } = useAppSelector(
    (state) => state.roulette
  );

  return (
    <Box className={classes.panel}>
      <Box className={classes.panelHeader}>
        <Typography>Your bets</Typography>
        <Box sx={{ display: "flex", alignItems: "center", color: "#ffffff55" }}>
          Total: {toUSDFormat(totalBetAmout, 0)}{" "}
          <Box
            component={"img"}
            src={cashIcon}
            sx={{
              width: 20,
              height: 20,
              marginRight: "8px",
              marginLeft: "8px",
            }}
          ></Box>
        </Box>
      </Box>
      <Box className={classes.panelBody}>
        {bets.length === 0 ? (
          <Box className={classes.betEmpty}>
            <Typography>Make your selection</Typography>
          </Box>
        ) : (
          <Box className={classes.betList}>
            {bets.map((bet, key) => {
              return (
                <RouletteBetBoardItem
                  key={key}
                  bet={bet}
                  betDisabled={betDisabled}
                />
              );
            })}
          </Box>
        )}
      </Box>

      {betStatus === RouletteBetStatus.REQUESTED ? (
        <Button
          className={classes.spinButton}
          onClick={finishBet}
          disabled={remainBlock > 0}
        >
          Spin now!
        </Button>
      ) : (
        <Button
          className={classes.spinButton}
          disabled={
            bets.length === 0 ||
            betDisabled ||
            !roulettes[currentRouletteId].isOpened
          }
          onClick={initializeBet}
        >
          {roulettes[currentRouletteId]?.isOpened ? (
            "Initialize Bet"
          ) : (
            <Box color="red">Table is closed</Box>
          )}
        </Button>
      )}
    </Box>
  );
};

export default RouletteBetBoard;
