import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, IconButton, Typography } from "@mui/material";

import Logo from "../Logo";

import voteIcon from "../../assets/imgs/dao/vote.png";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DaoProposalStatus } from "../../constants/enum/enum";

import useWallet from "../../hook/useWallet";
import { setManageRoundId } from "../../reducers/dao.slice";
import useStyles from "./index.styles";
import { convertDateTimeWithCountdown, toUSDFormat } from "../../utils/utils";

interface DaoRoundHeaderProps {
  roundNumber: number;
  userCount?: number;
  totalVotes?: number;
  timeLeft?: number;
  roundStatus: DaoProposalStatus;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  showManagePopup: () => void;
}

const DaoRoundHeader: React.FC<DaoRoundHeaderProps> = ({
  roundNumber,
  userCount,
  totalVotes,
  timeLeft,
  roundStatus,
  expanded,
  setExpanded,
  showManagePopup,
}) => {
  const { account } = useWallet();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const handleOpenManagePopup = () => {
    showManagePopup();
    dispatch(setManageRoundId(roundNumber));
  };

  const { isAdmin } = useAppSelector((state) => state.dao);

  return (
    <Box className={classes.daoRoundListItemHeader}>
      <Box className={classes.headerLeftPanel}>
        <Box className={classes.roundInfoPanel}>
          <Typography className={classes.daoRoundListItemRoundId}>
            ROUND {roundNumber + 1}
          </Typography>

          <Box className={classes.headerUserCount}>
            <PersonIcon sx={{ color: "rgb(99, 99, 99)" }} />
            <Typography
              fontFamily="Philosopher"
              fontSize={16}
              fontWeight={900}
              color="white"
            >
              {userCount}
            </Typography>
          </Box>

          <Box className={classes.headerMafia}>
            <Logo
              sx={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: "black",
              }}
            />
            <Typography
              fontFamily="Philosopher"
              fontSize={16}
              fontWeight={900}
              color="white"
            >
              {toUSDFormat(totalVotes, 0)} Voted
            </Typography>
          </Box>
        </Box>

        <Box className={classes.headerTimeLeft}>
          {timeLeft && timeLeft > 0 && (
            <>
              <Box
                component={"img"}
                src={voteIcon}
                sx={{ width: "24px" }}
              ></Box>
              <Typography
                fontFamily="Philosopher"
                fontSize={16}
                fontWeight={900}
                color="white"
              >
                {convertDateTimeWithCountdown(timeLeft || 0)}
              </Typography>
            </>
          )}
        </Box>
      </Box>

      <Box className={classes.headerStatus}>
        {account && isAdmin && (
          <Button
            className={classes.openButton}
            onClick={handleOpenManagePopup}
          >
            <Edit />
          </Button>
        )}

        <Typography
          fontFamily="Philosopher"
          fontSize={16}
          fontWeight={900}
          color="rgb(99, 99, 99)"
        >
          Round Status:
        </Typography>
        <Typography
          fontFamily="Philosopher"
          fontSize={16}
          fontWeight={900}
          color={
            roundStatus === DaoProposalStatus.Closed
              ? "rgb(66, 107, 35)"
              : "rgb(155, 123, 51)"
          }
        >
          {roundStatus === DaoProposalStatus.Closed
            ? "Completed"
            : roundStatus === DaoProposalStatus.Opened &&
              timeLeft &&
              timeLeft > 0
            ? "In Progress"
            : roundStatus === DaoProposalStatus.Opened &&
              timeLeft &&
              timeLeft <= 0
            ? "Finished"
            : roundStatus === DaoProposalStatus.Canceled
            ? "Canceled"
            : "Pending"}
        </Typography>

        <IconButton onClick={() => setExpanded(!expanded)}>
          <ExpandMoreIcon
            sx={{
              color: "white",
              transition: "transform 0.3s ease",
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DaoRoundHeader;
