import { Box, Button, Theme, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { CustomTypeText } from "../../theme";

import ConvertPanel from "../../components/Exchange/ConvertPanel";
import KeyPanel from "../../components/Exchange/KeyPanel";
import OTCDesk from "../../components/Exchange/OTC/OTCDesk";
import OTCPanel from "../../components/Exchange/OTC/OTCPanel";
import SwapPanel from "../../components/Exchange/SwapPanel";
import Withdraw from "../../components/Exchange/WithdrawPanel";

import minusIcon from "../../assets/imgs/exchange/minus.png";
import plusIcon from "../../assets/imgs/exchange/plus.png";
import convertIcon from "../../assets/imgs/exchange/recycle.png";
import tradeIcon from "../../assets/imgs/exchange/transfer.png";
import keyIcon from "../../assets/imgs/inventory/key.png";

import useStyles from "./index.styles";

const Exchange = ({ subpage }: { subpage: string }) => {
  const { classes } = useStyles();
  const theme = useTheme<Theme>();
  const text = theme.palette.text as CustomTypeText;

  const [activePanel, setActivePanel] = useState("deposit");

  const navigate = useNavigate()

  const handleCreateOTC = () => {
    navigate("/exchange/trade-create")
  };

  const handleBackToOTCDesk = () => {
    setActivePanel("otcTrade");
  };

  const renderPanel = () => {
    switch (activePanel) {
      case "deposit":
        return <SwapPanel setActivePanel={setActivePanel} />;
      case "withdraw":
        return <Withdraw />;
      case "convert":
        return <ConvertPanel />;
      case "otcTrade":
        return <OTCDesk handleCreateOTC={handleCreateOTC} />;
      case "otcCreate":
        return <OTCPanel handleBackToOTCDesk={handleBackToOTCDesk} />;
      case "keys":
        return <KeyPanel />;
      default:
        break;
    }
  };

  useEffect(() => {
    setActivePanel(subpage);

    return () => {
      setActivePanel("");
    };
  }, [subpage]);

  return (
    <Box>
      <Box className={classes.body}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Typography
              fontFamily={"Philosopher"}
              color={"white"}
              textAlign={"center"}
              fontSize={36}
              fontWeight={700}
            >
              The Exchange office
            </Typography>

            <Typography
              fontFamily={"Philosopher"}
              color={text.grayColor}
              textAlign={"center"}
              fontSize={16}
              fontWeight={700}
            >
              What can I help you with?
            </Typography>
          </Box>

          <Box className={classes.actions}>
            <Box>
              <Link to="/exchange/deposit">
                <Button
                  className={activePanel === "deposit" ? "active" : ""}
                  startIcon={
                    <Box
                      component="img"
                      src={plusIcon}
                      className={classes.buttonIcon}
                    />
                  }
                >
                  Deposit
                </Button>
              </Link>
            </Box>

            <Box>
              <Link to="/exchange/withdraw">
                <Button
                  className={activePanel === "withdraw" ? "active" : ""}
                  startIcon={
                    <Box
                      component="img"
                      src={minusIcon}
                      className={classes.buttonIcon}
                    />
                  }
                >
                  Withdraw
                </Button>
              </Link>
            </Box>

            <Link to="/exchange/convert">
              <Button
                className={activePanel === "convert" ? "active" : ""}
                startIcon={
                  <Box
                    component="img"
                    src={convertIcon}
                    className={classes.buttonIcon}
                  />
                }
              >
                Convert
              </Button>
            </Link>

            <Box>
              <Link to="/exchange/trade">
                <Button
                  className={
                    activePanel === "otcTrade" || activePanel === "otcCreate"
                      ? "active"
                      : ""
                  }
                  startIcon={
                    <Box
                      component="img"
                      src={tradeIcon}
                      className={classes.buttonIcon}
                    />
                  }
                >
                  OTC Trade
                </Button>
              </Link>
            </Box>

            <Link to="/exchange/keys">
              <Button
                className={activePanel === "keys" ? "active" : ""}
                startIcon={
                  <Box
                    component="img"
                    src={keyIcon}
                    className={classes.buttonIcon}
                  />
                }
                sx={{
                  width: "100%",
                }}
              >
                Keys
              </Button>
            </Link>
          </Box>

          {renderPanel()}
        </Box>
      </Box>
    </Box>
  );
};

export default Exchange;
