import { RouletteBetType } from "../constants/enum/roulette";
import { RouletteBet } from "../types/Contract/Roulette/RouletteBet";
import { RouletteBetResult } from "../types/RouletteBetResult";
import { IRouletteBet } from "./../reducers/roulette.slice";

export const generateBets = (bets: IRouletteBet[]) => {
  const betData = bets.map((bet) => {
    let newBet: RouletteBet = {
      amount: 0,
      betType: 0,
      number: 0,
    };

    if (
      Number(bet.type) ||
      bet.type === RouletteBetType.ZERO ||
      bet.type === RouletteBetType.DZERO
    ) {
      newBet = {
        amount: bet.value,
        betType: 5,
        number: bet.type === RouletteBetType.DZERO ? 37 : Number(bet.typeText),
      };
    } else if (
      bet.type === RouletteBetType.R1 ||
      bet.type === RouletteBetType.R2 ||
      bet.type === RouletteBetType.R3
    ) {
      newBet = {
        amount: bet.value,
        betType: 1,
        number: (4 - Number(bet.type[1])) % 3,
      };
    } else if (
      bet.type === RouletteBetType.S1 ||
      bet.type === RouletteBetType.S2 ||
      bet.type === RouletteBetType.S3
    ) {
      newBet = {
        amount: bet.value,
        betType: 2,
        number: Number(bet.type[1]) - 1,
      };
    } else if (
      bet.type === RouletteBetType.H1 ||
      bet.type === RouletteBetType.H2
    ) {
      newBet = {
        amount: bet.value,
        betType: 3,
        number: Number(bet.type[1]) - 1,
      };
    } else if (
      bet.type === RouletteBetType.BLACK ||
      bet.type === RouletteBetType.RED
    ) {
      newBet = {
        amount: bet.value,
        betType: 0,
        number: bet.type === RouletteBetType.BLACK ? 0 : 1,
      };
    } else if (
      bet.type === RouletteBetType.EVEN ||
      bet.type === RouletteBetType.ODD
    ) {
      newBet = {
        amount: bet.value,
        betType: 4,
        number: bet.type === RouletteBetType.EVEN ? 0 : 1,
      };
    }

    newBet.amount = bet.value;

    return newBet;
  });

  return betData;
};

export const getRouletteNumberColor = (n: number) => {
  if (n === 0 || n === 37) return "green";
  if (n <= 10 || (n >= 19 && n <= 28)) {
    return n % 2 === 0 ? "black" : "red";
  } else {
    return n % 2 === 1 ? "black" : "red";
  }
};

export const parseInitializedBetEvent = (data: any) => {
  return {
    rouletteId: data.rouletteId,
    player: data.player,
    amount: data.amount,
    timestamp: data.timestamp,
    block: data.block,
  };
};

export const parseFinishedBet = (data: any) => {
  return {
    rouletteId: Number(data.rouletteId),
    betId: Number(data.betId),
    player: data.player,
    nonce: Number(data.nonce),
    betAmount: Number(data.betAmount),
    totalReward: Number(data.totalReward),
    rewardReceived: Number(data.rewardReceived),
    feeAmount: Number(data.feeAmount),
    timestamp: Number(data.timestamp),
    block: Number(data.block),
  } as RouletteBetResult;
};
